@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;

@import (reference) "@common/prerequisites/prerequisites.less";

.title-header {
  .display-flex();

  &__badge-region {
    .flex(0 0 auto);
    .icon--badge {
      margin-right: 1rem;

      .screen-md-portrait-min({
        margin-right: 1.5rem;
      });
    }

    &:empty {
      .hidden;
    }
  }

  &__title-region {
    .display-flex();
    .flex-grow(1);
    .align-items(center);
    min-width: 0;

    // Needed so .text-ellipsis works in flex containers
    // https://css-tricks.com/flexbox-truncated-text/
    .basic-header-title, .subtext-header-title {
      min-width: 0;
    }

    h4 {
      word-break: break-word;
    }

    h4,
    p {
      margin: 0;
    }

    &:empty {
      .hidden;
    }
  }
}

.pretext-header__title {
  margin-bottom: 0;
  font-size: @ax-font-size-s;
}
