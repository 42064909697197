.event-item .learning-event-notification-item {
  .learning-event-notification-user {
    height: 4rem;
    padding-top: 0;
    width: 4rem;

    .learning-events-user-placeholder {
      background-image: url('@training/assets/images/icons_sprite_events.png');
      height: 4rem;
      width: 4rem;
    }
  }

  .learning-event-notification-info {
    margin-bottom: @ax-spacing-s;

    .ax-breakpoint-m({
      margin-bottom: 0;
    });

    .learning-event-removed {
      color: @ax-color-red-60;
    }

    .notification-info__caption {
      margin-bottom: @ax-spacing-s;

      .ax-breakpoint-m({
        margin-bottom: 0;
      });
    }

    .learning-event-notification-eventdate {
      .bullet-point {
        display: none;

        .ax-breakpoint-m({
          display: inline-block;
        });
      }

      .event-notification-location,
      .event-notification-date {
        display: block;
        word-wrap: break-word;

        .ax-breakpoint-m({
          display: inline-block;
        });
      }
    }
  }

  .learning-event-notification-date {
    white-space: nowrap;
    text-align: right;
  }

  .icon-angle_right {
    background-color: transparent;
    color: @primary-grey;
    font-size: inherit;
  }
}
