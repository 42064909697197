.clickable {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.focus {
  border: .1rem solid @black;
  background-color: @white;
  .box-shadow-black(inset 0, .1rem, .2rem, 0.1);
  .no-outline();
}

.no-outline {
  outline: 0;
}

:focus {
  .no-outline();
}

.no-pointer-events {
  pointer-events: none;
}

// Error handler
#header.error-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3.5rem;

  .logo {
    .parent-height();
  }
}

.error-body {
  position: absolute;
  top: 3.6rem;
  bottom: 0;
}

.error-body,
.scorm-error-body {
  left: 0;
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .sectiontitle {
    .no-padding();
    .nomargin();
    background-color: @white;

    h1 {
      position: static;
    }
  }

  .buttonbar,
  .message {
    text-align: left;
    margin: 0.8rem 0;
  }

  .buttonbar {
    margin: 1.6rem 0;

    .axon-button {
      min-width: 15.0rem;
      .nomargin();
      .inline-block();
    }
  }

  .error-container {
    position: relative;
    margin: 0;

    .details-title {
      font-size: 1.4rem;
      color: @primary-blue;
      cursor: pointer;
    }

    .error-message-wrapper {
      position: relative;
      clear: both;
      display: none;

      &.expanded {
        display: block;
      }

      pre {
        .border-radius(@ax-border-radius-m);
        display: block;
        padding: 1rem;
        margin: 0 0 1.0rem;
        font-size: @ax-font-size-body;
        line-height: 1.42857143;
        word-break: break-all;
        word-wrap: normal;
        color: #333333;
        background-color: #f5f5f5;
        border: .1rem solid #cccccc;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}

.ax-breakpoint-m({
  #header.error-header {
    z-index: 2;
    height: 5rem;

    .logo {
      background-position-x: 0;
      margin-left: 1.3rem;
      .inline-block();
    }
  }

  .error-body {
    top: 5.1rem;
  }

  .error-body,
  .scorm-error-body {
    .sectiontitle h1 {
      padding: 1.6rem;
      line-height: 4rem;
    }

    .buttonbar,
    .message {
      margin: 1.6rem 0;
    }

    .message {
      font-size: 1.4rem;
    }

    .error-container {
      margin: 0;
    }
  }
});
