@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.preview-unavailable, .preview-unavailable-video {
	background-color: @primary-bg;
	margin-bottom: 1rem;
	border: 0;
	width: 100%;
	height: auto;
	.border-radius(0);
}

.preview-unavailable {
	padding: 3rem 0;
}
