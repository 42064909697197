// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//  FLEX GRID USAGE
//  - Default - 12 mobile-first columns (s) with gutters (space between columns)
//  - Breakpoints - Optional classes to target tablets (m) or desktops (l)
//  - Size - Not specifying a column size will result in equal-width sizing
//  - Wrapping - 12 columns within a single row, extra columns will wrap onto the next line

//  EXAMPLE - Using ax-grid for default column structure
//    <div class="ax-grid">
//      <div class="ax-grid__col--12 ax-grid__col--m-4 ax-grid__col--l-3">s: 12 / m: 4 / l: 3</div>
//      <div class="ax-grid__col--12 ax-grid__col--m-8 ax-grid__col--l-9">s: 12 / m: 8 / l: 9</div>
//    </div>

//  EXAMPLE - Using ax-grid for custom flex grid structure
//  <div class="ax-grid ax-grid--no-gutter">
//    <div class="ax-grid__col--4">4 columns wide</div>
//    <div>Equal width</div>
//    <div class="ax-grid__col--auto-size">Adjust size based on content size</div>
//  </div>

//  GRID MODIFIERS
//    - .ax-grid--[optional: m-/l-]no-gutter - Remove spacing between columns
//    - .ax-grid--[optional: m-/l-]direction-[column/row] - Change main axis direction vertically / horizontally
//    - .ax-grid--[optional: m-/l-]justify-content-[start/center/end] - Align on main axis (vertical for columns, horizontal for rows)
//    - .ax-grid--[optional: m-/l-]align-items-[start/center/end/stretch] - Align on cross axis (horizontal for columns, vertical for rows)

//  COLUMN MODIFIERS
//    - .ax-grid__col[optional: --m/--l] - Use default equal width sizing (default behavior for mobile+)
//    - .ax-grid__col--[optional: m-/l-]auto-size - Automatically scale column size to reflect content size
//    - .ax-grid__col--[optional: m-/l-]offset-[#] - Increase left gutter by specified number of columns (row flow only)
//    - .ax-grid__col--[optional: m-/l-]align-self-[start/center/end/stretch] - Override align-items default (horizontal for columns, vertical for rows)
//    - .ax-grid__col--[optional: m-/l-]grow-[#] - Increase column size to take up available space, if necessary
//    - .ax-grid__col--[optional: m-/l-]order-[#] - Re-arrange column order depending on size
//    - NOTE: This can create negative UX effects for accessibility. While the order is changed visually, sequential
//      navigation is not changed. A user tabbing between items could find themselves jumping around your layout in a
//      very confusing way. Read more: https://tink.uk/flexbox-the-keyboard-navigation-disconnect/

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@import '_ax-grid-modifiers.less';
@import '_ax-grid-column-modifiers.less';

@_grid-column-count: 12;

// - - - - - - - - - - - - - - - - -
//   Mixins
// - - - - - - - - - - - - - - - - -
._LoopColumnRules(@column, @gutter: @ax-gutter-grid, @size-prefix: ~'') when (@column > 0) {
  // Setup individual column size classes based off required number of total columns

  // BASE SIZE PERCENTAGES
  ._create-column(@column, @gutter, @size-prefix);

  // COLUMN MODIFIERS
  ._create-column-offset(@column, @gutter, @size-prefix);
  ._create-column-order(@column, @size-prefix);
  ._create-column-grow(@column, @size-prefix);

  // Repeat until all required columns setup
  ._LoopColumnRules(@column - 1, @gutter, @size-prefix);
}

._create-column(@column, @gutter, @size-prefix) {
  @_size: percentage(@column / @_grid-column-count);
  @_size-minus-gutter: calc(@_size - @gutter);

  > .ax-grid__col--@{size-prefix}@{column} {
    .flex(0 0 auto);
    width: @_size-minus-gutter;
  }
}

._set_gutter(@value) {
  margin-left: @value / 2;
  margin-right: @value / 2;
}

._equal-width-col() {
  ._set_gutter(@ax-gutter-grid);
  .box-sizing();
  .flex(~"1 1 0px"); // Ensure CSS generator doesn't strip out `px` (needed for browser support)
  max-width: 100%;
  min-width: 0;
  width: 100%;
}

._set-equal-width-col(@size-prefix: ~'') when (@size-prefix = ~'') {
  ._equal-width-col();
}

._set-equal-width-col(@size-prefix: ~'') when not (@size-prefix = ~'') {
  > .ax-grid__col@{size-prefix} {
    ._equal-width-col();
  }
}

// - - - - - - - - - - - - - - - - -
//   Base flex grid
// - - - - - - - - - - - - - - - - -
.ax-grid {
  ._set_gutter(-@ax-gutter-grid);
  .display-flex();
  .flex-wrap();

  // Default equal flex sizing for all grid content
  > * {
    ._set-equal-width-col();
  }

  // Create rules
  ._LoopColumnRules(@_grid-column-count);

  .ax-breakpoint-m({
    ._set-equal-width-col(~'--@{ax-breakpoint-prefix-m}');
    ._LoopColumnRules(@_grid-column-count, @ax-gutter-grid, @ax-breakpoint-prefix-optional-m);
  });

  .ax-breakpoint-l({
    ._set-equal-width-col(~'--@{ax-breakpoint-prefix-l}');
    ._LoopColumnRules(@_grid-column-count, @ax-gutter-grid, @ax-breakpoint-prefix-optional-l);
  });
}
