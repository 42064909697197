.base-card--continue-training {
  min-height: 17.6rem;

  .base-card__progress {
    .screen-md-portrait-min({
      .flex-direction(column-reverse);
      margin-top: 1.6rem;
    });

    .progress-message {
      margin-left: 0;
    }
  }

  .base-card__wrapper--lower {
    .display-flex();
    .align-items(flex-end);
  }
}

.continue-training__item-region.swiper-slide {
  .display-flex();
  .align-items(stretch);
}
