@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.image-crop {
  .media-thumbnail-input {
    opacity: 0;
    height: 0;
  }

  .cropped-image-upload {
    .border-radius(@ax-border-radius-m);
    border: 0.1rem solid @ax-color-grey-30;
    padding: @ax-spacing-m;

    .adjust-photo-title {
      margin-bottom: @ax-spacing-s;
    }

    label {
      font-weight: @ax-font-weight-medium;
      margin-bottom: @ax-spacing-s;
      display: inline-block;
    }

    .adjust-photo-subTitle {
      margin-bottom: @ax-spacing-s; 
      color: @ax-color-grey-60;
    }

    .adjust-photo-change-button {
      margin-top: @ax-spacing-l;
    }

    .preview-wrap img {
      display: none;
      max-width: none;
    }

    .small-previews-wrap {
      position: relative;
    }

    .ax-button {
      &.apply {
        position: absolute;
        right: 0;
      }
    }

    .show-upload-button .media-thumbnail-inputs-wrap {
      opacity: 100;
      height: auto;
      overflow: visible;
      margin-bottom: @ax-spacing-s;
    }

    .preview-wrap {
      &.no-image {
        .thumbnail-preview,
        .thumbnail-preview-size {
          background-image: url('@common/assets/images/community_photos.png?_v1');
          background-repeat: no-repeat;
        }
      }
    }

    .media-thumbnail-region {
      max-width: 20rem;
      max-height: 20rem;
    }

    .adjust-photo-wrap {
      border-right: 0.1rem solid @accent-grey;
      min-height: 35rem;
      padding-right: @ax-spacing-m;
      margin-right: @ax-spacing-m;

      .adjust-photo label:not(.adjust-photo-title) {
        margin: 0;
        margin-left: @ax-spacing-xs;
      }
    }

    .adjust-photo-preview {
      &--no-image {
        text-align: center;
        color: @ax-color-grey-60;
        margin-top: @ax-spacing-xxl;
      }
    }

    .media-previews {
      min-width: 25rem;
    }

    .thumbnail-preview {
      .size(200px); // px required for image sprite background
      .border-radius(@ax-border-radius-m);
      margin-bottom: @ax-spacing-m;
      overflow: hidden;
      position: relative;
    }

    .thumbnail-preview-size {
      overflow: hidden;
      margin-right: @ax-spacing-xs;
      .border-radius(@ax-border-radius-m)
    }

    .thumbnail-preview-size-1 {
      .size(5rem);
      background-position: 0 -218px;
    }

    .thumbnail-preview-size-2 {
      .size(4rem);
      background-position: 0 -286px;
    }

    .media-thumbnail-wrap {
      .size(20rem, 27rem);
    }

    .thumbnail-wrap {
      display: inline-block;
      max-width: 36.5rem;
      max-height: 27rem;

      .icon-remove {
        position: absolute;
        display: block;
        top: -0.7rem;
        right: -0.9rem;
        height: 2.5rem;
        width: 2.5rem;
        font-size: 2rem;
        line-height: 2.5rem;
        text-align: center;
        cursor: pointer;
        background-color: @ax-color-red-60;
        color: @ax-color-white;
        .border-radius(@ax-border-radius-m);
      }

      .remove-wrap {
        z-index: 300;
        position: relative;
      }
    }

    .jcrop-holder {
      background-color: @white !important;
      
      .jcrop-keymgr {
        visibility: hidden;
      }
    }

    .media-thumbnail-wrap img{
      max-width: none;
    }

    .hidden-thumbnail {
      visibility: hidden;
    }

    .media-thumbnail {
      position: relative;
      text-align: center;
    }

    .media-thumbnail-inputs-wrap {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }

    .preview-wrap img {
      display: none;
    }

    .media-thumbnail .image-thumbnail,
    .media-thumbnail .video-thumbnail {
      display:block;
    }
  }
}
