.search__results .timeline-card, .post-container {
  max-width: @ax-page-width-timeline;

  .header {
    padding: @ax-spacing-m;

    .user-profile-icon {
      .round-avatar();
      .size(@ax-line-height-xl);
      line-height: @ax-line-height-xl;
    }

    .author-info-right {
      .display-flex();
      .align-items(center);
      .justify-content(space-between);
      font-size: @ax-font-size-xs;
      line-height: @ax-line-height-xs;

      .metadata-region {
        margin-left: @ax-spacing-s;
        overflow: hidden;
        .metadata-detail-item {
          display: block;
          &:first-child {
            font-weight: @ax-font-weight-bold;
          }
          span {
            max-width: 100%;
            .text-ellipsis();
            display: inline-block !important; // NOTE: The metadata-detail-item node has the .truncate-2 class, which sets display to '-webkit-box !important' - so we need to override the important flag
          }
        }

        .community-date-info {
          color: @ax-color-grey-60;
          line-height: 1.4rem;

          a,
          p {
            font-size: @ax-font-size-xs;
            line-height: 2rem;
            display: block;
            margin: 0;
          }

          .bullet {
            display: none;
            margin-right: @ax-spacing-xs;
          }

          a {
            margin-right: @ax-spacing-xs;
            color: @ax-color-grey-60;
            max-width: 15rem;
            .text-ellipsis;

            &:hover {
              text-decoration: underline;
            }

            &.focus-visible:focus {
              .ax-focus-keyboard();
            }

            .ax-breakpoint-m({
              max-width: 100%;
            });
          }

          .ax-breakpoint-m({
            a,
            p,
            .bullet {
              display: inline;
            }
          })
        }
      }

      .action-menu-region {
        align-self: start;
        position: relative;

        // overriding some of the default styling of this component
        .menu-dropdown__toggle-button {
          border: none;
          background: none;
        }

        button {
          min-height: @ax-spacing-xl;
        }
      }
    }
  }

  .reactions-region .reaction-view-container {
    padding: @ax-spacing-s @ax-spacing-m;

    .reaction-buttons {
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  .content-region {
    margin: 0 @ax-spacing-m;
  }
}

.search__results .timeline-card {
  overflow: visible;

  .base-card__view-all {
    margin: @ax-spacing-s;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
