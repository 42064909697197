// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//  GRID MODIFIERS
//  See documentation in ax-grid.less

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// - - - - - - - - - - - - - - - - -
//   NO GUTTER
// - - - - - - - - - - - - - - - - -
// Remove gutter from sides of grid and between columns
._NoGutterStyles() {
  // Resize col sizes not using gutters
  ._set_gutter(0);

  > * {
    ._LoopColSizes(@_grid-column-count, @size-prefix);
    ._set_gutter(0);
  }
}

._NoGutterRuleDefault(@size-prefix: ~'') {
  // Use default selector only
  .ax-grid.ax-grid--no-gutter {
    ._NoGutterStyles();
  }
}

._NoGutterRuleBreakpoint(@size-prefix) {
  // Use default AND size prefixed selector
  // Ensures col size is set whether --no-gutter is being used for a specific size or all sizes
  .ax-grid.ax-grid--no-gutter,
  .ax-grid.ax-grid--@{size-prefix}no-gutter {
    ._NoGutterStyles();
  }
}

._LoopColSizes(@column, @size-prefix: ~'') when (@column > 0) {
  &.ax-grid__col--@{size-prefix}@{column} {
    width: percentage(@column / @_grid-column-count);
  }

  // Repeat until all required cols setup
  ._LoopColSizes(@column - 1, @size-prefix);
}

// Create rules
._NoGutterRuleDefault();

.ax-breakpoint-m({
  ._NoGutterRuleBreakpoint(@ax-breakpoint-prefix-optional-m);
});

.ax-breakpoint-l({
  ._NoGutterRuleBreakpoint(@ax-breakpoint-prefix-optional-l);
});

// - - - - - - - - - - - - - - - - -
//  DIRECTION COLUMN
// - - - - - - - - - - - - - - - - -
// Stack all cols vertically
._LoopColHeights(@column, @size-prefix: ~'') when (@column > 0) {
  &.ax-grid__col--@{size-prefix}@{column} {
    .flex-basis(auto);
    width: auto;
    height: percentage(@column / @_grid-column-count);
  }

  // Repeat until all required cols setup
  ._LoopColHeights(@column - 1, @size-prefix);
}

._DirectionColumnRule(@size-prefix: ~'') {
  // Setup rule for changing grid direction from row (default) to column
  // Also remove any spacing that was added for default row direction
  .ax-grid.ax-grid--@{size-prefix}direction-column {
    ._set_gutter(0);
    .flex-flow(column nowrap);

    > * {
      // Replace default col width with height
      ._set_gutter(0);
      .flex(1 1 auto);
      width: auto;

      ._LoopColHeights(@_grid-column-count, @size-prefix);
    }
  }
}

// Create rules
._DirectionColumnRule();

.ax-breakpoint-m({
  ._DirectionColumnRule(@ax-breakpoint-prefix-optional-m);
});

.ax-breakpoint-l({
  ._DirectionColumnRule(@ax-breakpoint-prefix-optional-l);
});

// - - - - - - - - - - - - - - - - -
//  DIRECTION ROW (Use to override direction after setting default flow to column)
// - - - - - - - - - - - - - - - - -
// Lay out columns horizontally
._DirectionRowClass(@size-prefix: ~'') {
  .ax-grid.ax-grid--@{size-prefix}direction-row {
    .flex-flow(row wrap);
  }
}

// Create rules
._DirectionRowClass();

.ax-breakpoint-m({
  ._DirectionRowClass(@ax-breakpoint-prefix-optional-m);
});

.ax-breakpoint-l({
  ._DirectionRowClass(@ax-breakpoint-prefix-optional-l);
});

// - - - - - - - - - - - - - - - - -
//  JUSTIFY CONTENT
// - - - - - - - - - - - - - - - - -
// Defines alignment along the main axis:
// Align all row cols vertically / Align all column cols horizontally
._JustifyContentRule(@size-prefix: ~'') {
  // Setup rules for each justify content option
  ._JustifyContentClass(~'start', ~'flex-start', @size-prefix);
  ._JustifyContentClass(~'center', ~'center', @size-prefix);
  ._JustifyContentClass(~'end', ~'flex-end', @size-prefix);
  ._JustifyContentClass(~'space-between', ~'space-between', @size-prefix);
}

._JustifyContentClass(@class, @value, @size-prefix: ~'') {
  // Setup selector and property for each justify content option
  .ax-grid.ax-grid--@{size-prefix}justify-content-@{class} {
    .justify-content(@value);

    > * {
      .flex-basis(~"0px"); // Ensure CSS generator doesn't strip out `px` (needed for browser support)
    }
  }
}

// Create rules
._JustifyContentRule();

.ax-breakpoint-m({
  ._JustifyContentRule(@ax-breakpoint-prefix-optional-m);
});

.ax-breakpoint-l({
  ._JustifyContentRule(@ax-breakpoint-prefix-optional-l);
});

// - - - - - - - - - - - - - - - - -
//  ALIGN ITEMS
// - - - - - - - - - - - - - - - - -
// Defines alignment along the cross axis of the current grid:
// Align all row cols horizontally / Align all column cols vertically
._AlignItemsRule(@size-prefix: ~'') {
  // Setup rules for each align item option
  ._AlignItemsClass(~'start', ~'flex-start', @size-prefix);
  ._AlignItemsClass(~'center', ~'center', @size-prefix);
  ._AlignItemsClass(~'end', ~'flex-end', @size-prefix);
  ._AlignItemsClass(~'stretch', ~'stretch', @size-prefix);
}

._AlignItemsClass(@class, @value, @size-prefix: ~'') {
  // Setup selector and property for each align item option
  .ax-grid.ax-grid--@{size-prefix}align-items-@{class} {
    .align-items(@value);
  }
}

// Create rules
._AlignItemsRule();

.ax-breakpoint-m({
  ._AlignItemsRule(@ax-breakpoint-prefix-optional-m);
});

.ax-breakpoint-l({
  ._AlignItemsRule(@ax-breakpoint-prefix-optional-l);
});

