.community-insights__title {
  .ax-font {
    font-size: @ax-font-size-l;
    font-weight: @ax-font-weight-medium;
    margin: @ax-spacing-s 0 @ax-spacing-s 0;
    .ax-breakpoint-m({
      margin: @ax-spacing-s 0 @ax-spacing-l 0;
    });
  }
}

.filter-wrap {
  .ax-breakpoint-m({
    margin-bottom: @ax-spacing-s;
  });
}

.community-insights__filter-by-type {
  .ax-font {
    color: @ax-color-grey-60;
  }
}

.community-insights__filters {
  margin-top: @ax-spacing-m;

  .ax-breakpoint-m({
    margin-bottom: @ax-spacing-s;
    .display-flex();
    .flex-direction(column);
    .justify-content(flex-end);
  });

  .ax-font {
    margin-top: 0;
    .ax-breakpoint-m({
      margin-top: @ax-spacing-s;
      margin-bottom: 0;
    });
  }

  p {
    color: @ax-color-grey-70;
  }
}

.community-insights__communities {
  margin-top: 1.2rem;

  .ax-breakpoint-m({
    margin-top: @ax-spacing-m;
  });

  .filterable-community {
    min-width: 31rem;
    margin: 0;

    .ax-font {
      color: @ax-color-grey-60;
      margin-top: 0;
    }
    .dropdown-menu {
      overflow-y: hidden;
      height: 27rem;

      .ax-breakpoint-m({
        height: 33rem;
      })
    }
    .icon-caret_down {
      display: none;

      .ax-breakpoint-m({
        display: inline-block;
      });
    }
  }
}

.community-insights__reset {
  color: @ax-color-grey-60;
  text-decoration: underline;
  margin: @ax-spacing-m 0 @ax-spacing-s;

  &:hover {
    color: @ax-color-grey-80;
  }

  &.focus-visible:focus {
    .ax-focus-keyboard();
  }

  .ax-breakpoint-m({
    margin: 0 0 @ax-spacing-l;
  });
}

.filtered-communities-list__wrapper {
  height: 22rem;
  overflow-y: scroll;

  .ax-breakpoint-m({
    height: 28rem;
  });
}

.filter-actions {
  background-color: @ax-color-grey-10;
  margin: @ax-spacing-s;

  .filter-actions-wrapper {
    .display-flex();
    .flex-direction(row);
    margin-top: @ax-spacing-s;

    .filter-actions__button--apply, .filter-actions__button--cancel {
      flex: 1;
      padding-top: @ax-spacing-xs;
      padding-bottom: @ax-spacing-xs;
    }

    .filter-actions__button--apply {
      background-color: @ax-color-blue-60;
      color: @ax-color-white;
      margin-right: @ax-spacing-xs;
    }

    .filter-actions__button--cancel {
      margin-left: @ax-spacing-xs;
    }
  }
}

.community-insights__tab-container {
  width: 100%;
  margin-top: @ax-spacing-s;
  .ax-breakpoint-m({
    margin-top: 0;
  });
  .list-region {
    overflow-x: scroll;
    .ax-breakpoint-l({
      overflow-x: hidden;
    });
  }

  .page-control-top-region {
    margin: @ax-spacing-m 0 0 0;
  }
}

.community-insights__table-region {
  .base-card__title {
    height: @ax-spacing-l;
  }

  .basic-header-title .h1{
    font-weight: bold;
    font-size: @ax-font-size-m;
  }
}

.community-insights__table {
  width: 100%;

  thead {
    height: auto;
  }

  &.community-insights__table--no-shrink {
    min-width: 1000px;
  }

  h4,
  p,
  h2 {
    margin: 0;
  }

  .community-insights-table__subheader {
    margin: 0;
    color: @ax-color-grey-70;
    text-transform: uppercase;
  }

  thead th {
    background-color: @ax-color-grey-20;
    cursor: default;
    padding: @ax-spacing-s;
  }

  tr {
    th:last-child {
      border: none;
    }

    th[colspan]{
      padding-bottom: 0;
    }
  }

  .js-community-insights__table {
    margin-top: @ax-spacing-m;
  }

  .community-insights__desktop-column {
    display: none;
  }

  .community-insights__desktop-title {
    .break-long-words();
    font-weight: @ax-font-weight-medium;
  }

  .community-insights__desktop-community {
    color: @ax-color-grey-60;
  }

  .community-insights__desktop-time {
    color: @ax-color-grey-60;
  }

  .recent-table__title-column {
    width: 60%;
  }

  .reports-table__title-column {
    max-width: 20rem;
  }

  .community-insights__mobile-column {
    text-align: right;
    padding-right: 1em;
  }

  .reports-table__type-column {
    min-width: 10rem;
  }

  .recent-pages-table__subtable {
    max-width: 12rem;
  }

  .reports-table__value-column {
    text-align: right;
    padding-left: 0;
    padding-right: 1em;
    max-width: 10rem;
  }

  .community-insights__navigate-column--link {
    &:hover {
      cursor: pointer;
    }

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }
  }

  .ax-breakpoint-l({
    .community-insights__desktop-column {
      display: table-cell;
    }
    .community-insights__mobile-column {
      display: none;
    }
  })
}

.community-insights-actions__wrapper {
  .display-flex();
  .justify-content(space-between);
  margin-top: @ax-spacing-m;
}

.community-insights-export-button {
  display: inline-block;
  font-size: @ax-font-size-s;
  margin-left: @ax-spacing-m;
}

.community-insights-date-filter--overview {
  margin-top: @ax-spacing-m;
  .ax-breakpoint-m({
    margin-top: 0;
    .justify-content(flex-end);
  });
}

.community-insights-date-filter__grid {
  .display-flex();
  .flex-direction(row);
  margin-right: 0;
  margin-top: -@ax-spacing-xs;
}

.community-insights-date-filter__item {
  font-size: @ax-font-size-s;
  margin-right: 0;
  margin-left: @ax-spacing-s;
  margin-top: @ax-spacing-xs;

  .ax-breakpoint-m({
    .flex-direction(row);
    margin-left: @ax-spacing-m;
  });
}

.community-insights__overview-wrapper {
  margin-top: @ax-spacing-s;
  margin-bottom: @ax-spacing-xl;
  padding: @ax-spacing-l;
}

.community-insights__overview-header {
  padding-bottom: @ax-spacing-s;
  .display-flex();
  .flex-direction(column);
  .ax-breakpoint-m({
    .flex-direction(row);
    padding-bottom: @ax-spacing-m;
  });

  .community-insights__overview-title {
    font-weight: bold;
    font-size: @ax-font-size-m;
  }
}

.community-insights__how-it-works {
  .display-flex();
  margin-bottom: @ax-spacing-m;
  .justify-content(flex-start);
  .ax-breakpoint-m({
    .justify-content(flex-end);
  });
}

.overview-items {
  .display-flex();
  .flex-direction(column);
  .ax-breakpoint-m({
    .flex-direction(row);
  });
}

.community-insights {
  .card {
    padding: @ax-spacing-l;

    .base-card__wrapper {
      padding: 0;
    }

    .base-card__wrapper--upper {
      padding-bottom: @ax-spacing-m;
    }
  }

  .communications-insights-overview__tile {
    margin-top: @ax-spacing-m;
  }

  .overview-item {
    .overview-item__change--non-negative {
      color: @ax-color-grey-60;
    }

    .overview-item__change--negative {
      color: @ax-color-red-60;
    }

    .overview-item__value {
      font-size: @ax-font-size-xl;
      line-height: @ax-line-height-xs * 2;
      word-wrap: break-word;
      font-weight: @ax-font-weight-medium;
      margin: 0;

      .ax-breakpoint-m({
        font-weight: @ax-font-weight-regular;
      });
    }

    .overview-item__date-range {
      color: @ax-color-grey-60;
      line-height: @ax-line-height-m;
    }

    .overview-item__label {
      line-height: @ax-line-height-l;
      font-size: @ax-font-size-m;
      margin: 0;
    }

    .overview-item__change-arrow {
      line-height: @ax-line-height-l;
      font-size: @ax-font-size-l;
      font-weight: @ax-font-weight-bold;
    }
  }
}


.comms-type-filter {
  margin-top: @ax-spacing-m;
  span {
    margin-right: @ax-spacing-s;
  }
}

.recent-pages-table__border{
  border-right: 1px solid @ax-color-grey-40;
}


