@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.read-more__content--container {
  word-break: break-word;
  position: relative;
  overflow: hidden;
  max-height: 8rem;

  .screen-md-portrait-min({
    max-height: 6rem;
  });

  &.read-more__content--expanded {
    max-height: none;
  }

  .read-more__preview-gradient {
    .transparent-to-white-vertical-gradient();
    .size(100%);
    position: absolute;
    bottom: 0;
  }

  .read-more__overflow-content {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}

.read-more__button--container {
  .display-flex();
  .justify-content(center);
  margin-top: 0.5rem;

  .read-more__button {
    font-weight: @ax-font-weight-medium;
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
