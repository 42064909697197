// TODO: Move following styles into common location once common swiper component is created
@import 'swiper/less';
@import 'swiper/less/keyboard';
@import 'swiper/less/a11y';
@import 'swiper/less/navigation';

.task-completed-indicator-helper(@icon-size, @overall-size) {
  background-color: @ax-color-icon-green-a11y;
  .border-radius(50%);
  text-align: center;
  width: @overall-size;
  height: @overall-size;
  .box-sizing(border-box);
  color: @white;

  .icon-ok {
    font-size: @icon-size;
    color: @white;
    opacity: 1;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.tasks-section-divider-helper {
  border-bottom: 1px solid @accent-grey;
}

.tasks-section-bottom-spacing-helper {
  padding-bottom: 1.6rem;
}

.task-summary-tabs  {
  .tasks-section-bottom-spacing-helper;

  a {
    padding: 0 2.4rem;
  }
}

.open-task-count-wrapper {
  .tasks-section-bottom-spacing-helper();
  margin: 0 0 1.6rem;
}

.task-list-container {
  .task-list-empty {
    text-align: center;
    color:@secondary-text-color;
  }
}

.task-item {
  button {
    .box-sizing(border-box);
    display: table;
    .screen-sm-max({padding: 0.75em});
    text-decoration: none;
    width: 100%;
    margin-bottom: 1.6rem;
  }

  .icon-task {
    color: @white;
    opacity: 1;
  }

  .proceed-item {
    color: @primary-grey;
    background-color: transparent;
    opacity: 1;
    font-size: inherit;
  }

  .task-list-item-wrapper {
    display: table-row;
  }

  .task-list-item-icon,
  .task-list-item-description,
  .task-list-item-reward-points,
  .task-list-item-forward {
    display: table-cell;
    vertical-align: middle;
    padding: 1.6rem;

    .screen-sm-max({
      padding: 0.4rem;
    })
  }

  .task-list-item-icon {
    padding-right: 0;
    position: relative;
  }

  .task-completed-indicator {
    .task-completed-indicator-helper(16px, 24px);
    padding-top: 3px;
    display: none;
    position: absolute;
    top: 5px;
    right: 3px;

    .screen-sm-max({
      .task-completed-indicator-helper(12px, 18px);
      padding-top: 0;
    })
  }

  &.task-completed {
    .task-completed-indicator {
      display: inline;
    }
  }

  .task-list-item-description {
    width: 100%;
    min-width: 50px;
    padding-left: 0.8rem;
    text-align: left;

    .text-muted,
    .item-name {
      .text-ellipsis;
      float: left;
      width: 0;
      min-width: 100%;
    }

    .item-name {
      color: @ax-color-text;
    }
  }

  .task-list-item-reward-points {
    text-align: center;
    color: @ax-color-text;
    min-width: 4.8rem;

    .screen-md-portrait-min({
      white-space: nowrap;
    });
  }
}

.task-detail-header-region {
  .tasks-section-divider-helper;
  .tasks-section-bottom-spacing-helper;
  padding: 1.6rem 0;
  margin-bottom: 1.6rem;

  .task-detail-header {
    display: table;

    .screen-md-portrait-max({
      display: block;
      margin: auto;
      width: auto;
    });
  }

  .task-detail-header-wrapper {
    display: table-row;

    .screen-md-portrait-max({
      display: block;
    });
  }

  .task-detail-header-info {
    display: table-cell;
    vertical-align: middle;
    padding-right: @ax-spacing-l;

    h2 {
      margin: 0;
    }

    .screen-md-portrait-max({
      display: block;
      padding-right: 0;
    });
  }

  .task-detail-header-points {
    font-weight: @ax-font-weight-regular;
    display: table-cell;
    vertical-align: middle;
    text-align: left;

    .screen-md-portrait-max({
      display: block;
      text-align: left;
      white-space: iniital;
      border-top: 1px solid @accent-grey;
      padding-top: 1.6rem;
    });

    &.task-points-earned {
      font-weight: @ax-font-weight-medium;
    }

    .task-detail-header-points-wrapper {
      float: right;

      .screen-md-portrait-max({
        float: none;
      });
    }
  }

  .task-detail-header-program {
    font-weight: @ax-font-weight-regular;
    font-size: @ax-font-size-l;
    margin-bottom: 0;
    .text-muted;
  }
}

.task-detail-main-region {
  .tasks-section-bottom-spacing-helper();

  .task-detail-main a.focus-visible:focus {
    box-shadow: inset 0px 0px 2px 2px @primary-blue;
  }

  .task-completed-msg {
    border: 2px solid @green;
    .border-radius(@ax-border-radius-m);
    padding: 0.8rem;

    .task-completed-indicator {
      .task-completed-indicator-helper(12px, 20px);
      display: inline-block;
    }

    p {
      margin-left: 0.6rem;
      display: inline;
    }
  }
}

.task-detail-attachments-region {
  .tasks-section-bottom-spacing-helper;

  .task-detail-attachments {
    .clearfix();
    .tasks-section-divider-helper;
    padding: 0 0.8rem 0.8rem 1.6rem;

    .attachment--viewable.focus-visible:focus {
      box-shadow: inset 0px 0px 2px 2px @primary-blue;
    }

    & > div {
      padding-bottom: 0.8rem;
    }

    .screen-md-portrait-min({
      & > div {
        .pull-left;
        width: ~"calc(50% - .8rem)";

        &:nth-child(even) {
          margin-left: 1.6rem;
        }
      }
    });

    .screen-lg-min({
      & > div {
        .pull-left;
        width: ~"calc(100%/3 - 2rem/3)";
        margin-right: 1.6rem;

        &:nth-child(even) {
          margin-left: auto;
        }
        &:nth-child(3) {
          margin-right: auto;
        }
      }
    });
  }
}

.task-detail-info-region-larger-viewport {
  .screen-md-portrait-max({
    display: none;
  });
}

.task-detail-info-region-smaller-viewport {
  display: none;
  .screen-md-portrait-max({
    display: block;
  });
}

.task-detail-info {
  .task-detail-label {
    .text-muted();
    font-weight: @ax-font-weight-regular;
    margin: 0;
  }

  .task-detail-name {
    font-weight: @ax-font-weight-regular;
    font-size: @ax-font-size-s;
    margin-bottom: @ax-spacing-l;
  }

  .task-detail-info-region-larger-viewport & .task-detail-info-wrapper {
    display: inline-block;
    margin-right: 1.6rem;
    min-width: 19.2rem;
  }
}

.task-detail-info-region-larger-viewport,
.task-detail-info-wrapper {
  .tasks-section-bottom-spacing-helper;
}
