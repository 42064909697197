@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.layer-overlay-container {
  background: transparent;

  // These styles should NOT be overridden.
  // Positioning errors will occur otherwise.
  > .layer-content-region,
  > .layer-overlay-region {
    margin: 0!important;
    border: 0!important;
    padding: 0!important;
  }

  > .layer-content-region {
    z-index: 0;
    position: relative;
  }

  > .layer-overlay-region {
    z-index: 0;
    position: absolute;
  }
}
