// Login page
.page form {
  display: block;
  width: 100%;
}

.page form input.username,
.page form input.code,
.page form [data-editor='Password'] { margin-bottom: 1em; }

.page form label :not(.mfa-form__checkbox),
.page form .label {
  text-align: left;
  display: block;
  cursor: default;
  color: @ax-color-grey-60;
}

.page form .username input,
.page form .code input,
.page form .password input {
  display: block;
  outline: 0;
  width: 92%;
}
.page form .username input,
.page form .code input {
  margin: 0 0 1em 0;
}

/* Hide login labels on webkit-based browsers because placeholder value is visible */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .page form .username label,
  .page form .password label,
  .page form .code label {
    display:none;
  }
}

.page #login-wrapper.oauth form {
  .username input,
  .password input {
    width: 100%;
  }
}

.page form ul li { text-align: left; }

#login-wrapper h2 { text-align: center; }

#login-wrapper .buttonbar {
  padding: 0;

  button {
    margin-bottom: 1em;
  }
}

#login-wrapper .axon-button {
  display: block;
  width: 100%;

  &.login,
  &.verify {
    display: inline-block;
    min-width: 150px;
    width: auto;
  }
}

#login-wrapper .saml-login {
  padding-top: 1em;

  .axon-button {
    margin-top: 0.5em;
  }
}
#login-wrapper .saml-login.top-border {
  border-top: 1px solid @accent-grey;
}

#login-wrapper a.focus-visible:focus {
  box-shadow: inset 0px 0px 2px 2px @primary-blue;
}

// Login Branding
#login-branding,
#mfa-branding {
  padding: 0;
  margin: @ax-spacing-l 0;
}

#login-branding .login-form input.username,
#mfa-branding .mfa-form__code {
  width: 100%;
}

#mfa-branding .mfa-form__checkbox {
  width: auto;
  margin: @ax-spacing-m 0;
}

.help-contact #login-branding {
  border-bottom: none;
  .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
}

.login-heading {
  font-weight: @ax-font-weight-medium;
  font-size: @ax-font-size-s;
  border-bottom: none;
  margin: 0;
  text-align: center;
}

#login-wrapper .contact {
  display: block;
  padding: 0;
  text-align: center;
}

#login-wrapper.login-error .contact {
  color: @ax-color-red-60;
}

#login-wrapper .contact .contact__email {
  white-space: nowrap;
}

.reset-links {
  margin: auto;
  margin-bottom: 1em;

  .pipe-sign {
    margin-left: @ax-spacing-xs;
  }
}

.reset-links-wrapper {
  width: 100%;
  text-align: center;
}

#login-bottom {
  text-align: center;
  margin: 1em 0;
}

.login-form input {
  direction: ltr/*rtl:ignore*/;
}

.login-language-selector-wrapper {
  .display-flex();

  .login-language-selector {
    margin: 0 auto;
    text-align: center;
  }
}

#activation-link {
  display:none;
}

.privacy-policy {
  text-align: center;
  margin-top: 1.5em;
}

.floatleft.large {
  width: 50%;

  input {
    width: 90%;
  }
}

// Reset Password
#resetPasswordForm {
  padding: 0.5em;
  margin: 0 1.5em 1em;

  input,
  label {
    display: block;
  }

  input#username,
  [data-editor='Password'] {
    width: 100%;
    margin-bottom: 0.5em;
  }
}

h2.password-reset {
  padding: 1.5em 0em 1em 1em;
  font-size: 1.6rem;
}

// OAuth
.page #login-wrapper.oauth {
  display: block;
  text-align: left;
}

.page #login-wrapper.oauth form {
  .username input,
  .password input {
    width: 80%;
  }
}

#login-wrapper.oauth #login-branding {
  padding: 0em 0;
}

#login-wrapper.oauth .login-form {
  float: none;
  display: block;
  margin: 0 auto 1.5em auto;
}

#login-wrapper.oauth .oauth-providers {
  float: none;
  border-top: 1px solid #ececec;
  padding-top: 1.5em;
  margin: 0 auto;
}

#login-wrapper.oauth .oauth-provider {
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;

  .grid-button--center-text {
    text-align: center;
  }
}

#login-wrapper.oauth .oauth-provider button {
  margin: 0 auto @ax-spacing-m;
  .box-sizing(border-box);
  width: 100%;
  border: 1px solid @secondary-grey;
  text-align: left;
  font-weight: @ax-font-weight-regular;

  &.ax-button--icon-left {
    background: @secondary-bg;

    &:hover,
    &:focus {
      background-color: @white;
    }
  }

  .oauth-icon {
    .size(@ax-font-size-xl);
    margin-right: @ax-spacing-s;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
  }

  .oauth-icon,
  .oauth-text {
    vertical-align: middle;
  }
}

#login-wrapper.oauth .oauth-providers .axon-button .oauth-text {
  margin-left: 0.5em;
}

// Removes the password reveal button auto added in MS Edge
#login-wrapper ::-ms-reveal {
  display: none;
}
