@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.inline-user-submission__download-container {
  max-width: auto;

  .ax-breakpoint-m({
    max-width: @ax-input-max-width-m
  });

  .attachment__container {
    margin: 0;
  }
}

.inline-user-submission__preview-container {
  margin-top: @ax-spacing-m;
}

.inline-user-submission__info {
  background: @white;
  border: 1px solid @primary-blue;
  padding: 1em;
  .border-radius(@ax-border-radius-m);
  margin-bottom: @ax-spacing-m;
  max-width: auto;
  .icon-info_sign {
    vertical-align: middle;
    color: @ax-color-blue-60;
  }
  .inline-user-submission__info-text {
    vertical-align: middle;
  }
}
