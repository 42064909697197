@axDatePicker-selectedDay-background-color:       var(--axButton-branded-background);
@axDatePicker-selectedDay-background-color-hover: var(--axButton-branded-background-hover);
@axDatePicker-selectedDay-color:                  var(--axButton-branded-color);
@axDatePicker-today-background-color:             'transparent';
@axDatePicker-today-background-color-hover:       rgba(@ax-color-grey-80, @ax-opacity-s);
@axDatePicker-today-border-color:                 var(--ax-color-grey-80);

:root {
  --axDatePicker-selectedDay-background-color:       @axDatePicker-selectedDay-background-color;
  --axDatePicker-selectedDay-color:                  @axDatePicker-selectedDay-color;
  --axDatePicker-selectedDay-background-color-hover: @axDatePicker-selectedDay-background-color-hover;
  --axDatePicker-today-background-color:             @axDatePicker-today-background-color;
  --axDatePicker-today-background-color-hover:       @axDatePicker-today-background-color-hover;
  --axDatePicker-today-border-color:                 @axDatePicker-today-border-color;
}
