.messages__list {
  .flex-grow(1);
}

.messages__row {
  &:not(:last-child) {
    margin-bottom: @ax-spacing-m;
  }

  .message-row {
    h4 {
      margin: 0;
    }

    .icon-bullhorn {
      .flex-center;
      .size(3.4rem);
      font-size: 2rem;
      margin: @ax-spacing-xs @ax-spacing-m 0 0;
      padding: 0;

      .screen-md-portrait-min({
        .size(4rem);
        font-size: 2.6rem;
      });
    }
  }

  .nonews {
    color: @secondary-text-color;
  }
}
