.titled-section__region {
  &--content {
    .display-flex;
    .flex-direction(column);
  }
}

.titled-section__region--extra-margin {
  margin-top: 5rem;
}

// There is code to add special styling for the first titled section region (which can overlay the branded banner background)
// When that special styling is added, we also want to make sure the SECOND titled region has the default background styling
// even if that means covering up a bit of the branded banner background (to avoid the second title section breaking
// accessibility styling rules)
.titled-section__region--second {
  background-color: @ax-color-app-background;
  margin: -@ax-spacing-m -500rem;
  padding: @ax-spacing-m 500rem;
}
