@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
.image-preview {

  &.loading {
    min-width: 150px;
    min-height: 150px
  }

  img {
    display: block;
    width: auto;
    height: auto;
    border: none;
    max-width: 100%;
    max-height: 100%;
  }
}
