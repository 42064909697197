.claimed-invite {
  margin: @ax-spacing-s 0 @ax-spacing-m;
  padding: @ax-spacing-m;
  text-align: center;

  .ax-breakpoint-m({
    text-align: left;
  });

  .claimed-invite-wrapper {
    display: block;

    .ax-breakpoint-m({
      padding-bottom: 0;
    });

    .claim-left-side {
      display: block;

      .ax-breakpoint-m({
        float: left;
        width: 7rem;
      });

      .large-name-logo {
        .border-radius(50%);
        font-size: 2.6rem;
        color: @white;
        height: 6rem;
        line-height: 6rem;
        margin: 0 auto @ax-spacing-s;
        text-align: center;
        width: 6rem;

        .ax-breakpoint-m({
          font-size: 2.3rem;
          height: 7rem;
          line-height: 7rem;
          margin: 0;
          width: 7rem;
        });
      }
    }

    .claim-right-side {
      display: block;

      .ax-breakpoint-m({
        float: left;
        width: 80%;
      });

      .right-side-wrapper {
        margin: 0;

        .ax-breakpoint-m({
          margin: 0 0 0 @ax-spacing-m;
        });

        p {
          margin: 0;
        }
      }

      .reward-structure {
        padding: @ax-spacing-m 0;
      }
    }
  }
}
