// Coach Selection
.selectcoach .coacheslist {
  text-align: center;

  &.start {
    margin: @ax-spacing-m;
  }

  .coach {
    float: left;
    position: relative;
    margin-bottom: @ax-spacing-l;
    height: 8.5rem;

    .ax-breakpoint-m({
      height: 11rem;
    });

    &.selected {
      border: .1rem solid @green;

      .type {
        background-color: fade(@green, 20%);
      }

      .coach-selection {
        position: absolute;
        width: 5rem;
        height: 5rem;
        top: 0;
        left: 0;
        background: url('@training/assets/images/correctcheck.png') center center no-repeat;
      }
    }

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }
  }

  .type {
    text-align: center;
    height: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 11rem;

    .ax-breakpoint-m({
      background-size: 14.5rem;
    });

    &.NoCoach {
      background-size: contain;
    }
  }
}
