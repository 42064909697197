.rewards__row-region {
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  .title-header {
    .h1,
    .text-muted {
      .text-ellipsis;
      margin: 0;
    }
  }

  .no-rows {
    color: @secondary-text-color;
  }
}
