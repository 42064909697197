@_color-font-title: @ax-color-grey-40;
@_color-font-value: @white;
@_height-reel: 13.5rem;
@_height-reel-m: 25rem;
@_height-message: 5rem;
@_height-message-m: 4.5rem;

#slot-machine {
  .box-sizing(border-box);
  .gradient(@startColor: @ax-color-grey-80, @endColor: @ax-color-grey-70);
  margin: @ax-spacing-l -@ax-gutter-page;
  overflow: hidden;
  padding: (@_height-message + (@ax-spacing-m *2)) @ax-spacing-m @ax-spacing-m;
  position: relative;

  .ax-breakpoint-m({
    .border-radius(@ax-border-radius-m);
    margin: @ax-spacing-l 0;
    padding: @ax-spacing-m;
  });
}

.slotwinner {
  color: @ax-color-green-60;
}

.payline {
  background: @ax-color-green-60;
  height: .4rem;
  left: @ax-spacing-xxs;
  position: absolute;
  right: @ax-spacing-xxs;
  top: 50%;
  z-index: 4;
}

.reel-container {
  .border-radius(@ax-border-radius-m);
  background: @white;
  position: relative;
}

.reel-shadow {
  .border-radius(@ax-border-radius-m);
  border: .2rem solid @ax-color-grey-80;
  bottom: 0;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .3) inset, 0 0 1px rgba(0, 0, 0, .2) inset;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.reel {
  height: @_height-reel;
  overflow: hidden;
  padding: 0 @ax-spacing-s;
  position: relative;
  text-align: center;

  .ax-breakpoint-m({
    height: @_height-reel-m;
  });
}

#reel-two {
  margin: 0;

  .ax-breakpoint-m({
    margin: 0 @ax-spacing-m;
  });
}

.reel .prize-image {
  .align-items(center);
  .display-flex();
  .justify-content(center);
  font-weight: @ax-font-weight-bold;
  margin-top: @ax-spacing-s;
  position: relative;
  top: -4.8rem;
}

.reel img {
  height: 6.8rem;

  .ax-breakpoint-m({
    height: 10.7rem;
  });
}

.lever .spin-button {
  height: 100%;
  text-transform: uppercase;
  width: 100%;
}

.slot-machine-content {
  text-align: center;
  position: relative;
}

.slot-machine-content.footer {
  .ax-breakpoint-m({
    margin-top: @ax-spacing-l;
  });
}

.cost-per-spin {
  .border-radius(@ax-border-radius-m);
  font-size: 1.6rem;
  border: none;
  color: @_color-font-title;
  padding: @ax-spacing-s;

  .ax-breakpoint-m({
    font-size: 1.2rem;
    background: black;
    padding: @ax-spacing-m;
    text-align: center;
  });

  .auc-label {
    margin-right: @ax-spacing-s;

    .ax-breakpoint-m({
      margin-right: 0;
    });
  }
}

.cost-per-spin-container {
  height: 100%;
}

.slot-machine-message {
  .border-radius(@ax-border-radius-m);
  background: black;
  height: @_height-message;
  left: @ax-gutter-grid / 2;
  position: absolute;
  right: @ax-gutter-grid / 2;
  top: -(@_height-reel + @_height-message + @ax-spacing-m);

  .ax-breakpoint-m({
    height: @_height-message-m;
    left: auto;
    position: relative;
    right: auto;
    top: auto;
  });
}

.spin-prize {
  margin-bottom: @ax-spacing-l;
}

.slot-machine-status {
  .border-radius(@ax-border-radius-m);
  background: black;
  padding: @ax-spacing-s;
  text-align: left;

  .ax-breakpoint-m({
    margin-top: @ax-spacing-s;
    padding: @ax-spacing-s @ax-spacing-m;
  });

  &.spin-count {
    margin-top: @ax-spacing-s;
  }

  &.points-balance {
    margin-top: 0;

    .ax-breakpoint-m({
      margin-top: @ax-spacing-s;
    });
  }
}

.slot-machine-content .auc-label {
  color: @_color-font-title;
}

.slot-machine-content .auc-label,
.slot-machine-content .auc-value {
  display: block;

  .flex-basis(auto);
  width: auto;

  .ax-breakpoint-m({
    .flex-basis(100%);
    width: 100%;
  });
}

.slot-machine-content .auc-value,
.slot-machine-content .msg {
  color: @_color-font-value;

  &--l {
    margin-left: @ax-spacing-xs;

    .ax-breakpoint-m({
      font-size: 3rem;
      line-height: 4.1rem;
      margin-left: 0;
    });
  }

  &--m {
    margin-left: @ax-spacing-xs;

    .ax-breakpoint-m({
      font-size: @ax-font-size-l;
      line-height: 3.1rem;
      margin-left: 0;
    });
  }

  &--h2 {
    font-size: @ax-font-size-xl;
    margin-bottom: 0;
    font-weight: @ax-font-weight-regular;
  }
}

.slot-machine-content .prize-image.locked {
  img {
    opacity: 0.75;
  }

  .locks {
    .align-items(center);
    .display-flex();
    .justify-content(center);
    bottom: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:before {
      .border-radius(50%);
      background: url("@training/assets/images/lock-closed.png?_v13") center center no-repeat @primary-bg;
      content: '';
      display: inline-block;
      height: 8rem;
      width: 8rem;
    }
  }
}
