// TopicProgress card styles
.base-card.base-card--topic-progress {

  .topic-progress__row {
    &:not(:first-child) {
      border-top: .1rem solid @ax-color-grey-30;
    }

    &--wrapper {
      .display-flex();
      .align-items(center);
      padding: @ax-spacing-xs 0;

      > * {
        display: inline-block;
        .flex-grow(0);

        &.name {
          .flex-grow(1);
          line-height: normal;
        }

        &.value {
          margin-right: @ax-spacing-m;
        }
      }

      [class^='icon-'] {
        .size(@ax-font-size-m);
        font-size: @ax-font-size-m;
        margin-right: @ax-spacing-m;
      }
    }
  }
}

