.milestone-status {
  // TODO: Update hex values once color scale is updated
  @_milestone-overdue-color: #CA0D16;
  @_milestone-behind-color: #C14A17;
  .display-flex();
  .justify-content(center);
  .flex-direction(column);
  padding: @ax-spacing-m;
  padding-top: @ax-spacing-xs;

  .ax-breakpoint-m({
    text-align: center;
  });

  .icon-milestone {
    font-size: 3rem;
    display: none;
    min-height: 3rem;
  }

  &--complete {
    .milestone-status__next-item,
    .milestone-status__due {
      display: none;
    }
    .milestone-status__status-message {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }
  }

  &--overdue {
    .milestone-status__due {
      color: @_milestone-overdue-color;
    }
  }

  &--behind-pace {
    .milestone-status__due {
      color: @_milestone-behind-color;
    }
  }

  .ax-breakpoint-m({
    padding: @ax-spacing-l;

    .icon-milestone {
      display: block;
      margin-bottom: 2rem;
    }

    &--overdue {
      border-left: @ax-border-radius-m solid @_milestone-overdue-color;
    }
  });
}

h5 {
  font-size: @ax-font-size-xs;
  margin: 0;
}

.milestone-status__header {
  text-transform: uppercase;
  font-size: @ax-font-size-xs;
  font-weight: @ax-font-weight-regular;
  color: @ax-color-grey-80;
  margin-bottom: @ax-spacing-m;

  .ax-breakpoint-m({
    margin-bottom: 2rem;
  });
}

.milestone-status__next-item {
  font-weight: @ax-font-weight-bold;
  font-size: @ax-font-size-s;
  margin:0;
}

.milestone-status__due {
  color: @ax-color-grey-60;
  margin-top: @ax-spacing-s;
  font-weight: @ax-font-weight-regular;
  font-size: @ax-font-size-xs;
  margin-bottom: 0;
}

.milestone-status__status-message {
  border-top: 0.1rem solid @ax-color-grey-30;
  margin-top: @ax-spacing-m;
  padding-top: @ax-spacing-m;
  font-weight: @ax-font-weight-regular;
  font-size: @ax-font-size-xs;
  margin-bottom: 0;

  .ax-breakpoint-m({
    margin-top: @ax-spacing-l;
    padding-top: @ax-spacing-l;
  });
}
