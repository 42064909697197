// User Profile - Upload Avatar
.user-profile {
  padding: @ax-spacing-s @ax-gutter-page @ax-spacing-xxl;
  width: 100%;
  .box-sizing(border-box);

  .ax-breakpoint-m({
    padding: 0;
  });
}

.user-profile form {
  .ax-input,
  .ax-select {
    margin-bottom: @ax-spacing-m;
  }
}

.user-profile .media-thumbnail-container {
  display: block;
}

.user-profile .media-image-thumbnail {
  border: .1rem solid @secondary-grey;
  margin: @ax-spacing-m auto;
  .border-radius(@ax-border-radius-m);
  .box-shadow-black(0, .1rem, .2rem, 0.1);

  .ax-breakpoint-m({
    margin: @ax-spacing-m 0;
  });

  .image-preview img {
    .fit-parent;
  }
}

.user-profile .media-image-thumbnail,
.thumbnail-preview {
  position:relative;
  // Needs to stay px values until the image cropper inline styles also use rem (IE11 issue)
  width: 200px;
  height: 200px;
}

.thumbnail-preview {
  margin: @ax-spacing-m 0 @ax-spacing-s 0;
  overflow: hidden;
}

.upload {
  padding: @ax-spacing-m 0;

  h3 {
    margin: 0;
  }
}

#user-form .image-thumbnail-overlay label {
  margin: 0
}

.user-profile .image-thumbnail-overlay {
  border: none;
  width: 100%;
  text-align: left;
  position: absolute;
  height: 3.4rem;
  bottom: 0;
  .user-profile-sprite;
  background-position: -235px -110px; // px value to fix IE11 rem rounding issue
  background-color: rgba(67, 70, 73, 0.7);
  cursor: pointer;
  z-index: 800;
  .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);

  &:focus,
  &:hover {
    background-color: darken(rgba(67, 70, 73, 0.7), 15%);
  }
}

.user-profile .media-image-thumbnail {
  .remove {
    .remove-icon;
    z-index: 1;
    border: none;
  }

  .remove-wrap {
    z-index: 300;
    position: relative;
  }
}

.adjust-photo-wrap {
  border-right: .1rem solid @accent-grey;
  min-height: 40rem;
  padding: 0 @ax-spacing-m;

  .choose-photo,
  .adjust-photo {
    margin-right: @ax-spacing-l;
  }
}

#user-form .choose-photo,
#user-form .adjust-photo {
  color: white;
  text-indent: 4.5rem;
}

.image-upload-form {
  .preview-container,
  .remove {
    display: none;
  }

  .media-thumbnail-input {
    height: 0;
    visibility: hidden;
  }

  .change-photo.focus-visible:focus {
    .box-shadow(inset 0 0 .2rem .2rem @primary-blue);
  }

  .choose-photo,
  .adjust-photo {
    display: none;
  }
}

.image-upload-form {
  .preview-container,
  .remove {
    display: none;
  }
}

.user-profile-sprite {
  background-image: url('@training/assets/images/userProfile_sprite.png?_v2');
  background-repeat: no-repeat;

  .retina({
    background-image: url('@training/assets/images/userProfile_sprite@2x.png?_v2');
    background-size: 33.5rem;
  });
}


.no-image {
  .thumbnail-preview-size,
  .thumbnail-preview,
  .media-thumbnail-desktop,
  .media-thumbnail {
    .user-profile-sprite;
  }
}

.thumbnail-preview-size {
  overflow: hidden;
  margin-right: @ax-spacing-s;
}

.thumbnail-preview-size-1 {
  width: 6rem;
  height: 6rem;
  background-position: -20.5rem 0;
}

.thumbnail-preview-size-2 {
  width: 4.5rem;
  height: 4.5rem;
  .border-radius(50%);
  background-position: -20.5rem -6.5rem;
}

.thumbnail-preview-size-3 {
  width: 3.5rem;
  height: 3.5rem;
  .border-radius(50%);
  background-position: -30rem -6.5rem;
}

.media-thumbnail-wrap {
  margin-right: @ax-spacing-l;
}

.remove-icon {
  position: absolute;
  display: block;
  top: -@ax-spacing-s;
  right: -@ax-spacing-m;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  background-image: url('@training/assets/images/modal-close-white.png?_v5');
  background-position: center center;
  background-repeat: no-repeat;
  background-color: @red;
  .border-radius(@ax-border-radius-m);

  .retina({
    .thumbnail-wrap .remove {
      background-image:url('@training/assets/images/modal-close-white@2x.png?_v5');
      background-size: 2.5rem;
    }
  });

  &:focus,
  &:hover {
    background-color: darken(@red, 15%);
  }
}

.thumbnail-wrap {
  .remove {
    .remove-icon;
  }
  .remove-wrap {
    z-index: 300;
    position: relative;
  }
}

.hidden-thumbnail {
  visibility: hidden;
}

.media-thumbnail {
  position: relative;
}

.media-thumbnail-desktop {
  height: 100%;

  .table-centering-hack {
    display: none;
  }
  .user-profile-sprite;
}

.preview-wrap img {
  display: none;
}

#user-form .media-thumbnail {
  width: 20rem;
  height: 20rem;
}

.media-thumbnail .image-thumbnail,
.media-thumbnail .video-thumbnail {
  display:block;
}

.adjust-photo-title {
  font-size: @topic-font-size;
  font-weight: @ax-font-weight-bold;
  color: @black;
}

.adjust-photo-subTitle {
  color: @secondary-text-color;

  .ax-breakpoint-m({
    margin-right: @ax-spacing-s;
    margin-bottom: @ax-spacing-m;
  });
}

.profile-image-upload {
  .close {
    margin: @ax-spacing-xs;
  }

  .close,
  .remove {
    border: none;
  }

  .adjust-photo-preview {
    padding: 0 @ax-spacing-l;
  }

  .modal-footer {
    padding: 0 @ax-spacing-m @ax-spacing-m;
  }
}

.retina({
  .user-profile .image-thumbnail-overlay,
  .user-profile .media-image-thumbnail,
  .image-upload-form .thumbnail-preview {
    background-image: url('@training/assets/images/userProfile_sprite@2x.png?_v2');
    background-size: 33.5rem;
  }
});
