.article-contributor-history-layout {
  #history-page {
    .title-container {
      h1 {
        .display-flex();
        overflow: hidden;
        padding: 1.3rem 1.3rem 1.3rem 0;
        background-color: transparent;
        margin: 0;

        .page-header__title {
          margin-right: 1rem;
        }

        .title__text {
          .text-ellipsis;
        }

        a {
          .text-ellipsis;
          color: @primary-blue;
          text-decoration: underline;
          display: block;
        }
      }
    }

    #content {
      height: 100%;
      margin: 0 0 4.8rem;

      .contributor-history-title {
        color: @black;
        font-weight: @ax-font-weight-bold;
        padding: 1em 1.5rem;
      }

      .contributor-history-revision {
        border: solid @secondary-grey 1px;
        margin: 1em 1.5rem;
        padding: 1em;
        position: relative;
        .border-radius(@ax-border-radius-m);

        .page-preview-meta-content__avatar {
          .round-avatar();
          margin-top: 0.2rem; // deliberately 0.2rem less than the default
        }

        .page-preview__meta-content-contributors {
          margin-left: 4.8rem;
        }

        .date {
          color: @secondary-text-color;
          position: absolute;
          right: 1em;
          top: 1em;
        }

        .message,
        .comment {
          overflow-wrap: break-word;
          word-wrap: break-all;
          word-wrap: break-word;
          color: @black;
        }

        .message {
          color: @secondary-text-color;
        }

        .version {
          color: @black;
          font-weight: @ax-font-weight-bold;
        }
      }
    }
  }
}
