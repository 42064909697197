@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.todays-activities {
  &__card {
    word-break: break-word;
    .size(initial);
    .flex(1 1 auto);

    .subtext-header-title {
      .flex-grow();

      &--progress-wrapper {
        .display-flex();

        .progressBarRegion {
          .screen-md-portrait-min({
            .flex-grow();
          });

          .progress-bar {
            display: none;
            .screen-md-portrait-min({
              display: block;
              height: 7px;
              .progress-bar-inset {
                background-color: @primary-blue;
              }
            });
          }
        }

        p {
          color: @secondary-text-color;
        }
      }
      .screen-md-portrait-min({
        .subtext-header-title__message {
          margin-left: 1.6rem;
        }
      });
    }

    .base-card__view-all {
      position: absolute;
      top: @ax-spacing-m;
      right: @ax-spacing-m;
    }
  }

  &__lower--region {
    width: 100%;
    .display-flex();
    .flex-direction(column);

    .base-card__meta {
      margin-bottom: 1.6rem;
      margin-top: 1.2rem;

      .screen-md-portrait-min({
        margin: 0;
        min-height: 0;
      });
    }

    .metadata-detail-item {
      display: block;
      margin-top: 0.4rem;

      .screen-md-portrait-min({
        display: inline-block;
        margin-top: 0;
      });
    }

    & > div {
      .flex(1 0 auto);
      .display-flex();
      .flex-direction(column);

      .screen-md-portrait-min({
        .flex(0 1 auto);
      });
    }

    button {
      .full-width();
      height: auto;
      font-size: 1.6rem;

      .screen-md-portrait-min({
        width: auto;
        padding-right: 1.6rem;
        padding-left: 1.6rem;
        min-width: 200px;
      });

      .action-text {
        .screen-md-portrait-min({
          white-space: nowrap;
        });
      }
    }

    .screen-md-portrait-min({
      .flex-direction(row);
      .align-items(center);
      .justify-content(space-between);
    });
  }
}
