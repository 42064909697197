:root {
  --ax-table-header-background-color: var(--ax-color-grey-20);
  --ax-table-header-padding         : var(--ax-spacing-s);
  --ax-table-header-height          : calc(var(--ax-line-height-s) + (2 * var(--ax-spacing-s)));
  --ax-table-header-color           : var(--ax-color-grey-70);
  --ax-table-header-font-size       : var(--ax-font-size-s);
  --ax-table-header-font-weight     : var(--ax-font-weight-bold);
  --ax-table-header-line-height     : var(--ax-line-height-s);
  --ax-table-cell-font-size         : var(--ax-font-size-s);
  --ax-table-cell-line-height       : var(--ax-line-height-s);
  --ax-table-cell-font-weight       : var(--ax-font-weight-regular);
  --ax-table-cell-color             : var(--ax-color-grey-80);
  --ax-table-cell-padding           : var(--ax-spacing-s);
}
