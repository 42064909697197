@training-card-height-mobile: 14.7rem;
@training-card-height: 40rem;
@training-card-image-size-mobile: 7.5rem;
@training-card-thumbnail-height: 19rem;

.training-card {
  height: 100%;
  min-height: @training-card-height-mobile;
  padding: @ax-spacing-s;
  .flex-flow(column nowrap);

  .progress-bar .progress-bar-inset {
    background-color: @ax-color-blue-60;
  }

  .progress-bar .progress-bar-inset--complete {
    background-color: @ax-color-green-60;
  }

  .ax-breakpoint-m({
    padding: 0 0 @ax-spacing-s 0;
    min-height: @training-card-height;
  });

  &__bottom-info-action {
    width: 100%;
    flex-shrink: 0;
    margin-top: @ax-spacing-s;

    .ax-button {
      width: 100%;
    }

    .ax-breakpoint-m({
      width: auto;
      margin-top: 0;

      .ax-button {
        width: auto;
      }
    });
  }
}

.training-card__top {
  .ax-breakpoint-m({
    display: block;
  });
}

.training-card__recommendation-tag-wrapper {
  .display-flex();
  .justify-content(flex-end);
  width: 100%;

  .ax-breakpoint-m({
    position: absolute;
    right: @ax-spacing-s;
    top: @ax-spacing-s;
  });
}

.training-card__recommendation-tag {
  background-color: @white;
  color: @ax-color-blue-60;
}

.training-card__graphic-wrapper {
  .size(@training-card-image-size-mobile);
  min-width: unset;
  flex-basis: @training-card-image-size-mobile;

  .ax-breakpoint-m({
    width: 100%;
    height: auto;
    display: inline-block;
  });
}

.training-card__graphic {
  .display-flex();
  .align-items(center);
  .border-radius(@ax-border-radius-m);
  .size(@training-card-image-size-mobile);

  .ax-breakpoint-m({
    display: inline-block;
    .border-radius(0);
    width: 100%;
    height: auto;
  });
}

.training-card__graphic-icon {
  .border-radius(@ax-border-radius-m);
  font-size: 4rem;
  .size(100%);
  .display-flex();
  .align-items(center);
  .justify-content(center);

  &&--reinforcement {
    color: @ax-color-programs;
    background-color: transparent;
  }

  &&--certifications {
    color: @ax-color-certification-80;
    background-color: @ax-color-certification-40;
  }

  &&--topic {
    color: @ax-color-training;
  }

  &&--task {
    color: @ax-color-assessments-task;
    background-color: #FBE1E1;
  }

  &&--path {
    color: @ax-color-programs;
  }

  &&--intro {
    color: @ax-color-training;
  }

  &&--refresher {
    color: @ax-color-training;
  }

  &&--training_page::before {
    color: @ax-color-programs;
  }

  .ax-breakpoint-m({
    display: inline-block;
    margin: @ax-spacing-m @ax-spacing-m 0 @ax-spacing-m;
    &&--certifications, &&--topic, &&--task, &&--path {
      background-color: transparent;
    }
  });
}

.training-card__graphic-thumbnail {
  background-position: center;
  background-size: cover;
  height: @training-card-image-size-mobile;
  width: @training-card-image-size-mobile;
  .border-radius(@ax-border-radius-m);

  .ax-breakpoint-m({
    height: @training-card-thumbnail-height;
    width: 100%;
    .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
  });
}

.training-card__header-tag-wrapper {
  min-width: 0;
  .flex-basis(calc(100% - @training-card-image-size-mobile));
}

.training-card__header-info-wrapper {
  margin-left: @ax-spacing-m;
  margin-right: @ax-spacing-m;
  margin-top: 0;

  .ax-breakpoint-m({
    margin-top: @ax-spacing-s;
  });
}

.training-card__discover-header-info-wrapper {
  margin-left: @ax-spacing-m;
  margin-right: @ax-spacing-m;

  .ax-breakpoint-m({
    margin-top: @ax-spacing-m;
  });
}

.training-card__header-info-subject {
  font-size: @ax-font-size-xs;
  .text-ellipsis();
  color: @ax-color-grey-60;
  margin: 0;
  line-height: 1.8rem;
}

.training-card__header-info-title {
  font-size: @ax-font-size-s;
  line-height: @ax-line-height-s;

  margin-top: @ax-spacing-xs;
  margin-bottom: @ax-spacing-xs;

  .ax-breakpoint-m({
    font-size: @ax-font-size-l;
    line-height: 3rem;
  });
}

.training-card__header-info-description {
  color: @ax-color-grey-60;
  font-family: @ax-font-family;
  font-size: @_ax-font-size-p;
  line-height: 2rem;
  padding-bottom: .3rem;
  margin-bottom: -.3rem;
}

.training-card__header-tags-wrapper {
  margin: @ax-spacing-xs @ax-spacing-m @ax-spacing-s @ax-spacing-m;
}

.training-card__bottom {
  margin-top: @ax-spacing-m;

  .ax-breakpoint-m({
    margin: 0 @ax-spacing-m @ax-spacing-s @ax-spacing-m;
  });
}

.training-card__bottom-info {
  .display-flex();
  .justify-content(center);
  flex-wrap: wrap;

  .ax-breakpoint-m({ 
    .align-items(center);
    .justify-content(space-between);
    flex-wrap: nowrap;
  });
  
}

.training-card__discover-bottom {
  .training-card__bottom-info-action {
    .display-flex();
    .justify-content(flex-end);
    .ax-breakpoint-m({
      .justify-content(center);
      margin: 0 @ax-spacing-m @ax-spacing-s @ax-spacing-m;
    });
  }
}

.training-card__bottom-info-text {
  font-size: @ax-font-size-xs;
  color: @ax-color-grey-60;
  margin: 0;
  padding-right: @ax-spacing-s;
  overflow-wrap: anywhere;
}

.training-card__bottom-info-text--warning {
  color: @red;
}

.training-card__bottom-info-text-wrapper {
  .display-flex();
  .align-items(center);
  .flex-grow(1);
}

.training-card__bottom-info-text-icon {
  font-size: 2.5rem;
  margin-right: @ax-spacing-s;

  &&--boost-confidence {
    color: @ax-color-blue-60;
  }

  &&--ok,
  &&--ok_circle {
    color: @ax-color-icon-green-a11y;
  }

  &&--exclamation {
    color: @ax-color-orange-80;
  }

  &&--milestone {
    color: @red;
  }

  &&--intro {
    color: @intro-training-color;
  }

  &&--graduate {
    color: @ax-color-blue-60;
  }
}

.training-card__progress-bar-wrapper {
  margin-bottom: @ax-spacing-m;
}

.icon--discover {
  .flex-center();
  background-color: @blue-background;
  color: @ax-color-blue-80;
  font-size: 4rem;
  width: 100%;
  height: 100%;
  .border-radius(@ax-border-radius-m);


  .ax-breakpoint-m({
    width: 100%;
    height: 20rem;
    font-size: 6.2rem;
    .border-radius(0);
  });
}

// Training Carousel styling (mostly overwriting the mobile styling)

.continue-training__item-region .training-card {
  width: 100%;
  min-height: @training-card-height;
  padding: 0;
  height: @training-card-height;

  .training-card__top {
    display: block;
  }

  .training-card__recommendation-tag-wrapper {
    position: absolute;
    right: @ax-spacing-s;
    top: @ax-spacing-s;
  }

  .training-card__graphic-wrapper {
    width: 100%;
    height: auto;
    display: inline-block;
  }

  .training-card__graphic {
    display: inline-block;
    .border-radius(0);
    width: 100%;
    height: auto;
  }

  .training-card__graphic-icon {
    display: inline-block;
    margin: @ax-spacing-m @ax-spacing-m 0 @ax-spacing-m;
    background-color: transparent;
  }

  .training-card__graphic-thumbnail {
    height: @training-card-thumbnail-height;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .training-card__header-info-wrapper {
    margin-top: 0;
  }

  .training-card__header-info-title {
    font-size: @ax-font-size-s;
    line-height: @ax-line-height-s;

    .ax-breakpoint-m({
      font-size: @ax-font-size-l;
      line-height: 3rem;
    });
  }

  .training-card__bottom {
    margin: 0 @ax-spacing-m @ax-spacing-m @ax-spacing-m;
  }
}
