@ax-tooltip-textAlign       : center;
@ax-tooltip-fontSize        : 1.2rem;
@ax-tooltip-lineHeight      : 1.4rem;
@ax-tooltip-background-color: @ax-color-black;
@ax-tooltip-padding         : @ax-spacing-s;
@ax-tooltip-button-size     : var(--ax-font-size-xl);
@ax-tooltip-button-color    : var(--ax-link-color);

:root {
  --ax-tooltip-textAlign       : @ax-tooltip-textAlign;
  --ax-tooltip-fontSize        : @ax-tooltip-fontSize;
  --ax-tooltip-lineHeight      : @ax-tooltip-lineHeight;
  --ax-tooltip-background-color: @ax-tooltip-background-color;
  --ax-tooltip-padding         : @ax-tooltip-padding;
  --ax-tooltip-button-size     : @ax-tooltip-button-size;
  --ax-tooltip-button-color    : @ax-tooltip-button-color;
}
