@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.hierarchy-selector .dropdown-listing {
  margin: 0;
  border:0;
  height: 100%;
  overflow: auto;

  .fancytree-grid-container {
    height: 100%;
  }

  .tree-wrapper {
    .bulk-edit-button {
      &:hover {
        border: none;
        opacity: 1;
      }
      border: none;
      opacity: 0.65;
      position: absolute;
      right: 0;
    }
    height: auto;
    .full-width();

    .fancytree-active:not(.fancytree-selected) {
       .fancytree-title {
        background-color: transparent;
      }
    }

    td {
      padding: 0 0.5rem;
    }
  }
}
