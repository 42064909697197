@bottom-menu-height-regular: 55px;
@bottom-menu-height-minimal: 28px;

.bottom-menu {
  height: @bottom-menu-height-regular;

  &.bottom-menu-minimal {
    height: @bottom-menu-height-minimal;
  }

  &.menu-open {
    position: relative; // creates issues with fixed footer layout, if always relative (e.g. team selection in manager)
  }
}

.bottom-menu-wrapper {
  .display-flex();
  background-color: @white;
  border-top: 1px solid @secondary-grey;
  height: 100%;
  width: 100%;

  @import '../menu/item/MenuItemBottom.less';

  .menu-open & {
    position: absolute;

    .bottom-menu-overflow-button .menu-item,
    .bottom-menu-overflow-button.hover .menu-item,
    .bottom-menu-overflow-button.focus-visible:focus .menu-item {
      background-color: rgba(0,0,0,0.1);
    }
  }
}

.bottom-menu-overflow-button {
  .button-reset-style();
  .flex(1);
  overflow: hidden;
  height: 100%;
  border: 0;
  padding: 0;

  &.focus-visible:focus {
    .ax-focus-keyboard();
  }
}

.bottom-menu-minimal .bottom-menu-overflow-button .menu-item {
  .align-items(flex-end);
  padding-right: 3.2rem;

  .item-text {
    display: none;
  }
}

.bottom-menu-overflow-menu {
  position: fixed;
  width: 100%;
  background-color: @black;
  top: 100%;
  left: 0;
  height: 100%;
}

.bottom-overflow-menu-item {
  .display-flex();
  .align-items(center);
  line-height: 4.8rem;
  background: none;
  border: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  width: 100%;
  text-align: left;
  padding: 0;
  box-sizing: border-box;

  &.selected {
    border-left: 3px solid @white;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    background-color: #4c4c4c;
  }

  .item-text {
    color: @white;
    font-size: 1.6rem;
    .text-ellipsis();
    .flex-grow(1);
  }

  .item-icon {
    color: @white;
    opacity: 1;
    font-size: 2.85rem;
    margin: 1.6rem;
  }

  .bottom-overflow-app-indicator {
    display: none;
  }

  &.focus-visible:focus,
  &.active,
  &.hover {
    background-color: #4c4c4c;
  }

  &.app-item {
    &.admin {
      background-color: @admin-color;
    }

    &.training {
      background-color: @training-color;
    }

    &.manager {
      background-color: @manager-color;
    }

    .bottom-overflow-app-indicator {
      display: block;
    }
  }
}
