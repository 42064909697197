.hub-preview-group {
  .display-flex();
  .flex-wrap(wrap);
  .box-sizing(border-box);
  .justify-content(space-between);
  .align-items(stretch);
}

.hub-preview-group__region {
  .box-sizing(border-box);
  width: 100%;
  margin-bottom: 3.2rem;

  .screen-md-portrait-min({
    width: ~'calc(50% - 1.3rem)';
    margin-bottom: 2.4rem;
  });

  .loading-skeleton-container,
  .loading-skeleton-container > *,
  .loading-skeleton-container > * > * {
    height: 100%;
  }
}

@import 'messages/MessagesCard.less';
@import 'rewards/RewardsCard.less';
@import 'events/EventsCard.less';
