
@webfont-path: '@common/prerequisites/assets/webfonts';

.font-face(@font-family, @filepath, @font-weight: @ax-font-weight-regular, @font-style: normal){
    @font-face {
        font-family: @font-family;
        src: url('@{filepath}.woff2') format('woff2'),
             url('@{filepath}.woff') format('woff'),
             url('@{filepath}.ttf')  format('truetype');
        font-weight: @font-weight;
        font-style: @font-style;
    }
}

.font-face('Roboto', '@{webfont-path}/Roboto-Regular-webfont');
.font-face('Roboto', '@{webfont-path}/Roboto-Regular-Italic-webfont', @ax-font-weight-regular, italic);
.font-face('Roboto', '@{webfont-path}/Roboto-Medium-webfont', @ax-font-weight-medium);
.font-face('Roboto', '@{webfont-path}/Roboto-Bold-webfont', @ax-font-weight-bold);
.font-face('Padauk', '@{webfont-path}/Padauk-Regular');
.font-face('Padauk', '@{webfont-path}/Padauk-Bold', @ax-font-weight-bold);
