/**
  Component classes to make life easier when developing components
*/

@import './learning-objectives.less';
@import './learning-objective-card.less';
@import './ProgramOverview.less';
@import './EvaluationDetails.less';
@import './MilestoneStatus.less';
@import './ProgressCard.less';
@import './Unenroll.less';
@import './UnenrollModal.less';

.task:not(.disabled) .box {
  width: 100%;

  .item-name,
  .completion-date,
  .points-container {
    color: @ax-color-text;
  }

  &:hover,
  &:focus {
    .clickable;
    border: 1px solid @black;
    background-color: @subtle-hover;
    .transition( all .2s );
  }
}

.event_enrollment:not(.disabled) button.box {
  width: 100%;

  .item-name,
  .completion-date,
  .points-container {
    color: @ax-color-text;
  }

  &:hover,
  &:focus {
    .clickable;
    border: 1px solid @black;
    background-color: @subtle-hover;
    .transition( all .2s );
  }
}

.event-learning-objective-item-view,
.topic-learning-objective-item-view,
.task-learning-objective-item-view {
  .screen-md-portrait-min({
    .display-flex();
    .text-ellipsis-softwrap;
  })
}

.gl-how-to-link {
  margin-right: @ax-gutter-page;
}

.guided-learning__page-header {
  margin-bottom: 0;
}

.guided-learning-icon-wrapper {
  .icon {
    font-size: 1.6rem;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }

  &.task {
    .icon {
      background-color: @red;
    }
  }

  &.event {
    .icon {
      background-color: @training-color;
    }
  }

  &.certification {
    .icon {
      background-color: @ax-color-certification;
    }
  }
}

.chevron-container {
  position: absolute;
  color: #cfcece;
  line-height: 4rem;

  [class^="icon-"],
  [class*=" icon-"] {
    font-size: inherit;
  }
}

.unavailable-banner-wrapper h2 {
  .align-items(center);
  .display-flex();
  margin: @ax-spacing-m .2rem;
  white-space: nowrap;

  &:before, &:after {
    border-top: 1px solid @accent-grey;
    content: '';
    .flex-grow(1);
  }

  &:before {
    margin-right: @ax-spacing-s;
  }

  &:after {
    margin-left: @ax-spacing-s;
  }
}

.learning-flow-overview {
  .completeness {
    .flex-grow(1);

    .progress-container {
      width: 100%;
    }
  }

  .assessment-left {
    padding: 0 1.6rem;
    .clearfix();

    .assessment-header {
      .display-flex();
      .align-items(center);

      .screen-sm-max({
        width: 100%;

        .bonuscharacter div {
          width: 10rem;
          height: 8.5rem;
          background-size: 104%;
          background-repeat: no-repeat;
        }
      });

      .screen-md-portrait-min({
        width: 100%;
      });

      .screen-lg-min({
        width: 80%;
        margin: 0 auto;
      });

      .thumbnail-image-wrapper {
        .size(8rem);
        min-width: 8rem;
        margin: 1rem 2rem 1rem 0;

        .screen-md-portrait-min({
          .size(15rem, 10rem);
          margin-left: 0.5rem;
          margin-right: 4rem;
        });

        .screen-lg-min({
          margin-left: -1.5rem;
        });

        &.milestone-header {
          .screen-md-portrait-min({
            margin-left: 3.5rem;
          });

          .screen-lg-min({
            margin-left: 1.2rem;
          });
        }

        img {
          .border-radius(@ax-border-radius-m);
          .size(100%);
          object-position: center;
          object-fit: cover;
        }
      }

      .progress-container {
        .transition(opacity @ax-transition-ease @ax-transition-speed);

        &.fade-out {
          opacity: 0;
        }

      }

      .enroll {
        .screen-md-portrait-min({
          margin-top: 0;
        });

        .screen-lg-min({
          margin-top: 1rem;
        });
      }
    }
  }

  .friendly-name-wrapper {
    float: left;
    padding-left: .5em;
    line-height: 1.6rem;
    min-height: 3.5rem;
    .display-flex();
    .flex(1);
    .flex-direction(column);
    .justify-content(center);
    .text-ellipsis-softwrap;

    .meta-text,
    .item-name {
      text-align: left;
      .text-ellipsis-softwrap;
    }

    .screen-sm-max({
      float: none;
      line-height: 1.6rem;

      .meta-text,
      .item-name {
        .text-ellipsis;
      }
    });
  }

  .points-container,
  .completion-date {
    line-height: 3.5rem;
  }

  .progress-bar-inset {
    background-color: @primary-blue;
  }

  .overview-wrapper {
    padding-bottom: 2em;
  }

  .call-to-action-wrapper {
    width: 90%;

    .screen-md-portrait-min({
      padding-top: 4.2rem;
      width: 80%;
    });
  }

  .meta-text {
    color: @secondary-text-color;
  }

  .reward-container {
    .chevron-container {
      line-height: 3.4rem;
      right: .8rem;
      bottom: 1rem;

      .screen-md-portrait-min({
        bottom: auto;
      });
    }
  }

  .buttons-container {
    .axon-button {
      width: auto;
    }

    .hidden {
      display: block!important;
      visibility: hidden!important;
    }

    .screen-xs({
      float:none;
      display: block;
    });
  }

  .second-line {
    float:right;

    .screen-sm-min({
      padding-left: 1rem;
    });

    .screen-sm-max({
      float: none;
      display: block;
      margin-top: 1.5em;

      .box-item {
        margin-left: 0;
      }
      .buttons-container {
          float: right;
          .text {
            display: none;
          }
          .axon-button {
            .border-radius(@ax-border-radius-m);
            width: auto;
            min-width: auto;
            height: 30px;
            line-height: 3rem;
            padding: 0 1em;

            [class^="icon-"] {
              padding: 0;
              line-height: 3rem;
              vertical-align: top;
            }
          }
      }
    });
  }

  .flow-overview-tiles {
    .display-flex();
    .flex-wrap();
  }

  .overview-knowledge-item {
    margin: 0 1em 1em 0;
    border: 1px solid @secondary-grey;
    .border-radius(@ax-border-radius-m);
    .box-shadow-black(0px, 1px, 2px, 0.1);
    vertical-align: top;
    min-height: 95px;

    &.disabled {
      &:hover {
        background-color: @accent-grey;
      }
    }

    .icon-chevron_right {
      position: absolute;
      right:1em;
      top:1em;
      color:@secondary-grey;
    }

    .icon-lock {
      position: absolute;
      right:1em;
      top:1em;
      color:@primary-grey;
    }

    &.disabled {
      background-color: @accent-grey;
      cursor: not-allowed;

      border: 1px solid @secondary-grey !important;

      .icon-chevron_right {
        display: none;
      }
    }

    .screen-md-portrait-min({
      width: 100%;
    });

    .screen-sm-max({
      width: 100%;
    });

    .screen-lg-min({
      width:31%;

      &:nth-child(2n+0) {
        margin-right: 1em;
      }

      &:nth-child(3n+0) {
        margin-right: 0;
      }
    });


    &:hover {
      border: 1px solid @black;
      background-color: @subtle-hover;
      .transition( all .2s );
    }

    &.focus-visible:focus {
      box-shadow: inset 0px 0px 2px 2px @primary-blue;
    }

    .overview-summary {
      padding:1em;

      .progress-container-wrapper {
        margin-top:1em;
      }

      .hidden-thumbnail {
        display:none;
      }

      .overview-title {
        width: 80%;
      }

      h3 {
        font-size: @ax-font-size-body;
        margin-left: 0;
        margin-bottom: 0;
        margin-top: 0;
        .text-ellipsis;
      }

      .icon-container {
        margin-right: 1em;
        color: @secondary-grey;
        .icon-marker {
          font-size: @icon-nav-size;
          color: @secondary-text-color;
        }
      }
    }
  }
}

.milestone-content {
  .display-flex();
}

.milestone-content__icon {
  .flex-grow(0);
  font-size: 2.2rem;
  margin: @ax-spacing-s @ax-spacing-m 0 0;
}

.milestone-content__info-wrapper {
  .align-items(center);
  .display-flex();
  .flex-grow(1);

  .screen-md-portrait-max({
    display: block;
  });
}

.milestone-content__info {
  .flex-grow(1);

  .screen-md-portrait-max({
    margin-right: 0;
    margin-bottom: @ax-spacing-s;
  });

  p {
    .screen-md-landscape-min({
      margin-bottom: 0;
    });
  }
}

.milestone-content__title {
  font-size: @ax-font-size-s;
}

.milestone-content__due-date,
.milestone-content__reward-text {
  color: @ax-color-grey-60;
}

.milestone-content__reward-text {
  .screen-md-landscape-min({
    white-space: nowrap;
  });
}

.milestone-content__rewards {
  .screen-md-landscape-min({
    padding-left: @ax-spacing-l;
  });
}

.milestone-content__rewards p {
  font-weight: @ax-font-weight-medium;
  margin-bottom: @ax-spacing-s;

  .screen-md-landscape-min({
    white-space: nowrap;
  });
}

.milestone-content__reward-icon {
  .border-radius(100%);
  padding: 2px;
  font-size: 1rem;
  margin-right: 5px;
}

.guided-learning-empty-text {
  height: auto;
  padding: 1em 0;
  border: 0;
  background: @white;
  background-color: @white;
  text-align: center;
  color: @secondary-text-color;
  .box-shadow-black(0px, 1px, 2px, 0.1);
  .border-radius(0px);
}

.learning-flow-overview {
  &.no-enrollment {
    .learning-objective-card__action {
      display: none;
    }
  }

  .overview-header {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .screen-md-portrait-min({
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    });
  }

  .milestone-status-thumbs-up{
    color: @black;
    background: transparent;
  }
  .milestone-status-yellow{
    color: @yellow;
    background: transparent;
  }
  .milestone-status-red{
    color: @red;
    background: transparent;
  }

  .milestones-static {
    padding-top: 10px;
  }

  .h2-font-size{
    font-size: @h2-font-size;
  }

  .progress-figure{
    padding-right: 20px;
    margin: 0;
    font-size: @news-item-icon-size-small;
  }

  .grandfathered{
    .align-items(center);
  }

  .grey-background {
    background: @primary-grey;
    color: @white;
  }
  .green-background {
    background: @green;
    color: @white;
  }

  .milestone-row {
    .clearfix();
    position: relative;
  }

  .milestone-row-container{
    padding: 3rem 0;
    margin: -@learning-objective-bottom-margin 0 0 @progress-width;
  }
}

.gl-explanation-modal {
  background: @white;
  max-width: 900px;
  padding-bottom: 0;
  .screen-sm-max({
    width: 100%;
  });
  .screen-md-portrait-min({
    width: 95%;
  });

  .gl-modal-header{
    border: none;
  }

  .gl-modal-description {
    overflow: hidden;
    white-space: normal;
    .no-padding();

    h2 {
      padding-bottom: @ax-spacing-s;
    }
  }

  .modal-text{
    display: inline-block;
    height: inherit;
    line-height: inherit;
    padding-left: 10px;
    padding-bottom: 10px;
    h1 {
      padding-top: 20px;
      width: inherit;
      word-break: break-word;
    }
    p {
      padding-top: 5px;
      width: inherit;
      word-break: break-word;
    }
  }

  .gl-modal-flexbox {
    width: 95%;
    margin-left: 2.5%;
    border: none;
    .display-flex();
    .flex-direction(column);
    .justify-content(center);
    .last-item {
      border: none;
    }
    .gl-modal-item-text{
      .flex-basis(100%);
      h2{
        font-size: @h2-font-size;
      }
      p{
        line-height: inherit;
      }
    }
  }

  .gl-modal-row {
    padding: 10px;
    border-bottom: 1px solid @secondary-grey;
    padding-bottom: 20px;
    .display-flex();
    .align-items(center);
    .flex-direction(row);
  }

  .gl-modal-icons {
    position: relative;
    padding-right: 10px;
    min-width: 55px;
    .flex-basis(55px);
  }

  .milestone-icon{
    margin-left: 10px;
    .border-radius(100%);
    background: @primary-grey;
  }
  .thumbs-up-icon {
    font-size: @tile-icon-size;
    color: @black;
  }
  .needs-coaching-icon {
    font-size: @tile-icon-size;
  }
  .layered-icon {
    margin: 0;
    margin-top: -10px;
    margin-left: 20px;
  }
  .trans-back{
    background: transparent;
  }
}
