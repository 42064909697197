@learning-objective-bottom-margin: @ax-spacing-m;

@progress-width: 5rem;
@progress-width-mobile: 3.5rem;
@progress-indicator-width: 2rem;
@progress-indicator-width-mobile: 1.4rem;
@progress-indicator-medium-width: 2.4rem;
@progress-indicator-medium-width-mobile: 1.8rem;
@progress-indicator-large-width: 3.8rem;
@progress-indicator-large-width-mobile: 2.4rem;

.learning-objective-mobile-styles(@rules) {
  .screen-md-portrait-max({
    @rules();
  });
}

.learning-objectives {
  .clearfix();
  margin-left: @progress-width;
  margin-bottom: @learning-objective-bottom-margin;
  overflow: visible;

  .learning-objective-mobile-styles({
    margin-left: @progress-width-mobile;
  });
}

.learning-objectives__item {
  .clearfix();
  border-bottom: 1px solid @secondary-grey;
  position: relative;

  &:last-child {
    border-bottom: none;
  }
}

.learning-objectives__progress {
  left: -@progress-width;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: -@ax-spacing-m;
  margin-bottom: -@ax-spacing-m;
  width: @progress-indicator-large-width;

  .learning-objective-mobile-styles({
    left: -@progress-width-mobile;
    width: @progress-indicator-large-width-mobile;
  });
}

.learning-objectives__progress-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}

.learning-objectives__progress-indicator,
.learning-objectives__progress-connector {
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.learning-objectives__progress-indicator {
  .align-items(center);
  .justify-content(center);
  background-color: @secondary-grey;
  .border-radius(50%);
  .display-flex();
  font-size: 1.5rem;
  position: absolute;
  top: calc(50% - (@progress-indicator-width / 2));
  .size(@progress-indicator-width);

  .learning-objective-mobile-styles({
    top: calc(50% - (@progress-indicator-width-mobile / 2));
    .size(@progress-indicator-width-mobile);
  });

  &&--complete {
    background-color: @ax-color-icon-green-a11y;
    &:before {
      color: white;
      content: @icon-ok;

      .learning-objective-mobile-styles({
        font-size: 1.2rem;
      });
    }
  }

  &&--event,
  &&--failed {
    background-color: @white;
    top: calc(50% - (@progress-indicator-medium-width / 2));
    width: @progress-indicator-medium-width;
    height: @progress-indicator-width;

    .learning-objective-mobile-styles({
      top: calc(50% - (@progress-indicator-medium-width-mobile / 2));
      width: @progress-indicator-medium-width-mobile;
      height: @progress-indicator-width-mobile;
    });

    &:before {
      font-size: @progress-indicator-medium-width;

      .learning-objective-mobile-styles({
        font-size: @progress-indicator-medium-width-mobile;
      });
    }
  }

  &&--event {
    &:before {
      color: @ax-color-grey-80;
      content: @icon-pending;
    }
  }

  &&--failed {
    &:before {
      color: @red;
      content: @icon-exclamation;
    }
  }

  &&--large {
    .size(@progress-indicator-large-width);
    top: calc(50% - (@progress-indicator-large-width / 2));

    .learning-objective-mobile-styles({
      top: calc(50% - (@progress-indicator-large-width-mobile / 2));
      .size(@progress-indicator-large-width-mobile);
    });

    &:before {
      font-size: 2.2rem;

      .learning-objective-mobile-styles({
        font-size: 1.8rem;
      });
    }
  }
}

.learning-objectives__progress-connector {
  background-color: @secondary-grey;
  height: 50%;
  position: absolute;
  width: .4rem;


  &&--previous {
    top: 0;
  }

  &&--next {
    top: 50%;
  }

  &&--complete {
    background-color: @green;
  }

  &&--none {
    display: none;
  }
}

.learning-objectives__content {
  min-height: 100px; //  this is temp and will go away when card content is done
}

.milestone-row-container .learning-objectives__progress {
  left: 0;
}
