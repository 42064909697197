.full-height,
.parent-height {
  height: 100%;
}

.parent-width,
.full-width,
.fullwidth {
  width: 100%;
}

.fit-parent {
  .parent-height();
  .parent-width();
}

.inline {display: inline;}
.clear {clear: both;}
.hidden {display: none!important;}
.invisible{visibility: hidden;}
.block {display: block;}
.inline-block {display: inline-block;}
.vertical-align-middle {vertical-align: middle;}
.vertical-align-top {vertical-align: top;}
.vertical-align-bottom {vertical-align: bottom;}
.no-hover {
  &:hover,
  &:focus {opacity: 1.0}
}

.vertical-absolute-middle {
  position: absolute;
  top: 50%;
  .transform(translateY(-50%));
}

.relative {position: relative;}

.absolute {position: absolute;}
.absolute-fit-parent {
  .absolute();
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.auto {
    overflow: auto;
  }
}
