.skeleton {
  display: block;
  height: @ax-spacing-l;
  width: 15rem;
  background-color: @ax-skeleton-background-color;
  transform-origin: 0 55%;
  transform: scale(1, 0.60);
  border-radius: @ax-skeleton-border-radius;
  -webkit-animation: skeleton-animation 1s ease-in-out alternate 0.5s infinite;
  animation: skeleton-animation 1s ease-in-out alternate 0.5s infinite;

  &:empty:before {
    content:"\00a0";
  }

  &--sm {
    width: 10rem;
  }

  &__badge {
    height: @ax-spacing-xxl;
    width: @ax-spacing-xxl;
    margin-top: @ax-spacing-xs;
    margin-right: @ax-spacing-m;

    &--sm {
      height: @ax-spacing-xl;
      width: @ax-spacing-xl;
    }

    &--lg {
      height: 4.8rem;
      width: 4.8rem;
    }
  }
}

@keyframes skeleton-animation {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.4
  }
  100% {
    opacity: 1
  }
}
