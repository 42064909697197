.axon-button {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  .border-radius(@ax-border-radius-m);
  .box-shadow-black(0, .1rem, .2rem, 0.1);
  .transition(background-color @ax-transition-speed);
  background-color: @primary-blue;
  border: .1rem solid @primary-blue;
  color: @white;
  display: inline-block;
  line-height: 3.5rem;
  min-height: 3.5rem;
  min-width: 7.5rem;
  outline: none;
  padding: 0 1em;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  .ax-breakpoint-m({
    min-width: 0;
  });

  &:hover,
  &:focus,
  &.pressed,
  &.pushed {
    background-color: darken(@primary-blue, 5%);
    cursor: pointer;
    text-decoration: none;
  }

  input {
    background: @primary-blue;
    cursor: pointer!important;

    &:hover,
    &:focus {
      background: @primary-hover-blue;
    }
  }

  .action-text {
    padding: 0 .2rem;
  }

  [class^="icon-"],
  [class*="icon-"] {
    color: @white;
    font-size: @icon-button-size;
    line-height: @ax-font-size-body;
    opacity: 1;
    padding: 0 .2rem;
    text-align: center;
    vertical-align: middle;
  }

  // Delete button
  &.red,
  &.delete {
    background-color: @red;
    border-color: @red;
    color: @white;

    &:hover,
    &:focus,
    &.pressed,
    &.pushed {
      background-color: @red-hover;
      border-color: @red-hover;
      color: @white;
    }

    [class*="icon-"] {
      color: @white;
    }
  }

  // Save button
  &.green {
    background-color: @ax-color-icon-green-a11y;
    border-color: @ax-color-icon-green-a11y;
    color: @white;

    &:hover,
    &:focus,
    &.pressed,
    &.pushed {
      background-color: @green-hover!important;
      border-color: @ax-color-icon-green-a11y!important;
      color: @white;
    }

    [class*="icon-"] {
      color: @white;
    }

    // Disabled buttons don't maintain their normal color
    &.disabled:hover,
    &.disabled:focus,
    &.disabled.pressed,
    &.disabled.pushed {
      background-color: @ax-color-grey-60 !important;
      border-color: @ax-color-grey-60 !important;
    }
  }

  // Secondary buttons
  &.white,
  &.auction {
    background-color: @secondary-bg;
    border: .1rem solid @secondary-grey;
    color: @black;

    &:hover,
    &:focus,
    &.pressed,
    &.pushed {
      background-color: @white;
      border-color: @secondary-grey!important;
      color: @black;
    }

    [class*="icon-"] {
      color: @black;
    }

    &.editbid,
    &.editbid:link,
    &.editbid:visited {
      background: @black;
      border: .1rem solid @black;
      color: @white;
    }

    &.auction {
      padding: 0;
      width: auto;
    }

    [class*="icon-"] {
      color: @black;
      padding-right: 0;
      text-align: left;
      vertical-align: middle;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  // Disabled states
  &.disabled,
  &.disabled:focus,
  &.disabled:hover,
  &.grey,
  &.grey:hover,
  &.grey:focus {
    background-color: @ax-color-grey-60;
    border-color: @ax-color-grey-60;
    color: @ax-color-white;
    cursor: default;

    [class*="icon-"] {
      color: @accent-grey;
    }
  }

  &.nomargin {
    margin: 0;
  }

  &.header-button {
    .box-sizing(border-box);
    float: right;
    width: 100%;

    .ax-breakpoint-m({
      width: auto;
    });
  }

  .header-buttons {
    padding-top: .5em;

    .ax-breakpoint-m({
      padding-top: 0;
    });
  }

  .icon-skip {
    font-size: 2.4rem;

    &:before {
      position: relative;
      top: -.2rem;
    }
  }
}

.btn-default {
  color:@black;
  background-color: @secondary-bg;
  border: .1rem solid @secondary-grey;
  [class*="icon-"] {
      color:@black;
      opacity: 1.0;
    }
  &:hover,
  &:focus {
    background-color:@white;
    cursor:pointer;
    text-decoration: none;
    .transition( all .2s );
  }
}

//Bootstrap Overrides
.btn-primary,
  .btn-default {
    background-image: none;
    padding: @ax-spacing-xs @ax-spacing-m;
    font-size: @ax-font-size-body;
  }

.btn-primary {
  background-color: @primary-blue;
  border: .1rem solid @primary-blue;
  [class*="icon-"] {
      color:@white;
      opacity: 1.0;
    }
  &:hover,
  &:focus {
    background-color:@primary-hover-blue;
    cursor:pointer;
    text-decoration: none;
    .transition( all .2s );
  }
}
