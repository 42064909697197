// Report card main list

.knowledgeitemlist {
  margin: 0 -@ax-gutter-page;

  .ax-breakpoint-m({
    border-top: .1rem solid @ax-color-grey-30;
    margin: 0;
  });

  .knowledgeitemwrapper {
    border-top: .1rem solid @ax-color-grey-30;
    position: relative;

    .ax-breakpoint-m({
      border-bottom: .1rem solid @ax-color-grey-30;
      border-top: none;
    });
  }

  .knowledgeitem .questions {
    margin: 2em 1.8em 0;
    display: none;

    .questionswrap .answerblock {
      padding: 2em 0;

      &:nth-of-type(2) {
        border-top: .1rem solid @ax-color-grey-30;
      }
    }

    .graduation {
      display: block;
    }
  }

  > div:nth-of-type(2) {
    border: none;
    box-shadow: none;
  }
}

#knowledgemap .trainingavailable .questions .footer,
#knowledgemap .questionsavailable .questions .footer,
#knowledgemap .trainingavailable .questions .footer .extratrainingbuttons,
#knowledgemap .questionsavailable .questions .footer .extratrainingbuttons,
.trainingavailable .knowledgeitem .extratrainingbuttons,
.questionsavailable .knowledgeitem .extratrainingbuttons {
  display: block;
}
