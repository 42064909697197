/* rtl:begin:ignore */

//Base Resets
html {
  box-sizing: border-box;
  font-size: @ax-font-size-html;
  font-family: @ax-font-family;

  body {
    font-family: @ax-font-family;
  }

  &[data-language="my"],
  &[data-language="my"] body {
    // Burmese font change
    font-family: @ax-font-family-MY;
  }

  &[data-language="vi"] {
    // Vietnamese
    font-size: @ax-font-size-html-m;
  }

  &[data-language="my"],
  &[data-language="th"] {
    // Burmese & Thai
    font-size: @ax-font-size-html-l;
  }

  &[data-language="km"] {
    // Cambodian
    font-size: @ax-font-size-html-xl;
  }

  // IE/Edge rounding causes issues when using rem for calculations, especially for pixel-perfect widths (ie. ax-grid)
  .ax-media-query-ie-edge({
    font-size: @ax-font-size-html-ie-edge;
  });
}

//Body font
body,
#main {
  font-style: normal;
  line-height: @ax-line-height-s;
  color: @ax-color-text;
  padding: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  font-weight: @ax-font-weight-regular;
  font-size: @ax-font-size-body;
}

//Font Styles
strong, b {font-weight: @ax-font-weight-bold;}

//Lists
ul,ol, li {
  list-style: none;
	margin: 0;
	padding:0;
}

//Paragraph Text
p {
	margin: 0;
	padding: 0;
}

/* rtl:end:ignore */
