@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.menu-dropdown__container {
  position: relative; // this is important, so that the absolutely positioned popover is aligned relative to the button
}

.menu-dropdown__button-container {
  .menu-dropdown__default-secondary-button {
    .thick-gray-button();
  }
}

.menu-dropdown__popover {
  // the "left" position is adjusted by JS
  position: absolute;
  top: 4.8rem; // this is 4rem, (the trigger button height) plus @ax-spacing-s
  z-index: 1000;
  min-width: 24rem;
  background-color: @white;
  border: 1px solid @secondary-grey;

  .border-radius(@ax-border-radius-m);
  .box-shadow-black(0px, 1px, 2px, 0.1);

  ul {
    margin: 0;

    li {
      border-bottom: none;
      list-style: none;
      margin: 0;

      &:first-child {
        border-top-left-radius: @ax-border-radius-m;
        border-top-right-radius: @ax-border-radius-m;
      }

      &:last-child {
        border-bottom: 0;
        border-bottom-left-radius: @ax-border-radius-m;
        border-bottom-right-radius: @ax-border-radius-m;
      }

      &:hover {
        background-color: @element-hover-bright;
      }

      a {
        color: inherit;
        padding: @ax-spacing-s;
        font: inherit;
        cursor: pointer;
        background: none;
        width: 100%;
        box-sizing : border-box;

        &.focus-visible:focus {
          .ax-focus-keyboard();
        }
      }

      a [class*="icon-"] {
        opacity: 1;
      }

      button {
        border: none;
        color: inherit;
        background: none;
        text-align: left;
        padding: 0 @ax-spacing-s;

        .full-width();
        min-height: 4rem;
        font: inherit;
        cursor: pointer;

        &.focus-visible:focus {
          .ax-focus-keyboard();
        }

      }
      .dropdown-button-icon {
        margin-right: @ax-spacing-s;
      }
      .dropdown-button-text {
        margin-left: 0;
        font-size: @ax-font-size-s;
      }
    }
  }
}

.actionable-menu-dropdown {
  .main-action--with-options {
    .border-radius(@ax-border-radius-m 0 0 @ax-border-radius-m);
    border-right-width: 0;
  }
  .menu-dropdown {
    &__toggle-button {
      .border-radius(0 @ax-border-radius-m @ax-border-radius-m 0);
      padding: 0;
      position: relative;
      border-left: none;
    }

    &__popover {
      min-width: 14rem;
    }
  }
}
