@ax-progressBar-background-color: @ax-color-grey-30;
@ax-progressBar-border-radius: @ax-border-radius-m;
@ax-progressBar-border: .1rem solid @ax-color-grey-50;
@ax-progressBar-inset-background-color: @black;
@ax-progressBar-height: 1rem;

:root {
  --ax-progress-bar-background-color: @ax-progressBar-background-color;
  --ax-progress-bar-border-radius: @ax-progressBar-border-radius;
  --ax-progress-bar-border: @ax-progressBar-border;
  --ax-progress-bar-inset-background-color: @ax-progressBar-inset-background-color;
  --ax-progress-bar-height: @ax-progressBar-height;
}