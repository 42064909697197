.tags-actions {
  margin-top: @ax-spacing-xl;
}

.tags_warning {
  margin-top: @ax-spacing-m;
  .icon-exclamation {
    color: @ax-color-blue-60;
    margin-right: @ax-spacing-s;
  }
}
