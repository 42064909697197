
@border-width-content-tabs: .2rem;
@rewards-tab-icon-size:     3.2rem;

.rewards-tabs {
  .display-flex();
  color: @ax-tabs-color;
  height: @ax-tabs-height;
  margin: 0 -@ax-gutter-page;
  padding: 0 @ax-gutter-page;
  background-color: transparent;
  border-bottom: @ax-tabs-divider-height solid @ax-tabs-divider-color;

  &.tab-count-4 li {
    width: 25%;
  }

  .ax-breakpoint-m({
    margin: 0;
  });

  .ax-breakpoint-l({
    font-size: 1.6rem;

    &.tab-count-4 li {
      width: auto;
    }
  });
}

.rewards-tabs li {
  .flex-grow(1);
  line-height: 5rem;
  text-align: center;

  .ax-breakpoint-m({
    .flex-grow(0);
  });
}

.rewards-tabs li a {
  .text-ellipsis();
  height: @ax-tabs-height;
  color: @ax-tabs-color;
  display: block;
  padding: 0 .6rem;
  text-decoration: none;
  min-width: 5rem;
  box-sizing: border-box;
  
  .transition( color ease-in-out 0.2s );

  .ax-breakpoint-m({
    padding: 0 1.2rem;
  });
}


.rewards-tabs li a {
  display: block;

  .tab-icon {
    font-size: @rewards-tab-icon-size;
    opacity: 1;
  }

  .tab-label {
    font-size: 0;
    visibility: hidden;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: @ax-tabs-color-selected;
  }

  &.selected,
  &:hover.selected,
  &:focus.selected {
    border-bottom: @ax-tabs-divider-height solid var(--ax-tabs-borderColor-selected);
    color: @ax-tabs-color-selected;
  }

  &.focus-visible:focus {
    .ax-focus-keyboard(inset);
  }
}

.subtabs .border-black {
  border-color: @black !important;
}

// Overrides
.subtabs.hide {
  display: none;
}

.page-content > .subtabs {
  .ax-breakpoint-m {
    margin-top: 0;
  }
}
