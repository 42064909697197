.quick-links-card {
  &__upper {
    align-items: center;
    height: auto;

    .ax-breakpoint-m({
      height: @ax-spacing-xl;
    });
  }

  &__view-all--btn {
    display: none;

    .ax-breakpoint-m({
      display: block;
    });
  }

  &__view-all--thumbnail {
    display: block;
    margin: 0;
    height: @ax-spacing-xl;
    width: @ax-spacing-xl;

    .ax-breakpoint-m({
      display: none;
    });
  }

  &__lower {
    padding: 0;

    .ax-breakpoint-m({
      padding: 0 @ax-spacing-m @ax-spacing-m;
    });
  }

  &__list {
    display: none;

    &--item {
      padding: 0;

      .ax-breakpoint-m({
        padding: @ax-spacing-s;
      });
    }

    &--item-badge {
      margin: 0 auto @ax-spacing-s;
    }

    .ax-breakpoint-m({
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      justify-content: space-around;
      align-items: center;
    })
  }
}
