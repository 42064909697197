.ax-container {
  width: 100%;
  margin: 0 auto;
  max-width: @ax-page-width-s;

  .screen-lg-min({ max-width: @ax-page-width-l; });

  &&--full-page {
    max-width: 100%!important; // Forces page to break out of the grid set up by ax-container(); ie login page
  }
}

#page-list,
.page-content {
  padding: @ax-gutter-page;

  & & {
    padding: 0;
  }
}
