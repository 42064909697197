// banners appear in three ways.
// 1) needs review, in grid mode
// 2) needs review, in list mode
// in those two places, there is only ever one banner, they are never stacked up (as of April 2023)

// 3) on the Article Detail page,
// and on that page there could be multiple banners all stacked up

.article-search__results-region {
  // this styles the banners in the Needs Review page
  // when in grid mode, there is content in ".grid-banner-region"
  // when in list mode, there is content in ".list-banner-region"

  // both grid and list mode have this container
  .notify-container {
    .display-flex();
    .align-items(center);
    margin-bottom: @ax-spacing-xs;
    padding: @ax-spacing-xs;
    padding-left: @ax-spacing-s;
    background-color: @white;

    .icon {
      width: inherit;
      height: inherit;
      color: inherit;
      margin: 0;
      background-color: inherit;
      float: none;
      display: inline-block;
      font-size: @ax-font-size-m;
      vertical-align: top;
      line-height: @ax-line-height-m;
    }
    .info {
      margin-left: .5rem;
      display: inline-block;
      .flex(auto);
      line-height: @ax-line-height-s;
      h3, p {
        margin: 0;
      }

      strong {
        white-space: nowrap;
        word-wrap: nowrap;
      }

      .explanation {
        max-height: 6rem;
        line-height: @ax-line-height-s;
        overflow: hidden;
      }

    }
  }

   // the background and font color are configured the same in grid or list mode
   .inReview-banner {
    background: @ax-color-blue-80;
    color: @ax-color-white;
  }
  .reported-banner {
    background: #900924;
    color: @ax-color-white;
  }
  .expired-banner {
    background: #CC4800;
    color: @ax-color-white;
  }
  .scheduled-banner {
    background: #CC4800;
    color: @ax-color-white;
  }
  .multiple-banner {
    background: #545459;
    color: @ax-color-white;
  }

  // this styles the banners in the Needs Review page, when in Grid view
  .grid-banner-region {
    .banner-wrapper {
      left: 0;
      right: 0;
    }
    .notify-container {
      .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0); // grid view, rounded NW and NE
      .screen-md-portrait-min({
        margin-bottom: 0;
      });
    }
  }

  // this styles the banners in the Needs Review page, when in List view
  .list-banner-region {
    .display-flex();
    .flex-basis(auto);
    .justify-content(flex-end);
    .border-radius(0 @ax-border-radius-m 0 0);
    min-height: 0;
    max-height: 20rem;
    .notify-container {
      padding-right: @ax-spacing-s;
      .border-radius(0 @ax-border-radius-m 0 @ax-border-radius-m); // list view, rounded NE and SW
      .info {
        width: auto;
      }
    }
  }
}



// this styles the banners on the article detail view, and the "closed beta" banner on the
// ask max page
.article-details__region, .conversationalSearch {

  .page-content-notifications {
    .display-flex();
    .flex-direction(column);
    .banner-wrapper {
      margin-bottom: @ax-spacing-s;
    }
  }

  .notify-container {
    margin: 0;
    padding: @ax-spacing-s;
    border: 1px solid @ax-color-blue-60;
    color: @ax-color-blue-100;
    .flex-direction(row);
    .border-radius(@ax-border-radius-m);

    i {
      float: left;
      margin-right: @ax-spacing-s;
    }

    .info {
      h3, p {
        margin: 0;
      }

      strong {
        white-space: nowrap;
        word-wrap: nowrap;
      }
    }

    &.late-publish {
      border: 1px solid @ax-color-red-60;
      color: @ax-color-red-80;
    }

    &:nth-child(2) {
      margin-top: @ax-spacing-s;
    }

    &.inReview-banner {
      border: 1px solid @ax-color-blue-100;
      color: @ax-color-blue-100;

      .icon-checkbox-outline {
        color: @ax-color-blue-100;
      }
      .display-flex();
      .justify-content(space-between);
    }

    &.expired-banner {
      color: @ax-color-orange-100;
      border: 1px solid @ax-color-orange-100;

      p {
        color: @ax-color-grey-80;
      }

      h3 strong {
        color: @ax-color-orange-100;
      }

      .icon-exclamation {
        color: @ax-color-orange-100;
      }
    }

    &.reported-banner {
      color: @ax-color-grey-80;
      border: 1px solid @ax-color-orange-100;
      h3 {
        color: @ax-color-orange-100;
        font-weight: bold;
      }
      .icon-exclamation_sign-outline {
        background-color: none;
        color: @ax-color-orange-100;
      }
    }

    &.beta-banner {
      border: 1px solid @ax-color-blue-100;
      color: @ax-color-grey-60;

      h3 {
        color: @ax-color-grey-80;
        font-weight: bold;
      }
      .icon-exclamation_sign-outline {
        color: @ax-color-blue-100;
      }
    }

    .banner-context {
      .display-flex();
      .flex-direction(row);
    }

  }

}
