@_badge-topic-text-color: @ax-color-topic-80;
@_badge-topic-background-color: @ax-color-topic-40;

@_badge-path-text-color: @ax-color-topic-80;
@_badge-path-background-color: @ax-color-topic-40;

@_badge-certification-text-color: @ax-color-certification-80;
@_badge-certification-background-color: @ax-color-certification-40;

@_badge-exam-text-color: @ax-color-evaluation-80;
@_badge-exam-background-color: @ax-color-evaluation-40;

@_badge-task-text-color: @ax-color-task-80;
@_badge-task-background-color: @ax-color-task-40;

@_badge-event-text-color: @ax-color-event-80;
@_badge-event-background-color: @ax-color-event-40;

@_badge-recommendation-text-color: @ax-color-blue-60;
@_badge-recommendation-background-color: @ax-color-white;
@_badge-recommendation-border-color: @_badge-recommendation-background-color;

@_badge-beta-text-color: @ax-color-blue-60;
@_badge-beta-background-color: @ax-color-white;
@_badge-beta-border-color: @_badge-beta-background-color;

@_badge-milestone-text-color: @ax-color-grey-80;
@_badge-milestone-background-color: @ax-color-grey-20;

@_badge-evaluation-text-color: @ax-color-evaluation-80;
@_badge-evaluation-background-color: @ax-color-evaluation-40;

@_badge-validation-passed-text-color: @ax-color-green-80;
@_badge-validation-passed-background-color: @green-background;
@_badge-validation-failed-text-color: @ax-color-red-80;
@_badge-validation-failed-background-color: @red-background;
@_badge-validation-pending-text-color: @ax-color-grey-80;
@_badge-validation-pending-background-color: @ax-color-grey-30;

@_badge-module-text-color: #035E42;
@_badge-module-background-color: #D6F7EF;
@_badge-module-border-color: @_badge-module-background-color;

@_badge-survey-text-color: #035E42;
@_badge-survey-background-color: #D6F7EF;
@_badge-survey-border-color: @_badge-survey-background-color;

@_badge-reassigned-text-color: #272D2A;
@_badge-reassigned-background-color: #EFEFEF;
@_badge-reassigned-border-color: @_badge-reassigned-background-color;

@_badge-link-text-color: #1767DD;
@_badge-link-background-color: #D0EEFF;

@_badge-expired-text-color: #B43F00;
@_badge-expired-background-color: #FFE0B2;

@_badge-draft-text-color: @ax-color-grey-80;
@_badge-draft-background-color: @ax-color-grey-30;

.ax-tag {
  .border-radius(1.2rem);
  display: inline;
  font-size: 1.2rem;
  font-weight: @ax-font-weight-medium;
  padding: .4rem .8rem;
  text-transform: uppercase;
  margin: 0;
  line-height: 2rem;

  &&--beta{
    color: @_badge-recommendation-text-color;
    background-color:  @_badge-recommendation-background-color;
    border: 1px solid @_badge-recommendation-text-color;
    border-radius: 2rem;
    padding: .2rem .8rem;
    font-size: 0.9rem;
    text-transform: none;
  }
  &&--recommendation{
    background-color: @_badge-recommendation-background-color;
    color: @_badge-recommendation-text-color;
  }
  &&--topic {
    background-color: @_badge-topic-background-color;
    color: @_badge-topic-text-color;
  }
  &&--certification {
    background-color: @_badge-certification-background-color;
    color: @_badge-certification-text-color;
  }
  &&--exam {
    background-color: @_badge-exam-background-color;
    color: @_badge-exam-text-color;
  }
  &&--event_enrollment,
  &&--event {
    background-color: @_badge-event-background-color;
    color: @_badge-event-text-color;
  }
  &&--task {
    background-color: @_badge-task-background-color;
    color: @_badge-task-text-color;
  }
  &&--assigned,
  &&--enrolled,
  &&--milestone {
    background-color: @_badge-milestone-background-color;
    color: @_badge-milestone-text-color;
  }
  &--path {
    background-color: @_badge-path-background-color;
    color: @_badge-path-text-color;
  }
  &&--evaluation {
    background: @_badge-evaluation-background-color;
    color: @_badge-evaluation-text-color;
  }
  &&[class*="validation-"] {
    text-transform: none;
    font-size: 1.4rem;
    line-height: 1.2rem;
  }
  &&--validation-passed {
    background: @_badge-validation-passed-background-color;
    color: @_badge-validation-passed-text-color;
  }
  &&--validation-failed {
    background: @_badge-validation-failed-background-color;
    color: @_badge-validation-failed-text-color;
  }
  &&--validation-pending {
    background: @_badge-validation-pending-background-color;
    color: @_badge-validation-pending-text-color;
  }
  &&--export-type {
    text-transform: none;
    background-color: @ax-color-grey-20;
    color: @ax-color-grey-70;
  }
  [class*="icon-"] {
    font-size: 1.5rem;
    margin-right: 0.3rem;
  }
  &&--module {
    background-color: @_badge-module-background-color;
    color: @_badge-module-text-color;
  }
  &&--survey {
    background-color: @_badge-survey-background-color;
    color: @_badge-survey-text-color;
  }
  &&--reassigned {
    background-color: @_badge-reassigned-background-color;
    color: @_badge-reassigned-text-color;
  }
  &&--link {
    background-color: @_badge-link-background-color;
    color: @_badge-link-text-color;
  }
  &&-event {
    background-color: @_badge-event-background-color;
    color: @_badge-event-text-color;
  }
  &&-expired {
    background-color: @_badge-expired-background-color;
    color: @_badge-expired-text-color;
  }
  &&-draft {
    background-color: @_badge-draft-background-color;
    color: @_badge-draft-text-color;
  }
}

:root {
  --axTag-border-color: @ax-color-grey-40;
  
  --axTag-topic-text-color: @_badge-topic-text-color;
  --axTag-topic-background-color: @_badge-topic-background-color;
  
  --axTag-path-text-color: @_badge-path-text-color;
  --axTag-path-background-color: @_badge-path-background-color;
  
  --axTag-certification-text-color: @_badge-certification-text-color;
  --axTag-certification-background-color: @_badge-certification-background-color;
  
  --axTag-exam-text-color: @_badge-exam-text-color;
  --axTag-exam-background-color: @_badge-exam-background-color;
  
  --axTag-task-text-color: @_badge-task-text-color;
  --axTag-task-background-color: @_badge-task-background-color;
  
  --axTag-event-text-color: @_badge-event-text-color;
  --axTag-event-background-color: @_badge-event-background-color;
  
  --axTag-beta-text-color: @_badge-beta-text-color;
  --axTag-beta-background-color: @_badge-beta-background-color;
  --axTag-beta-border-color: @_badge-beta-border-color;

  --axTag-recommendation-text-color: @_badge-recommendation-text-color;
  --axTag-recommendation-background-color: @_badge-recommendation-background-color;
  --axTag-recommendation-border-color: @_badge-recommendation-border-color;
  
  --axTag-milestone-text-color: @_badge-milestone-text-color;
  --axTag-milestone-background-color: @_badge-milestone-background-color;
  
  --axTag-evaluation-text-color: @_badge-evaluation-text-color;
  --axTag-evaluation-background-color: @_badge-evaluation-background-color;
  
  --axTag-validation-passed-text-color: @_badge-validation-passed-text-color;
  --axTag-validation-passed-background-color: @_badge-validation-passed-background-color;
  
  --axTag-validation-failed-text-color: @_badge-validation-failed-text-color;
  --axTag-validation-failed-background-color: @_badge-validation-failed-background-color;
  
  --axTag-validation-pending-text-color: @_badge-validation-pending-text-color;
  --axTag-validation-pending-background-color: @_badge-validation-pending-background-color;

  --axTag-article-text-color: @ax-color-article-80;
  --axTag-article-background-color: @ax-color-article-40;

  --axTag-link-text-color: @_badge-link-text-color;
  --axTag-link-background-color: @_badge-link-background-color;
  
  --axTag-question-text-color: @ax-color-question-80;
  --axTag-question-background-color: @ax-color-question-40;

  --axTag-published-text-color: @ax-color-green-100;
  --axTag-published-background-color: @ax-color-green-40;
  
  --axTag-in-review-text-color: @ax-color-blue-100;
  --axTag-in-review-background-color: @ax-color-blue-40;
  
  --axTag-reported-text-color: @ax-color-red-100;
  --axTag-reported-background-color: @red-background;

  --axTag-module-text-color: @_badge-module-text-color;
  --axTag-module-background-color: @_badge-module-background-color;
  --axTag-module-border-color: @_badge-module-border-color;

  --axTag-survey-text-color: @_badge-survey-text-color;
  --axTag-survey-background-color: @_badge-survey-background-color;
  --axTag-survey-border-color: @_badge-survey-border-color;

  --axTag-reassigned-text-color: @_badge-reassigned-text-color;
  --axTag-reassigned-background-color: @_badge-reassigned-background-color;
  --axTag-reassigned-border-color: @_badge-reassigned-border-color;

  --axTag-expired-text-color: @_badge-expired-text-color;
  --axTag-expired-background-color: @_badge-expired-background-color;

  --axTag-draft-text-color: @_badge-draft-text-color;
  --axTag-draft-background-color: @_badge-draft-background-color;
  --axtag-draft-border-color: @_badge-draft-background-color;
}
