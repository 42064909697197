@ax-bottom-navigation-label-color:                 @ax-color-grey-80;
@ax-bottom-navigation-label-font-size:             1.2rem;
@ax-bottom-navigation-label-font-weight:           @ax-font-weight-medium;
@ax-bottom-navigation-label-line-height:           normal;
@ax-bottom-navigation-label-padding:               0.4rem 0;
@ax-bottom-navigation-label-boder-bottom:          0.2rem solid transparent;
@ax-bottom-navigation-label-boder-bottom-selected: 0.2rem solid @ax-bottom-navigation-label-color;
@ax-bottom-navigation-icon-size:                   2.4rem;
@ax-bottom-navigation-icon-color:                  @ax-color-grey-80;

:root {
  --ax-bottom-navigation-label-color:                 @ax-bottom-navigation-label-color;
  --ax-bottom-navigation-label-font-size:             @ax-bottom-navigation-label-font-size;
  --ax-bottom-navigation-label-font-weight:           @ax-bottom-navigation-label-font-weight;
  --ax-bottom-navigation-label-line-height:           @ax-bottom-navigation-label-line-height;
  --ax-bottom-navigation-label-padding:               @ax-bottom-navigation-label-padding;
  --ax-bottom-navigation-label-boder-bottom:          @ax-bottom-navigation-label-boder-bottom;
  --ax-bottom-navigation-label-boder-bottom-selected: @ax-bottom-navigation-label-boder-bottom-selected;
  --ax-bottom-navigation-icon-size:                   @ax-bottom-navigation-icon-size;
  --ax-bottom-navigation-icon-color:                  @ax-bottom-navigation-icon-color;
}

