@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.empty-transcodings {
  padding: 1em;
  border: 1px solid @primary-grey;
  background-color: @secondary-bg;
}

.video-not-supported {
  padding: 3em;
  background-color: @accent-grey;
  outline: auto;
}

// Reset for mediaelement's weird placement of closed captions in center of fullscreen video
.mejs-container-fullscreen .mejs-captions-position {
  bottom: 50px !important;
}

.player-wrapper {
  border-radius: @ax-border-radius-m;
  overflow: hidden;
}