.greeting-view {
  .display-flex();
  .align-items(flex-start);

  .textarea {overflow: hidden}

  .screen-md-portrait-min({
    display: block;
  });

  .welcome-top {
    background: none;
    font-size: 2rem;
    font-weight: @ax-font-weight-regular;

    line-height: 2rem;
    margin-bottom: 0.8rem;
    padding: 0;

    .screen-md-portrait-min({
      text-align: center;
      font-size: 2.5rem;
      line-height: 3.2rem;
    });
  }

  .welcome-bottom {
    margin: 0;
    line-height: 1.5rem;
    font-size: 1.3rem;
    font-weight: @ax-font-weight-regular;

    .screen-md-portrait-min({
      text-align: center;
      font-size: 1.8rem;
      line-height: 2.7rem;
    });
  }

  .characterarea {
    padding-top: 0;
    margin-right: 1.2rem;
    .flex(0 0 auto);

    .screen-md-portrait-min({
      margin-right: 0;
      margin-bottom: 1.5rem;
    })
  }

  .characterarea .bonuscharacter {
    width: 53px;
    height: 53px;

    .screen-md-portrait-min({
      height: 100px;
      width: 100px;
    });

    div {
      background-size: 100%;
      background-position: top center;
      background-color: @accent-grey;
      right: 0;
      .border-radius(100%);
    }
  }
}
