.axonifont {
  font-family: 'axoni-fonts'!important;
  font-weight: @ax-font-weight-regular;
}

// forces a single line with ellipsis for height- and width-constrained text
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: -webkit-fill-available;
}

// uses ellipsis when the width is constrained enough to truncate a string, but allows line wrapping on whitespace
.text-ellipsis-softwrap {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: -webkit-fill-available;
}

.text-stroke(@stroke-width: 1px) {
  text-shadow:
        -@stroke-width -@stroke-width 0 #000,
        @stroke-width -@stroke-width 0 #000,
        -@stroke-width @stroke-width 0 #000,
        @stroke-width @stroke-width 0 #000;
}

.text-info, .text-warning {
  color: @yellow !important;
}

.text-error {
  color: @red !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.rtl-content() {
  text-align: right;
  direction: rtl;
  unicode-bidi: embed;
}

.ltr-content() {
  text-align: left;
  direction: ltr;
  unicode-bidi: normal;
}
