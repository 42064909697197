.search-communities-list {
  .empty-view {
    width: 100%;
  }

  .search-community-list-item {
    margin-bottom: @ax-spacing-m;
    position: relative;

    .row-arrow {
      display: none;
    }

    .text {
      font-size: @ax-font-size-xs;
      margin: 0 0 0 4em;
      text-align: left;
      line-height: 2rem;

      .meta,
      .last-updated {
        .text-ellipsis;
        color: @secondary-text-color;
      }
      .name {
        .text-ellipsis;
        margin: 0;
      }

      .reported,
      .needs-review {
        margin: 0;
      }

      .reported {
        color: @red;
        margin-right: 1.6rem;
        .text-ellipsis;
      }
    }

    &__needsreview {
      padding: 0;

      .needsreview-border-highlight {
        height: 100%;
        width: .5rem;
        float: left;
        background-color: @primary-blue;
        border-radius: @ax-border-radius-m 0 0 @ax-border-radius-m;
      }

      .community-list-item-wrapper {
        padding: .8rem;
      }
    }

    .community-logo-wrapper {
      float: left;
      .community-logo {
        .border-radius(@ax-border-radius-m);
        @size: 4rem;
        width: @size;
        height: @size;
        .icon {
          font-size: @size;
          background-color: @white;

          &.icon-star-solid,
          &.icon-needsreview,
          &.icon-reported,
          &.icon-reports,
          &.icon-settings {
            color: @black;
          }

          &.icon-needsreview {
            color: @primary-blue;
          }
        }
        &.icon.icon-communities {
          color: @ax-color-grey-60;
          background-color: @ax-color-grey-30;
          line-height: @ax-spacing-xxl;
          font-size: 3.2rem;
        }
        img {
          .border-radius(@ax-border-radius-m);
          max-height: 100%; // fixing IE issue
          max-width: 100%;
        }
      }
    }
  }
}

.screen-md-portrait-max({
  .search-communities-list {
    .search-community-list-item {
      margin-bottom: @ax-spacing-m;
      float: none;

      .text {
        margin: 0 2em 0 4em;
      }

      .row-arrow {
        display: block;
        .row-chevron(50%, .4em, 50%, .4em);
      }
    }
  }
});

.browse-community-header-create-button {
  .ax-breakpoint-m--visible-flex();
}

.create-page-modal-common,
.search .search-bar-container .create-page-modal-common,
.search .browse-all-communities .articles-header .create-page-modal-common {


  @icon-height: 32px;

  .border-radius(@ax-border-radius-m);
  min-width: 20rem;
  position: absolute;
  top: 6rem;
  right: 0;
  background-color: white;
  border: 1px solid @secondary-grey;
  z-index: 1000;
  .box-shadow-black(0px, 1px, 2px, 0.1);

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }

    .screen-md-landscape-min({
      span:nth-of-type(2) {
        float: left;
        margin-left: 1rem;
      }
    })
  }

  ul {
    margin: 0;

    li {
      border-bottom: none;

      button > span {
        font-size: 1.6rem;
      }

      .icon-document-nobg {
        background-color: transparent;
        color: @ax-color-blue-80 !important;
        .size(3rem);
        line-height: 3rem;
        text-indent: .1em;
        opacity: 1;
      }

      .icon-reference-nobg {
        background-color: transparent;
        color: @ax-color-external-link !important;
        .size(3rem);
        line-height: 3rem;
        text-indent: .12em;
        opacity: 1;
      }

      .icon-question-nobg {
        background-color: transparent;
        color: @ax-color-question !important;
        .size(3rem);
        line-height: 3rem;
        text-indent: .09em;
        opacity: 1;
      }
    }

    li:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    li:last-child {
      border-bottom: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    li:hover {
      background-color: @element-hover-bright;
    }
  }

  .create-page-link,
  .option-link {
    padding: 0.5em 1.6rem;
    width: 100%;

    span {
      line-height: 3rem;
      color: @black;
      cursor: pointer;
    }
  }

  .cp-modal__cancel {
    display: none;
  }
}

.community-context-button-container {
  .ax-breakpoint-m--visible-flex();
}

.create-page-button-container-common {
  .display-flex();

  #create-page-button,
  .create-page-button {
    position: relative;
    height: 54px;
    .border-radius(@ax-border-radius-m);
    min-width: 5rem;
    min-height: 4rem;
    padding: 0 1rem;

    span:nth-of-type(2) {
      display: none;
    }

    .ax-breakpoint-m({
      min-width: 10rem;
      height: inherit;
      span:nth-of-type(2) {
        display: inline;
      }
    });
  }

  .icon-create-dz {
    opacity: 1;
    padding: 0 0.5rem;
  }
}

#tag-bar {
  display: none;

  ul {
    margin: 0;

    li {
      display:-moz-inline-stack;
      display:inline-block;
      zoom:1;
    }
  }

  &.community-tag-bar {
    .tag-bar__list {
      .ax-breakpoint-m({
        padding-top: @ax-spacing-m;
      });
    }
  }

  &.article-search-tag-bar {
    .tag-bar__list {
      .ax-breakpoint-m({
        padding-top: @ax-spacing-xs;
      });
    }
  }
}

.tags-button-wrapper {
  .display-flex();
  overflow-x: auto;
  white-space: nowrap;

  .ax-breakpoint-m({
    display: block;
    overflow-x: none;
    white-space: normal;
    position: sticky;
    top: @ax-spacing-m;
  });

  .tag {
    .border-radius(@ax-border-radius-m);
    font-size: @ax-font-size-xs;
    border: 1px solid @subtle-border-grey;
    background-color: @white;
    color: @ax-color-text;
    padding:.5em;
    margin-right:.75em;
    display: block;
    float: left;

    .tag__remove {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: @secondary-bg;
    }

    &__selected {
      background-color: @white;
      border: 1px solid @black;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 4px;

      .tag__remove {
        display: inline-block;
        width: .8rem;
        height: 1.5rem;
        font-size: 1em;
        vertical-align: middle;
      }
    }
  }
}

.screen-md-portrait-min({
  .edit-layout .squire-ui .menu .dropdown-menu {
    left: -16rem;
  }
});
