.modal {
  h2.bidmodal {
    margin: 0 0 @ax-spacing-s;
    text-align: center;

    .ax-breakpoint-m({
      margin: 0 0 @ax-spacing-l;
    });
  }

  .title {
    margin-bottom: @ax-spacing-s;
  }

  .biddetails {
    margin: @ax-spacing-s 0;
    padding: 0 @ax-spacing-m;

    .ax-breakpoint-m({
      margin: @ax-spacing-m 0;
    });

    &--with-border {
      .biddetail {
        margin: @ax-spacing-s 0;

        &.bids {
          border-right: .1rem solid @accent-grey;
        }

        .ax-breakpoint-m({
          &.currentbid {
            border-right: .1rem solid @accent-grey;
          }
        });
      }
    }

    .biddetail {
      text-align: center;

      .bidcount,
      .amountbid {
        font-size: 1.8rem;
        display: block;
      }

      &.buynow {
        width: 100%;
      }
    }
  }
}

.modalHeader {
  .box-shadow-black(inset 0, 0, .4rem, 0.1);
  background-color: @primary-bg;
  padding: @ax-spacing-m;

  .ax-breakpoint-m({
    padding: @ax-spacing-s @ax-spacing-m;
  });
}

.buynowask {
  .border-radius(@ax-border-radius-m);
  font-size: 1.6rem;
  color: @red;
  margin: 0 auto;
  padding: 0;

  .ax-breakpoint-m({
    font-size: 2.1rem;
  });
}

.placebid {
  padding: 0 @ax-spacing-m;

  label {
    margin-bottom: @ax-spacing-s;
  }

  input {
    text-align: center;
  }

  .minbid {
    font-size: @ax-font-size-xs;
  }

  .itemimage {
    height: 10rem;
    margin: @ax-spacing-m 0 @ax-spacing-s;

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }

    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-m;
    });

    img {
      height: 10rem;
      width: auto;
    }
  }

  .bid__input--wrapper {
    .display-flex();
    .flex-direction(column);
    margin: auto;
    width: 18rem;

    label {
      .align-self(flex-start);
      display: block;
      margin-bottom: 0;
      color: @ax-color-grey-60;
    }
  }
}

.buynowmodal,
.placebid {
  text-align: center;
}

.buttongroup {
  margin-top: @ax-spacing-m;
  text-align: center;

  a {
    border: 0!important;
    display: inline-block;
    margin: 0 auto;
    width: 30%;
  }
}

#modalview.auction-item-modal,
#modalview.buy-now-modal,
#modalview.sold-out-modal,
#modalview.win-modal {
  padding: 0;
}

.auction-item-modal .modalHeader,
.buy-now-modal .modalHeader,
.sold-out-modal .modalHeader,
.win-modal .modalHeader {
  background: @primary-bg;
  margin: 0;
}
