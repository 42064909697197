// Article grid view styles used for both recommended articles, grid view articles and topics in DZ
.grid-view .article-result__tile, .recommended-articles__list .article-result__tile {

  .size(100%);
  .justify-content(space-between);
  .align-items(stretch);

  .base-card__wrapper {
    .flex-direction(column);

    &--whole {
      .flex-grow();
    }

    &--upper {
      .align-items(flex-start);

      .subtext-header-title .h1 {
        font-size: 1.6rem;
      }
    }
  }

  .article-type-icon {
    margin: 10.2rem 0 0 calc(100% - 6rem);
  }

  .article-type-icon:not(.icon-training-article-nobg) {
    .article-type-icon-style-mixin(3.2rem);
  }

  .article-type-icon.icon-training-article-nobg {
    .article-type-icon-style-mixin(training, 2.8rem, 2.1rem, 0.6rem);
  }

  .base-card__image.article-background {
    .size(100%, 12.2rem);
    .flex(0 1 auto);
    min-height: 3.2rem;
    margin: 0;
    background: none;

    img {
      .flex(0 0 auto);
      height: auto;
      max-height: 100%;
      min-width: 0;
      object-fit: contain;
      width: auto;
    }
  }

  .search-result__lower {
    .flex-direction(column);
    .flex-grow();
    padding-top: 0;

    .star-container-region .star-container {
      display: flex;
    }
  }

  .description-region {
    display: block;
    .flex(1 1 auto);
  }

  .article-description {
    max-width: 100%;
  }

  &.base-card.no-image {
    .article-type-icon {
      margin-top: @ax-spacing-s;
    }

    .base-card__image {
      height: 0;
    }

    .article-description {
      max-height: 100px;
    }
  }

  .extra-info-region {
    .align-self(flex-start);
    .flex(0 1 auto);
    font-size: @ax-font-size-xs;
    line-height: @ax-line-height-xs;
    margin-top: @ax-spacing-s;
    margin-bottom: @ax-spacing-s;
    width: 100%;
  }

  .metadata-detail-item:first-child span {
    font-weight: @ax-font-weight-bold;
  }
}

.grid-view.article-search__view-type-selector {

  & .grid-view-button {
    color: @black;
    font-weight: @ax-font-weight-bold;

    [class*="icon-"] {
      color: @primary-blue;
    }
  }

  * .list-view-button {
    color: @primary-grey;
    font-weight: @ax-font-weight-regular;

    [class*="icon-"] {
      color: @primary-grey;
    }

    &:hover,
    &:focus {
      color: @primary-hover-blue;

      [class*="icon-"] {
        color: @primary-hover-blue;
      }
    }
  }

  .icon-list {
    font-weight: @ax-font-weight-bold;
  }
}

.search-result, .article-result {
  &__tile {
    .description-region {
      padding-bottom: 1rem;
      width: 100%;
    }

    .article-description {
      word-break: break-word !important;
      overflow: hidden;
    }

    &.base-card {
      overflow: visible;
    }

    .base-card {
      &__wrapper--whole {
        padding: 0;
        position: relative;
      }

      &__wrapper--content {
        .display-flex();
        .flex-direction(column);
        .flex-grow();
        .full-width();
      }

      &__wrapper--upper {
        .align-items(flex-start);
        padding-bottom: 0;

        .ax-breakpoint-m({
          .align-items(center);
          padding-bottom: 1.6rem;
        });
      }

      &__wrapper--lower {
        padding-top: @ax-spacing-s;
        padding-bottom: @ax-spacing-s;
      }

      &__badge-assigned {
        background-image: url('@training/assets/images/badge-assigned.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;

        .assigned-text {
          color: @white;
        }

        .ax-breakpoint-m({
          display: block;
          min-width: 120px;
          height: 33px;
          top: 0.8rem;
          left: -18px;
          position: absolute;
          padding-top: 0.8rem;
          text-align: center;
        });
      }

      &__badge-assigned--mobile {
        .border-radius(.1rem);
        position: absolute;
        left: 0.5rem;
        background: #004FA3;
        box-shadow: 0 1px 0 0 #06315F;
        width: 6.7rem;
        min-height: 2.5rem;
        word-wrap: break-word;
        text-align: center;
        margin-top: 3.5rem;
        padding: 0 0.4rem;
        margin-top: 3.2rem;
        .display-flex();

        .assigned-text {
          color: @white;
          margin: auto;
        }

        .ax-breakpoint-m({
          display: none;
        });
      }

      &__image {
        .display-flex();
        .align-items(center);
        .flex-direction(column);
        .border-radius(@ax-border-radius-m);
        .size(55px, 45px);
        .flex(0 0 auto);
        margin: 1.6rem 0 0 1.6rem;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;

        &.topic-background {
          background-size: auto 75%;
          background-image: url('@training/assets/images/topic.svg');
        }

        &.article-background {
          background-size: 50%;
          background-image: url('@training/assets/images/discoveryzone-light.svg');

          img {
            .flex(0 0 auto);
            height: auto;
            max-height: 100%;
            object-fit: contain;
            width: auto;
          }
        }

        img {
          min-width: 55px;
          max-height: 100%;
          height: 45px;
          margin: 0 auto;
          .border-radius(@ax-border-radius-m);
          background-color: @white;
          box-sizing: border-box;
          padding: .3rem;
        }

        .ax-breakpoint-m({
          overflow: hidden;
          .justify-content(center);
          .size(180px, 122px);
          margin: 1.6rem 0 1.6rem 2.4rem;

          &.article-background {
            background-size: 35%;
          }
        });
      }

      &__view-all {
        display: inline-block;
        margin-bottom: @ax-spacing-l;
        margin-top: @ax-spacing-xxs;

        span:first-child {
          display: inline-block;
        }

        p {
          margin-bottom: 0;
        }

        &:focus {
          overflow: visible;
        }
      }


    }

    &.no-image .base-card__image {
      background-color: @pagination-grey;
    }

    .article-type-icon,
    .icon-training-article-nobg {
      position: absolute;
      margin: 1.6rem 0 0 6.2rem;

      .ax-breakpoint-m({
        margin: 2.4rem 0 0 1.0rem;
      });
    }

    .article-type-icon {
      .article-type-icon-style-mixin();
    }

    .icon-training-article-nobg {
      .article-type-icon-style-mixin(training);
    }

    .view-article-button {
      margin-bottom: 0;
      margin-left: auto;

      &:focus {
        padding: @_btn-padding-l;
      }
    }

    .title-region {
      width: 100%;
      .display-flex();
      .flex-grow(1);

      .base-card__title {
        .flex-grow(0);

        .h1 {
          word-break: break-word;
        }
      }

      .icon-certification {
        align-self: flex-end;
        line-height: 2rem;
        margin-left: 1.6rem;

        &.certified {
          color: @correct-green;
        }

        &.due {
          color: @ax-color-certification;
        }

        &.overdue {
          color: @error-state;
        }
      }
    }

  }

  &__lower {

    &, & .extra-info-region {
      .display-flex();
      .flex-direction(row);
      .align-items(center);
      position: relative;
    }
    padding-top: 1rem;

    .tile__meta {
      .flex-grow(1);
    }

    .axon-button {
      .action-text {
        display: none;
      }

      span {
        padding-right: 0;
        line-height: inherit;
      }

      .ax-breakpoint-m({
        min-width: 125px;
        height: 45px;
        padding-top: 0rem;

        .icon-play {
          display: none;
        }

        .action-text {
          .display-flex();
          .justify-content(center);
        }

        .icon--badge {
          font-size: 1.8rem;
          background-color: transparent;
          width: auto;
          padding: 0 0.5rem 0 0;
        }
      })
    }

    .profile-icon-region {
      margin-right: 0.8rem;

      .user-profile-icon {
        .round-avatar();
        font-size: 1.2rem;
        margin-top: @ax-spacing-xxs;

        .ax-breakpoint-m({
          font-size: 1.6rem;
        });
      }
    }

    .star-container-region {
      margin-left: 1.6rem;

      .star-container {
        display: none;

        .ax-breakpoint-m({
          .display-flex();
          .flex(1 1 auto);
        });

        .star-icon {
          margin: 0.1rem 0.3rem 0 0;

          &:focus.focus-visible {
            .ax-focus-keyboard();
          }
        }
      }
    }

    .ax-breakpoint-m({
      padding-top: 1.6rem;
    });
  }
}

.recommended-articles__list .recommended-articles__item-region .article-result__tile {
  min-height: 33rem;

  .base-card {
    &__wrapper--whole {
      padding: 0;
      position: relative;
    }
  }

  .extra-info-region {
    margin-bottom: @ax-spacing-s;
  }

  .metaRegion {
    width: 100%;
    .stacked-collection-view {
      .display-flex();
      .flex-direction(column);
    }
  }
}
