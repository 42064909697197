.learner-training-page .page-content {
  position: relative;
}

.learner-training-stacked-content {
  padding-bottom: @ax-spacing-m;
}

.learner-training-search-box {
  padding: @ax-spacing-m 0;
}

.learner-training-tab-content__empty {
  margin:0 auto;
  text-align:center;
}

.path-tab__header {
  font-size: @ax-font-size-l;
  margin-top: 0;
}

.learner-training-section-tab .loading-row {
  height: 10rem;
  .flex-basis(100%);
}

.learner-training-catalog-list-region__result-count {
  padding: 0 @ax-spacing-s @ax-spacing-m @ax-spacing-s;
}

.learner-training-tab-content--catalog,
.learner-training-tab-content--catalog__modal {
  .catalog-filter__header {
    .display-flex();
    border-bottom: 0.1rem solid @ax-color-grey-20;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: @white;

    .ax-breakpoint-m({
      display: none;
    });

    .catalog-filter__title {
      font-size: @ax-font-size-m;
      margin: auto;
      padding-left: @ax-spacing-m;
    }
  }

  .catalog-filter__form {
    margin: 0 @ax-spacing-l 0 @ax-spacing-l;
  }

  .learner-training-filter-button-region {
    padding: @ax-spacing-m 0;
    .display-flex();
    .align-items(center);
    .justify-content(center);

    .ax-breakpoint-m({
      display: none;
    });
  }

  .learner-training-catalog-filter-wrapper {
    position: sticky;
    top: @ax-spacing-m;
    background-color: @ax-color-app-background;
    display: none;

    .ax-breakpoint-m({
      display: block;
    });
  }

  .learner-training-catalog-filter-wrapper,
  .learner-training-catalog-filter-modal-wrapper {
    .icon-remove {
      margin-right: @ax-spacing-m;

      .ax-breakpoint-m({
        display: none;
      });
    }

    .catalog-filter__subject-checkboxes {
      max-height: 36rem;
      overflow-y: auto;

      li {
        margin-bottom: @ax-spacing-s;

        label {
          word-break: break-word;
        }
      }
    }
  }

  .catalog-filter__subject-container {
    h2 .catalog-filter__title {
     display: inline-block;
    }
  }

  .catalog-filter__type-container,
  .catalog-filter__subject-container {
    padding-bottom: @ax-spacing-m;
  }

  .catalog-filter__subject-container {
    border-top: 0.1rem solid @ax-color-grey-20;
  }

  .catalog-filter__title {
    font-size: @h1-font-size;
    font-weight: @ax-font-weight-medium;
    padding: @ax-spacing-m 0 0;
  }

  .catalog-filter__title--first {
    .ax-breakpoint-m({
      padding-top: 0;
    });
  }

  .catalog-filter__action-button--wrapper {
    .display-flex();
    .justify-content(center);
    margin: @ax-spacing-l @ax-spacing-l 0 @ax-spacing-l;

    .catalog-filter__reset-button {
      margin-right: 1rem;
    }

    .catalog-filter__apply-button,
    .catalog-filter__reset-button {
      .full-width();
    }
  }

  .page-control-top-region {
    margin: 0;
  }

  .search-results li {
    margin-bottom: @ax-spacing-m;
  }

  .catalog-filter__button {
    .display-flex();
    .justify-content(center);
    .align-items(center);

    .icon-filter {
      font-size: @ax-font-size-xl;
    }

    .filter-applied-dot {
      .border-radius(50%);
      padding: @ax-spacing-xs;
      background-color: @ax-color-blue-60;
      position: absolute;
      margin-left: @ax-spacing-m;
      margin-bottom: @ax-spacing-m;
    }
  }

  .search-box input {
    min-width: auto;
  }
}

.learner-training-page {
  .todays-activities__card {
    margin: @ax-spacing-l 0;
  }

  h1.page-header__content {
    margin-bottom: 0;
  }

  .how-to-link {
    margin-right: @ax-gutter-page;
  }
}
