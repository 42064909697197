.gl-unenroll-modal {
  max-width: 50rem;
  width: 100%;
  background: #ffffff;
}

.unenroll-modal {
  padding: 2rem;
  text-align: center;
}

.unenroll-modal-title {
  font-size: @ax-font-size-s;
  margin-bottom: @ax-spacing-xl;
}

.unenroll-modal-content {
  color: @ax-color-grey-70;

  &:first-of-type {
    margin-bottom: @ax-spacing-m;
  }
}
