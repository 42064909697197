@import (reference) "@common/prerequisites/prerequisites.less";

@_width-sidebar-question-modal: 300px;

.adjustable-modal {
  .modalHeader {
    position: relative;
    border-top: .1rem solid @ax-color-grey-20;
    border-bottom: .1rem solid @ax-color-grey-20;
    padding: 0;

    .screen-md-portrait-min({
      border-top: none;
    });

    h1 {
      background-color: @ax-color-white;
      padding: @ax-spacing-m @ax-spacing-l;
      margin: 0;
      font-size: inherit;
      line-height: inherit;
      font-weight: @ax-font-weight-medium;
    }

    .icon-remove:not(.post-modal-mobile-close):not(.post-modal-desktop-close)  {
      position: absolute;
      top: 0;
      margin: @ax-spacing-s 0;
      right: 2rem;
      background-color: @ax-color-white;
      color: @ax-color-grey-80;

      .screen-md-portrait-min({
        margin: 1.4rem 0;
      });

      &.close {
        background-image: none;
        text-indent: inherit;
      }

      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }
  }

  .actionbar .actioncontent,
  .actionbar.fixed .actioncontent {
    .display-flex();
    background-color: unset;
    padding: 1rem 0;
    text-align: center;
    border-top: .1rem solid @ax-color-grey-20;

    .ax-breakpoint-m({
      text-align: right;
    });

    button {
      .flex-grow(1);

      .screen-md-portrait-min({
        .flex-grow(0);
        min-width: 12rem;
      });

      &:last-child {
        .ax-breakpoint-m({
          margin-right: @ax-spacing-m;
        });
      }
    }

    .cancel-btn {
      background-color: @secondary-button-color;

      &:hover {
        background-color: @secondary-button-hover;
      }
    }

    .axon-button.sidebyside {
      &:first-of-type {
        margin-left: @ax-spacing-m;
      }

      &:nth-of-type(2) {
        margin-right: @ax-spacing-m;
      }
    }

    .screen-md-portrait-min({
      .justify-content(flex-end);
    })
  }
}

.adjustable-layout {
  .training-preview-modal {
    .ax-breakpoint-l({
      width: fit-content;
      min-width: 80rem;
      min-height: 50rem;
    });

    .actionbar .actioncontent,
    .actionbar.fixed .actioncontent {
      border-top: none;
      text-align: center;
      display: block;

      button {
        .ax-breakpoint-m({
          margin-right: @ax-spacing-s;
        });
      }
    }

    .training-module-preview-modal {
      padding: @ax-spacing-m;
    }

    .url-panel {
      width: auto;
    }

    .scorm-module, .tincan-module, .pdf-module {
      .ax-breakpoint-l({
        height: 70rem;
        width: 90rem;
      })
    }
  }
}

.article-report-modal {
  #report-form,
  #modal-label {
    padding: @ax-spacing-m @ax-gutter-page;
  }

  .report-type-prompt {
    padding: @ax-spacing-m 0;
  }

  .report-type-label {
    .full-width;
    display: block;
    margin-bottom: 1rem;

    .screen-md-portrait-min({
      margin-top: 1rem;
    });
  }

  #report-type-select {
    > div {
      clear: both;
      padding: 1rem 0;
    }

    textarea {
      .full-width;
      display: block;
      padding: 1rem 0 0 1rem;
      outline: 0;
      height: 12rem;
      .box-sizing(border-box);
    }
  }

  .report-comment-wrapper textarea {
    resize: none;
  }

  .actioncontent{
    .justify-content(center);

    button {
      max-width: 12rem;
    }
  }
}

.question-create-modal {
  .modalHeader {
    margin-bottom: 0;
  }

  .question-create-region {
    .screen-md-landscape-min({
      height: 390px;
    });
  }

  .row-content {
    height: 100%;
  }

  .row-title {
    display: none;
    padding: 0;
  }

  .edit-layout {
    #content {
      position: relative;
      float: left;

      .screen-md-landscape-min({
        display: inline-block;
        float: none;
        width: calc(100% - @_width-sidebar-question-modal);
      });

      #form-content {
        margin: @ax-spacing-l @ax-spacing-m 0;
        padding-bottom: @ax-spacing-l;
        border-bottom: 0.1rem solid @ax-color-grey-30;
        height: 100%;

        .screen-md-landscape-min({
          border-bottom: none;
          height: calc(100% - 6.3rem);
        });

        #content-container {
          .display-flex();
          .flex-direction(column);

          .screen-md-landscape-min({
            .justify-content(space-between);
          })
        }

        textarea {
          height: 14.8rem;
          padding: 0;
          border: 0.1rem solid @secondary-grey;
          resize: none;
          padding: 1em 1.3em;
          background: @white;
          .box-shadow();
          .box-sizing(border-box);

          &.rederror {
            border-color: @error-state;
          }
          &:focus {
            .box-shadow();
          }

          .screen-md-landscape-min({
            margin: 2rem 2rem 0;
          });
        }

        .screen-md-landscape-min({
          padding: 2rem;
        })
      }

      .character-count {
        margin-top: @ax-spacing-s;
        position: static;

        .content {
          position: static;
          left: .5em;
        }

        .screen-md-landscape-min({
          margin: 2rem 0 4rem 2rem;
        });
      }

      .froala-editor {
        padding: 1em 1.8em;
        font-family: inherit !important;
        font-size: inherit !important;
        color: @black !important;
        ul {
          li {
            list-style-type: disc;
          }
        }
        ul, ol {
          padding: 0;
          margin: 0 0 0 .8rem;
          li {
            padding-left: 0;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
    }

    #metadata {
      .full-width();
      padding-bottom: @ax-spacing-l;
      position: static;
      left: auto;
      background-color: @ax-color-white;

      .screen-md-landscape-min({
        display: inline-block;
        width: @_width-sidebar-question-modal;
        height: calc(100% - 5.8rem);
        position: absolute;
        padding-bottom: 0;
      });
    }

    #meta-data-container {
      .full-width();
      .parent-height();
      .box-sizing(border-box);

      .metadata__input {
        position: relative;
        .publish-date-controls{
          .icon-calendar_empty {
            display: table-cell;
          }
          .date-input-container {
            display: table-cell;
            width: 100%;
            input {
              width: 100%;
            }
          }
          .datetime-popdown {
            margin-left: -32px;
          }
        }
      }

      label,
      h4 {
        font-size: @ax-font-size-m;
        font-weight: @ax-font-weight-bold;
        margin: 1.5em 0 1em;
        display: inline-block;
      }

      h3 {
        margin: 1.5em 0 1em;
      }

      .character-count .content {
        color: @secondary-grey;
        right: .5em;
      }

      input[type=checkbox],
      input[type=radio] {
        box-shadow: none;
        margin-top: -4px;
        margin-right: 0.7em;
      }

      select {
        max-width: 100%;

        &.rederror {
          border-color: @error-state;
        }
      }

      .metadata__recommendations--switch-container {
        margin: 1.5rem 0;

        h4 {
          padding-right: 1rem;
        }

        & > * {
          vertical-align: middle;
          display: inline-block;
          margin: 0;
        }

        .ax-toggle.focus-visible:focus {
          box-shadow: inset 0px 0px 2px 2px @primary-blue;
        }
      }

      .screen-md-landscape-min({
        margin: 0;
        padding: 0 2rem;
        border-top: none;
        float: right;
        width: 300px;
      })
    }

    .media-input-wrap {
      opacity: 0.01;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 1px;
      width: 0px;
      overflow: hidden;
    }
  }

  .select2-container--default .select2-selection--multiple {
    .border-radius(@ax-border-radius-m);
    .box-shadow-black(inset 0px, 1px, 2px, 0.1);
    font-weight: @ax-font-weight-regular;
    overflow-y: auto;
    max-height: 132px;
    padding-left: .6em;
    outline: 0;
    background-color: @secondary-bg;
  }

  #tag-select-wrapper {
    margin-bottom: 2.4rem;
    ul li {
      border: none;
    }
    ul li.select2-selection__choice {
      padding-top: .5em;
      padding-bottom: .5em;
      margin-top: 1em;
      .select2-selection__choice__remove {
        margin-right: 1em;
      }
      @media screen and (max-height: 600px) {
        padding-top: .3em;
        padding-bottom: .3em;
        margin-top: 0.6em;
      }
    }
  }

  #publish-settings.hide {
    display: none;
  }

  .actioncontent {
    flex-wrap: wrap;
  }
}

.article-share-modal {
  .warning-container {
    padding: @ax-spacing-s;
    margin: @ax-spacing-m;
  }

  .page-control-top-region {
    margin: 0;
  }

  .page-control-bottom-region {
    margin-bottom: @ax-spacing-m;
  }

  .search__results {
    margin: 0 @ax-spacing-m;
    min-height: 35rem;

    label.focus-visible {
      .ax-focus-keyboard();
    }
  }
}

.article-publish-all-modal {
  .disclaimer {
    margin: @ax-spacing-m;
  }

  .languages-table {
    max-height: calc(100vh - 394px);
    overflow: auto;
    margin: @ax-spacing-m;
    border: .1rem solid @secondary-grey;
    .border-radius(@ax-border-radius-m);

    th {
      text-align: left;
      border-bottom: 0.2rem solid @secondary-grey;
    }

    tr {
      height: 3rem;
      border-bottom: 0.1rem solid @accent-grey;
      border-top: 0.1rem solid @accent-grey;
    }

    label {
      font-size: @ax-font-size-body;
    }
  }
}

.change-language-modal .confirm-dialog__title {
  margin-top: @ax-spacing-xxl;
}

.import-errors-modal {
  min-height: 50rem;

  .adjustable-container {
    // Force a max height for the modal to allow content scrolling
    max-height: calc(100vh - 100px);
  }

  .errors-table {
    // Force a max height on the table to allow scrolling to be focussed on the table and not the entire modal
    max-height: calc(100vh - 394px);
    overflow: auto;
    padding: @ax-spacing-m;

    th {
      text-align: left;
    }

    tr {
      border-bottom: 0.1rem solid @ax-color-grey-30;

      &:nth-child(even) {
        background-color: @ax-color-grey-10;
      }
    }
  }
}
