.header-dropdown-wrapper {
  position: relative;
  height: 100%;
}

.header-dropdown-button-wrapper {
  cursor: pointer;
  height: 100%;

  .header-dropdown-button-grid {
    .flex-wrap(nowrap);
    height: 100%;
  }

  &.hover,
  &.active,
  &.focus-visible:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &.focus-visible:focus {
    .ax-focus-keyboard(inset);
  }
}

.header-profile-dropdown-region .header-dropdown-button-wrapper {
  padding: 0 @_header-gutter-profile 0 var(--ax-app-header-button-gutter);
}

.menu-open .header-dropdown-button-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
}

.header-down-indicator {
  font-size: @ax-font-size-xs;
}

.menu-empty {
  pointer-events: none;

  .header-down-indicator {
    display: none;
  }
}

.header-dropdown-button {
  border: none;
  padding: 0;
}

.header-dropdown-menu {
  display: table; // needed for height adjustment
  position: absolute;
  top: 100%;
  right: 0;
  margin: 0 @ax-spacing-xxs;
  z-index: 100;
  max-width: none;

  ul {
    display: table-cell;
    .box-shadow-black(0px, 1px, 9px, 0.2);
    .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);
    overflow: auto;
    background-color: @white;

    li {
      overflow: hidden;
    }
  }
}

.header-dropdown-item {
  .display-flex();
  .align-items(center);
  .box-sizing(border-box);
  min-width: 100%; // width: 100% in a button sets a min-width: 0 on Firefox 38
  background-color: @white;
  border: 0;
  border-left: .3rem solid transparent;
  border-right: .3rem solid transparent;
  color: @ax-color-text;
  padding: @ax-spacing-s @ax-spacing-m;
  white-space: nowrap;

  &.selected {
    border-left: .3rem solid @black;
    border-top: .3rem solid transparent;
    border-bottom: .3rem solid transparent;
  }

  &.focus-visible:focus {
    .ax-focus-keyboard(inset);
  }

  &.hover,
  &.active,
  &.focus-visible:focus {
    background-color: rgba(0,0,0,0.1);
  }

  .header-dropdown-item-wrapper {
    .display-flex();
    .align-items(center);
    font-size: inherit;
  }

  .item-icon {
    font-size: 2rem;
    color: @ax-color-text;
    margin-right: @ax-spacing-s;
    opacity: 1;
  }

  .item-text {
    font-size: @ax-font-size-xs;
    line-height: normal;
  }
}

.off-screen {
  .header-dropdown-item {
    visibility: hidden;
  }
}

.header-dropdown-item.app-item {
  &.admin {
    .item-text,
    .item-icon {
      color: @admin-color;
    }
  }

  &.training {
    .item-text,
    .item-icon {
      color: @training-color;
    }
  }

  &.manager {
    .item-text,
    .item-icon {
      color: @manager-color;
    }
  }
}

.header-dropdown-divider {
  border-top: .1rem solid @secondary-grey;
}
