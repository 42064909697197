.back-button {
  padding: 0 var(--ax-app-header-button-gutter) 0 @_header-gutter-back;
  height: @header-height;
  line-height: @header-height;
  .box-sizing(border-box);
  white-space: nowrap;
  display: none;

  .content-wrapper {
    .text-ellipsis();
  }

  .icon-chevron_left {
    opacity: 1;
    font-size: 1.8rem;
    line-height: @header-height;
  }

  .back-text {
    vertical-align: top;
    margin-left: @ax-spacing-xs;
    font-size: 1.4rem;
  }
}

.show-back-button {
  .back-button {
    .display-flex(inline-flex);
    .align-items(center);
    height: 100%;
  }
}
