.name-logo {
  .border-radius(50%);
  color: @white;
  float: left;
  height: 4rem;
  line-height: 4rem;
  margin-right: @ax-spacing-s;
  text-align: center;
  width: 4rem;
}
