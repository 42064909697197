@import "variables";

@font-face {
  font-family: '@{icomoon-font-family}';
  src:
    url('@{icomoon-font-path}/@{icomoon-font-family}.woff2?w1lpdg') format('woff2'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?w1lpdg') format('truetype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.woff?w1lpdg') format('woff'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.svg?w1lpdg#@{icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clap {
  &:before {
    content: @icon-clap; 
  }
}
.icon-hands {
  &:before {
    content: @icon-hands; 
  }
}
.icon-applause {
  &:before {
    content: @icon-applause; 
  }
}
.icon-empty-search {
  &:before {
    content: @icon-empty-search; 
  }
}
.icon-text-search {
  &:before {
    content: @icon-text-search; 
  }
}
.icon-max-report {
  &:before {
    content: @icon-max-report; 
  }
}
.icon-max-sources {
  &:before {
    content: @icon-max-sources; 
  }
}
.icon-no-photo {
  &:before {
    content: @icon-no-photo; 
  }
}
.icon-no-camera {
  &:before {
    content: @icon-no-camera; 
  }
}
.icon-confetti {
  &:before {
    content: @icon-confetti; 
  }
}
.icon-announcement {
  &:before {
    content: @icon-announcement; 
  }
}
.icon-quiz {
  &:before {
    content: @icon-quiz; 
  }
}
.icon-poll {
  &:before {
    content: @icon-poll; 
  }
}
.icon-priority-messages {
  &:before {
    content: @icon-priority-messages; 
  }
}
.icon-communities {
  &:before {
    content: @icon-communities; 
  }
}
.icon-administration {
  &:before {
    content: @icon-administration; 
  }
}
.icon-dashboard-reports {
  &:before {
    content: @icon-dashboard-reports; 
  }
}
.icon-dashboard-admin {
  &:before {
    content: @icon-dashboard-admin; 
  }
}
.icon-insights1 {
  &:before {
    content: @icon-insights1; 
  }
}
.icon-insights {
  &:before {
    content: @icon-insights; 
  }
}
.icon-max {
  &:before {
    content: @icon-max; 
  }
}
.icon-sparkle {
  &:before {
    content: @icon-sparkle; 
  }
}
.icon-menu {
  &:before {
    content: @icon-menu; 
  }
}
.icon-snooze {
  &:before {
    content: @icon-snooze; 
  }
}
.icon-campaign_communications {
  &:before {
    content: @icon-campaign_communications; 
  }
}
.icon-complete-communications {
  &:before {
    content: @icon-complete-communications; 
  }
}
.icon-tasks {
  &:before {
    content: @icon-tasks; 
  }
}
.icon-correct-answer {
  &:before {
    content: @icon-correct-answer; 
  }
}
.icon-incorrect-answer {
  &:before {
    content: @icon-incorrect-answer; 
  }
}
.icon-embed {
  &:before {
    content: @icon-embed; 
  }
}
.icon-edit-data {
  &:before {
    content: @icon-edit-data;     
    color: #545459;
  }
}
.icon-viewers {
  &:before {
    content: @icon-viewers;     
    color: #545459;
  }
}
.icon-reach {
  &:before {
    content: @icon-reach;     
    color: #545459;
  }
}
.icon-training {
  &:before {
    content: @icon-training;     
    color: #545459;
  }
}
.icon-training-catalog {
  &:before {
    content: @icon-training-catalog;     
    color: #545459;
  }
}
.icon-learnerzone {
  &:before {
    content: @icon-learnerzone; 
  }
}
.icon-home {
  &:before {
    content: @icon-home; 
  }
}
.icon-admin_home {
  &:before {
    content: @icon-admin_home; 
  }
}
.icon-guided_learning {
  &:before {
    content: @icon-guided_learning; 
  }
}
.icon-path {
  &:before {
    content: @icon-path; 
  }
}
.icon-training-plan {
  &:before {
    content: @icon-training-plan; 
  }
}
.icon-checklist {
  &:before {
    content: @icon-checklist; 
  }
}
.icon-todo {
  &:before {
    content: @icon-todo; 
  }
}
.icon-intro {
  &:before {
    content: @icon-intro; 
  }
}
.icon-light_bulb {
  &:before {
    content: @icon-light_bulb; 
  }
}
.icon-bulb {
  &:before {
    content: @icon-bulb; 
  }
}
.icon-discoveryzone {
  &:before {
    content: @icon-discoveryzone; 
  }
}
.icon-achievements {
  &:before {
    content: @icon-achievements; 
  }
}
.icon-games {
  &:before {
    content: @icon-games; 
  }
}
.icon-gamepad {
  &:before {
    content: @icon-gamepad; 
  }
}
.icon-GameChallengeLoss {
  &:before {
    content: @icon-GameChallengeLoss; 
  }
}
.icon-GameChallengeWon {
  &:before {
    content: @icon-GameChallengeWon; 
  }
}
.icon-fire {
  &:before {
    content: @icon-fire; 
  }
}
.icon-uniF2DC {
  &:before {
    content: @icon-uniF2DC; 
  }
}
.icon-rewards {
  &:before {
    content: @icon-rewards; 
  }
}
.icon-events {
  &:before {
    content: @icon-events; 
  }
}
.icon-tellafriend {
  &:before {
    content: @icon-tellafriend; 
  }
}
.icon-profile {
  &:before {
    content: @icon-profile; 
  }
}
.icon-user {
  &:before {
    content: @icon-user; 
  }
}
.icon-uniF2C0 {
  &:before {
    content: @icon-uniF2C0; 
  }
}
.icon-uniF2BE {
  &:before {
    content: @icon-uniF2BE; 
  }
}
.icon-uniF2BD {
  &:before {
    content: @icon-uniF2BD; 
  }
}
.icon-logout {
  &:before {
    content: @icon-logout; 
  }
}
.icon-leaderzone {
  &:before {
    content: @icon-leaderzone; 
  }
}
.icon-usermetrics {
  &:before {
    content: @icon-usermetrics; 
  }
}
.icon-teammetrics {
  &:before {
    content: @icon-teammetrics; 
  }
}
.icon-impacts {
  &:before {
    content: @icon-impacts; 
  }
}
.icon-compare {
  &:before {
    content: @icon-compare; 
  }
}
.icon-teammessage {
  &:before {
    content: @icon-teammessage; 
  }
}
.icon-random {
  &:before {
    content: @icon-random; 
  }
}
.icon-groups {
  &:before {
    content: @icon-groups; 
  }
}
.icon-group {
  &:before {
    content: @icon-group; 
  }
}
.icon-admin_zone {
  &:before {
    content: @icon-admin_zone; 
  }
}
.icon-content {
  &:before {
    content: @icon-content; 
  }
}
.icon-pencil {
  &:before {
    content: @icon-pencil; 
  }
}
.icon-programs {
  &:before {
    content: @icon-programs; 
  }
}
.icon-reports {
  &:before {
    content: @icon-reports; 
  }
}
.icon-compass {
  &:before {
    content: @icon-compass; 
  }
}
.icon-support {
  &:before {
    content: @icon-support; 
  }
}
.icon-_433 {
  &:before {
    content: @icon-_433; 
  }
}
.icon-cog {
  &:before {
    content: @icon-cog; 
  }
}
.icon-settings {
  &:before {
    content: @icon-settings; 
  }
}
.icon-bolt {
  &:before {
    content: @icon-bolt; 
  }
}
.icon-bolt1 {
  &:before {
    content: @icon-bolt1; 
  }
}
.icon-very_sure {
  &:before {
    content: @icon-very_sure; 
  }
}
.icon-somewhat_sure {
  &:before {
    content: @icon-somewhat_sure; 
  }
}
.icon-filter {
  &:before {
    content: @icon-filter; 
  }
}
.icon-public-filters {
  &:before {
    content: @icon-public-filters; 
  }
}
.icon-advanced-filter {
  &:before {
    content: @icon-advanced-filter; 
  }
}
.icon-pop {
  &:before {
    content: @icon-pop; 
  }
}
.icon-calendar {
  &:before {
    content: @icon-calendar; 
  }
}
.icon-calendar-outline {
  &:before {
    content: @icon-calendar-outline; 
  }
}
.icon-calendar_empty {
  &:before {
    content: @icon-calendar_empty; 
  }
}
.icon-calendar-check {
  &:before {
    content: @icon-calendar-check; 
  }
}
.icon-calendar-pending {
  &:before {
    content: @icon-calendar-pending; 
  }
}
.icon-frequenttraining {
  &:before {
    content: @icon-frequenttraining; 
  }
}
.icon-ParticipationFrequency10 {
  &:before {
    content: @icon-ParticipationFrequency10; 
  }
}
.icon-ParticipationFrequency15 {
  &:before {
    content: @icon-ParticipationFrequency15; 
  }
}
.icon-ParticipationFrequency20 {
  &:before {
    content: @icon-ParticipationFrequency20; 
  }
}
.icon-ParticipationFrequency25 {
  &:before {
    content: @icon-ParticipationFrequency25; 
  }
}
.icon-ParticipationFrequency30 {
  &:before {
    content: @icon-ParticipationFrequency30; 
  }
}
.icon-GameHighScore1 {
  &:before {
    content: @icon-GameHighScore1; 
  }
}
.icon-GameHighScore2 {
  &:before {
    content: @icon-GameHighScore2; 
  }
}
.icon-GameHighScore3 {
  &:before {
    content: @icon-GameHighScore3; 
  }
}
.icon-CorrectAnswerStreak1 {
  &:before {
    content: @icon-CorrectAnswerStreak1; 
  }
}
.icon-CorrectAnswerStreak10 {
  &:before {
    content: @icon-CorrectAnswerStreak10; 
  }
}
.icon-CorrectAnswerStreak20 {
  &:before {
    content: @icon-CorrectAnswerStreak20; 
  }
}
.icon-CorrectAnswerStreak50 {
  &:before {
    content: @icon-CorrectAnswerStreak50; 
  }
}
.icon-CorrectAnswerStreak100 {
  &:before {
    content: @icon-CorrectAnswerStreak100; 
  }
}
.icon-CorrectAnswerStreak200 {
  &:before {
    content: @icon-CorrectAnswerStreak200; 
  }
}
.icon-CorrectAnswerStreak500 {
  &:before {
    content: @icon-CorrectAnswerStreak500; 
  }
}
.icon-CorrectAnswerStreak1000 {
  &:before {
    content: @icon-CorrectAnswerStreak1000; 
  }
}
.icon-_388 {
  &:before {
    content: @icon-_388; 
  }
}
.icon-graduated {
  &:before {
    content: @icon-graduated; 
  }
}
.icon-TopicLevelGraduation1 {
  &:before {
    content: @icon-TopicLevelGraduation1; 
  }
}
.icon-TopicLevelGraduationStreak1 {
  &:before {
    content: @icon-TopicLevelGraduationStreak1; 
  }
}
.icon-TopicLevelGraduationStreak2 {
  &:before {
    content: @icon-TopicLevelGraduationStreak2; 
  }
}
.icon-TopicLevelGraduationStreak3 {
  &:before {
    content: @icon-TopicLevelGraduationStreak3; 
  }
}
.icon-TopicLevelGraduationStreak5 {
  &:before {
    content: @icon-TopicLevelGraduationStreak5; 
  }
}
.icon-TopicLevelGraduationStreak10 {
  &:before {
    content: @icon-TopicLevelGraduationStreak10; 
  }
}
.icon-TopicLevelGraduationStreak15 {
  &:before {
    content: @icon-TopicLevelGraduationStreak15; 
  }
}
.icon-TopicLevelGraduationStreak20 {
  &:before {
    content: @icon-TopicLevelGraduationStreak20; 
  }
}
.icon-TopicLevelGraduationStreak30 {
  &:before {
    content: @icon-TopicLevelGraduationStreak30; 
  }
}
.icon-TopicLevelGraduationStreak50 {
  &:before {
    content: @icon-TopicLevelGraduationStreak50; 
  }
}
.icon-TopicLevelGraduationStreak75 {
  &:before {
    content: @icon-TopicLevelGraduationStreak75; 
  }
}
.icon-TopicLevelGraduationStreak100 {
  &:before {
    content: @icon-TopicLevelGraduationStreak100; 
  }
}
.icon-TopicLevelGraduationStreak150 {
  &:before {
    content: @icon-TopicLevelGraduationStreak150; 
  }
}
.icon-SessionStreak1 {
  &:before {
    content: @icon-SessionStreak1; 
  }
}
.icon-SessionStreak10 {
  &:before {
    content: @icon-SessionStreak10; 
  }
}
.icon-SessionStreak20 {
  &:before {
    content: @icon-SessionStreak20; 
  }
}
.icon-SessionStreak50 {
  &:before {
    content: @icon-SessionStreak50; 
  }
}
.icon-SessionStreak100 {
  &:before {
    content: @icon-SessionStreak100; 
  }
}
.icon-SessionStreak200 {
  &:before {
    content: @icon-SessionStreak200; 
  }
}
.icon-SessionStreak500 {
  &:before {
    content: @icon-SessionStreak500; 
  }
}
.icon-SessionStreak1000 {
  &:before {
    content: @icon-SessionStreak1000; 
  }
}
.icon-unlink-item {
  &:before {
    content: @icon-unlink-item; 
  }
}
.icon-indent-link {
  &:before {
    content: @icon-indent-link; 
  }
}
.icon-link-item {
  &:before {
    content: @icon-link-item; 
  }
}
.icon-unindent-link {
  &:before {
    content: @icon-unindent-link; 
  }
}
.icon-sliders {
  &:before {
    content: @icon-sliders; 
  }
}
.icon-_449 {
  &:before {
    content: @icon-_449; 
  }
}
.icon-not-started {
  &:before {
    content: @icon-not-started; 
  }
}
.icon-beginner {
  &:before {
    content: @icon-beginner; 
  }
}
.icon-adjust {
  &:before {
    content: @icon-adjust; 
  }
}
.icon-intermediate {
  &:before {
    content: @icon-intermediate; 
  }
}
.icon-training_activity {
  &:before {
    content: @icon-training_activity; 
  }
}
.icon-star-outline {
  &:before {
    content: @icon-star-outline; 
  }
}
.icon-star-solid {
  &:before {
    content: @icon-star-solid; 
  }
}
.icon-thumbtack-outline {
  &:before {
    content: @icon-thumbtack-outline; 
  }
}
.icon-thumbtack {
  &:before {
    content: @icon-thumbtack; 
  }
}
.icon-pushpin {
  &:before {
    content: @icon-pushpin; 
  }
}
.icon-pending {
  &:before {
    content: @icon-pending; 
  }
}
.icon-time {
  &:before {
    content: @icon-time; 
  }
}
.icon-create-article-nobg {
  &:before {
    content: @icon-create-article-nobg; 
  }
}
.icon-create-article {
  &:before {
    content: @icon-create-article; 
  }
}
.icon-external_link {
  &:before {
    content: @icon-external_link; 
  }
}
.icon-create-link-nobg {
  &:before {
    content: @icon-create-link-nobg; 
  }
}
.icon-create-link {
  &:before {
    content: @icon-create-link; 
  }
}
.icon-create-dz {
  &:before {
    content: @icon-create-dz; 
  }
}
.icon-broadcast {
  &:before {
    content: @icon-broadcast; 
  }
}
.icon-bullhorn {
  &:before {
    content: @icon-bullhorn; 
  }
}
.icon-hub_activity {
  &:before {
    content: @icon-hub_activity; 
  }
}
.icon-question_summary {
  &:before {
    content: @icon-question_summary; 
  }
}
.icon-contentexchange {
  &:before {
    content: @icon-contentexchange; 
  }
}
.icon-content-marketplace {
  &:before {
    content: @icon-content-marketplace; 
  }
}
.icon-squiggle {
  &:before {
    content: @icon-squiggle; 
  }
}
.icon-content-studio {
  &:before {
    content: @icon-content-studio; 
  }
}
.icon-needsreview {
  &:before {
    content: @icon-needsreview; 
  }
}
.icon-survey {
  &:before {
    content: @icon-survey; 
  }
}
.icon-check {
  &:before {
    content: @icon-check; 
  }
}
.icon-ok {
  &:before {
    content: @icon-ok; 
  }
}
.icon-ok_circle {
  &:before {
    content: @icon-ok_circle; 
  }
}
.icon-ok_sign {
  &:before {
    content: @icon-ok_sign; 
  }
}
.icon-checkbox-outline {
  &:before {
    content: @icon-checkbox-outline; 
  }
}
.icon-underconfident {
  &:before {
    content: @icon-underconfident; 
  }
}
.icon-correct_observation {
  &:before {
    content: @icon-correct_observation; 
  }
}
.icon-due_certs {
  &:before {
    content: @icon-due_certs; 
  }
}
.icon-warning_sign {
  &:before {
    content: @icon-warning_sign; 
  }
}
.icon-needs_coaching {
  &:before {
    content: @icon-needs_coaching; 
  }
}
.icon-incorrect_observation {
  &:before {
    content: @icon-incorrect_observation; 
  }
}
.icon-exclamation {
  &:before {
    content: @icon-exclamation; 
  }
}
.icon-exclamation_sign-outline {
  &:before {
    content: @icon-exclamation_sign-outline; 
  }
}
.icon-exclamation_sign {
  &:before {
    content: @icon-exclamation_sign; 
  }
}
.icon-dashboard {
  &:before {
    content: @icon-dashboard; 
  }
}
.icon-reportcard {
  &:before {
    content: @icon-reportcard; 
  }
}
.icon-knowledge {
  &:before {
    content: @icon-knowledge; 
  }
}
.icon-metrics {
  &:before {
    content: @icon-metrics; 
  }
}
.icon-_479 {
  &:before {
    content: @icon-_479; 
  }
}
.icon-bar_chart {
  &:before {
    content: @icon-bar_chart; 
  }
}
.icon-_480 {
  &:before {
    content: @icon-_480; 
  }
}
.icon-chart-area {
  &:before {
    content: @icon-chart-area; 
  }
}
.icon-chart-line {
  &:before {
    content: @icon-chart-line; 
  }
}
.icon-_529 {
  &:before {
    content: @icon-_529; 
  }
}
.icon-_530 {
  &:before {
    content: @icon-_530; 
  }
}
.icon-non_participants {
  &:before {
    content: @icon-non_participants; 
  }
}
.icon-user_switch {
  &:before {
    content: @icon-user_switch; 
  }
}
.icon-overdue_certs {
  &:before {
    content: @icon-overdue_certs; 
  }
}
.icon-certifications {
  &:before {
    content: @icon-certifications; 
  }
}
.icon-extratraining {
  &:before {
    content: @icon-extratraining; 
  }
}
.icon-news {
  &:before {
    content: @icon-news; 
  }
}
.icon-_460 {
  &:before {
    content: @icon-_460; 
  }
}
.icon-timeline {
  &:before {
    content: @icon-timeline; 
  }
}
.icon-refresher {
  &:before {
    content: @icon-refresher; 
  }
}
.icon-coffee {
  &:before {
    content: @icon-coffee; 
  }
}
.icon-trophy {
  &:before {
    content: @icon-trophy; 
  }
}
.icon-topperformers {
  &:before {
    content: @icon-topperformers; 
  }
}
.icon-trophy-gold {
  &:before {
    content: @icon-trophy-gold; 
  }
}
.icon-trophy-silver {
  &:before {
    content: @icon-trophy-silver; 
  }
}
.icon-trophy-bronze {
  &:before {
    content: @icon-trophy-bronze; 
  }
}
.icon-team-trophy-gold {
  &:before {
    content: @icon-team-trophy-gold; 
  }
}
.icon-team-trophy-silver {
  &:before {
    content: @icon-team-trophy-silver; 
  }
}
.icon-team-trophy-bronze {
  &:before {
    content: @icon-team-trophy-bronze; 
  }
}
.icon-inspections {
  &:before {
    content: @icon-inspections; 
  }
}
.icon-zoom_in {
  &:before {
    content: @icon-zoom_in; 
  }
}
.icon-zoom_out {
  &:before {
    content: @icon-zoom_out; 
  }
}
.icon-search {
  &:before {
    content: @icon-search; 
  }
}
.icon-ban_circle {
  &:before {
    content: @icon-ban_circle; 
  }
}
.icon-envelope_alt {
  &:before {
    content: @icon-envelope_alt; 
  }
}
.icon-envelope {
  &:before {
    content: @icon-envelope; 
  }
}
.icon-heart_empty {
  &:before {
    content: @icon-heart_empty; 
  }
}
.icon-heart {
  &:before {
    content: @icon-heart; 
  }
}
.icon-pause {
  &:before {
    content: @icon-pause; 
  }
}
.icon-play {
  &:before {
    content: @icon-play; 
  }
}
.icon-play_sign {
  &:before {
    content: @icon-play_sign; 
  }
}
.icon-onboarding_topic {
  &:before {
    content: @icon-onboarding_topic; 
  }
}
.icon-microphone {
  &:before {
    content: @icon-microphone; 
  }
}
.icon-microphone_off {
  &:before {
    content: @icon-microphone_off; 
  }
}
.icon-film {
  &:before {
    content: @icon-film; 
  }
}
.icon-video {
  &:before {
    content: @icon-video; 
  }
}
.icon-camera {
  &:before {
    content: @icon-camera; 
  }
}
.icon-camera_retro {
  &:before {
    content: @icon-camera_retro; 
  }
}
.icon-camera-plus {
  &:before {
    content: @icon-camera-plus; 
  }
}
.icon-photo {
  &:before {
    content: @icon-photo; 
  }
}
.icon-picture {
  &:before {
    content: @icon-picture; 
  }
}
.icon-image {
  &:before {
    content: @icon-image; 
  }
}
.icon-file_alt {
  &:before {
    content: @icon-file_alt; 
  }
}
.icon-_550 {
  &:before {
    content: @icon-_550; 
  }
}
.icon-sitemap {
  &:before {
    content: @icon-sitemap; 
  }
}
.icon-th_large {
  &:before {
    content: @icon-th_large; 
  }
}
.icon-th_list {
  &:before {
    content: @icon-th_list; 
  }
}
.icon-lock-forgot-password {
  &:before {
    content: @icon-lock-forgot-password; 
  }
}
.icon-lock {
  &:before {
    content: @icon-lock; 
  }
}
.icon-unlock_alt {
  &:before {
    content: @icon-unlock_alt; 
  }
}
.icon-unlock {
  &:before {
    content: @icon-unlock; 
  }
}
.icon-key {
  &:before {
    content: @icon-key; 
  }
}
.icon-tag {
  &:before {
    content: @icon-tag; 
  }
}
.icon-print {
  &:before {
    content: @icon-print; 
  }
}
.icon-map_marker {
  &:before {
    content: @icon-map_marker; 
  }
}
.icon-add-above {
  &:before {
    content: @icon-add-above; 
  }
}
.icon-add-below {
  &:before {
    content: @icon-add-below; 
  }
}
.icon-plus {
  &:before {
    content: @icon-plus; 
  }
}
.icon-plus_sign {
  &:before {
    content: @icon-plus_sign; 
  }
}
.icon-plus_square_o {
  &:before {
    content: @icon-plus_square_o; 
  }
}
.icon-f0fe {
  &:before {
    content: @icon-f0fe; 
  }
}
.icon-minus {
  &:before {
    content: @icon-minus; 
  }
}
.icon-check_minus {
  &:before {
    content: @icon-check_minus; 
  }
}
.icon-minus_sign_alt {
  &:before {
    content: @icon-minus_sign_alt; 
  }
}
.icon-medium {
  &:before {
    content: @icon-medium; 
  }
}
.icon-minus_sign {
  &:before {
    content: @icon-minus_sign; 
  }
}
.icon-remove {
  &:before {
    content: @icon-remove; 
  }
}
.icon-remove_sign {
  &:before {
    content: @icon-remove_sign; 
  }
}
.icon-remove_circle {
  &:before {
    content: @icon-remove_circle; 
  }
}
.icon-overconfident {
  &:before {
    content: @icon-overconfident; 
  }
}
.icon-question {
  &:before {
    content: @icon-question; 
  }
}
.icon-create-question-nobg {
  &:before {
    content: @icon-create-question-nobg; 
  }
}
.icon-question_sign {
  &:before {
    content: @icon-question_sign; 
  }
}
.icon-create-question {
  &:before {
    content: @icon-create-question; 
  }
}
.icon-info_sign {
  &:before {
    content: @icon-info_sign; 
  }
}
.icon-_279 {
  &:before {
    content: @icon-_279; 
  }
}
.icon-screenshot {
  &:before {
    content: @icon-screenshot; 
  }
}
.icon-asterisk {
  &:before {
    content: @icon-asterisk; 
  }
}
.icon-behaviors {
  &:before {
    content: @icon-behaviors; 
  }
}
.icon-eye_open {
  &:before {
    content: @icon-eye_open; 
  }
}
.icon-eye_close {
  &:before {
    content: @icon-eye_close; 
  }
}
.icon-uniF2A8 {
  &:before {
    content: @icon-uniF2A8; 
  }
}
.icon-legal {
  &:before {
    content: @icon-legal; 
  }
}
.icon-ticket {
  &:before {
    content: @icon-ticket; 
  }
}
.icon-slots {
  &:before {
    content: @icon-slots; 
  }
}
.icon-shopping_cart {
  &:before {
    content: @icon-shopping_cart; 
  }
}
.icon-thumbs_down_alt {
  &:before {
    content: @icon-thumbs_down_alt; 
  }
}
.icon-_334 {
  &:before {
    content: @icon-_334; 
  }
}
.icon-thumbs_up_alt {
  &:before {
    content: @icon-thumbs_up_alt; 
  }
}
.icon-upload {
  &:before {
    content: @icon-upload; 
  }
}
.icon-upload_alt {
  &:before {
    content: @icon-upload_alt; 
  }
}
.icon-download {
  &:before {
    content: @icon-download; 
  }
}
.icon-download_alt {
  &:before {
    content: @icon-download_alt; 
  }
}
.icon-downloads {
  &:before {
    content: @icon-downloads; 
  }
}
.icon-bookmark_empty {
  &:before {
    content: @icon-bookmark_empty; 
  }
}
.icon-bookmark {
  &:before {
    content: @icon-bookmark; 
  }
}
.icon-paper_clip {
  &:before {
    content: @icon-paper_clip; 
  }
}
.icon-link {
  &:before {
    content: @icon-link; 
  }
}
.icon-unlink {
  &:before {
    content: @icon-unlink; 
  }
}
.icon-copy {
  &:before {
    content: @icon-copy; 
  }
}
.icon-_553 {
  &:before {
    content: @icon-_553; 
  }
}
.icon-high {
  &:before {
    content: @icon-high; 
  }
}
.icon-low {
  &:before {
    content: @icon-low; 
  }
}
.icon-arrow_down {
  &:before {
    content: @icon-arrow_down; 
  }
}
.icon-arrow_left {
  &:before {
    content: @icon-arrow_left; 
  }
}
.icon-arrow_right {
  &:before {
    content: @icon-arrow_right; 
  }
}
.icon-caret_down {
  &:before {
    content: @icon-caret_down; 
  }
}
.icon-caret_up {
  &:before {
    content: @icon-caret_up; 
  }
}
.icon-caret_left {
  &:before {
    content: @icon-caret_left; 
  }
}
.icon-caret_right {
  &:before {
    content: @icon-caret_right; 
  }
}
.icon-angle_up {
  &:before {
    content: @icon-angle_up; 
  }
}
.icon-angle_down {
  &:before {
    content: @icon-angle_down; 
  }
}
.icon-angle_left {
  &:before {
    content: @icon-angle_left; 
  }
}
.icon-angle_right {
  &:before {
    content: @icon-angle_right; 
  }
}
.icon-chevron_up {
  &:before {
    content: @icon-chevron_up; 
  }
}
.icon-chevron_down {
  &:before {
    content: @icon-chevron_down; 
  }
}
.icon-chevron_left {
  &:before {
    content: @icon-chevron_left; 
  }
}
.icon-chevron_right {
  &:before {
    content: @icon-chevron_right; 
  }
}
.icon-double_angle_up {
  &:before {
    content: @icon-double_angle_up; 
  }
}
.icon-double_angle_down {
  &:before {
    content: @icon-double_angle_down; 
  }
}
.icon-double_angle_left {
  &:before {
    content: @icon-double_angle_left; 
  }
}
.icon-double_angle_right {
  &:before {
    content: @icon-double_angle_right; 
  }
}
.icon-move {
  &:before {
    content: @icon-move; 
  }
}
.icon-fullscreen {
  &:before {
    content: @icon-fullscreen; 
  }
}
.icon-resize_full {
  &:before {
    content: @icon-resize_full; 
  }
}
.icon-resize_small {
  &:before {
    content: @icon-resize_small; 
  }
}
.icon-resize_vertical {
  &:before {
    content: @icon-resize_vertical; 
  }
}
.icon-resize_horizontal {
  &:before {
    content: @icon-resize_horizontal; 
  }
}
.icon-exchange {
  &:before {
    content: @icon-exchange; 
  }
}
.icon-undo {
  &:before {
    content: @icon-undo; 
  }
}
.icon-reply {
  &:before {
    content: @icon-reply; 
  }
}
.icon-refresh {
  &:before {
    content: @icon-refresh; 
  }
}
.icon-reinforcement {
  &:before {
    content: @icon-reinforcement; 
  }
}
.icon-share_alt {
  &:before {
    content: @icon-share_alt; 
  }
}
.icon-retweet {
  &:before {
    content: @icon-retweet; 
  }
}
.icon-comment {
  &:before {
    content: @icon-comment; 
  }
}
.icon-comment_alt {
  &:before {
    content: @icon-comment_alt; 
  }
}
.icon-comments {
  &:before {
    content: @icon-comments; 
  }
}
.icon-comments_alt {
  &:before {
    content: @icon-comments_alt; 
  }
}
.icon-notification {
  &:before {
    content: @icon-notification; 
  }
}
.icon-bell_alt {
  &:before {
    content: @icon-bell_alt; 
  }
}
.icon-bell {
  &:before {
    content: @icon-bell; 
  }
}
.icon-file_text {
  &:before {
    content: @icon-file_text; 
  }
}
.icon-file_text_alt {
  &:before {
    content: @icon-file_text_alt; 
  }
}
.icon-game_popularity {
  &:before {
    content: @icon-game_popularity; 
  }
}
.icon-desktop {
  &:before {
    content: @icon-desktop; 
  }
}
.icon-keyboard {
  &:before {
    content: @icon-keyboard; 
  }
}
.icon-mobile_phone {
  &:before {
    content: @icon-mobile_phone; 
  }
}
.icon-phone {
  &:before {
    content: @icon-phone; 
  }
}
.icon-circle_blank {
  &:before {
    content: @icon-circle_blank; 
  }
}
.icon-_446 {
  &:before {
    content: @icon-_446; 
  }
}
.icon-circle {
  &:before {
    content: @icon-circle; 
  }
}
.icon-dot_circle_alt {
  &:before {
    content: @icon-dot_circle_alt; 
  }
}
.icon-bullseye {
  &:before {
    content: @icon-bullseye; 
  }
}
.icon-archive {
  &:before {
    content: @icon-archive; 
  }
}
.icon-category {
  &:before {
    content: @icon-category; 
  }
}
.icon-folder_close {
  &:before {
    content: @icon-folder_close; 
  }
}
.icon-folder_close_alt {
  &:before {
    content: @icon-folder_close_alt; 
  }
}
.icon-folder_open {
  &:before {
    content: @icon-folder_open; 
  }
}
.icon-folder_open_alt {
  &:before {
    content: @icon-folder_open_alt; 
  }
}
.icon-milestone {
  &:before {
    content: @icon-milestone; 
  }
}
.icon-reported {
  &:before {
    content: @icon-reported; 
  }
}
.icon-flag {
  &:before {
    content: @icon-flag; 
  }
}
.icon-flag_alt {
  &:before {
    content: @icon-flag_alt; 
  }
}
.icon-flag_checkered {
  &:before {
    content: @icon-flag_checkered; 
  }
}
.icon-ellipsis_horizontal {
  &:before {
    content: @icon-ellipsis_horizontal; 
  }
}
.icon-ellipsis_vertical {
  &:before {
    content: @icon-ellipsis_vertical; 
  }
}
.icon-trash {
  &:before {
    content: @icon-trash; 
  }
}
.icon-_473 {
  &:before {
    content: @icon-_473; 
  }
}
.icon-_484 {
  &:before {
    content: @icon-_484; 
  }
}
.icon-_485 {
  &:before {
    content: @icon-_485; 
  }
}
.icon-shield {
  &:before {
    content: @icon-shield; 
  }
}
.icon-sort_by_alphabet {
  &:before {
    content: @icon-sort_by_alphabet; 
  }
}
.icon-_329 {
  &:before {
    content: @icon-_329; 
  }
}
.icon-sort_by_attributes {
  &:before {
    content: @icon-sort_by_attributes; 
  }
}
.icon-sort_by_attributes_alt {
  &:before {
    content: @icon-sort_by_attributes_alt; 
  }
}
.icon-sort_by_order {
  &:before {
    content: @icon-sort_by_order; 
  }
}
.icon-sort_by_order_alt {
  &:before {
    content: @icon-sort_by_order_alt; 
  }
}
.icon-font {
  &:before {
    content: @icon-font; 
  }
}
.icon-bold {
  &:before {
    content: @icon-bold; 
  }
}
.icon-italic {
  &:before {
    content: @icon-italic; 
  }
}
.icon-underline {
  &:before {
    content: @icon-underline; 
  }
}
.icon-strikethrough {
  &:before {
    content: @icon-strikethrough; 
  }
}
.icon-header {
  &:before {
    content: @icon-header; 
  }
}
.icon-text_height {
  &:before {
    content: @icon-text_height; 
  }
}
.icon-text_width {
  &:before {
    content: @icon-text_width; 
  }
}
.icon-list {
  &:before {
    content: @icon-list; 
  }
}
.icon-ul {
  &:before {
    content: @icon-ul; 
  }
}
.icon-ol {
  &:before {
    content: @icon-ol; 
  }
}
.icon-exam {
  &:before {
    content: @icon-exam; 
  }
}
.icon-reorder {
  &:before {
    content: @icon-reorder; 
  }
}
.icon-table {
  &:before {
    content: @icon-table; 
  }
}
.icon-pdf {
  &:before {
    content: @icon-pdf; 
  }
}
.icon-spreadsheet {
  &:before {
    content: @icon-spreadsheet; 
  }
}
.icon-slide {
  &:before {
    content: @icon-slide; 
  }
}
.icon-doc {
  &:before {
    content: @icon-doc; 
  }
}
.icon-file {
  &:before {
    content: @icon-file; 
  }
}
.icon-zip {
  &:before {
    content: @icon-zip; 
  }
}
.icon-audio {
  &:before {
    content: @icon-audio; 
  }
}
.icon-message-plus {
  &:before {
    content: @icon-message-plus; 
  }
}
.icon-suggestquestion {
  &:before {
    content: @icon-suggestquestion; 
  }
}
.icon-cogs {
  &:before {
    content: @icon-cogs; 
  }
}
.icon-_557 {
  &:before {
    content: @icon-_557; 
  }
}
.icon-_558 {
  &:before {
    content: @icon-_558; 
  }
}
.icon-switch_on {
  &:before {
    content: @icon-switch_on; 
  }
}
.icon-switch_off {
  &:before {
    content: @icon-switch_off; 
  }
}
.icon-facebook {
  &:before {
    content: @icon-facebook; 
  }
}
.icon-facebook-logo {
  &:before {
    content: @icon-facebook-logo; 
  }
}
.icon-facebook_sign {
  &:before {
    content: @icon-facebook_sign; 
  }
}
.icon-linkedin {
  &:before {
    content: @icon-linkedin; 
  }
}
.icon-linkedin_sign {
  &:before {
    content: @icon-linkedin_sign; 
  }
}
.icon-google_plus {
  &:before {
    content: @icon-google_plus; 
  }
}
.icon-googleplus-logo {
  &:before {
    content: @icon-googleplus-logo; 
  }
}
.icon-salesforce-logo {
  &:before {
    content: @icon-salesforce-logo; 
  }
}
.icon-zendesk-logo {
  &:before {
    content: @icon-zendesk-logo; 
  }
}
.icon-mostactive {
  &:before {
    content: @icon-mostactive; 
  }
}
.icon-stats {
  &:before {
    content: @icon-stats; 
  }
}
.icon-uniF1C0 {
  &:before {
    content: @icon-uniF1C0; 
  }
}
.icon-th {
  &:before {
    content: @icon-th; 
  }
}
.icon-male {
  &:before {
    content: @icon-male; 
  }
}
.icon-integration {
  &:before {
    content: @icon-integration; 
  }
}
.icon-publish {
  &:before {
    content: @icon-publish; 
  }
}
.icon-evaluations {
  &:before {
    content: @icon-evaluations; 
  }
}
.icon-source-info {
  &:before {
    content: @icon-source-info; 
  }
}
.icon-translate {
  &:before {
    content: @icon-translate; 
  }
}
.icon-send {
  &:before {
    content: @icon-send; 
  }
}
.icon-like .path1 {
  &:before {
    content: @icon-like-path1;  
    color: rgb(2, 116, 176);  
    opacity: 0.15;
  }
}
.icon-like .path2 {
  &:before {
    content: @icon-like-path2;  
    margin-left: -1em;  
    color: rgb(2, 116, 176);
  }
}
.icon-love .path1 {
  &:before {
    content: @icon-love-path1;  
    color: rgb(186, 12, 47);  
    opacity: 0.15;
  }
}
.icon-love .path2 {
  &:before {
    content: @icon-love-path2;  
    margin-left: -1em;  
    color: rgb(186, 12, 47);
  }
}
.icon-celebrate .path1 {
  &:before {
    content: @icon-celebrate-path1;  
    color: rgb(118, 175, 60);  
    opacity: 0.2;
  }
}
.icon-celebrate .path2 {
  &:before {
    content: @icon-celebrate-path2;  
    margin-left: -1em;  
    color: rgb(118, 175, 60);
  }
}
.icon-thinking .path1 {
  &:before {
    content: @icon-thinking-path1;  
    color: rgb(0, 50, 158);  
    opacity: 0.2;
  }
}
.icon-thinking .path2 {
  &:before {
    content: @icon-thinking-path2;  
    margin-left: -1em;  
    color: rgb(0, 50, 158);
  }
}
.icon-upset .path1 {
  &:before {
    content: @icon-upset-path1;  
    color: rgb(204, 122, 0);  
    opacity: 0.16;
  }
}
.icon-upset .path2 {
  &:before {
    content: @icon-upset-path2;  
    margin-left: -1em;  
    color: rgb(204, 122, 0);
  }
}

