@import (reference) "@common/prerequisites/prerequisites.less";

.pill-container {
  .display-flex();
  .justify-content(center);
  .flex-wrap();

  .pill__item {
    display: inline-block;
    position: relative;
    min-width: 9.6rem;
    margin: 1.6rem;

    input {
      -webkit-appearance: none;
      background: none;
      border: 2px solid;
      height: 100%;
      left: 0;
      opacity: .00001;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;

      &:hover ~ label,
      &.focus-visible ~ label {
        color: @white;
        background-color: @primary-hover-blue;
      }
      &.selected ~ label {
        color: @white;
        background-color: @primary-blue;
      }
    }

    label {
      .border-radius(1.5rem);
      background: @secondary-bg;
      color: @ax-color-text;
      border: 2px solid transparent;
      display: block;
      padding: .25em .75em;
      text-align: center;

      &:after {
        .border-radius(1.5rem);
        border: 2px solid;
        border-color: rgba(0,0,0,0);
        bottom: 0;
        content: "";
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
