@section-gap: 3.2rem;
@section-gap-desktop: 5.6rem;
.common-layout-styles() {
  .full-width;
  .box-sizing(border-box);
}

.home-page-layout {
  .common-layout-styles();
  .display-flex();
  .flex-direction(column);
  padding: @ax-spacing-l @ax-spacing-m;

  .screen-lg-min({
    padding: @ax-spacing-xl @ax-spacing-l @ax-spacing-xxl;
    .margin-center;
    .align-items(center);
  });

  .post-training__daily-activities {
    > .nudges {
      margin-top: @ax-spacing-l;
    }
  }

  .skip-training {
    .display-flex();
    .justify-content(center);
    margin-top: @ax-spacing-l;
  }

  &__region {
    .common-layout-styles();
    margin-top: @section-gap;

    .screen-md-portrait-min({
      margin-top: @section-gap-desktop;
    });

    // Special spacing for regions on the hub/home page
    &.greeting {
      margin-top: 0;
      margin-bottom: 0;
    }

    &.stats {
      margin-top: 2.2rem;

      .screen-md-portrait-min({
        margin-top: 2rem;
      });
    }

    &.events {
      margin-top: 1.6rem;

      .screen-md-portrait-min({
        margin-top: 2.4rem;
      });
    }

    &.todos {
      margin-top: 1.6rem;

      .screen-md-portrait-min({
        margin-top: 2.4rem;
      });
    }

    &.nudges {
      margin-top: @ax-spacing-l;
    }
  }

  .post-training__daily-activities .daily-activity {
    margin-top: @ax-spacing-l;

    &:first-child {
      margin-top: 0;
    }
  }

  .swiper-container {
    width: 100%;

    .screen-lg-max({
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      margin-left: -1.6rem;
      margin-right: -1.6rem;
    });
  }


  // NOTE: It's possible these two style declarations are no longer needed. I hesitate to pull them out right now without fully testing, but in future revisions we can look into whether they can be removed
  .swiper-wrapper {
    .align-items(); // This should handle the sizing of child elements, but not if they have set heights/widths
  }
  .swiper-slide:not(.empty-swiper) {
    // Unsetting the default height/width so that the .align-items call above will work
    height: initial;
    width: initial;
  }
}

.home-page-layout__content {
  position: relative;
}

.home-page-training {
  .post-training__daily-activities,
  .event-checkin__card {
    margin-bottom: @ax-spacing-l;

    .ax-breakpoint-m {
      margin-bottom: @ax-spacing-xxl;
    }
  }
}
