div.welcome-message.welcome-message__wrapper {
    .screen-height-xxs-max({
        display: none;
    });
}

@import '@common/components/axMarkdown/AxMarkdown.less';

.animation-max-thinking {
  height: 2.2rem;
  width: 5.2rem;
  background-size: calc(100%/3) 50% !important;
  --_g: no-repeat radial-gradient(circle closest-side, #999 95%, transparent);
  background:
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  animation: d3 1s infinite linear;
  @keyframes d3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
  }
};

.max-thinking-timer {
  transition: all 800ms;

  &.max-thinking-timer--hidden {
    opacity: 0;
  }
}
