.referral-confirmation {
  .characterarea {
    .border-radius(@ax-border-radius-m);
    border: .1rem solid @white;
    margin-bottom: @ax-spacing-l;
    padding-top: 0;

    .NoCoach {
      .ax-breakpoint-m({
        height: 2rem;
      });
    }
  }

  .messaging,
  .go-messaging {
    font-size: @h3-font-size;
    margin: 0 @ax-spacing-s @ax-spacing-m;
    text-align: center;

    .ax-breakpoint-m({
      font-size: @topic-font-size;
      margin: 0 auto @ax-spacing-m;
      width: 75%;
    });
  }

  .go-messaging {
    color: @red;
    font-weight: @ax-font-weight-bold;
    margin-bottom: @ax-spacing-m;

    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-l;
    });
  }
}

li.empty-referral {
  color: @ax-color-grey-40;
  padding: @ax-spacing-m 0;
}
