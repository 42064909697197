//Headings
.h1,
.h2,
.h3 {
	font-weight: @ax-font-weight-medium;
	margin: 0;
}

.h1 {
  font-size: @h1-mobile-font-size;

  .ax-breakpoint-m({
    font-size: @h1-font-size;
  });

  &.first-level {
    line-height: 3.4rem;

    .ax-breakpoint-m({
      line-height: 4rem;
    });
  }
}

.h2 {
  font-size: @h2-mobile-font-size;

  .ax-breakpoint-m({
    font-size: @h2-font-size;
  });
}

.h3 {
  font-size: @h3-mobile-font-size;

  .ax-breakpoint-m({
    font-size: @h3-font-size;
  });
}

.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
  line-height:normal;
}

//Muted Text
.text-muted {color:@secondary-text-color;}

//Text Alignment
.center {text-align: center;}
.align-left {text-align: left;}
.align-right {text-align: right;}

.text-right {text-align: right;}
.text-left {text-align: left;}
.text-center {text-align: center;}

.break-long-words {
  // Based on solution from https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
  // These are technically the same, but use both
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  // This is the dangerous one in WebKit, as it breaks things wherever
  word-break: break-all;
  // Instead use this non-standard one:
  word-break: break-word;

  // Adds a hyphen where the word breaks, if supported (No Blink)
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
