
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
//  COMMON VARIABLES ONLY
//  - Names should describe value output and keep reusability in mind (ie. @font-size-icon-nav)
//  - Use clothing size conventions (xs, s, m, l, xl) for size ranges
//  - Feature specific variables should be in their related stylesheets
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// UI 3.0 variables
@import "ui-kit/ax-variables.less";

//App Colors
@training-color:                            #436282;
@manager-color:                             #c02534;
@exchange-color:                            #8251A0;
@admin-color:                               #437e54;

// This should be overridden by each of the apps to specify their app color.
@app-zone-color:                            #ffffff00;

//Global Colors
@white:                                     @ax-color-white;
@transparent-white:                         rgba(255, 255, 255, 0);
@black:                                     @ax-color-grey-70;
@nav-hover:                                 @ax-color-grey-80;
@red:                                       @ax-color-task;
@red-hover:                                 #cc0505;
@green:                                     @ax-color-training;
@green-hover:                               #70a038;
@correct-green:                             @ax-color-green-60;
@light-green:                               #e0f5c8;
@yellow:                                    #b58b17;
@light-blue:                                #21E6C2;
@current:                                   #ffb03b;
@baseline:                                  #46a3fe;
@survey:                                    @ax-color-programs;
@exam:                                      @primary-grey;
@training-program:                          @ax-color-programs;
@orange:                                    #ffa500;
@deep-black:                                #000000;
@search-highlight:                          #4BE70C;
@turquoise:                                 #2fa58c;
@purple:                                    #4e13e5;
@cold-streak:                               #558dca;
@blue-background:                           #CDEDFE; // Previously @ax-color-blue-20
@green-background:                          #E6F2D9; // Previously @ax-color-green-20
@red-background:                            #FCCFD8; // Previously @ax-color-red-20

@reinforcement-color:                       @ax-color-programs;
@assignedpath-color:                        @ax-color-programs;
@intro-training-color:                      @ax-color-training;
@eval-color:                                @ax-color-assessments;
@refresher-color:                           @ax-color-training;
@formal-exam-color:                         @ax-color-assessments;
@self-directed-color:                       @ax-color-programs;

@primary-blue:                              @ax-color-blue-60;
@primary-hover-blue:                        #23527c;

// Disabled states, etc.
@primary-grey:                              #a6a6a6;

//Item borders, input borders, tile borders
@secondary-grey:                            #cfcece;
@subtle-border-grey:                        rgba(0,0,0,.16);

//Rules, Page seperators, row underlines
@accent-grey:                               #ececec;

//Page title, button bar
@primary-bg:                                @ax-color-grey-20;

//Form element bgs (input, textareas, selects)
@secondary-bg:                              #f6f6f6;
@pagination-grey:                           #efefef;

//Subtle hover
@subtle-hover:                              @ax-color-app-background;

//Hover & active state color - tds,rows basically anything
@element-hover-bright:                      @ax-color-grey-20;
@element-hover-white:                       @white;

//Error Handling
@error-state:                               @red;

//Main action buttons
@primary-button:                            @primary-blue;
@primary-button-hover:                      @primary-hover-blue;
@primary-button-disabled:                   @primary-grey;

@disabled-color:                            @primary-grey;

//Text
@secondary-text-color:                      @ax-color-text-secondary;
//Use for secondary text that is set on a grey background; for AA compliance
@secondary-text-grey-bg:                    #6D6D6D;
@link-color:                                @primary-blue;
@link-hover:                                @primary-hover-blue;
@meta-color:                                @primary-grey;

//Buttons
@primary-button-color:                      @primary-button;
@primary-button-color-hover:                @primary-hover-blue;
@primary-button-color-disabled:             @primary-grey;

@secondary-button-color:                    @primary-bg;
@secondary-button-hover:                    @white;

//Elements
@horizontal-rule:                           @accent-grey;
@tile-bg:                                   @primary-grey;
@input-bg-color:                            @secondary-grey;
@item-hover-active:                         @element-hover-bright;

//Knowledge Color States
@knowledge-expert:                          @yellow;
@knowledge-good:                            @ax-color-green-60;
@knowledge-fair:                            @yellow;
@knowledge-poor:                            @ax-color-red-60;
@knowledge-beginner:                        @ax-color-blue-60;
@graduate:                                  @ax-color-blue-60;

//Global variables
//Desktop
@header-spacing:                            1.3rem;
@h1-spacing:                                1.3rem;
@h1-font-size:                              1.6rem;
@h2-font-size:                              1.4rem;
@h3-font-size:                              1.4rem;
@h4-font-size:                              1.8rem;
@topic-font-size:                           1.4rem;
@meta-text-size:                            @ax-font-size-body;
@icon-button-size:                          2rem;
@icon-nav-size:                             2.4rem;
@icon-nav-size-px:                          24px;   // Needed for accessibility (large font sizes)
@pagination-button:                         1.4rem;
@tile-icon-size:                            3.4rem;
@dz-article-icon-size:                      3.2rem;
@dz-attachment-icon-size:                   3.2rem;
@table-header-font-size:                    @ax-font-size-body;
@news-item-icon-size-mobile:                4rem;
@news-item-icon-size-mobile-with-count:     3rem;
@news-item-icon-size:                       4rem;
@news-item-icon-size-with-count:            3.5rem;
@news-item-icon-size-small:                 3.8rem;
@button-icon-size:                          2.4rem;
@user-topic-score:                          3.4rem;
@dz-create-icon-size:                       3.6rem;
@max-communications-content-width:          67rem;

//Mobile
@h1-mobile-font-size:                       1.4rem;
@h2-mobile-font-size:                       1.3rem;
@h3-mobile-font-size:                       1.3rem;
@h4-mobile-font-size:                       @h4-font-size / 1.05;
@icon-mobile-nav-size:                      2.0rem;
@pagination-mobile-button:                  1.4rem;
@game-min-width-mobile:                     20rem;


/* =============================================================================
   Screen size variables
   ========================================================================== */

@screen-xs-min:                    1px;
@screen-xs-max:                    ( @screen-sm-min - 1 );
@screen-sm-min:                    @screen-xs-min + ( ( @screen-md-portrait-min - @screen-xs-min ) / 2 );
@screen-sm-max:                    ( @screen-md-portrait-min - 1 );
@screen-md-portrait-min:           @ax-breakpoint-tablet-min;
@screen-md-portrait-max:           ( @screen-md-landscape-min - 1 );
@screen-md-landscape-min:          @screen-md-portrait-min + ( ( @screen-lg-min - @screen-md-portrait-min ) / 2);
@screen-md-landscape-max:          ( @screen-lg-min - 1 );
@screen-lg-min:                    @ax-breakpoint-desktop-min;
@screen-lg-max:                    @ax-breakpoint-desktop-max;
@screen-xl-min:                    @ax-breakpoint-desktop-max + 1;
@screen-height-xs-max:             @ax-breakpoint-height-max-xs;
@screen-height-xxs-max:            @ax-breakpoint-height-max-xxs;

@header-height-desktop:                     5.6rem;
@header-height:                             4rem;
@location-header-height:                    6rem;
@search-bar-height:                         3.5rem;
@footer-height:                             4.3rem;
@nav-menu-width:                            1.9rem;
@transition-speed:                          .2s;

@min-index:                                 0;
@root-index:                                @min-index + 10;
@menu-index:                                @root-index + 10;
@page-index:                                @menu-index + 10;
@blanket-index:                             @page-index + 10;
@max-index:                                 999;

:root {
   --ax-app-header-height-mobile: @header-height;
   --ax-app-header-height-desktop: @header-height-desktop;

   --ax-max-communications-content-width: @max-communications-content-width;
}