@ax-filterable-community-margin: 0 1.4rem @ax-spacing-m;

.filterable-community {
  position: relative;
  margin: @ax-filterable-community-margin;

  .filter-controls {
    background-color: @ax-color-white;
    border: 1px solid @ax-color-grey-30;
    border-radius: @ax-border-radius-m;
    box-sizing: border-box;

    .community-icon {
      margin: 0.7rem 0 0 0.7rem;
    }

    input.filter-input {
      border: 0 none;
      background-color: @ax-color-white;
      box-shadow: none;
    }

    .user-count {
      padding: 0.7rem 0 0.7rem 0.7rem;
    }

    .icon-groups {
      margin: 0.7rem;
    }

    .trigger-dropdown {
      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    z-index: 900;
    height: 27.7rem;

    .ax-breakpoint-m({
      height: 33.7rem;
    });

    width: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: @ax-spacing-s;
    margin: @ax-spacing-xs 0;
    background-color: @ax-color-white;
    box-shadow: @ax-box-shadow-l;
    border-radius: @ax-border-radius-m;

    .recent-communities {
      margin-bottom: @ax-spacing-s;
    }

    .select-all {
      background: @ax-color-grey-20;
    }

    .community-checkbox {
      padding-right: @ax-spacing-s;
    }

    .community-name {
      padding-left: @ax-spacing-s;

      &__multiselect {
        padding-left: 0;
      }
    }

    .communities-list {
      li {
        padding: .8rem;

        &:hover {
          background-color: @ax-color-grey-20;
        }

        &.highlight {
          background-color: @ax-color-grey-20;
        }
      }
    }

    &__multiselect {
      padding: 0;
    }
  }

  .community-icon {
    width: 2rem;
    height: 2rem;
    border-radius: @ax-border-radius-s;
    background-color: @ax-color-grey-30;

    img {
      width: 2rem;
      height: 2rem;
    }

    &__multiselect {
      margin-right: @ax-spacing-s;
    }
  }

  .dropdown-menu .multiselect-communities .communities-list li {
    padding: 0;

    .ax-checkbox label {
      padding: @ax-spacing-s;

      &:after {
        margin: @ax-spacing-s;
      }
    }
  }
}
