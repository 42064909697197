@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

button.filename-card {
  padding: @ax-spacing-s 0;
  border-radius: @ax-border-radius-xl;
  border: 0.1rem solid @ax-color-grey-30;
}

.filename-card {
  .ax-grid {
    align-items: center;
    width: fit-content;
    padding-right: @ax-spacing-m;

    .attachment-icon {
      font-size: 4.5rem;
      margin-left: @ax-spacing-s;
      margin-right: @ax-spacing-xs;
    }
    .attachment-icon.icon-pdf {
      color: @ax-color-red-60;
    }
    .filename {
      line-height: @ax-line-height-s;
      text-align: left;
      max-width: 14rem;
      .screen-md-landscape-min({
        max-width: 25rem;
      });
      .text-ellipsis();

      .subtitle {
        display: block;
        font-weight: @ax-font-weight-regular;
      }
    }
    .download-unavailable {
      color: @ax-color-semantic-informational;
    }
  }
}
