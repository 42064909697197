/*------------------------------------------------------------------------------
 * 'filter' extension
 *----------------------------------------------------------------------------*/

.fancytree-ext-filter-dimm {
  span.fancytree-node span.fancytree-title {
    color: @fancy-font-color-dimm;
  }

  tr.fancytree-submatch span.fancytree-title,
  span.fancytree-node.fancytree-submatch span.fancytree-title {
    color: @black;
    font-weight: @ax-font-weight-regular;
  }

  tr.fancytree-match span.fancytree-title,
  span.fancytree-node.fancytree-match span.fancytree-title {
    color: @black;
    font-weight: @ax-font-weight-bold;
  }
}

.fancytree-ext-filter-hide {
  tr.fancytree-hide,
  span.fancytree-node.fancytree-hide {
    display: none;
  }

  tr.fancytree-submatch span.fancytree-title,
  span.fancytree-node.fancytree-submatch span.fancytree-title {
    color: @fancy-font-color-dimm;
    font-weight: lighter;
  }

  tr.fancytree-match span.fancytree-title,
  span.fancytree-node.fancytree-match span.fancytree-title {
    color: @black;
    font-weight: @ax-font-weight-regular;
  }

  tr.fancytree-match span.fancytree-title mark,
  span.fancytree-node.fancytree-match span.fancytree-title mark {
    padding: 0;
    margin: 0;
    .partial-match;
  }
}
