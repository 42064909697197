.menu-list {
  .display-flex();

  @import 'item/MenuItemCommon.less';

  &.menu-list-top {
    height: 100%;
    margin-left: auto;

    li {
      height: 100%;
    }

    @import 'item/MenuItemTop.less';

    &.menu-list-locked-to-top {
      width: 100%;
      margin-left: 0;

      li {
        .flex-grow(1);
        .flex-basis(0);
        width: 0;
      }

      @import 'item/MenuItemLockedToTop.less';
    }
  }

  &.menu-list-bottom {
    height: 100%;

    li {
      .flex-basis(0);
      .flex-grow(1);
      height: 100%;
      min-width: 0; // needed for text ellipsis
    }
  }
}

@import '../footer/BottomMenu.less';
@import 'offscreenMeasurement/OffscreenMeasurementMenu.less';
