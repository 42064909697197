.menu-item {
  .justify-content(center);
  border: none;
  padding: 0 3px;

  .item-text {
    font-size: 1.4rem;
    .text-ellipsis();
    margin-bottom: 0;
  }

  .item-icon {
    font-size: 2rem;
    margin-bottom: 0;
  }

  .item-selected-bar {
    display: none;
  }
}
