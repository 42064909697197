@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
/*
This comment clarifies the relationship between this file `MediaelementPlayerLegacyOverride.less`,
and the `mediaelementplayer-legacy.css` file located in the MediaElement library at `common/vendor/mediaelement/v7/mediaelementplayer-legacy.css`.

In the TranscodedPlayer, we use the class prefix "mejs-" that corresponds to mediaelementplayer-legacy.css.
We decided to retain this prefix while transitioning from version 2 to versions 3 and 4.

The new version (v7) of the MediaElement library has adopted "rem" units, assuming a root font size of 16px.
However, our application uses a root font size of 10px.
To resolve this discrepancy, I need to override some of their CSS rules to accommodate the difference in rem bases.
*/

/* ============ Axonify Overrides FOR mediaelementplayer-legacy.css============= */

.player-wrapper {
  .mejs-offscreen {
      border: 0;
      clip: rect( 1px, 1px, 1px, 1px );
      -webkit-clip-path: inset( 50% );
              clip-path: inset( 50% );
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      word-wrap: normal;
  }

  .mejs-container {
      background: #000;
      box-sizing: border-box;
      font-family: 'Helvetica', Arial, serif;
      position: relative;
      text-align: left;
      text-indent: 0;
      vertical-align: top;
  }

  .mejs-container * {
      box-sizing: border-box;
  }

  /* Hide native play button and control bar from iOS to favor plugin button */
  .mejs-container video::-webkit-media-controls,
  .mejs-container video::-webkit-media-controls-panel,
  .mejs-container video::-webkit-media-controls-panel-container,
  .mejs-container video::-webkit-media-controls-start-playback-button {
      -webkit-appearance: none;
      display: none !important;
  }

  .mejs-fill-container,
  .mejs-fill-container .mejs-container {
      height: 100%;
      width: 100%;
  }

  .mejs-fill-container {
      background: transparent;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
  }

  .mejs-container:focus {
      outline: none;
  }

  .mejs-iframe-overlay {
      height: 100%;
      position: absolute;
      width: 100%;
  }

  .mejs-embed,
  .mejs-embed body {
      background: #000;
      height: 100%;
      margin: 0;
      overflow: hidden;
      padding: 0;
      width: 100%;
  }

  .mejs-fullscreen {
      overflow: hidden !important;
  }

  .mejs-container-fullscreen {
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1000;
  }

  .mejs-container-fullscreen .mejs-mediaelement,
  .mejs-container-fullscreen video {
      /* ============ Axonify Overrides ============= */
      height: 100%;
      width: 100% !important;
  }

  /* Start: LAYERS */
  .mejs-background {
      left: 0;
      position: absolute;
      top: 0;
  }

  .mejs-mediaelement {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
  }

  .mejs-poster {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      left: 0;
      position: absolute;
      top: 0;
      z-index: 1;
  }

  :root .mejs-poster-img {
      display: none;
  }

  .mejs-poster-img {
      border: 0;
      padding: 0;
  }

  .mejs-overlay {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
  }

  .mejs-layer {
      z-index: 1;
  }

  .mejs-overlay-play {
      cursor: pointer;
  }

  .mejs-overlay-button {
      background: transparent;
      border: 0;
  }

  .mejs-overlay:hover .mejs-overlay-button svg {
      opacity: 1;
  }

  .mejs-overlay-button svg {
      opacity: 0.75;
  }

  .mejs-overlay-button:focus svg {
      opacity: 1;
  }

  .mejs-overlay-button,
  .mejs-overlay-button svg {
      height: 80px;
      width: 80px;
  }

  .mejs-overlay-loading,
  .mejs-overlay-loading svg {
      height: 80px;
      width: 80px;
  }

  .mejs-overlay-loading-bg-img {
      -webkit-animation: mejs-loading-spinner 1s linear infinite;
              animation: mejs-loading-spinner 1s linear infinite;
      display: block;
      height: 80px;
      width: 80px;
      z-index: 1;
  }

  @-webkit-keyframes mejs-loading-spinner {
      100% {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
      }
  }

  @keyframes mejs-loading-spinner {
      100% {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
      }
  }

  /* End: LAYERS */

  /* Start: CONTROL BAR */
  .mejs-controls {
      bottom: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 40px;
      left: 0;
      list-style-type: none;
      margin: 0;
      padding: 0 10px;
      position: absolute;
      width: 100%;
      z-index: 3;
  }

  .mejs-controls:not([style*='display: none']) {
      background: rgba(255, 0, 0, 0.7);
      background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.35));
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.35));
  }

  .mejs-button,
  .mejs-time,
  .mejs-time-rail {
      font-size: 10px;
      height: 40px;
      line-height: 10px;
      margin: 0;
      width: 32px;
  }

  .mejs-button > button {
      background-color: transparent;
      border: 0;
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 18px;
      line-height: 0;
      margin: 10px 6px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      text-decoration: none;
      width: 18px;
  }

  .mejs-button svg {
      fill: currentColor;
      height: 18px;
      width: 18px;
  }

  /* :focus for accessibility */
  .mejs-button > button:focus {
      outline: dotted 2px #fff;
  }

  .mejs-container-keyboard-inactive a,
  .mejs-container-keyboard-inactive a:focus,
  .mejs-container-keyboard-inactive button,
  .mejs-container-keyboard-inactive button:focus,
  .mejs-container-keyboard-inactive [role=slider],
  .mejs-container-keyboard-inactive [role=slider]:focus {
      outline: 0;
  }

  /* End: CONTROL BAR */

  /* Start: Play (Play / Pause / Replay) */
  .mejs-playpause-button svg {
      display: none;
  }

  .mejs-play svg.mejs-icon-play {
      display: block;
  }

  .mejs-pause svg.mejs-icon-pause {
      display: block;
  }

  .mejs-replay svg.mejs-icon-replay {
      display: block;
  }
  /* End: Play (Play / Pause / Replay) */

  /* Start: Fullscreen (Fullscreen / Unfullscreen) */
  .mejs-fullscreen-button svg.mejs-icon-unfullscreen {
      display: none;
  }
  .mejs-fullscreen svg.mejs-icon-fullscreen {
      display: block;
  }

  .mejs-fullscreen svg.mejs-icon-unfullscreen {
      display: none;
  }

  .mejs-unfullscreen svg.mejs-icon-unfullscreen {
      display: block;
  }

  .mejs-unfullscreen svg.mejs-icon-fullscreen {
      display: none;
  }
  /* End: Fullscreen (Fullscreen / Unfullscreen) */

  /* Start: Time (Current / Duration) */
  .mejs-time {
      box-sizing: content-box;
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      height: 24px;
      overflow: hidden;
      padding: 16px 6px 0;
      text-align: center;
      width: auto;
  }
  /* End: Time (Current / Duration) */

  /* Start: Progress Bar */
  .mejs-time-rail {
      direction: ltr;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      height: 40px;
      margin: 0 10px;
      padding-top: 10px;
      position: relative;
  }

  .mejs-time-total,
  .mejs-time-buffering,
  .mejs-time-loaded,
  .mejs-time-current,
  .mejs-time-float,
  .mejs-time-hovered,
  .mejs-time-float-current,
  .mejs-time-float-corner,
  .mejs-time-marker {
      border-radius: 2px;
      cursor: pointer;
      display: block;
      height: 10px;
      position: absolute;
  }

  .mejs-time-total {
      background: rgba(255, 255, 255, 0.3);
      margin: 5px 0 0;
      width: 100%;
  }

  .mejs-time-buffering {
      -webkit-animation: buffering-stripes 2s linear infinite;
              animation: buffering-stripes 2s linear infinite;
      background: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
      background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
      background-size: 15px 15px;
      width: 100%;
  }

  @-webkit-keyframes buffering-stripes {
      from {
          background-position: 0 0;
      }
      to {
          background-position: 30px 0;
      }
  }

  @keyframes buffering-stripes {
      from {
          background-position: 0 0;
      }
      to {
          background-position: 30px 0;
      }
  }

  .mejs-time-loaded {
      background: rgba(255, 255, 255, 0.3);
  }

  .mejs-time-current,
  .mejs-time-handle-content {
      background: rgba(255, 255, 255, 0.9);
  }

  .mejs-time-hovered {
      background: rgba(255, 255, 255, 0.5);
      z-index: 10;
  }

  .mejs-time-hovered.negative {
      background: rgba(0, 0, 0, 0.2);
  }

  .mejs-time-current,
  .mejs-time-buffering,
  .mejs-time-loaded,
  .mejs-time-hovered {
      left: 0;
      -webkit-transform: scaleX(0);
          -ms-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0 0;
          -ms-transform-origin: 0 0;
              transform-origin: 0 0;
      -webkit-transition: 0.15s ease-in all;
      transition: 0.15s ease-in all;
      width: 100%;
  }

  .mejs-time-buffering {
      -webkit-transform: scaleX(1);
          -ms-transform: scaleX(1);
              transform: scaleX(1);
  }

  .mejs-time-hovered {
      -webkit-transition: height 0.1s cubic-bezier(0.44, 0, 1, 1);
      transition: height 0.1s cubic-bezier(0.44, 0, 1, 1);
  }

  .mejs-time-hovered.no-hover {
      -webkit-transform: scaleX(0) !important;
          -ms-transform: scaleX(0) !important;
              transform: scaleX(0) !important;
  }

  .mejs-time-handle,
  .mejs-time-handle-content {
      border: 4px solid transparent;
      cursor: pointer;
      left: 0;
      position: absolute;
      -webkit-transform: translateX(0);
          -ms-transform: translateX(0);
              transform: translateX(0);
      z-index: 11;
  }

  .mejs-time-handle-content {
      border: 4px solid rgba(255, 255, 255, 0.9);
      border-radius: 50%;
      height: 10px;
      left: -7px;
      top: -4px;
      -webkit-transform: scale(0);
          -ms-transform: scale(0);
              transform: scale(0);
      width: 10px;
  }

  .mejs-time-rail:hover .mejs-time-handle-content,
  .mejs-time-rail .mejs-time-handle-content:focus,
  .mejs-time-rail .mejs-time-handle-content:active {
      -webkit-transform: scale(1);
          -ms-transform: scale(1);
              transform: scale(1);
  }

  .mejs-time-float {
      background: #eee;
      border: solid 1px #333;
      bottom: 100%;
      color: #111;
      display: none;
      height: 17px;
      margin-bottom: 9px;
      position: absolute;
      text-align: center;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 36px;
  }

  .mejs-time-float-current {
      display: block;
      left: 0;
      margin: 2px;
      text-align: center;
      width: 30px;
  }

  .mejs-time-float-corner {
      border: solid 5px #eee;
      border-color: #eee transparent transparent;
      border-radius: 0;
      display: block;
      height: 0;
      left: 50%;
      line-height: 0;
      position: absolute;
      top: 100%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 0;
  }

  .mejs-long-video .mejs-time-float {
      margin-left: -23px;
      width: 64px;
  }

  .mejs-long-video .mejs-time-float-current {
      width: 60px;
  }

  .mejs-broadcast {
      color: #fff;
      height: 10px;
      position: absolute;
      top: 15px;
      width: 100%;
  }

  /* End: Progress Bar */

  /* Start: Mute/Volume */
  .mejs-volume-button {
      position: relative;
  }

  .mejs-volume-button > .mejs-volume-slider {
      -webkit-backface-visibility: hidden;
      background: rgba(50, 50, 50, 0.7);
      border-radius: 0;
      bottom: 100%;
      display: none;
      height: 115px;
      left: 50%;
      margin: 0;
      position: absolute;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 25px;
      z-index: 1;
  }

  .mejs-volume-button:hover {
      border-radius: 0 0 4px 4px;
  }

  .mejs-volume-total {
      background: rgba(255, 255, 255, 0.5);
      height: 100px;
      left: 50%;
      margin: 0;
      position: absolute;
      top: 8px;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 2px;
  }

  .mejs-volume-current {
      background: rgba(255, 255, 255, 0.9);
      left: 0;
      margin: 0;
      position: absolute;
      width: 100%;
  }

  .mejs-volume-handle {
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #fff;
      border-radius: 1px;
      cursor: ns-resize;
      height: 6px;
      left: 50%;
      position: absolute;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 16px;
  }

  .mejs-horizontal-volume-slider {
      display: block;
      height: 36px;
      position: relative;
      vertical-align: middle;
      width: 56px;
  }

  .mejs-horizontal-volume-total {
      background: rgba(50, 50, 50, 0.8);
      border-radius: 2px;
      font-size: 1px;
      height: 8px;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 16px;
      width: 50px;
  }

  .mejs-horizontal-volume-current {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 2px;
      font-size: 1px;
      height: 100%;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
  }

  .mejs-horizontal-volume-handle {
      display: none;
  }

  .mejs-mute svg,
  .mejs-unmute svg {
      stroke: currentColor;
  }

  .mejs-volume-button svg {
      display: none;
  }

  .mejs-mute .mejs-icon-mute {
      display: block;
  }

  .mejs-unmute .mejs-icon-unmute {
      display: block;
  }
  /* End: Mute/Volume */

  /* Start: Track (Captions and Chapters) */
  .mejs-captions-button,
  .mejs-chapters-button {
      position: relative;
  }

  .mejs-chapters-button svg,
  .mejs-captions-button svg {
      padding-top: 2px;
  }

  .mejs-captions-button > .mejs-captions-selector,
  .mejs-chapters-button > .mejs-chapters-selector {
      background: rgba(50, 50, 50, 0.7);
      border: solid 1px transparent;
      border-radius: 0;
      bottom: 100%;
      margin-right: -43px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      right: 50%;
      visibility: visible;
      width: 86px;
  }

  /* reduced opacity if captions button is in toggle mode and deactivated */
  .mejs-captions-button-toggle {
      opacity: 0.7;
  }
  /* normal opacity if captions button is in toggle mode and activated */
  .mejs-captions-button-toggle-on {
      opacity: 1;
  }

  .mejs-chapters-button > .mejs-chapters-selector {
      margin-right: -55px;
      width: 110px;
  }

  .mejs-captions-selector-list,
  .mejs-chapters-selector-list {
      list-style-type: none !important;
      margin: 0;
      overflow: hidden;
      padding: 0;
  }

  .mejs-captions-selector-list-item,
  .mejs-chapters-selector-list-item {
      color: #fff;
      cursor: pointer;
      display: block;
      list-style-type: none !important;
      margin: 0 0 6px;
      overflow: hidden;
      padding: 0;
  }

  .mejs-captions-selector-list-item:hover,
  .mejs-chapters-selector-list-item:hover {
      background-color: rgb(200, 200, 200) !important;
      background-color: rgba(255, 255, 255, 0.4) !important;
  }

  .mejs-captions-selector-input,
  .mejs-chapters-selector-input {
      clear: both;
      float: left;
      left: -1000px;
      margin: 3px 3px 0 5px;
      position: absolute;
  }

  .mejs-captions-selector-label,
  .mejs-chapters-selector-label {
      cursor: pointer;
      float: left;
      font-size: 10px;
      line-height: 15px;
      padding: 4px 10px 0;
      width: 100%;
  }

  .mejs-captions-selector-list-item:hover .mejs-captions-selector-label,
  .mejs-chapters-selector-list-item:hover .mejs-chapters-selector-label {
      text-decoration: underline;
  }

  .mejs-captions-selected,
  .mejs-chapters-selected {
      color: rgba(33, 248, 248, 1);
      font-weight: bold;
  }

  .mejs-captions-translations {
      font-size: 10px;
      margin: 0 0 5px;
  }

  .mejs-captions-layer {
      bottom: 0;
      color: #fff;
      font-size: 16px;
      left: 0;
      line-height: 20px;
      position: absolute;
      text-align: center;
  }

  .mejs-captions-layer a {
      color: #fff;
      text-decoration: underline;
  }

  .mejs-captions-layer[lang=ar] {
      font-size: 20px;
      font-weight: normal;
  }

  .mejs-captions-position {
      bottom: 15px;
      left: 0;
      position: absolute;
      width: 100%;
  }

  .mejs-captions-position-hover {
      bottom: 35px;
  }

  .mejs-captions-text,
  .mejs-captions-text * {
      background: rgba(20, 20, 20, 0.5);
      box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
      padding: 0;
      white-space: pre-wrap;
  }

  .mejs-container.mejs-hide-cues video::-webkit-media-text-track-container {
      display: none;
  }
  /* End: Track (Captions and Chapters) */

  /* Start: Error */
  .mejs-overlay-error {
      position: relative;
  }
  .mejs-overlay-error > img {
      left: 0;
      max-width: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
  }
  .mejs-cannotplay,
  .mejs-cannotplay a {
      color: #fff;
      font-size: 0.8em;
  }

  .mejs-cannotplay {
      position: relative;
  }

  .mejs-cannotplay p,
  .mejs-cannotplay a {
      display: inline-block;
      padding: 0 15px;
      width: 100%;
  }
  /* End: Error */
}
/* ============ Axonify Overrides ============= */
