// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//   FORM SELECT (NATIVE) EXAMPLE
//   <div class="ax-select">
//     <label for="select-1">Select demo label</label>
//     <div class="ax-select__container">
//       <select id="select-1">
//         <option value="option-1">Option 1</option>
//       </select>
//     </div>
//   </div>

//  MODIFIERS
//    - .ax-select--[m/l] - Modify input height (medium is default)
//    - .ax-select--error - Add error styling to select
//    - .ax-select--required - Add required * after the label
//    - .ax-select--full-width - Make container 100% width

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

select {
  .ax-form-input-base-styles();

  &:focus {
    .ax-focus-mouse();
  }
}

.ax-select {
  .ax-form-input-container-modifiers();

  &__container {
    display: inline-block;
    position: relative;

    &:after {
      &:extend([class^="icon-"]);
      content: @icon-caret_down;
      font-size:@ax-spacing-l;
      height: @ax-spacing-xl;
      margin: auto;
      pointer-events: none;
      position: absolute;
      right: @ax-spacing-s;
      top: @ax-spacing-xs;
      width: @ax-spacing-l;
    }
  }

  select {
    .text-ellipsis();
    padding-right: @ax-spacing-xl;

    // Disable default browser styling
    -moz-appearance: none;
    -webkit-appearance: none;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    &::-ms-expand {
      display: none;
    }
  }
}
