@ms-teams-header-height: 4rem;

.msteams-header {
  .display-flex();
  .align-items(center);
  height: @ms-teams-header-height;
}

#header .msteams-header__right-header-region {
  margin: 0 @ax-spacing-xs 0 auto;
  height: auto;
}

@import 'headerSlideout/HeaderSlideout.less';
