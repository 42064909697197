
#highlights-container {
  border-bottom: .1rem solid @secondary-grey;
}

.highlights {
  margin: @ax-spacing-m 0;
}

.highlights.highlights__gl-header {
  .display-flex();
  .flex-direction(row);
  .justify-content(space-between);
  .align-items(center);
  .flex-wrap(wrap);

  .highlight--gl-status-item{
    .display-flex(inline-flex);
    .flex-direction(row);
    .justify-content(flex-start);
    .align-items(center);
    border-left: .5rem solid @primary-blue;
    padding: 2rem;
    width: auto;
    margin: 0 0 1rem;
  }
}

.highlights.highlights--large{
  border: 0;
  margin-left: 0;

  .ax-breakpoint-m({
    margin-left: 2.8rem;
  });
}

.highlights .blanket {
  display: none; // Hide on mobile

  .ax-breakpoint-m({
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: @white;
  });
}

.highlights .highlight_button_container {
  margin-top: 1em;
  text-align: center;

  .axon-button {
    width: auto;
    min-width: 24rem;
    height: 4.8rem;
    line-height: 4.8rem;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.highlight {
  &.graduated_topic_level,
  &.intro_topics_passed,
  &.reward_points_earned,
  &.answered_correctly {
    padding-top: 1em;

    .score,
    .tag { text-align: center; }
  }
}

.highlight {
  &.graduated_topic_level,
  &.intro_topics_passed,
  &.reward_points_earned,
  &.answered_correctly {
    .score {
      margin-top: -3.5rem;
    }
  }
}

.highlight.graduated_topic_level {
  .score.reportcard,
  .tag.reportcard {
    text-align: left;
  }
}

.highlight.graduated_topic_level .score.reportcard {
  margin-top: auto;
}

.highlight.intro_topics_passed .gaugeicon {
  color: @intro-training-color;
}

.highlights .highlight .gauge {
  height: 22rem;
  margin: 0 auto;
}

.highlights .highlight .gaugeicon {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  text-align: center;
  font-size: 6rem;
  line-height: 22.5rem;
}

.highlights .highlight-progress .progress {
  .border-radius(@ax-border-radius-m);
  .transition(width .5s ease-in-out);
  background-color: @primary-blue;
  height: 100%;
  width: 0;
}

.highlight.highlight-progress .gauge,
.highlight.highlight-progress .gauge {
  .border-radius(@ax-border-radius-m);
  background-color: @accent-grey;
  height: 1.2rem;
  bottom: 0;
  left: 6.4rem;
  right: 1em;
  margin: 0 0 1.3rem 0;
  position: absolute;
}

// Mobile specific UI changes
// $os check replaces bar data graphs with gauges
.highlight.highlight-progress.reward_points_earned,
.highlight.highlight-progress.answered_correctly {
  height: 8rem;
  border-top: .1rem solid @accent-grey;
  position: relative;
}

.highlight.highlight-progress .gaugeicon.pts,
.highlight.highlight-progress .gaugeicon.check {
  height: 6rem;
  width: 6rem;
  float: left;
  margin-right: 0.2em;
  position: static;
  text-align: center;
  font-size: 4.5rem;
  line-height: 6rem;
}

.highlight.highlight-progress {
  &.reward_points_earned,
  &.answered_correctly {
    .tag,
    .score {
      text-align: left;
    }

    .score {
      margin-top: 0;
    }
  }
}

.highlights .highlight .score {
  vertical-align: top;
}

// Sub highlights
.highlights .highlight.subhighlight {
  padding: @ax-spacing-s 0;
  border-left: .3rem solid @training-color;
  margin-bottom: @ax-spacing-m;

  .ax-breakpoint-m({
    margin-bottom: 0;
  });
}

.highlights .highlight.subhighlight .wrap {
  margin: 0 auto;
  padding-left: @ax-spacing-m;
}

// Header
.highlights.header {

  // Position points within header on desktop
  .ax-breakpoint-m({
    position: absolute;
    background: none;
    top: 1.3rem;
    width: auto;
    right: 1em;
    padding: 0;
    margin: 0;
    text-align: center;
  });

  .highlight {
    float: none;
    width: auto;
    text-align: center;
    margin: 0;

    .ax-breakpoint-m({
      float: right;
      padding: 0;
      text-align: right;
    });
  }

  .highlight div .score {
    margin: 0;
    display: block;
  }

  .highlight div .title {
    display: block;
    font-size: 1.3rem;
  }
}

// Side Column Highlights
.game-scores {
  .challenge-stats-region .highlights,
  .recent-challenges-region .highlights {
    margin: 0.75em 0 0;
  }

  .challenge-stats-region .highlight,
  .recent-challenges-region .highlight {
    width: auto;
    margin-top: 0;
  }
}
