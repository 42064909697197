@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.default-thumb-placeholder {
  background-image: url('@common/assets/images/preview-placeholder.png');
  background-size: 100%;
  height: 150px;
  width: 150px;
  border: 1px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  .retina({
    background-image: url('@common/assets/images/preview-placeholder@2x.png');
  });

  .table-centering-hack {
    /* To vertically centre text, in a way that IE9 supports, we need to
       use a div with display:table containing a div with display:table-cell.
       Once we drop IE9 support we can just use flexbox, and get rid of the
       extra html.*/
    display: table;
    position: absolute;
    table-layout: fixed;
    width: 150px;
    height: 150px;
  }

  .no-preview-message-text {
    display: table-cell; /*centre text (see .table-centering-hack)*/
    vertical-align: middle;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
  }
}
