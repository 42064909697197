.evaluation-details {
  background-color: @ax-color-grey-10;
  padding: 2rem 0rem;
  .ax-breakpoint-m({
    padding: 2rem;
  });
  word-wrap: break-word;
  word-break: break-word;
  position: relative;

  &__content {
    .screen-md-portrait-min({
      min-height: 26rem;
      &.no-description {
        min-height: 38rem;
      }
    });
  }

  &__no-content {
    padding: 5rem 3rem;
    text-align: center;
    color: @primary-grey;
    line-height: 2;
  }

  .evaluation-criteria {
    margin-bottom: @ax-spacing-m;
    overflow: hidden;
    .border-radius(@ax-border-radius-m);

    &-card {
      .display-flex();
      .flex-direction(column);

      .screen-md-portrait-min({
        .flex-direction(row);
      });

      &__details-wrapper {
        padding: @ax-spacing-l;
        .flex(1 1 auto);
        min-width: 0;

        .ax-breakpoint-m({
          width: 100%;
        });
      }

      &__status-wrapper {
        padding: 2rem;
        min-width: 8rem;
        .screen-md-portrait-min({
          .display-flex();
          .flex-direction(column);
          .justify-content(center);
        });
      }

      &__comment-wrapper {
        .flex(0 0 auto);
      }

      .behaviour-details {
        .behaviour-name {
          color: @ax-color-grey-80;
          margin-bottom: @ax-spacing-s;
        }

        .behaviour-description {
          color: @ax-color-grey-60;
        }
      }
    }

    &__comment-wrapper {
      background-color: @ax-color-grey-20;
    }

    &-comment {
      h3 {
        margin-top: 0;
        font-size: @ax-font-size-xs;

      }
    }

    &.pending {
      .evaluation-criteria-card__status-wrapper {
        background-color: @ax-color-grey-20;
        color: @ax-color-grey-70;
      }
    }

    &.passed {
      .evaluation-criteria-card__status-wrapper {
        background-color: @green-background;
        color: @ax-color-green-100;
      }
    }

    &.not-passed {
      .evaluation-criteria-card__details-wrapper {
        border-top: 0.4rem solid @ax-color-red-60;
        padding-top: @ax-spacing-l - 0.4rem;
        border-left: 0;
        padding-left: @ax-spacing-l;

        .screen-md-portrait-min({
          border-top: 0;
          padding-top: @ax-spacing-l;
          border-left: 0.4rem solid @ax-color-red-60;
          padding-left: @ax-spacing-l - 0.4rem;
        });
      }

      .evaluation-criteria-card__status-wrapper {
        background-color: @red-background;
        color: @ax-color-red-80;
      }
    }
  }

  .general-notes-wrapper {
    padding: @ax-spacing-l;
  }

  .general-notes-comment .general-notes-wrapper {
    margin: @ax-spacing-xl 0 0 0;
    background-color: @ax-color-white;
    .border-radius(@ax-border-radius-m);
    .box-shadow(@card-box-shadow-informational);
  }

  .comment-details {
    .display-flex();

    .comment-details__icon {
      .flex(0 0 auto);

      .item-icon {
        font-size: @ax-spacing-xl;
        margin-right: @ax-spacing-m;

        .ax-breakpoint-m({
          font-size: 2.8rem;
        });
      }
    }

    .comment-details__content {
      .flex(1 1 auto);
      min-width: 0;
      width: 100%;

      & > p {
        white-space: pre-wrap;
      }
    }
  }

  .general-notes-comment .comment-details__icon .item-icon {
    font-size: @ax-spacing-xl;
  }

  .criteria-status {
    .display-flex();

    .justify-content(flex-start);
    .flex-direction(row);
    .align-items(center);

    .screen-md-portrait-min({
      .justify-content(center);
      .flex-direction(column);
    });

    & > * {
      .flex();
      text-align: center;
      vertical-align: center;
    }

    span {
      font-size: 4rem;
    }

    div {
      margin-left: 1.2rem;
      font-size: @ax-font-size-m;
      line-height: @ax-line-height-m;

      .screen-md-portrait-min({
        margin-top: 1.2rem;
        margin-left: 0;
        font-size: @ax-font-size-xs;
        line-height: 1.8rem;
      })
    }
  }

  .evaluation-details__description .titled-section__region {
    .titled-section__region--title h2 {
      font-size: @ax-font-size-l;
      color: @ax-color-grey-80;
      font-weight: @ax-font-weight-medium;
    }

    .titled-section__region--content {
      font-size: @ax-font-size-s;
      color: @ax-color-grey-60;
    }
  }

  &__description {
    margin-bottom: @ax-spacing-l;
    &.no-description {
      display: none;
    }

    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-xxl;
    });

    .read-more__content--container .read-more__preview-gradient {
      .gradient(rgba(@ax-color-app-background,0), @ax-color-app-background);
    }
  }

  &__info {
    .ax-breakpoint-m({
      position: absolute;
      right: 0;
    });

    margin-bottom: @ax-spacing-xxl;
  }

  .evaluation-attempt-details,
  .inline-user-submission,
  .evaluation-print-form {
    margin-top: @ax-spacing-l;
  }

  .evaluation-coach-message {
    padding: @ax-spacing-m;
    .display-flex();
    .align-items(center);
    .justify-content(center);

    &.failed {
      .border-radius(@ax-border-radius-m);
      border-left: 0.4rem solid @ax-color-red-60;
      padding-left: @ax-spacing-m - 0.4rem;
    }

    .icon-info_sign {
      .border-radius(100%);
      .size(5rem);
      margin-right: 1.5rem;
      line-height: 5rem;
      font-size: 5.6rem;
      background-color: @white;
      color: @ax-color-blue-60;
    }

    .characterarea {
      padding-top: 0;
      margin-right: 1.2rem;
      .flex(0 0 auto);
    }

    .characterarea .bonuscharacter {
      height: auto;
      width: auto;
      
      & div {
        .size(8rem);
        background-size: 100%;
        background-position: top center;
        background-color: @accent-grey;
        right: 0;
        .border-radius(100%);
      }
    }

    .evaluation-coach-message__text {
      color: @ax-color-grey-70;
      .text-ellipsis-softwrap();
    }
  }

  .evaluation-attempt-details {
    padding: @ax-spacing-s @ax-spacing-l;
    color: @ax-color-grey-60;

    &__header--wrapper,
    &__score--wrapper,
    &__evaluator--wrapper {
      padding: @ax-spacing-s 0 @ax-spacing-s 0;
    }

    &__header--wrapper,
    &__score--wrapper,
    &__evaluator--wrapper,
    &__info--wrapper {
      .display-flex();
      .flex-direction(row);
      width: 100%;
    }

    .evaluation-attempt-details__header--wrapper .attempt-details-header {
      .display-flex();
      .flex-direction(row);
      width: 100%;

      .attempt-details-header__attempt-number {
        .justify-content(flex-start);
        width: 50%;
        padding-top: 0.2rem;
        color: @ax-color-grey-80;
        margin-bottom: 0;
        font-size: 1.8rem;
      }
    }

    .evaluation-attempt-details__score--wrapper,
    .evaluation-attempt-details__evaluator--wrapper {
      .icon--badge,
      .user-profile-icon {
        .border-radius(100%);
        .size(5rem);
        margin-right: 1.5rem;
        line-height: 5rem;
        color: @white;
      }

      .icon--badge {
        font-size: 5.6rem;
      }

      .user-profile-icon {
        font-size: @ax-font-size-xl;
        text-align: center;
      }

      .evaluation-score-title,
      .evaluator-title {
        color: @ax-color-grey-80;
      }

      .icon-user,
      .icon-unlock,
      .icon-pending {
        background-color: @ax-color-grey-20;
        color: @ax-color-grey-60;
        font-size: 3.2rem;
      }

      .icon-exclamation {
        background-color: @white;
        color: @ax-color-red-60;
      }

      .icon-ok_circle {
        background-color: @white;
        color: ax-color-icon-green-a11y;
      }
    }

    .evaluation-attempt-details__info--wrapper {
      .attempt-details-info {
        width: 100%;
      }

      .view-last-attempt {
        width: 100%;
        margin-bottom: @ax-spacing-s;
      }

      .attempt-details-info__result {
        padding: @ax-spacing-s 0;

        .attempt-details-info__result--evaluation-date {
          font-weight: @ax-font-weight-medium;
        }
      }

      .attempt-details-info__points {
        padding: @ax-spacing-s 0;
        border-top: 1px solid @accent-grey;
      }
    }
  }

  .evaluation-attempt-details__evaluator--wrapper .title-header {
    min-width: 0;

    .subtext-header-title {
      width: 100%;
    }
  }

  .evaluation-print-form {
    .display-flex();
    .justify-content(flex-end);
  }
}

.evaluation-details__submission-upload,
.upload-evaluation-submission-modal .submission-warning__reupload-wrapper,
.upload-evaluation-submission-modal .submission-preview__reupload-wrapper {
  height: 1px;
  width: 1px;
  .opacity(0.001);
  overflow: hidden;
}

.upload-evaluation-submission-modal {
  background: @white;
  .size(100%);

  .ax-breakpoint-m({
    .border-radius(@ax-border-radius-m);
    .size(50%, auto);
    max-width: 75rem;
    max-height: calc(100% - (@ax-spacing-l * 2));
    overflow-y: auto;
  });

  .adjustable-content-wrapper {
    .display-flex();
    .flex-direction(row);
    .justify-content(flex-start);

    .ax-breakpoint-m({
      display: block;
    });

    .adjustable-content {
      max-width: 100%;
      .flex(1 1 auto);
    }
  }

  .actionbar {
    .flex(0 0 auto);
    .ax-breakpoint-m({
      text-align: right;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      padding: 0 @ax-spacing-l;
    });
  }

  .modal__header {
    position: relative;
    margin: @ax-spacing-l;
    word-break: break-word;

    #modal-label {
      line-height: 2.5rem;
      background-color: transparent;
      padding: 0 @ax-gutter-grid 0 0;
      .flex(1 1 auto);
      font-size: @ax-font-size-m;

      .ax-breakpoint-m({
        font-size: @ax-font-size-s;
      })
    }

    .close-modal-button {
      top: 0;
      right: 0;
      .size(2.5rem);
    }
  }

  .modal__body {
    margin: 0 @ax-spacing-l @ax-spacing-l @ax-spacing-l;
    word-break: break-word;
  }

  .evaluation-name {
    margin-bottom: @ax-spacing-l;

    h2 {
      display: inline-block;
    }
  }

  .icon-evaluation {
    display: inline-block;
    color: @eval-color;
    vertical-align: top;
  }

  .file-upload {
    border: 0.1rem solid @ax-color-grey-40;
    .border-radius();
    overflow: hidden;

    .submission-preview__reselect-file-wrapper,
    .submission-warning__reselect-file-wrapper {
      text-align: center;
      padding: 0;

      .ax-breakpoint-m({
        text-align: left;
        padding: @ax-spacing-s @ax-spacing-m;
      });
    }
  }

  .file-uploading,
  .submission-warning__details-wrapper,
  .submission-preview__details-wrapper,
  .submission-preview__progress-wrapper {
    padding: @ax-spacing-s @ax-spacing-m;
  }

  .submission-warning__display-wrapper {
    padding: @ax-spacing-xxl;
  }

  .file-uploading,
  .submission-warning,
  .submission-preview {
    .card.card--informational {
      .box-shadow(none);
      margin: 0;
      padding: 0;
    }
  }

  .progress-bar .progress-bar-inset {
    background-color: @primary-blue;
  }

  .submission-warning__display-wrapper,
  .submission-preview__display-wrapper {
    background-color: @ax-color-grey-10;

    .warning-text {
      text-align: center;
    }

    .video-preview {
      text-align: center;
    }

    .image-wrapper {
      text-align: center;
      margin: @ax-spacing-s 0;
      max-height: 20rem;

      .ax-breakpoint-m({
        max-height: 40rem;
      });
    }

    img {
      display: inline-block;
    }
  }

  .submission-preview-placeholder {
    padding: @ax-spacing-xxl;

    .ax-breakpoint-m({
      padding: calc(@ax-spacing-xxl * 2);
    });

    span.attachment-icon {
      font-size: 4rem;
      line-height: 4rem;

      .ax-breakpoint-m({
        font-size: 7.8rem;
        line-height: 7.8rem;
      });
    }
  }

  a.button.reselect-file {
    cursor: pointer;
    line-height: @ax-line-height-xl;
    font-weight: @ax-font-weight-bold;
    text-align: center;
    .align-self(center);

    margin: 0 @ax-gutter-grid;
    width: calc(100% - (@ax-gutter-grid * 2));

    .ax-breakpoint-m({
      width: calc(25% - (@ax-gutter-grid * 2));
    });

    &.focus-visible:focus {
      box-shadow: inset 0px 0px 2px 2px @ax-color-blue-60;
    }
  }
}
