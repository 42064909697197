// Report card chart
.knowledge-wrapper {
  display: block;
  margin: @ax-spacing-m 0;
  overflow-x: scroll;
  position: relative;

  .ax-breakpoint-m({
    margin: @ax-spacing-m 0;
    overflow-x: visible;
  });
}

#knowledgechart {
  .box-sizing(border-box);
  background: @white;
  display: block;
  height: 18rem;
  padding: @ax-spacing-s 0;

  .ax-breakpoint-m({
    .border-radius(@ax-border-radius-m);
    background: @white;
    height: 30rem;
    padding: @ax-spacing-l @ax-spacing-s @ax-spacing-s;
    width: 100%;

    .knowledge-show-table & {
      display: none;
    }
  });

  .loading-row {
    height: 100%;
  }

  &.mobile {
    min-width: 70rem !important;
  }
}

.knowledge-show-table #knowledgechart {
  display: none;

  .ax-breakpoint-m({
    display: block;
  });
}

#knowledge-table-toggle {
  .off-screen();

  &:focus {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 100;
  }

  .knowledge-show-table & {
    height: auto;
    position: static;
    margin-bottom: 1em;
    width: auto;
  }
}

#knowledge-table {
  display: none;
  width: 100%;

  tr {
    border-bottom: .1rem solid @ax-color-grey-20;
    border-top: .1rem solid @ax-color-grey-20;
  }

  td {
    padding: @ax-spacing-s;
  }

  .knowledge-show-table & {
    display: table;
  }
}
