/* rtl:begin:ignore */

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent; // For some Androids
}

body {
  background: @ax-color-app-background;
}

embed {
  display: block;
}

iframe {
  border: 0;
  display: block;
}

label {
  font-weight: @ax-font-weight-regular;
  margin-bottom: 0;
}

figure {
  margin: 0;
}

//Global Elements
.no-border {
  border: 0!important;
  &.top {border-top: 0!important;}
  &.bottom {border-bottom: 0!important;}
  &.left {border-left: 0!important;}
  &.right {border-right: 0!important;}
}

.topborder {border-top: .1rem solid @accent-grey;}
.rightborder {border-right: .1rem solid @accent-grey;}
.leftborder {border-left: .1rem solid @accent-grey;}
.bottomborder {border-bottom: .1rem solid @accent-grey;}

.scroll-y {
  overflow-y: auto;
}

.hr,
hr {
  clear: both;
  margin: 2.5em 0;
  display: block;
  height: 0;
  border: solid @accent-grey;
  border-width: .1rem 0 0;
  border-top: .1rem solid @accent-grey;
  border-bottom: .1rem solid @white;
}

button,
.button-reset-style {
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  background: transparent;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.dropdown-menu {
  font-weight: @ax-font-weight-regular;
  font-size: inherit;
  color: @black;
  min-width: 16.5rem;
  padding: 0;

  li {
    border-bottom: .1rem solid @accent-grey;

    &:hover,
    &:focus {
      background-color: @element-hover-bright;
    }
  }
  li > a {
    padding: 0.5em;
    [class*="icon-"] {
      opacity: 1.0;
    }
  }
}

//Links
a {
  color:@primary-blue;
  text-decoration: none;
  [class*="icon-"] {
    opacity: 0.5;
    &:hover,
    &:focus {
      opacity:1.0;
      cursor: pointer;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.screen-sm-max({
  a:hover {
    background-color: transparent;
  }
});

//TABLES
table {
  border-collapse: collapse;
  .screen-sm-max({
    width:100%;
  });
}

th {
  font-size:@table-header-font-size;
  font-weight: @ax-font-weight-bold;
}

.table-cell-helper,
td {
  padding:1em 0 1em 0.5em;

  table {
    td,
    tr {
      padding: 0;
    }
  }
}

tr {
  &.topicitem:nth-child(odd) {
    background: @secondary-bg;
  }
}

tbody {
  th {
    background: @accent-grey;
    border-bottom: .3rem solid @secondary-grey;
    height: 3rem;
    line-height: 3rem;
    padding: 0 0 0 0.5em;
    .screen-sm-max({
      height: 2rem;
      line-height: 2rem;
    });
  }
}

thead {
  background: @primary-bg;
  border-bottom: .3rem solid @secondary-grey;
  height: 3rem;
  line-height: 3rem;
  text-align: left;

  th {
    padding: 0 0 0 0.5em;
    background: @accent-grey;
  }

  .screen-sm-max({
    height: 2rem;
    line-height: 2rem;
  });
}

.row-separator td {
  background-color: @accent-grey;
  padding: 0;
  height: 1rem;
  border: 0;
}

.input.rederror {
  &:hover,
  &:focus {
    border-color: @error-state;
  }
}

input[type=checkbox] {
  border: none!important;
  margin-right: 0.7em;
  background: none;
  padding: 0;
  height: auto;
  font-size: 1.6rem !important;
  cursor: pointer;
  .border-radius(0);
  min-height: 0;
}

input[type=radio] {
  border: none!important;
  margin-right: 0.5em;
  height: inherit;
  font-size: 1.6rem !important;
  .border-radius(0);
  min-height: 0;
}

input[readonly],
.readonly-input {
  border: .1rem solid @secondary-grey;
  &:hover,
  &:focus {
    border-color:@secondary-grey;
    background-color: @secondary-bg;
  }
}

textarea.pressed,
iframe.focused {
  .focus();
}

.select {
  height: @_input-size-icon-button-m;
  max-height: @_input-size-icon-button-m;
  line-height: @_input-size-icon-button-m;
  border: .1rem solid @secondary-grey;
  .border-radius(@ax-border-radius-m);
  .box-shadow-black(0, .1rem, .2rem, 0.1);
  font-weight: @ax-font-weight-regular;
  outline: 0;
  background-color: @secondary-bg;
  color:@ax-color-text;

  .screen-sm-max({
    background-image: url('@common/assets/images/drop-arrow.png');
    background-position: 98% center;
    background-repeat: no-repeat;
    background-size: 1rem;
    padding: 0 1em 0 .5em;
    -webkit-appearance: none;
    -moz-appearance: none;

    .retina({
      background-image: url('@common/assets/images/drop-arrow@2x.png');
      background-size: 1rem;
      background-repeat: no-repeat;
    });
  });
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

//Placeholder text
::-webkit-input-placeholder {
  // Webkit, Edge
  color: @ax-color-grey-60;
}
::-moz-placeholder {
  // Firefox > 19
  color: @ax-color-grey-60;
  opacity: 1;
}
:-ms-input-placeholder {
  // IE
  color: @ax-color-grey-60;
}

//SlickGrid Icon treatments
td.icon-cell,
.slick-cell {
  //Base Icons
  [class*="icon-"] {
    color:@black;
    text-decoration: none;
    margin-right: .5rem;
  }

  button,
  a {
    &[class*="icon-"] {
    opacity: 0.5;
    color:@black;
    line-height: 6rem;
      &:hover,
      &:focus {
        opacity: 1.0;
      }
    }
  }

  .icon-expert {color:@yellow;}
  .icon-certified {color: @ax-color-green-60;}
  .icon-overdue {color:@red;}
  .icon-due {color:@yellow;}
}

td.icon-cell {
  width: 4rem;

  button,
  a {
    &[class*="icon-"] {
      line-height: unset;
    }
  }
}

.partial-match {
  background: fade(#4BE70C, 40%);
  color: @black;
}

/* rtl:end:ignore */
