// Self Register
#self-registration-view .buttonbar {
  margin-bottom: @ax-spacing-xl;
}

#self-registration-view #employee-id,
#self-registration-view [data-field='passwordRepeated'] {
  margin-bottom: 0;
}

#self-registration-view .field-description {
  margin: @ax-spacing-s 0 @ax-spacing-l;
}

#gotoLoginLink {
  font-size: 1.4rem;
  .clearfix();
  margin: @ax-spacing-m 0;

  &.focus-visible:focus {
    .box-shadow(inset 0 0 .2rem .2rem @primary-blue);
  }
}

.registration-complete {
  margin: 0 @ax-spacing-s;
  text-align: center;
  font-weight: @ax-font-weight-bold;

  h1 {
    margin: @ax-spacing-m 0;
    font-size: 2.6rem;
  }

  p {
    font-size: @topic-font-size;
    margin: @ax-spacing-s 0 @ax-spacing-l 0;
  }
}

.registration-complete {
  .buttonbar {
    background: @primary-bg;
    margin: 0;
    text-align: center;
    padding: @ax-spacing-s 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .axon-button {
      width: 80%;
      margin: 0 auto;
    }
  }
}

#registerForm #security-question-title {
  font-size: @ax-font-size-m;
  margin: @ax-spacing-m 0;
}

.ax-breakpoint-m({
  #self-registration-view h1 .floatright {
    position: absolute;
    margin-top: @ax-spacing-m;

    a { font-size: 1rem; }
  }
});

#self-registration-view .password-explain {
  color: @primary-grey;
}

#self-registration-view #recovery-container select {
  margin-bottom: @ax-spacing-s;
}

#self-registration-view .buttonbar {
  background: @primary-bg;
  margin: 0;
  text-align: center;
  padding: @ax-spacing-s 0;
}

#self-registration-view .buttonbar .axon-button {
  width: 100%;
  margin: 0 auto;
}

// Activation account page
#activateAccountForm {
  select {
    margin-bottom: @ax-spacing-s;
  }

  .password,
  [data-editor='Password'] {
    display: block;
    margin-bottom: @ax-spacing-s;

    #username {
      width: 100%;
    }
  }

  .password-rules {
    margin-bottom: @ax-spacing-l;
  }

  .security-question {
    margin-bottom: @ax-spacing-s;
  }
}

.recovery-input .password {
  margin-bottom: @ax-spacing-s;
}

.captcha-container{
  margin-bottom: @ax-spacing-s;
}

#details-view .expired {
  display: none;

  .ax-breakpoint-m({
    display: block;
  })
}

// Filter Chooser
.filtering {
  position:relative;
}

.filtering .filteritemchooser {
  position: absolute;
  z-index: 999;
  border: .1rem solid @secondary-grey;
  .border-radius(@ax-border-radius-m);
  background: @white;
  display: none;
  max-width: ~"calc(100vw - 7.5rem)";
  overflow: auto;
  margin-top: -20rem;
  .box-shadow(0 .2rem .5rem rgba(115,115,115,0.3));

  .ax-breakpoint-m({
    min-width: 35rem;
  });
}

#location.filtering .filteritemchooser {
  overflow: hidden;
  top: 0;
  left: 0;
  margin: 0;
  position: fixed !important; //overwrite inline styles :(
  width: 100%;
  max-width: 100%;
  height: 100%;

  .ax-breakpoint-m({
    top: @ax-spacing-xxl;
    position: absolute !important;
    height: auto;
  });
}

.filtering .filteritemchooser.last {
  left:auto;
  right:2rem;
}

.filtering .filteritemchooser .itemheader {
  padding: @ax-spacing-s;
  .gradient(@startColor: @primary-bg, @endColor: @accent-grey);
}

.filtering .filteritemchooser .itemfooter {
  border-top: .1rem solid @accent-grey;
  padding: @ax-spacing-s;
}

.filtering .filteritemchooser .itemheader h4 {
  padding: 0;
  margin: 0;
  line-height: auto;
  float: left;
  width: 93%;
  font-size: 1.6rem;
}

.filtering .filteritemchooser .moreitems {
  margin: 0;
  max-height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
}

#location.filtering .filteritemchooser .moreitems {
  max-height: none;
  height: 80vh;
  overflow: auto;
  padding: 0;
  font-size: 2rem;

  .ax-breakpoint-m({
    font-size: 1.6rem;
    height: 30rem;
  });

  li {
    p {
      white-space: nowrap;
      margin: 0;
      position: relative;
      top: @ax-spacing-s;
      padding: @ax-spacing-m;

      .ax-breakpoint-m({
        padding: @ax-spacing-s;
      });

      &:hover {
        background-color: @element-hover-bright;
      }

      li p {
        padding-left: @ax-spacing-l;

        .ax-breakpoint-m({
          padding-left: @ax-spacing-m;
        });
      }
    }
  }
}

.filtering .filteritemchooser .moreitems {
  margin:0;
  padding: @ax-spacing-xs;
  height:25rem;
  overflow-y:auto;
  overflow-x:hidden;
}
.filtering .filteritemchooser .moreitems a { color: @black; }
.filtering .filteritemchooser .moreitems a:hover { text-decoration: none; }

.filtering .filteritemchooser button.axon-button-green,
.filtering .filteritemchooser a.axon-button-green {
  .border-radius(@ax-border-radius-m);
  margin: 0 @ax-spacing-s 0 0;
  text-decoration: none;
  white-space: normal;
  background: @primary-bg;
  border: .1rem solid @green;
  color: @white;
  text-align: center;
  padding: @ax-spacing-xxs @ax-spacing-m @ax-spacing-xxs @ax-spacing-m;
  float: left;
}

.filtering .filteritemchooser button.axon-button-green.white,
.filtering .filteritemchooser a.axon-button-green.white {
  background:@primary-bg;
  border: .1rem solid @accent-grey;
  color: @black ;
  text-align: center;
  margin:0;
}

.x.close {
  background:url('@common/assets/images/modal-close.png') bottom left no-repeat transparent;
  width: 1.8rem;
  height: 1.8rem;
  display: block;
  float: right;
  cursor: pointer;
  .border-radius(@ax-border-radius-m);
  top: 1rem;
  right: 1rem;
}

input.readonly {
  width: auto;
}

#container-jobTitles,
#container-departments,
#container-locations,
#container-languages,
#container-lineOfBusinesses {
  width: auto;
}

.location-children {
  padding-left: @ax-spacing-m;
}
