@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.hidden-users-snapshot-view,
.user-snapshot {
  margin-top: @ax-spacing-xs;
  line-height: 3rem;
  .display-flex();

  .name-logo {
    .flex(0 0 auto);
  }

  .user-info {
    display: inline-block;
    padding-top: @ax-spacing-xs;
    overflow: hidden;

    .user-name {
      line-height: @ax-line-height-xs;
      margin-left: 0.6rem;
      .truncate-block();
    }

    .hidden-attendees,
    .user-id,
    .user-location {
      display:inline;
      color: @ax-color-grey-70;
      margin-left: 0.6rem;
    }
  }
}
