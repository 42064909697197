// Login Langauge Select

.languageselect {
  p {
    margin-bottom: @ax-spacing-m;
  }

  .header-margin {
    margin-top: @ax-spacing-l;
  }

  .selectableList li label {
    margin: 0;
  }

  .language {
    padding: @ax-spacing-xs 0;

    .ax-breakpoint-m( {
      display: block;
      float: left;
      min-height: 3.6rem;
      width: 30rem;
      padding: @ax-spacing-l;
      margin: -@ax-spacing-xxs 0 0 0;
      cursor: pointer;
      text-align: left;
      font-size: 2.5rem;

      &:hover:not(.empty-row) {
        background: darken(@element-hover-bright, 10%);
      }

      &.selected:not(.empty-row) {
        background: @element-hover-bright;

        &:hover {
          background: darken(@element-hover-bright, 10%);
        }
      }
    });

    &.empty-row {
      cursor: default;
    }

    .radioinputcontainer {
      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }

    .label {
      line-height: 3.6rem;
    }

  }
}
