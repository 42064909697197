.search {

  .search-results-message-region {
    margin: @ax-spacing-m @ax-spacing-m 0 0;

    .ax-breakpoint-m({
      margin-top: @ax-spacing-xl;
    });

    .search-results-message {
      min-height: 2rem;

      .ax-breakpoint-m({
        font-size: 1.5rem;
      });
    }
  }

  .article-search__view-type-selector {
    min-width: 24rem;

    span {
      vertical-align: middle;
    }

    [class*="icon-"] {
      margin-right: @ax-spacing-s;
    }

    .icon-list {
      font-size: 2.4rem;
      font-weight: @ax-font-weight-bold;
    }

    button {
      .box-shadow(none);
      background-color: transparent;
      border: none;
      min-width: auto;
      white-space: nowrap;

      &:hover, &:focus {
        background-color: transparent;
      }
    }

    .list-view-button {
      color: @black;
      font-weight: @ax-font-weight-bold;

      [class*="icon-"] {
        color: @primary-blue;
      }
    }

    .grid-view-button {
      color: @_secondary-text-color;

      &:hover,
      &:focus {
        color: @primary-hover-blue;

        [class*="icon-"] {
          color: @primary-hover-blue;
        }
      }
    }

    .grid-view-button, .list-view-button {
      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }
  }

  .article-search__results-region .page-control-top-region {
    display: none;
  }

  .category-sub-heading {
    font-size: 1.4rem;
    margin-top: 4rem;

    .ax-breakpoint-m({
      font-size: 1.8rem;
      margin: 4.8rem 0 0;
    });
  }

  &__results {
    >li {
      margin-bottom: @ax-spacing-m;
    }
  }

  .search-result {
    &__tile {
      .description-region {
        padding-bottom: 1rem;
        width: 100%;
      }

      .article-description {
        overflow: hidden;
        word-break: break-word !important;
      }

      &.base-card {
        overflow: visible;
      }

      .base-card {
        &__wrapper--whole {
          padding: 0;
          position: relative;
        }

        &__wrapper--upper {
          .align-items(flex-start);
          padding-bottom: 0;

          .ax-breakpoint-m({
            .align-items(center);
            padding-bottom: @ax-spacing-m;
          });
        }

        &__wrapper--lower {
          padding-top: @ax-spacing-s;
          padding-bottom: @ax-spacing-s;
        }

        &__badge-assigned {
          background-image: url('@training/assets/images/badge-assigned.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          display: none;

          .assigned-text {
            color: @white;
          }

          .ax-breakpoint-m({
            display: block;
            height: 3.3rem;
            left: -1.8rem;
            min-width: 12rem;
            padding-top: @ax-spacing-s;
            position: absolute;
            text-align: center;
            top: @ax-spacing-s;
          });
        }

        &__badge-assigned--mobile {
          .border-radius(.1rem);
          .display-flex();
          background: #004FA3;
          box-shadow: 0 .1rem 0 0 #06315F;
          left: @ax-spacing-xs;
          margin-top: @ax-spacing-xl;
          margin-top: 3.5rem;
          min-height: @ax-spacing-l;
          padding: 0 @ax-spacing-xs;
          position: absolute;
          text-align: center;
          width: 6.7rem;
          word-wrap: break-word;

          .assigned-text {
            color: @white;
            margin: auto;
          }

          .ax-breakpoint-m({
            display: none;
          });
        }

        &__image {
          .align-items(center);
          .border-radius(@ax-border-radius-m);
          .display-flex();
          .flex-direction(column);
          .flex(0 0 auto);
          .size(5.5rem, 4.5rem);
          background-color: @pagination-grey;
          background-position: center;
          background-repeat: no-repeat;
          margin: @ax-spacing-m 0 0 @ax-spacing-m;

          &.topic-background {
            background-image: url('@training/assets/images/topic.svg');
            background-size: auto 75%;
          }

          &.article-background {
            background-image: url('@training/assets/images/discoveryzone-light.svg');
            background-size: 50%;

            img {
              .flex(0 0 auto);
              height: auto;
              max-height: 100%;
              object-fit: contain;
              width: auto;
            }
          }

          &.path-background {
            background-image: url('@common/assets/images/path.svg');
            background-size: 98%;
          }

          img {
            .border-radius(@ax-border-radius-m);
            background-color: @white;
            box-sizing: border-box;
            min-height: 4.5rem;
            max-height: 100%;
            margin: 0 auto;
            min-width: 5.5rem;
            max-width: 100%;
            padding: @ax-spacing-xs;
            width: auto;
            height: auto;
          }

          .ax-breakpoint-m({
            .align-items(center);
            .border-radius(@ax-border-radius-m);
            .display-flex();
            .justify-content(center);
            .size(18rem, 12.2rem);
            background-color: @pagination-grey;
            background-position: center;
            background-repeat: no-repeat;
            margin: @ax-spacing-m 0 @ax-spacing-m @ax-spacing-l;
            overflow: hidden;

            &.topic-background {
              background-image: url('@training/assets/images/topic.svg');
            }

            &.path-background {
              background-image: url('@common/assets/images/path.svg');
            }

            &.article-background {
              background-image: url('@training/assets/images/discoveryzone-light.svg');
              background-size: 35%;
            }
          })
        }

        &__view-all {
          flex-shrink: 0;
          margin-bottom: 0;
          margin-left: 0;
          
          .label {
            display: none;
          }

          &__wrapper {
            flex-shrink: 0;
          }

          .ax-breakpoint-m({
            padding-left: @ax-spacing-m;
            padding-right: @ax-spacing-m;

            .label {
              display: inline-block;
            }

            [class^="icon-"] {
              display: none;
            }
          });
        }
      }

      .article-type-icon,
      .icon-training-article-nobg {
        margin: @ax-spacing-m 0 0 6.2rem;
        position: absolute;

        .ax-breakpoint-m({
          margin: @ax-spacing-l 0 0 @ax-spacing-s;
        });
      }

      .article-type-icon {
        .article-type-icon-style-mixin();
      }

      .icon-training-article-nobg {
        .article-type-icon-style-mixin(training);
      }

      .title-region {
        .display-flex();
        .flex-grow(1);
        width: 100%;
        justify-content: space-between;

        .base-card__title {
          .flex-grow(0);

          .h1 {
            word-break: break-word;
          }
        }

        .icon-certification {
          align-self: flex-end;
          line-height: 2rem;
          margin-left: @ax-spacing-m;

          &.certified {
            color: @correct-green;
          }

          &.due {
            color: @ax-color-certification;
          }

          &.overdue {
            color: @error-state;
          }
        }
      }
    }

    &__lower {
      padding-top: 1rem;

      &, & .extra-info-region {
        .align-items(center);
        .display-flex();
        .flex-direction(row);
      }

      .tile__meta {
        .flex-grow(1);
      }

      .axon-button {
        .action-text {
          display: none;
        }

        span {
          padding-right: 0;
        }

        .ax-breakpoint-m({
          height: 4.5rem;
          min-width: 12.5rem;
          padding-top: 0;

          .icon-play {
            display: none;
          }

          .action-text {
            .display-flex();
            .justify-content(center);
          }

          .icon--badge {
            font-size: 1.8rem;
            background-color: transparent;
            padding: 0 @ax-spacing-xs 0 0;
            width: auto;
          }
        })
      }

      .profile-icon-region {
        margin-right: @ax-spacing-s;

        .user-profile-icon {
          .round-avatar();
          font-size: 1.2rem;

          .ax-breakpoint-m({
            font-size: 1.6rem;
          });
        }
      }

      .star-container-region {
        margin-left: @ax-spacing-m;

        .star-container {
          display: none;

          .ax-breakpoint-m({
            .display-flex();
            .flex(1 1 auto);
          });

          .star-icon {
            margin: 0.1rem 0.3rem 0 0;
          }
        }
      }

      .ax-breakpoint-m({
        padding-top: @ax-spacing-m;
      });
    }
  }

  .grid-view .article-type-icon {
    top: 0;
  }

  &__paywall {
    .align-items(center);
    .display-flex();
    .flex-direction(column);

    h2 {
      font-size: 2rem;
      margin-top: 5.5rem;

      .ax-breakpoint-m({
        margin-top: 7.7rem;
      })
    }

    p {
      color: @_secondary-text-color;
      margin-top: @ax-spacing-m;
      text-align: center;

      .ax-breakpoint-m({
        margin-top: @ax-spacing-l;
      });
    }

    button {
      margin: @ax-spacing-l 0;
      font-size: @ax-spacing-m;
      line-height: 3.8rem;

      .ax-breakpoint-m({
        margin-top: @ax-spacing-xl;
      });
    }
  }

  .search__topic-results-region .search__topics-paywall h2 {
    margin-top: @ax-spacing-m;

    .ax-breakpoint-m({
      margin-top: 2.2rem;
    });
  }

  .search__results .subtext-header-title {
    p {
      padding-right: @ax-spacing-l;
    }

    h2, h3 {
      margin-bottom: 0;
      font-size: @ax-font-size-s;
    }
  }

  .article-search__results-region,
  .search__article-results-region {
    .article-result__lower {
      .align-self(flex-end);
      display: block;
    }
  }

  .grid-view.article-search__results-region {
    .article-result__lower {
      display: flex;
    }
    .search__results {
      li {
        // Stops reaction tray from hiding under cards
        &:hover {
          z-index: 1;
        }
      }
    }
  }

  .article-result__lower {
    padding: 0;

    .ax-breakpoint-m({
      .align-items(flex-end);

      .metaRegion {
        margin: @ax-spacing-xs 0 @ax-spacing-xxs;
      }

      .star-container-region {
        margin: 0.5rem 0 0.5rem @ax-spacing-m;
      }
    })
  }

  &__article-results-region,
  &__topic-results-region,
  &__path-results-region {
    margin-top: @ax-spacing-l;
  }

  // Empty search
  .no-search,
  .not-available {
    margin: 10vh;
    text-align: center;

    .placeholder-search-image {
      .size(10rem);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      margin: 0 auto;
    }

    &__prompt {
      font-size: 1.6rem;
      color: @_secondary-text-color;

      &--small {
        font-size: 1.4rem;
        margin-top: @ax-spacing-s;
      }
    }
  }

  .no-search .placeholder-search-image {
    background-image: url('@training/assets/images/search-illustration.svg');

    .ax-breakpoint-m({
      .size(20.5rem, 28rem);
    })
  }

  .not-available .placeholder-search-image {
    background-image: url('@training/assets/images/page-not-available.svg');

    .ax-breakpoint-m({
      .size(20.5rem, 20rem);
    })
  }

  .empty-search-results {
    word-break: break-word;
  }

  .empty-search-padding {
    .ax-breakpoint-m({
      padding-top: 6rem;
    });
  }
}
