@import "tell-a-friend-invites-active.less";
@import "tell-a-friend-invites-available.less";
@import "tell-a-friend-invites-claimed.less";
@import "tell-a-friend-invites-recent.less";
@import "tell-a-friend-modal-how-to.less";
@import "tell-a-friend-modal-invite.less";


.tell-a-friend-page {
  h1 {
    background-color: inherit;
  }
}
