[dir=rtl] {
  // Overriding icomoon icons
  .icon-chevron_right {
    &:before {
      content: "\e923";
    }
  }
  .icon-angle_right {
    &:before {
      content: "\e970";
    }
  }

  .icon-chevron_left {
    &:before {
      content: "\e924";
    }
  }
  .icon-angle_left {
    &:before {
      content: "\e971";
    }
  }
}
