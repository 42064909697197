// Achievements
.trophy-shelf ul {
  background-image: url("@training/assets/images/achievement_shelf_bg.png?_v2");
}

.trophy-shelf ul li {
  background-image: url("@training/assets/images/achievement_shelf_bg.png?_v2");
}

.trophy-shelf li {
  &.left-shelf {
    background-image: url("@training/assets/images/achievement_shelf_left_bg.png?_v2")/*rtl:url("@training/assets/images/achievement_shelf_right_bg.png?_v2") bottom left no-repeat*/;
  }
  &.right-shelf {
    background-image: url("@training/assets/images/achievement_shelf_right_bg.png?_v2")/*rtl:url("@training/assets/images/achievement_shelf_left_bg.png?_v2") bottom left no-repeat*/;
  }
}

.badge-scrolling {
  &::-webkit-scrollbar {
    display: none;
  }
}

