.selectgame .challenge-cell {
  border: .2rem solid @games-color-main;
  margin-bottom: @ax-spacing-l;
  padding: 0em;
  position: relative;
  min-width: @game-min-width-mobile;

  .game-wrapper {
    margin-bottom: 0;
    min-width: auto;
  }

  .game {
    border: 0;
    margin: 0;
    padding: @ax-spacing-s;
    float:none;

    .ax-breakpoint-m({
      width: 100%;
      .box-shadow(none);
      .box-sizing(border-box);

      &:hover {
        border: none;
        .box-shadow(none);
      }
    });

    .type {
      width: 100%;
      margin: 0;
    }
  }

  .challenge-info {
    color: @games-color-main;
    padding: 0.5em;

    .ax-breakpoint-m({
      display: block;
      margin-left: 0.5em;
    });
  }

  .game-name,
  .game-type {
    width: 100%;
  }
}

.gameslist .game-challenge {
  .ax-breakpoint-m({
    position: relative;
  });

  button {
    .button-reset-style();
    font-size: 1.8rem;
    font-weight: @ax-font-weight-medium;
    background: @games-color-main;
    .border-radius(0 @ax-border-radius-m 0 @ax-border-radius-m);
    color: @white;
    display: none;
    float: right;
    height: @ax-spacing-l;
    line-height: @ax-line-height-s;
    margin: -@ax-spacing-s -@ax-spacing-s 0 0;
    position: relative;
    text-align: center;
    width: @ax-spacing-l;

    .ax-breakpoint-m({
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 800;
      margin: 0;
      padding: 0;
      .border-radius(0 0 0 @ax-border-radius-m);
    });

    &:hover,
    &:focus {
      background: red;
      text-decoration: none;
    }
  }

  .challenge-wager {
    background: @games-color-main;
    position: relative;
    top:0;
    left: 0;
    right: 0;
    display: block;
    color:@white;
    font-weight: @ax-font-weight-medium;
    padding: 0 0.5em;
    line-height: 1.8em;

    .ax-breakpoint-m({
      background: @games-color-main;
      display: block!important;
      color:@white;
      padding: 0 0.5em;
      line-height: @ax-line-height-s;
    });
  }

  .game-name,
  .game-type {
    display: none;
  }

  .game {
    border: .2rem solid @games-color-main;
    position: relative;
    width: 9rem;

    .challenge-info {
      color: @games-color-main;
      width: 8.8rem;
    }
  }
}

.challenges .list-view.scrollable {
    margin-right: 1.2rem;
}

.bet-meta {

  .ax-breakpoint-m({
    padding-bottom: @ax-spacing-l;
  });

  .game-name {
    float: left;
  }

  .game-type-icon {
    margin: 0 0.5em 0 @ax-spacing-s;
    float: left;
    background-color: transparent!important;

    .ax-breakpoint-m({
      margin-right: 0.2em;
    });
  }

  .game-name-meta {
    display: block;

    .ax-breakpoint-m({
      display: inline-block;
    });
  }
}

.how-to-modal {
  .general-description,
  .wagering-description {
    margin-bottom: 1.5em;
  }

  h2 {
    margin: 0;
  }

  .icon-tellafriend {
    background-image: none !important;
  }
}
