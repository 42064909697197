
// Adjusts Auto-complete & Dropdowns
.adjustable-container .close {
  border: none;
  position: absolute;
  right: 4px; top:4px;
  z-index: 999;
}

// Auto complete
.auto-complete {
  position: relative;
  margin: 1.2rem 0;
  max-width: 50rem;

  .ax-breakpoint-m({
    margin: 0 0 @ax-spacing-l;
  });
}

// Override to add gutter
.screen-sm-max({
  .contentpad .auto-complete {
    width: auto;
    .box-sizing(border-box);
  }
});
