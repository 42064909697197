.unenroll {
  position: relative;
  padding: 1.4rem;
  text-align: center;
  overflow: visible;
}

.unenroll__title {
  .display-flex();
  .justify-content(center);
  text-transform: uppercase;

  & > div {
    margin-right: 1.4rem;
  }
}

.unenroll__trigger {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid @ax-color-grey-30;
  width: 5.2rem;
  height: 5.2rem;

  .menu-dropdown__container {
    height: 100%;
    .menu-dropdown__button-container {
      height: 100%;

      .menu-dropdown__toggle-button {
        height: 100%;
        width: 100%;
        border: 0;
        .border-radius(0 @ax-border-radius-m @ax-border-radius-m 0);
        background-color: transparent;

        &:hover,
        &:focus{
          background-color: darken(@ax-color-grey-20, 15%);
          border-color: darken(@ax-color-grey-20, 15%);
        }
      }
    }
  }
}

.unenroll__actions {
  top: 100%;
  width: 20rem;
  padding: @ax-spacing-m;
  box-shadow: @ax-box-shadow-m;
}
