// TODO: Swap these with ax-vars when ready
@_secondary-grey: @ax-color-grey-30;
@_primary-grey: @ax-color-grey-40;
@_secondary-text-color: @ax-color-grey-60;
@_accent-grey: @ax-color-grey-20;
@_primary-text-color: @ax-color-grey-80;

.topic-details {
  .box-sizing(border-box);
  margin: 0;

  .ax-breakpoint-m({
    padding: 5.6rem 0;
  });

  &__header {
    margin: @ax-spacing-m 0;

    .ax-breakpoint-m({
      margin: 0 0 5.6rem;
    });

    .text-muted {
      font-size: 1.4rem;
    }

    h1 {
      font-size: 2rem;
      line-height: 2.8rem;
      vertical-align: text-bottom;
    }
  }

  &__content {
    margin-bottom: 6.4rem;

    .ax-breakpoint-m({
      margin-bottom: 0;
    });
  }

  &__region {
    .box-sizing(border-box);
    margin-top: @ax-spacing-m;
    margin-bottom: @ax-spacing-m;

    .ax-breakpoint-m({
      margin-top: @ax-spacing-xl;
    });

    &--title {
      .flex(1 1 100%);
    }

    &--image {
      margin-top: 0;
      overflow: hidden;
      max-height: 25rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .ax-breakpoint-m({
        .border-radius(@ax-border-radius-m);
        max-height: 45rem;
        min-height: 35rem;
      });

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--performance {
      .ax-breakpoint-m({
        .border-radius(@ax-border-radius-m);
        margin-top: 0;
        margin-bottom: 0;
      });

      .performance {
        .ax-breakpoint-m({
          height: 35rem;
          margin-bottom: @ax-spacing-l;
        });

        & > * {
          text-align: left;

          .ax-breakpoint-m({
            text-align: center;
          });

          &.title {
            display: none;

            .ax-breakpoint-m({
              display: block;
              margin-top: @ax-spacing-m;
              text-transform: uppercase;
            });
          }
        }

        .user-stats-region {
          border-left: .2rem solid @_secondary-grey;
          padding: 0 @ax-spacing-s;

          .ax-breakpoint-m({
            border-left: none;
            border-bottom: .1rem solid @_accent-grey;
            padding: 0;
          });

          &__user-score, &__no-user-score {
            span {
              font-size: @h4-font-size;

              .ax-breakpoint-m({
                font-size: @user-topic-score;
              });
            }

            p {
              font-size: @h2-font-size;
              margin-top: @ax-spacing-s;
              margin-bottom: 0;

              .ax-breakpoint-m({
                font-size: @h1-font-size;
              });
            }
          }
        }

        .team-stats-region {
          border-left: .2rem solid @_secondary-grey;
          padding-left: @ax-spacing-s;

          .ax-breakpoint-m({
            border-left: none;
            padding: 0;
          });

          .average-topic-score {
            min-height: 5rem;

            .ax-breakpoint-m({
              .display-flex();
              .flex-direction(column);
              .justify-content(center);
              height: 100%;
              margin-top: @ax-spacing-s;
            });
          }

          &__average-score {
            font-size: 1.8rem;

            .ax-breakpoint-m({
              font-size: 2.4rem;
              color: @_secondary-text-color;
            });
          }

          p {
            font-size: @h2-font-size;
            margin-top: @ax-spacing-s;
            margin-bottom: 0;
          }
        }
      }

      .cta-button-container {
        background-color: @white;
        border-top: .1rem solid @_secondary-grey;
        padding: @ax-spacing-s;
        position: fixed;
        bottom: 5.6rem;
        left: 0;
        right: 0;
        z-index: 1;

        .ax-breakpoint-m({
          background-color: transparent;
          border-top: none;
          margin-bottom: @ax-spacing-xl;
          position: static;
          padding: 0;
        });
      }
    }

    &--paths {
      margin-top: 0;
    }
  }

  &.no-image {
    .topic-details__content {
      .ax-breakpoint-m({
        display: block;
      });
    }

    .topic-details__region--performance,
    .topic-details__region--paths {
      .ax-breakpoint-m({
        float: right;
        clear: right;
      });
    }

    .topic-details__region--description {
      margin-bottom: 0;

      .ax-breakpoint-m({
        margin-top: 0;
        float: left;
      });
    }
  }

  .topic-description {

    & > h2 {
      font-size: 1.6rem;
    }

    .description-text {
      white-space: pre-wrap;
      margin-bottom: @ax-spacing-xl;

      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: @ax-spacing-m;
        color: @_secondary-text-color;
      }
    }
  }

  .topic-level-list .topic-level__row {
    font-size: 1.4rem;
    padding: @ax-spacing-l 0;

    .progress-wrapper, .button-wrapper {
      .flex-direction(column);
      min-width: 5.2rem;

      .ax-breakpoint-m({
        min-width: 8.8rem;
      });
    }

    &--item {
      &.user-score {
        font-size: 1.4rem;
        color: @_secondary-text-color;
        order: 2;
        min-width: 5rem;

        .ax-breakpoint-m({
          order: inherit;
        });
      }
      &.progress {
        margin-bottom: 1.2rem;

        .ax-breakpoint-m({
          font-size: 2rem;
          margin-bottom: 0;
        });
      }
      &.ax-button {
        display: none;
        width: 100%;
        margin-top: @ax-spacing-s;
      }
      &.locked-wrapper {
        display: none;
        margin-top: @ax-spacing-s;

        .locked-wrapper__label {
          margin: 0 0 0 @ax-spacing-s;
        }

        .icon-lock, .lock-text {
          color: @ax-color-grey-70;

          .ax-breakpoint-m({
            vertical-align: middle;
            display: inline-block;
          })
        }

        .lock-text {
          margin: 0;
        }

        .icon-lock {
          font-size: 2.2rem;
          margin-right: @ax-spacing-s;
          margin-left: 0;
        }

        .pass-info {
          font-size: 1.4rem;
          display: none;

          .ax-breakpoint-m({
            display: block;
            color: @ax-color-grey-70;
            margin: 0 0 0 @ax-spacing-xs;
          });
        }
      }
    }

    &--badge {
      padding: 0 @ax-spacing-s;
      height: 2rem;
      width: 2rem;
      .ax-breakpoint-m({
        font-size: 1.6rem;
      });

      &.icon-certification {
        &.certified {
          color: @correct-green;
        }
        &.due {
          color: @_secondary-text-color;
        }
        &.overdue {
          color: @error-state;
        }
      }
    }

    .title-container {
      .flex-grow(1);

      &__level {
        font-size: 1.8rem;
        display: inline-block;
        font-weight: @ax-font-weight-medium;
      }
      &__meta,
      &__meta .text-muted {
        font-size: 1.4rem;
        margin-top: @ax-spacing-s;
        color: @ax-color-grey-70;
      }
    }
  }

  .topic-level-list li:not(:last-child) {
    border-bottom: .2rem solid @_accent-grey;
  }

  .learning-paths-list {
    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-m;
    });

    .learning-path-block {
      .display-flex();
      background-color: @secondary-bg;
      color: @_secondary-text-color;
      margin-bottom: @ax-spacing-m;
      padding: @ax-spacing-m;

      .ax-breakpoint-m({
        .border-radius(@ax-border-radius-m);
        background-color: @white;
        border: .1rem solid @_secondary-grey;
        margin-bottom: @ax-spacing-l;
        padding: @ax-spacing-l;
      });

      span {
        margin: 0 @ax-spacing-s;

        &.learning-path-item__text {
          font-size: @h1-font-size;

          .guidedPath {
            &.focus-visible:focus {
              .ax-focus-keyboard();
            }
          }
        }
      }
    }
  }

  .no-action {
    .display-flex();
    .align-items(center);
    font-size: 1.6rem;
    .justify-content(center);
    text-align: center;
    min-height: 5.3rem;
  }
}
