ul.recent-invites {
  margin-top: @ax-spacing-s;

  li {
    border-top: .1rem solid @ax-color-grey-20;

    .recent-wrapper {
      line-height: 2.1rem;
      padding: @ax-spacing-m 0;

      .ax-breakpoint-m({
        line-height: 2.4rem;
        padding: @ax-spacing-m @ax-spacing-s @ax-spacing-m 0;
      });
    }
  }
}
