@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

//============= Bootstrap Global Modal Classes  ============= */
#common-modal {
  &.modal {
    position: absolute;
  }

  .modal-dialog {
    &.send-message-modal {
      .modal-body {
        padding: 0;
      }
    }
    &.add-users-modal {
      .modal-body {
        padding-bottom: 0;
      }
    }
    &.add-rewards-target-modal,
    &.add-rewards-prizes-modal {
      .modal-body {
        padding: 0;
      }
      .loading-row {
        min-height: 20rem;
      }
    }
    &.add-rewards-target-modal #button-wrapper {
      .justify-content(space-between);
      .target-counts {
        margin-top: 0;
      }
    }
    &.confirm-modal,
    &.alert-modal {
      width: 500px;
      top: 30%;
      .modal-content {
        padding-top: 1.5em;
      }
      .modal-body {
        padding: 0;
        .confirm {
          .addpad {
            padding: 1em;
          }
        }
      }
      .modal-footer {
        padding-top: 0;
        border-top: 0;
        text-align: left;

        #button-wrapper {
          display: block;
        }
      }
    }
    &.alert-modal {
      .modal-body {
        .text {
          font-size: 1.7rem;
        }
      }
    }
    &.tincan-show-modal {
      .tincan-wrap {
        margin: 1.5em;
      }
    }
    &.training-module-modal {
      .modal-body {
        padding: 0;
        #external-link-content-holder {
          padding: 20px;
        }
      }
    }
    &.weekday-chooser-modal {
      width: 400px;
      top: 15%;
      .modal-body {
        padding: 0;
      }
    }
  }
  .modal-content {
    .modal-header {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #ededec;
      &.white-background {
        background: @white;
      }
      .contenthierarchy {
        border: 0;
        box-shadow: none;
        background: none;
      }
    }
    .modal-body {
      overflow: auto;
      min-height: 100px;

      .search-area {
        padding:1em;
        height: 35px;
        width: auto;
      }

      .confirm,
      .alert {
        font-size: 2rem;

        .secondary {
          font-size: @ax-font-size-m;
        }
      }
      .addprize {
        #selectprize {
          margin-top: 10px;
        }
      }
      &.no-padding-no-margin {
        padding: 0;
        margin: 0;
      }
      .adding.add-media {
        .createform {
          margin: 0;
        }
      }
    }
    .modal-footer {
      &.centered {
        text-align: center;
        .axon-button {
          float: none !important;
        }
      }
      .axon-button {
        &.green,
        &.normal,
        &.delete,
        &.cancel,
        &.red,
        &.btn-danger {
          border: 0;
          margin-right: 5px;
        }
      }

      a, button, .axon-button {
        margin-right: 5px;
      }

      &.white-background {
        background: @white;
      }
      .transcription-message-lineheight {
        line-height: 4rem;
      }
      padding: 1.5em;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #ededec;
    }
  }
}

.scorm-show-modal,
.tincan-show-modal {
	width: 96% !important;
}

.modal-backdrop.fade.in {
  display: none;
  opacity: 0.85;
  position: absolute;
}

//IE FIXES
//
.lt-ie9 {
  #common-modal {
    &.modal {
      left: 60px;
    }
  }
  .modal-dialog {
    width: 90%;
  }
}

.screen-md-landscape({
  .modal-dialog {
    width: 90%;
  }
});

.screen-lg-min({
  .modal-dialog {
    width: 940px;
    margin: 30px auto;
  }
});

@media only screen and (min-width: @screen-lg-min) {
  .modal-dialog {
    width: 940px;
    margin: 30px auto;
  }
}

.contentwrapper {
  position: relative;
  min-height: 200px;
}


/* =============LEGACY MODAL CLASSES =============*/

// /*===MODAL==*/

/*- modalview -*/
#feedbackmodalview,
#modalview {
  display: block;
  z-index:800;
  .border-radius(@ax-border-radius-m);
  background:@white;
  width: 940px;
  overflow: hidden; /* don't mess with these overflow or you'll be messing with modal scrolling */
  left: 50%/*rtl:ignore*/;
  margin-left: -470px/*rtl:ignore*/;
  z-index: 803;
  position: absolute;
  top: 25px;
  display: block;
  background-color: white;

  .modal {
    overflow: hidden;
    padding: 1em;
    background-color: @white;
    bottom: auto;
    /*Rounded Corners*/
    .border-radius(@ax-border-radius-m);
    h1 {
      box-shadow:none;
    }
  }
  .questionpreview.modal-header.modal-header-details {
    padding: 0;
  }
  .modal-header {
    position: relative;
    font-size: @topic-font-size;
    padding: 0.5em 1em;
    .gradient(@startColor: @primary-bg, @endColor: @accent-grey);
    .box-shadow-black(inset 0px, -1px, 2px, 0.1);
    font-weight: @ax-font-weight-medium;
  }

  .modal .close {
    background: url('@common/assets/images/modal-close.png') center center no-repeat;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0px;
    right:0px;
    text-indent: -999em;
    cursor: pointer;
    opacity: 1;

    &:hover,
    &:focus {
      opacity: 0.75;
    }

    .retina({
      background: url('@common/assets/images/modal-close@2x.png') center center no-repeat;
      background-size: 25px;
    })
  }

  &.modal--s {
    .ax-breakpoint-m({
      width: 47rem;
    });
  }
}

#modalview,
#feedbackmodalview,
.adjustable-modal {
  .adjustable-container {
    z-index: 1;
    .relative;
  }

  .actionbar {
    z-index: 3;
    .relative;
    background: @white;

    .actioncontent {
      display: block;
      text-align: center;

      .flex-actioncontent {
        .flex-actioncontent__left {
          text-align: left;
        }

        .flex-actioncontent__center {
          button:last-child {
            margin-right: 0;
          }
        }

        .flex-actioncontent__right {
          text-align: right;
        }
      }

      .submit-feedback-button {
        min-width: inherit;
        margin-left: @ax-spacing-m;
        height: 3.6rem;
      }
    }
  }

  &.in-game-question-modal {
    .flex-actioncontent__left {
      min-width: @ax-spacing-xxl;
    }

    .flex-actioncontent__right {
      min-width: @ax-spacing-xxl;
    }
  }
}

.modal-bottom {
  bottom: 25px!important;
}

.modal {
  &.zoom-modal {
    margin: 0;
    padding: 2em;
    top: 75px!important;
    text-align: center;
    border:1px solid @accent-grey;
    min-width: 400px;
    min-height: 250px;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    background-color: @white;
    visibility: hidden;
  .box-shadow-black(0px, 1px, 2px, 0.1);
  .border-radius(@ax-border-radius-m);
  .zoom-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      position: relative;
      top:2px;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
 }
}

.modal-bottom {
  bottom: 25px!important;
}
