@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

/* Base styles that come with the toastr vendor lib
 * We need to wrap it in a function so that the variables defined in
 * `toastr.less` don't overwrite our global variables.
 */
.toaster-mixin() {
  @import '@common/vendor/toastr/toastr.less';
}

.toaster-mixin();

@_size-icon-toast-status: @ax-spacing-xl;
@_size-icon-toast-close: @ax-spacing-m;
@_spacing-icon-toast-status: @_size-icon-toast-status + (@ax-spacing-m * 2);

/* Overrides for the base styles to work for our apps */
#toast-container {
  position: absolute;

  > div {
    border-radius: @ax-border-radius-s;
    opacity: 1;
    padding: @ax-spacing-m @ax-spacing-m @ax-spacing-m @_spacing-icon-toast-status;

    &:before {
      .size(@_size-icon-toast-status);
      color: @ax-color-white;
      display: inline-block;
      font-family: @icomoon-font-family;
      font-size: @_size-icon-toast-status;
      left: @ax-spacing-m;
      line-height: @_size-icon-toast-status;
      position: absolute;
      top: @ax-spacing-m;
    }

    &.toast-info,
    &.toast-success,
    &.toast-error,
    &.toast-warning {
      background-image: none !important;
    }

    &.toast-info {
      background-color: @ax-color-semantic-informational;

      &:before {
        content: @icon-info_sign;
      }
    }

    &.toast-success {
      background-color: @ax-color-semantic-positive;

      &:before {
        content: @icon-ok_circle;
      }
    }

    &.toast-error {
      background-color: @ax-color-semantic-negative;

      &:before {
        content: @icon-exclamation;
      }
    }

    &.toast-warning {
      background-color: @ax-color-semantic-warning;
      color: @ax-color-grey-80;

      &:before {
        color: @ax-color-grey-80;
        content: @icon-warning_sign;
      }

      &:after,
      .toast-message a,
      .toast-message a:hover,
      .toast-message label {
        color: @ax-color-grey-80;
      }
    }

    &:after {
      .size(@_size-icon-toast-close);
      color: @ax-color-white;
      content: @icon-remove;
      display: inline-block;
      font-family: @icomoon-font-family;
      font-size: @_size-icon-toast-close;
      line-height: @_size-icon-toast-close;
      position: absolute;
      right: @ax-spacing-s;
      top: @ax-spacing-s;
    }
  }

  &.toast-axonify-position > div {
    width: 96%;
    margin-left: auto;
    margin-right: auto;

    .screen-md-portrait-min({
      width: 38rem;
      margin: 0 0 @ax-spacing-s;
    });
  }
}

.toast-axonify-position {
  .toast-bottom-full-width;

  .screen-md-portrait-min({
    bottom: auto;
    width: auto;
    left: auto;
    .toast-top-right;
  });
}

.toast-title {
  &:extend(.ax-font__m);
  margin: 0 0 @ax-spacing-xs;
}

.toast-message {
  font-size: @ax-font-size-xs;
  line-height: @ax-line-height-xs;

  a,
  label {
    color: @ax-color-white;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: @ax-color-grey-10;
    }
  }

  p {
    margin: @ax-spacing-xxs 0;
  }
}

