/* rtl:begin:ignore */
.card {
  .border-radius(@card-border-radius);
  .box-shadow(@card-box-shadow-default);
  .box-sizing(border-box);
  background-color: @white;
  border: @card-border;
  position: relative;

  &&--informational {
    .box-shadow(@card-box-shadow-informational);
  }

  &&--interactive {
    -webkit-font-smoothing: antialised;
    -webkit-font-smoothing: subpixel-antialiased;
    .clickable();
    .transition-property(~"box-shadow, top");
    .transition(@card-interactive-transition);
    backface-visibility: hidden;
    top: 0;
    transform: translateZ(0);
    z-index: 1;

    &&-no-cursor-change {
      cursor: default;
    }

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }

    &:hover {
      .box-shadow(@card-box-shadow-interactive-hover);
      top: @card-interactive-top-hover;
    }

    &&--highlighted {
      .box-shadow(~"inset 0 0 .1rem .1rem @{primary-grey}, @{card-box-shadow-interactive-hover}");
      background-color: @element-hover-bright;
      top: @card-interactive-top-hover;
    }
  }

  &&--small {
    padding: @card-padding-s;
  }

  &&--medium {
    padding: @card-padding-m;
  }

  &&--large {
    padding: @card-padding-l;
  }
}

/* rtl:end:ignore */