.topic-tile {
  .tile-bottom {
    .display-flex();
    .flex-direction(column);
    .align-items(center);
    padding: @ax-spacing-m;

    .screen-md-portrait-min({
      .flex-direction(row);
    });
  }

  .tile-meta,
  .action-buttons-wrapper {
    width: 100%;
    .screen-md-portrait-min({
      width: auto;
    })
  }

  .tile-meta {
    align-items: flex-start;
    margin-bottom: @ax-spacing-s;

    .screen-md-portrait-min({
      margin-bottom: 0;
    });

    span {
      padding: 0 @ax-spacing-s;

      &.first {
        padding-left: 0;
      }
    }
  }

  .action-buttons-wrapper {
    .display-flex();
    margin-left: 0;

    .screen-md-portrait-min({
      margin-left: auto;
    });

    button {
      width: 100%;

      .screen-md-portrait-min({
        width: auto;
      })
    }
  }
}
