// Challenge Friend
.challenge-selection-region {
  padding-bottom: @ax-spacing-l;

  .ax-breakpoint-m({
    .box-sizing(border-box);
    float: none;
    width: 100%;

    .challengee-list {
      margin-bottom: 1.5em;
    }
  });
}

.ax-breakpoint-m({
  .recent-challenges-region {
    float: none;
    width: 100%;
  }
});

.user-small {
  padding: @ax-spacing-m;
  margin-bottom: @ax-spacing-m;

  .ax-breakpoint-m({
    margin-bottom: @ax-spacing-s;
  });
}

// Hot and cold streaks
.small-bottom-bar {
  .losing-streak,
  .cold-streak {
    color: @cold-streak;
  }

  .winning-streak,
  .hot-streak {
    color: @games-color-main;
  }

  .streak-icon {
    display: inline-block;
    vertical-align: top;
    padding-right: @ax-spacing-s;
  }
}

// Sidebar
.recent-challenges-region {
  height: auto;

  h3 {
    border-top: none;
    margin-top: 0;
  }

  .streak {
    .box-shadow-black(0px, 1px, 2px, 0.1);
    display: block;
    margin: 0 0 @ax-spacing-m;
    padding: @ax-border-radius-m;

    &.winning-streak,
    &.hot-streak {
      .border-radius(@ax-border-radius-m);
      background-color: lighten(@games-color-main, 35%);
      border: .1rem solid @games-color-main;

      span {
        color: @games-color-main;
        font-weight: @ax-font-weight-medium;
        padding-left:.8em;

        .ax-breakpoint-m({
          padding-left: .75em;
          line-height: 2.4rem;
        });
      }

      .streak-icon {
        display: inline-block;
        padding-left: 0;
        vertical-align: top;

        .ax-breakpoint-m({
          font-size: 2.4rem;
          vertical-align: bottom;
        });
      }
    }

    &.losing-streak,
    &.cold-streak {
      .border-radius(@ax-border-radius-m);
      background-color: lighten(@cold-streak, 35%);
      border: .1rem solid @cold-streak;

      span {
        color: @cold-streak;
        font-weight: @ax-font-weight-medium;
        padding-left:.8em;
      }

      .streak-icon {
        display: inline-block;
        padding-left: 0;
        vertical-align: top;
      }
    }
  }

  .challenger,
  .challengee {
    margin-bottom: @ax-spacing-m;
    text-align: center;
    width: 45%;
  }

  .versus {
    text-align: center;
    width: 10%;
    margin-top: @ax-spacing-m;
  }

  .recent-challenges-list {
    margin-top: @ax-spacing-m;

    li {
      border-bottom: .1rem solid @accent-grey;
      padding: @ax-spacing-m 0;

      &:first-child {
        padding-top: @ax-spacing-s;

        .challenger,
        .challengee {
          padding: 0;
        }

        .score {
          font-weight: @ax-font-weight-medium;
        }

        .tie,
        .winner {
          display: none;
        }

        .versus span {
          font-size: @topic-font-size;
          margin: 0;
        }
      }
    }
  }
}

.no-recent-challenges {
  font-size: @topic-font-size;
  color: @secondary-text-color;
}

.meta-win-loss-result {
  .display-flex();

  .loss {
    color: @cold-streak;
  }

  .against {
    margin: 0 @ax-spacing-xs;
  }

  .win {
    color: @games-color-main;
  }

  .tied {
    background: none;
    color: @black;
    float:left;
    font-weight: @ax-font-weight-medium;
    margin: auto @ax-spacing-xs auto auto;
    padding: 0;
    text-align: left;
    width: auto;
  }

  .opponent {
    .flex-grow(1);
  }
}

.challenge__wager-card {
  .transition(background-color, @ax-transition-speed);
  margin: @ax-spacing-m 0 0;

  .ax-breakpoint-m({
    margin: 0 0 @ax-spacing-l 0;
  });

  label {
    margin: 0;
  }

  .wager-meta {
    color: @ax-color-green-100;
    font-weight: @ax-font-weight-medium;
  }
}
