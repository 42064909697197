@import './content/TagGroupFiltersEditContentView.less';

.tag-group-filters-layout {
  margin-top: @ax-spacing-l;

  .translatable-name .chosen-container .chosen-single {
    // Remove dropdown caret (already added with axSelect)
    background-image: none;
  }

  .metadata__input {
    h2 {
      display: none;
    }
  }
}

.tag-group-filters-index__new-button {
  margin-bottom: @ax-spacing-l;
}

.tag-group-filters-index__count {
  p {
    color: @ax-color-grey-60;
  }
}

.tag-group-filters-empty-content {
  padding-top: 12.5rem;
  text-align: center;
}

.tag-group-filters-empty-content__icon {
  font-size: 4.5rem;
}

.tag-group-filters-empty-content__message {
  font-weight: @ax-font-weight-medium;
  font-size: @ax-font-size-s;
  margin-top: @ax-spacing-l;
}

.tag-group-filters-index__list-wrapper {
  li a{
    .text-ellipsis();
  }
}
