.adjustable-layout .how-to-modal {
  background: @white;
  height: 100%;
  width: 100%;

  .ax-breakpoint-m({
    .border-radius(@ax-border-radius-m);
    height: auto;
    width: 45rem;

    .actioncontent {
      .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);
    }

    .header {
      font-size: @ax-font-size-s;
    }
  });

  h1 {
    .box-shadow();
    background: none;
    padding: 0 0 @ax-spacing-s 0;
  }

  .header {
    margin: 0;
  }

  .welcome {
    font-size: @ax-font-size-s;
    line-height: @ax-line-height-s;
    margin-top: @ax-spacing-s;
    padding-bottom: @ax-spacing-xl;
    font-weight: @ax-font-weight-bold;

    .ax-breakpoint-m({
      font-size: @ax-font-size-m;
      line-height: @ax-line-height-m;
      margin: @ax-spacing-xl 0;
      padding: 0;
    });
  }

  .buttongroup {
    padding: 0;
  }

  .modal {
    padding: @ax-spacing-m;

    .ax-breakpoint-m({
      .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
    });
  }
}

.in-it-for-me .header,
.referral-disclaimer {
  margin-top: @ax-spacing-l;

  .ax-breakpoint-m({
    font-size: @ax-font-size-s;
  });
}

.referral-disclaimer {
  color: @ax-color-orange-100;
  font-weight: @ax-font-weight-bold;
  margin-bottom: 0;
  text-align: center;

  .ax-breakpoint-m({
    font-size: @topic-font-size;
    margin-bottom: @ax-spacing-m;
  });
}
