.stats {
  min-height: 8rem;
  
  &__list {
    .display-flex();
    .flex-wrap(nowrap);
    .box-sizing(border-box);
    .align-items(stretch);

    .screen-sm-max({
      .backdrop-filter(none);
    });

    &__empty {
      .flex(auto);
      color: @secondary-text-color;
      font-style: italic;
    }
  }

  &__tile {
    .display-flex();
    .box-sizing(border-box);
    height: auto;
    min-height: 60px;

    .screen-md-portrait-min({
      font-size: 2.2rem;
    });

    &:first-child {
      border-top-left-radius: .5rem;
      border-bottom-left-radius: .5rem;

      .screen-sm-max({
        border-left: none;
      });

      .stat-item {
        padding-left: 3rem;

        .screen-sm-max({
         padding-left: 1.5rem
        });
      }
    }

    &:last-child {
      border-top-right-radius: .7rem;
      border-bottom-right-radius: .7rem;

      .stat-item {
        padding-right: 3rem;

        .screen-sm-max({
          padding-right: 1.5rem
        });
      }
    }
  }

  // Singular Stat Item
  .stat-item {
    .box-sizing(border-box);
    .full-width;
    .text-ellipsis-softwrap;
    margin: 0;
    padding: 1.3rem 1.6rem;
    white-space: normal;
    color: @secondary-text-color;

    &__value {
      margin-bottom: .4rem;
      color: @ax-color-text;
      display: block;
    }

    &--skeleton {
      .stat-item__value {
        margin-bottom: 0;
      }
    }
  }
}
