@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";
@import '@common/components/forms/editors/date/DuetDatePickerVendorStyles.less';


// ---------------------------------------------
// Axonify Modified Styles at the bottom of the page.
// Removing unchanged properties from file causes styling problems
// Make modifications at the bottom of the page
// ---------------------------------------------

:root {
  --duet-color-primary: @ax-color-blue-60;
  --duet-color-text: @ax-color-grey-80;
  --duet-color-text-active: @ax-color-white;
  --duet-color-placeholder: @ax-color-grey-60;
  --duet-color-button: @ax-color-grey-20;
  --duet-color-surface: @ax-color-white;
  --duet-color-overlay: rgba(0, 0, 0, 0.4);
  --duet-color-border: @ax-color-grey-80;

  --duet-font: inherit;
  --duet-font-normal: @ax-font-weight-regular;
  --duet-font-bold: @ax-font-weight-medium;

  --duet-radius: @ax-border-radius-m;
  --duet-z-index: 1001;
}

// ---------------------------------------------
// DUET DATE PICKER __ Axonify Modified Properties -- Add more styles here
// ---------------------------------------------

.duet-input {
  margin-bottom: 0.2rem;
}

.duet-date__input {
  padding-left: @ax-spacing-s;
}

.duet-date__toggle {
  background: none;
  border: .1rem solid transparent;
  box-shadow: none;
  padding: 0 @ax-spacing-xs;
  width: auto;

  &:focus {
    border-color: transparent;
    box-shadow: none;
  }

  &.focus-visible:focus {
    .ax-focus-keyboard();
  }
}

.duet-date__dialog {
  left: 0;

  @media (max-width: 35.9375em) {
    background: var(--duet-color-overlay);
    bottom: 55px; // necessary to use pixels becase the navbar is also defined in pixels
    top: 4rem;
  }
}

.duet-date__dialog-content {
  width: 80%;
  padding: @ax-spacing-s;
  min-width: 10rem;

  @media (max-width: 35.9375em) {
    width: 100%;
    margin-right: -10%;
    height: 100%;
  }
}

.duet-date__close {
  @media (max-width: 35.9375em) {
    margin-right: 4rem;
  }
}

.duet-date__table {
  font-size: @ax-font-size-s;
}

.duet-date__table-header {
  font-size: @ax-font-size-s;
  padding-bottom: @ax-spacing-xs;
}

.duet-date__cell {
  text-align: center;
  padding: @ax-spacing-xs 0 @ax-spacing-xs @ax-spacing-xxs;
}

.duet-date__day {
  font-size: @ax-font-size-s;
  padding: @ax-spacing-xxs;
  height: @ax-spacing-xl;
  width: @ax-spacing-xl;
  border: .1rem solid transparent;

  &.focus-visible:focus {
    .ax-focus-keyboard();
    outline: 0;
  }
}

.duet-date__prev,
.duet-date__next {
  height: @ax-spacing-xl;
  width: @ax-spacing-xl;
  border: .1rem solid transparent;
}

.duet-date__select {
  select {
    border: .1rem solid transparent;
    font-size: @ax-font-size-m;

    &:focus + .duet-date__select-label {
      border-color: transparent;
      box-shadow: none;
    }

    &.focus-visible:focus + .duet-date__select-label {
      .ax-focus-keyboard();
    }
  }
}

.duet-date__select-label {
  font-size: @ax-font-size-m;
  border: .1rem solid transparent;

  svg {
    width: @ax-spacing-m;
    height: @ax-spacing-m;
  }
}


// reset values
.duet-date__table > thead {
  background: none;
  border-bottom: none;
}

.duet-date__table > thead,
.duet-date__table th {
  padding: 0;
  text-align: center;
}

.duet-date__mobile-heading {
  margin-left: 7%;
}

.time-picker {
  @supports (-webkit-touch-callout: none) {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

[dir=rtl] {
  .duet-date__toggle {
    left: @ax-spacing-xxs;
    right: auto;
  }

  .duet-date__nav {
    display: flex;
    flex-direction: row-reverse;
  }
}
