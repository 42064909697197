@icon-container-size: 5rem;
@icon-container-size-mobile: 4.5rem;

.news-container div.news-icon {
  .align-items(center);
  .border-radius(@ax-border-radius-m);
  .display-flex();
  .justify-content(center);
  font-size: @news-item-icon-size-mobile;
  float: left;
  height: @icon-container-size-mobile;
  line-height: @news-item-icon-size-mobile;
  text-align: center;
  width: @icon-container-size-mobile;
  margin: 0 @ax-spacing-m @ax-spacing-m 0;

  .screen-md-portrait-min({
    font-size: @news-item-icon-size;
    height: @icon-container-size;
    line-height: @news-item-icon-size;
    overflow: hidden;
    padding: 0;
    width: @icon-container-size;
  });
}

.news-container .newsstory.MilestoneAchievement {
  &.MilestoneAchievement {
    div.news-icon {
      background-color: @ax-color-icon-green-a11y;
      font-size: @icon-nav-size;

      .screen-md-portrait-min({
        font-size: @tile-icon-size;
      });
    }
  }
}

.news-container .newsstory.CorrectAnswerStreak {
  &.CorrectAnswerStreak1,
  &.CorrectAnswerStreak10,
  &.CorrectAnswerStreak20,
  &.CorrectAnswerStreak50,
  &.CorrectAnswerStreak100,
  &.CorrectAnswerStreak200,
  &.CorrectAnswerStreak500,
  &.CorrectAnswerStreak1000 {
    div.news-icon {
      background-color: @ax-color-icon-green-a11y;
    }
  }
}

.news-container .newsstory.ParticipationFrequency {
  &.ParticipationFrequency10,
  &.ParticipationFrequency15,
  &.ParticipationFrequency20,
  &.ParticipationFrequency25,
  &.ParticipationFrequency30 {
    div.news-icon {
      background-color: @turquoise;
    }
  }
}

.news-container .newsstory.TopicLevelGraduation div.news-icon {
  background-color: @primary-blue;
}

.news-container .newsstory.TopicLevelGraduationStreak {
  &.TopicLevelGraduationStreak1,
  &.TopicLevelGraduationStreak3,
  &.TopicLevelGraduationStreak5,
  &.TopicLevelGraduationStreak10,
  &.TopicLevelGraduationStreak15,
  &.TopicLevelGraduationStreak20,
  &.TopicLevelGraduationStreak30,
  &.TopicLevelGraduationStreak50,
  &.TopicLevelGraduationStreak75,
  &.TopicLevelGraduationStreak100,
  &.TopicLevelGraduationStreak150 {
    div.news-icon {
      background-color: @primary-blue;
    }
  }
}

.news-container .newsstory.AuctionWinner div.news-icon,
.news-container .newsstory.BuyNowWinner div.news-icon,
.news-container .newsstory.SlotsWinner div.news-icon,
.news-container .newsstory.RaffleWinner div.news-icon {
  font-size: @tile-icon-size;
  background-color: @green;

  .screen-md-portrait-min({
    font-size: @tile-icon-size;
  });
}

.news-container .newsstory.GameChallengeWon div.news-icon {
  background-color: @ax-color-icon-green-a11y;
}

.news-container .newsstory.GameChallengeTie div.news-icon {
  .icon-male;
  background-color: @primary-blue;
  font-size: @news-item-icon-size-small;
}

.news-container .newsstory.GameChallengeLoss div.news-icon {
  background-color: @red;
}

.news-container .newsstory.GameHighScore.GameHighScore1 div.news-icon {
  background-color: @ax-color-gold;
}

.news-container .newsstory.GameHighScore.GameHighScore2 div.news-icon {
  background-color: @ax-color-silver;
}

.news-container .newsstory.GameHighScore.GameHighScore3 div.news-icon {
  background-color: @ax-color-bronze;
}

.news-container .newsstory.SessionStreak {
  &.SessionStreak1,
  &.SessionStreak10,
  &.SessionStreak20,
  &.SessionStreak50,
  &.SessionStreak100,
  &.SessionStreak200,
  &.SessionStreak500,
  &.SessionStreak1000 {
    div.news-icon {
      background-color: @red;
    }
  }
}
