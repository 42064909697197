.report-header-wrapper {
  margin-bottom: @ax-spacing-xl;
}

.report-tile {
  position: relative;
  height: 44.5rem;
  overflow: hidden;
  margin-bottom: @ax-spacing-l;

  &.card--interactive {
    .tile-fade {
      .transparent-to-white-vertical-gradient();
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      .full-width();
      height: 6rem;
    }
  }
}

.reports-dashboard-table {
  .full-width();
  .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);

  .h1 {
    margin-bottom: @spacing-regular
  }

  table th {
    padding: @ax-spacing-xs;
    text-align: left;
    background: @accent-grey;
    font-weight: @ax-font-weight-medium;
    border-bottom: 0.2rem solid @secondary-grey;
  }

  .meta-text {
    color: @ax-color-text-secondary;
  }

  th {
    padding: 0 @ax-spacing-xs;
    &.right-align {
      text-align: right;
    }
  }

  tr {
    padding: 0;
    border-bottom: 0.1rem solid @accent-grey;
    border-top: 0.1rem solid @accent-grey;
  }

  td {
    padding: 1rem @ax-spacing-xs;
    .right {
      text-align: right;
    }
  }

  .empty-view {
    text-align: center;
    padding: 0;
    padding: @ax-spacing-s @ax-spacing-xs;
    line-height: 9.2rem;
    color: @meta-color;
  }
}

.reports-card__header-wrapper {
  .display-flex();

  h3 {
    width: 100%;
  }
}

.reports-dashboard-view {
  .pages-by-status h3 {
    margin-bottom: 0;
  }
}

.region-filters-community {
  margin-top: @ax-spacing-m;
  min-width: 30rem;

  .filterable-community {
    margin: 0;
    margin-left: @ax-spacing-m;
  }
}

.reports-filters {
  .screen-md-portrait-min({
    .region-filters-time {
      margin: 0;
      min-width: 10rem;
    }

    .region-filters-community {
      margin-top: 0;
      margin-right: @ax-spacing-s;
    }
  });
}

.reports-view {
  .reports-breadcrumb.breadcrumb-title-wrapper {
    padding: 0;
  }

  .breadcrumb-title-wrapper.reports-breadcrumb {
    .reports-title {
      font-size: @h1-font-size;
      text-decoration: underline;
      color: @ax-color-grey-80;
    }
  }
}

@media only screen and (max-width: @screen-md-portrait-min) {
  .reports-view {
    select {
      width: 100% !important;
    }

    .ax-select {
      margin-top: @ax-spacing-s;
    }

    .region-filters-time {
      height: 3.6rem;
      float: none;
      margin: @ax-spacing-xs 0 0;
      .chosen-container {
        .full-width() !important;
      }
    }

    .region-filters-community {
      float: none;
      margin: @ax-spacing-l 0 0;
    }

    .reports-title {
      font-weight: @ax-font-weight-medium;
    }

    .filterable-community {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: @screen-lg-min) {
  .reports-dashboard-view {
    .card.card--interactive {
      .transform(none);
    }
  }
}

a {
  &.focus-visible:focus {
    .ax-focus-keyboard();
  }
}
