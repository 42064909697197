.listselectall {
  .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
  .gradient(@startColor: @ax-color-grey-20, @endColor: @accent-grey);
  display: block;
  border: 0.1rem solid @ax-color-grey-40;
  border-bottom: 0;
  margin-top: @ax-spacing-s;

  .searchstring {
    .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
    .clearfix();
    padding: @ax-spacing-s;
    float: none;
    border-bottom: 0.1rem solid @ax-color-grey-40;

    input {
      width: 100%;
    }
  }

  .ax-checkbox label {
    padding: @ax-spacing-s;
    margin: 0;

    &:after {
      margin: @ax-spacing-s;
    }
  }

  .radio-label:first-of-type {
    margin-bottom: @ax-spacing-s;
  }

  &__radios {
    padding: @ax-spacing-s;
  }
}

ul.selectableList {
  .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);
  border: 0.1rem solid @ax-color-grey-40;
  border-top: none;

  li {
    position: relative;
    border: none;
    height: auto;
    margin: 0;
    padding: @ax-spacing-s;
    cursor: pointer;
    border-bottom: 0.1rem solid @ax-color-grey-40;

    label.disabled {
      color: @primary-grey;
    }
  }

  .loading-row {
    min-height: 4rem;
  }
}

.listselectall,
ul.selectableList {
  .ax-focus {
    .ax-focus-keyboard();
  }
}
