.filterable-selector-label {
  display: none;

  &.enabled {
    display: block;
    padding-bottom: 0.3em;
  }
}

.filterable-selector {
  margin: 0;
  position: relative;

  .title-wrapper {
    display: none;
  }

  .input-wrapper {
    color: @black;
    .box-sizing(border-box);
    z-index: 8;

    &.focused {
      input {
        background-color: @white;
      }
    }

    .ax-input {
      display: inline-block;
    }

    input {
      min-width: 0;

      &[readonly] {
        color: @black;
      }
    }
  }

  .filter-hold-left {
    text-align: left;
    padding: @ax-spacing-s @ax-spacing-m;
    margin: 0;
    background: @primary-bg;
    outline: 0;
    font-weight: @ax-font-weight-medium;
    font-size: @ax-font-size-s;

    [class*="icon-"] {
      vertical-align:middle;
    }
  }

  .ax-input button {
    display: none;
  }

  &.show-clear {
    .ax-input button {
      display: inherit;
    }
  }

  .dropdown-container {
    position: absolute;
    background: @white;
    width: 100%;
    height: 33rem;
    border: 1px solid @secondary-grey;
    list-style-type: none;
    margin: 0;
    z-index: @max-index;
    display: none;
    .box-sizing(border-box);
    .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);
  }

  .dropdown-listing {
    margin: 0;
    width: 100%;
  }

  .defaultFilterLabel {
    color: @ax-color-grey-60;
    font-weight: @ax-font-weight-regular;
  }

  .filterable-selector-item {
    .border-radius(0);
    border-left: .3rem solid transparent;
    border-right: none;
    border-top: none;
    display: block;
    padding: 0;
    text-align: left;

    &:hover {
      background-color: @ax-color-grey-20;
      border-left-color: @ax-color-grey-20;
    }

    &:not(.selected) {
      border-left-color: transparent;
      font-weight: @ax-font-weight-regular;

      a {
        color: @ax-color-text;
      }
    }
  }

  .filterable-selector-empty-view,
  .filterable-selector-item a,
  .filterable-selector-more-item a {
    .align-items(center);
    .display-flex();
    .flex-wrap(wrap);
    padding: @ax-spacing-m;
  }

  .filterable-selector-item a.has-parents {
    .parent {
      .flex(1 1 100%);
      font-size: 1.1rem;
      line-height: 1.1rem;
      color: @primary-grey;
    }
  }

  .filterable-selector-more-item.selected,
  .filterable-selector-item.selected {
    background-color: @ax-color-app-background;
    border-bottom-color: @ax-color-grey-40;
    font-weight: @ax-font-weight-bold;

    &:hover {
      background-color: @ax-color-app-background;
      border-right-color: transparent;
      border-top-color: transparent;

      a {
        cursor: default;
      }
    }

    a [class*="icon-"] {
      color: inherit;
      opacity: 1;

      &:hover {
        opacity: .8;
      }
    }
  }

  .filterable-selector-item:hover,
  .filterable-selector-item.selected:hover,
  .filterable-selector-more-item.selected:hover {
    border-bottom-color: @ax-color-grey-40;
  }
}

html[dir='rtl'] .filterable-selector .input-wrapper input {
  background-position: 0% center;
}

.filter-content-filler {
  display: none;
}

.opened, .filter-opened {
  .filterable-selector {
    position: relative;
    z-index: @page-index + 2;

    .input-container,
    input {
      z-index: @page-index + 1;
    }

    .dropdown-container {
      z-index: @page-index + 2;
      display: block;
      border-top: 0;
    }

    .screen-sm-max({
      position: fixed;
      top: calc(@header-height + 1px); // The 1 is for the header border.. .
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      padding: 0;

      .title-wrapper {
        display: block;
        margin:  0 0 0.75em 0;
        font-weight: @ax-font-weight-bold;
        min-height: 18px;

        .css-close-filter {
          background-image: url('@common/assets/images/clear-search.png?_v3');
          background-position: center center;
          background-repeat: no-repeat;
          right: @ax-spacing-s;
          left: auto;
          top: 0px;
          width: 32px;
          height: 32px;
          position: absolute;
          .opacity(1.0);

          .screen-md-portrait-min({
            .css-close-filter {
              .opacity(0.5);
            }
          });

          &:hover,
          &:focus {
            .opacity(1.0);
          }
        }
      }

      .css-clear-input {
        top: 37px;
        right: 7px;
      }

      input {
        z-index: @max-index - 1;
      }

      .filter-content {
        height: 100%;
        position: static;
      }

      .input-container {
        padding: 0.5em;
        background-color: @secondary-bg;
      }

      .dropdown-container {
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        border: 0;
        .border-radius(0);
        height: auto;
        border-top: 1px solid @secondary-grey;
      }
    });

    .screen-md-portrait-min({
      .input-wrapper {
        [class*="icon-"] {
          .border-radius(@ax-border-radius-m 0 0 0);
        }

        input {
          .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
        }
      }
    });
  }

  .screen-sm-max({
    .filter-content-filler {
      display: block;
    }
  });
}

// Styles specific to the child BehavioursTypeFilterableSelector class (used in LeaderZone)
.filterable-selector-item .behavior-filter-type {
  display: block;
  color: @ax-color-grey-60;
  margin-left: @ax-spacing-xs;
}

.behaviour-type__tooltip {
  float: right;

  &.focus-visible:focus {
    .ax-focus-keyboard();
  }
}
