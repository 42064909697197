.content-translations {
  .display-flex();
  .align-items(center);
  margin-top: @ax-spacing-s;

  .content-translations__toggle {
    color: @ax-color-grey-70;
    font-weight: @ax-font-weight-medium;

    &:hover {
      cursor: pointer;
    }

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }

  }

  .middot {
    margin: 0 @ax-spacing-xs;
  }

  .content-translations__google-disclaimer {
    background: url('@training/assets/images/google_disclaimer_greyscale_short.svg') no-repeat;
    width: 124px;
    height: 16px;
    display: inline-block;
  }
}
