.noevents {
  margin-left: @ax-spacing-m;
}

.learningevent-attendees,
.learningevent-detail {
  .name-logo {
    font-size: @topic-font-size;
    .ax-breakpoint-m({
      height: 5rem;
      line-height: 5rem;
      width: 5rem;
    });
  }

  .learningevent-link {
    margin: @ax-spacing-s 0;

    .ax-breakpoint-m({
      margin: 0;
    });
  }
}

.learning-event-item,
.learning-event-notification-item {
  margin-bottom: @ax-spacing-m;

  .instructor-container {
    margin-top: @ax-spacing-s;

    .ax-breakpoint-m({
      left: 80%;
      position: absolute;
      top: 1.3rem;
    });
  }

  .learning-event-item-date span {
    display: inline-block;

    .ax-breakpoint-m({
      display: block;
      text-align: center;
    });
  }

  .name-logo {
    font-size: @topic-font-size;
    margin-right: @ax-spacing-m;
  }

  .row-arrow {
    line-height: 1.5rem;
    font-size: 1.5rem;
    color: @ax-color-grey-40;
  }
}

.hidden-users-snapshot-view {
  .display-flex();
  .align-items(center);

  .name-logo {
    font-size: @ax-font-size-xl;
    color: @ax-color-white;
    background-color: @ax-color-grey-40;
  }

  .user-name {
    font-weight: @ax-font-weight-medium;
  }
}
