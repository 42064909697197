@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

ul.collection-list {
   margin: 1.5em 1.5em 1.5em 0;
     &.border {
       margin-top:0;
     }
     &.options {
       margin-left:1.5em;
       margin-top:0;
     }
}

ul.collection-list li {
  transition: background-color 0.5s ease;
  position: relative;
  &.selected {
    background-color: @element-hover-bright;
    border-left: 4px solid @primary-blue;
  }
  &:hover {
    text-decoration: none;
    background-color: @element-hover-bright;
  }
}

ul.collection-list li a {
  padding:1em 0.7em;
  display:block;
  float:none;
  color:@ax-color-text;
  .transition( all .2s );
  outline: none;
}

ul.collection-list li span.name {
  width:80%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ul.collection-list li .delete {
  display:block;
  position: absolute;
  cursor: pointer;
  top:30%;
  right:5px;
  width:16px;
  height:16px;
  opacity:0.5;
  font-size: 1.8rem;
}

ul.collection-list li .delete:hover,
ul.collection-list li .delete:focus {
  opacity: 1.0
}
