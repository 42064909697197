.time-zone-display-container {
  line-height: 1.3rem;
  .time-zone-display {
    display: block;
    color: @primary-grey;
    margin-top: 5px;
    line-height: 1.3rem;
  }
}

