@modal-badge-size: 7rem;
@modal-badge-icon-size: 4.5rem;
@modal-pop-size: 4rem;
@modal-pop-color: #f1961c;
@modal-pop-desktop-margin: 30px;
@modal-pop-mobile-margin: -30px;
@modal-ribbon-width: 220px;
@modal-ribbon-height: 96px;

.modal__pop-container-1 {
  margin-top: 15px;

  .screen-sm-max({
    margin-right: @modal-pop-mobile-margin;
  });
}

.modal__pop-container-2 {
  margin-top: 45px;

  .screen-md-portrait-min({
    margin-right: @modal-pop-desktop-margin;
  });
}

.modal__pop-container-3 {
  margin-top: 70px;

  .screen-md-portrait-min({
    margin-left: @modal-pop-desktop-margin;
  });
}

.modal__pop-container-4 {
  margin-top: 5px;

  .screen-sm-max({
    margin-left: @modal-pop-mobile-margin;
  });
}

.achievements-modal {
  width: 500px;

  .view-all {
    color: @ax-color-text;
    background: @accent-grey;
    border-color: @secondary-grey;
  }

  .modal__content {
    .flex-column-center-horizontally-mixin();

    .modal__ribbon-container {
      width: @modal-ribbon-width;

      .modal__ribbon {
        .size(@modal-ribbon-width, @modal-ribbon-height);
        background-image: url('@training/assets/images/badge-ribbon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        position: absolute;
        top: -25px;
      }
    }

    .modal__pop-wrapper {
      .display-flex();
      .align-items(stretch);
      .justify-content(center);
      width: 100%;

      .icon--badge {
        .size(@modal-badge-size);
        font-size: @modal-badge-icon-size;
        text-align: center;
        margin-top: 4.2rem;
        margin-bottom: @ax-spacing-s;
      }

      .icon-pop {
        font-size: @modal-pop-size;
        color: @modal-pop-color;
        padding: 1rem 1.5rem;
      }

      .modal__pop-container-1();
      .modal__pop-container-2();
      .modal__pop-container-3();
      .modal__pop-container-4();
    }

    .modal__badge-wrapper {
      margin-bottom: @ax-spacing-xl;

      .modal__badge-number {
        text-align: center;
      }
    }

    .modal__string-wrapper {
      .flex-column-center-horizontally-mixin();

      .modal-title {
        font-size: 1.8rem;
        margin: 10px 0;
        padding: 0;
        background-color: @white;
      }

      .current-badge {
        font-size: 1.6rem;
        font-weight: @ax-font-weight-medium;
        margin: 10px 0;
      }

      .next-badge {
        font-size: 1.4rem;
        color: @secondary-text-color;
        margin-top: 30px;
        margin-bottom: 42px;
      }
    }
  }
}
