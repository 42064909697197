@mq-middle-spacing: @mq-triangle-size + 2px;
@mq-correctness-indicator-size: 32px;
@mq-option-border-size: 1px;
@mq-button-padding: 1rem;
@mq-triangle-top-offset: 26px;
@mq-border-color: @primary-grey;
@mq-border-color-darker: darken(@mq-border-color, 18%);
@mq-selected-border-color: @black;
@mq-selected-border-color-darker: darken(@mq-selected-border-color, 18%);
@mq-focused-border-color: @primary-blue;
@mq-focused-border-color-darker: darken(@mq-focused-border-color, 15%);
@mq-options-selected-bg: @accent-grey;

.mq-triangle-top() {
  top: 20px; //fallback for IE
  top: calc(50% - @mq-triangle-size);

  .screen-sm-max({
    top: @mq-triangle-top-offset;
  });
}

.matching-question {
  .optionsarea {
    padding: 2.4rem 1.6rem 1rem;
  }

  .off-screen {
    top: -10px;
  }
}

.mq-first-options,
.mq-second-options {
  margin: 0;
  padding: 0;
}

.mq-first-options .zoom-icon {
  bottom: 0;
  left: 0;
  top: auto;
  right: auto;
}

.mq-second-options .zoom-icon {
  bottom: 0;
  right: 0;
  top: auto;
  left: auto;
}

.mq-paired-list,
.mq-unpaired-list {
  .clearfix();
}

.mq-options-position-wrapper {
  overflow: hidden;
  position: relative;
}

.mq-option {
  .button-reset-style();
  .display-flex();
  .flex-direction(row);
  .align-items(center);
  .justify-content(center);
  background: @white;
  box-sizing: border-box;
  border: @mq-option-border-size solid @mq-border-color;
  cursor: pointer;
  min-height: (@mq-triangle-top-offset * 2) + (@mq-triangle-size * 2);
  padding: @mq-button-padding;
  position: absolute;
  width: calc(50% -  (@mq-middle-spacing / 2));

  .screen-sm-max({
    .flex-direction(column);
  });

  &.mq-selected {
    background-color: @mq-options-selected-bg;
    border-color: @mq-selected-border-color;
  }

  &.focus-visible:focus {
    border-color: @mq-focused-border-color;
  }

  .mq-answered & {
    cursor: default;

    &.mq-incorrect {
      background: url(/assets/questions/wronganswerbg.gif?_v12) top left repeat;
    }

    &.mq-paired-option.mq-correct {
      background: transparent;
    }

    &.focus-visible:focus {
      background: transparent;
    }
  }

  .mq-first-options & {
    .border-radius(@ax-border-radius-m 0 0 @ax-border-radius-m);
    left: 0;
    padding-right: @mq-triangle-size + 1;
    z-index: 50;

    &.mq-paired-option {
      left: @mq-middle-spacing / 2;

      .mq-answered &:not(.focus-visible) {
        border-right-color: transparent;
      }

      .mq-answered & {
        padding-right: calc((@mq-correctness-indicator-size / 2) + @mq-button-padding);
      }
    }

    &.mq-animate,
    &.mq-paired-option.mq-animate  {
      left: 0;
    }

    &.mq-selected,
    &.focus-visible:focus {
      z-index: 150;

      &.mq-animate {
        z-index: 51;
      }
    }
  }

  .mq-second-options & {
    .border-radius(0 @ax-border-radius-m @ax-border-radius-m 0);
    right: 0;
    z-index: 60;

    &.mq-paired-option {
      right: (@mq-middle-spacing / 2) + 1;

      .mq-answered &:not(.focus-visible) {
        border-left-color: transparent;
      }

      .mq-answered & {
        padding-left: calc((@mq-correctness-indicator-size / 2) + @mq-button-padding);
        background-position: 0 1px;
      }
    }

    &.mq-animate,
    &.mq-paired-option.mq-animate {
      right: 0;
    }

    &.mq-animate {
      z-index: 61;
    }

    &.mq-selected,
    &.focus-visible:focus {
      z-index: 160;
    }
  }
}

.mq-option-text {
  word-wrap: break-word;
  text-align: center;
  width: 100%;

  &.mq-text-and-image {
    text-align: left;
    margin-left: @mq-button-padding;

    .screen-sm-max({
      text-align: center;
      margin-left: 0;
    });
  }
}

.mq-triangle-wrapper {
  .mq-triangle-top();
  position: absolute;

  .mq-answered & {
    display: none;
  }

  .mq-first-options & {
    right: -@mq-option-border-size;
  }

  .mq-second-options & {
    left: -@mq-triangle-size;
  }
}

.mq-triangle-border {
  border-bottom: @mq-triangle-size solid transparent;
  border-right: @mq-triangle-size solid @mq-border-color-darker;
  border-top: @mq-triangle-size solid transparent;
  height: 0;
  width: 0;

  .mq-selected & {
    border-right-color: @mq-selected-border-color-darker;
  }

  .mq-option.focus-visible:focus & {
    border-right-color: @mq-focused-border-color-darker;
  }
}

.mq-triangle-filler {
  border-bottom: (@mq-triangle-size - 1) solid transparent;
  border-right: (@mq-triangle-size - 1) solid @white;
  border-top: (@mq-triangle-size - 1) solid transparent;
  height: 0;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 0;

  .mq-second-options .mq-selected & {
    border-right-color: @mq-options-selected-bg;
  }
}

.mq-triangle-bar { // this bar is a fix for an animation issue (rarely the triangle is misaligned and this lessens the issue)
  background-color: @white;
  height: @mq-triangle-size * 2;
  position: absolute;
  right: -2px;
  top: 0;
  width: 2px;

  .mq-second-options .mq-selected & {
    background-color: @mq-options-selected-bg;
  }
}

.mq-correctness-indicator {
  display: none;

  .mq-answered .mq-second-options .mq-paired-option & {
    .mq-triangle-top();
    display: block;
    height: @mq-correctness-indicator-size;
    position: absolute;
    left: -(@mq-correctness-indicator-size / 2);
    width: @mq-correctness-indicator-size;
  }

  .mq-correct & {
    background: url(/assets/questions/correctcheck.png?_v13) 50% 50% no-repeat;
  }

  .mq-incorrect & {
    background: url(/assets/questions/incorrectcheck.png?_v13) 50% 50% no-repeat;
  }
}

.mq-a11y-note-selected {
  display: none;

  .mq-selected & {
    display: inline;
  }
}

.mq-a11y-note-correct {
  display: none;

  .mq-correct.mq-paired-option & {
    display: inline;
  }
}

.mq-a11y-note-incorrect {
  display: none;

  .mq-incorrect.mq-paired-option & {
    display: inline;
  }
}

.mq-a11y-note-paired {
  display: none;

  .mq-paired-option & {
    display: inline;
  }
}

.mq-a11y-note-unpaired {
  display: inline;

  .mq-paired-option & {
    display: none;
  }
}

.mq-preview  {
  margin: 3.2rem 1.6rem 0rem;

  .mq-preview-wrapper {
    .display-flex();
    .justify-content(space-between);
    border: 1px solid @primary-grey;
    .border-radius(@ax-border-radius-m);
    margin-bottom: 2.4rem;
    position: relative;

    &.incorrect {
      background:url('/assets/questions/wronganswerbg.gif') top left repeat;
    }
  }

  p {
    text-align: center;
  }

  .mq-preview-option {
    .display-flex();
    .align-items(center);
    .justify-content(center);
    width: calc(50% - 32px);
    padding: 1rem;
    min-height: 60px;
  }

  .mq-preview-result-indicator {
    position: absolute;
    display: block;
    height: 32px;
    z-index:10;
    left: calc(50% - 16px);
    border: 0;
    top: 35%;
    width: 32px;

    &.middle-correct {
      background: url('/assets/questions/correctcheck.png') 50% 50% no-repeat;
    }

    &.middle-incorrect {
      background:url('/assets/questions/incorrectcheck.png') 50% 50% no-repeat;
    }
  }

}
