
.zoom-overlay {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .zoom-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 35px;
    height: 35px;
    .border-radius(50%);
    background: url('@common/assets/images/zoom_in.png?_v1') center center no-repeat @white;
  }

  button.zoom-icon:focus {
    .ax-focus-keyboard()
  }
}
