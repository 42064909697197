.article-details {
  .box-sizing();
  border-bottom: 1px solid @accent-grey;
  word-break: break-word;

  .fr-second-toolbar {
    display: none;
  }

  &__header {
    padding: 1.6rem;
    background-color: @primary-bg;

    .display-flex();
    .justify-content(space-between);
    .align-items(center);

    &--title {
      .flex(1 1 auto);
      .text-ellipsis;

      .screen-md-portrait-min({
        white-space: normal;
        overflow: none;
        text-overflow: none;
      });

      h1 {
        background-color: inherit;
      }
    }

    &--sub {
      .flex(0 0 auto);
      margin-left: 1rem;

      &-mobile {
        .display-flex();
        .screen-md-landscape-min({
          display: none;
        });
      }

      &-desktop {
        display: none;
        .screen-md-landscape-min({
          .display-flex();
        });
      }

      .title__sub-container {
        .size(100%);

        .display-flex();
        .align-items(center);
      }

      .star-container {
        height: 26px;
        .display-flex();
        .align-items(center);

        .star-icon {
          cursor: pointer;
          font-size: 3rem;
        }
      }

      .star-count {
        margin-left: 0.5rem;
        font-weight: @ax-font-weight-medium;
        color: @primary-blue;
        font-size: 2rem;
      }

      .more-container {
        margin-left: 1rem;

        .icon {
          background-color: transparent;
          color: @primary-grey;
          .size(auto);
          font-size: 1.8rem;
        }

        .icon-paper_clip {
          display: none;
        }

        .more-button {
          -webkit-appearance: none;
          background: none;
          border: none;
          padding: 0;
          vertical-align: middle;

          .icon-ellipsis_horizontal {
            margin: 0;
          }
        }

        .more-button.attachment-icon .icon-paper_clip {
          display: block;
        }

        .ax-breakpoint-m({
          display: none;
        })
      }
    }
  }

  &__content {
    .box-sizing();
    position: relative;
  }

  &__region {
    .box-sizing();
    padding: 1.6rem;
    height: 100%;

    &--content {
      position: relative;
      background-color: @white;

      #page-content-view {
        .flex-grow(1);
        margin-bottom: @ax-spacing-m;
        padding-top: @ax-spacing-m;
        word-wrap: break-word;

        &.training-module {
          height: 100%;
        }

        #page-container {

          overflow-x: auto;

          .page-module {
            &.scorm-module,
            &.tincan-module {
              .flex-grow(1);
              height: 100%;
              .display-flex();
              .flex-direction(column);
            }
          }

          .page-module.scorm-module iframe,
          .page-module.tincan-module iframe {
            border: 0;
            height: 100%;
            width: 100%;
          }

          &.squire-editor {
            ol li {
              list-style: decimal;
            }

            ul li {
              list-style-type: disc;
              margin: 0 0 0 1em;
              padding: 0 1em;
              list-style: initial;
            }

            ul, ol {
              padding: 0;
              margin: 0 0 0 .8rem;

              li {
                padding-left: 0;
                padding-right: 0;
                margin-right: 0;
                margin-left: 4rem;
              }
            }

            a {
              text-decoration: underline;
            }

            code {
              .border-radius(@ax-border-radius-m);
              padding: 2px 4px;
              font-size: 95%;
              color: #c7254e;
              background-color: #f9f2f4;
            }

            .header {
              font-size: 2.3rem;
            }

            .media--video {
              ul, ol, li {
                margin: inherit;
                padding: inherit;
              }
            }
          }

          .media--embeddedVideo {
            text-align: center;

            &__iframe {
              max-width: 100%;
            }
          }

          .media--image {
            position: relative;

            img.focus-visible:focus {
              .ax-focus-keyboard();
            }

            .ax-media-query-ie({
              img {
                height: auto;
              }
            });
          }
        }
      }
    }

    &--metadata {
      background-color: @ax-color-app-background;
      padding: 0 0 1.6rem;
      .size(300px, 100%);
      position: absolute;
      top: 0;
      left: 100%;
      border-left: 1px solid @secondary-grey;
      z-index: 10;

      .metadata-item {
        padding: @ax-spacing-m;
        border-bottom: .1rem solid @secondary-grey;

        h3, label {
          font-weight: @ax-font-weight-bold;
          margin-bottom: @ax-spacing-xs;
        }

        &:last-child {
          border-bottom: 0;
        }

        &--updated {
          div {
            text-transform: capitalize;
          }
        }

        .inline-button-group {
          .display-flex();
          .inline-grouped-action {
            .justify-content(flex-start);
            &:not(:last-child) {
              margin-right: @ax-spacing-m;
            }
          }
        }
      }

      .screen-md-landscape-min({
        z-index: auto;
      });
    }
  }

  .metadata-home-community,
  .metadata-shared-communities {
    p {
      margin-bottom: 0;
    }

    .pin-icon {
      font-size: 1.8rem;
      cursor: pointer;
      margin: 0 @ax-spacing-m 0 @ax-spacing-m;

      &.disabled {
        color: @ax-color-icon-disabled;
        cursor: auto;
      }

      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }
  }

  .contributor-list,
  .expiry-notes-author {
    margin-top: 1rem;
  }

  .page-preview__meta-content {
    margin-bottom: 1.4rem;
  }

  .page-preview-meta-content__avatar {
    .round-avatar();
    margin-right: @ax-spacing-m;
  }

  .page-preview__meta-content-contributors {
    overflow: auto;

    .meta-content-contributors__last-edit-date {
      color: @secondary-text-color;
    }
    .meta-content-contributors__author-text {
      .text-ellipsis();
      font-weight: @ax-font-weight-medium;
    }
    .meta-content-contributors__author-status {
      color: @secondary-text-color;
    }

    .comment-value {
      margin-top: @ax-spacing-xs;
    }
  }

  .breadcrumb-path {
    padding: 1.6rem 1.6rem 0 1.6rem;
    .justify-content(space-between);

    button {
      font-weight: @ax-font-weight-regular;
    }

    .main-crumbs .pull-left:nth-of-type(2) {
      .display-flex();

      .screen-md-landscape-min({
        display: inherit;
      });
    }

    .main-crumbs {
      font-size: 1.4rem;

      .screen-md-landscape-min({
        font-size: 1.6rem;
      });
    }

    .all-crumb, .regular-crumb {
      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }
  }

  .star-container, .page-header__action-container {
    padding: 0 0.4rem;

    .screen-md-landscape-min({
      padding: 0 1rem;
    });
  }

  .star-container .star-icon {
    font-size: 2rem;

    .screen-md-landscape-min({
      font-size: 3rem;
    });
  }
}
