@article-search-icon-size-small: 1.6rem;
@article-search-icon-size-medium: 3.2rem;
@article-search-icon-border-small: 0.2rem;
@article-search-icon-border-medium: 0.3rem;

// Because training is handled differently (the 'icon' is equivalent to the background *around* the icon in other types)
// we need different styling optiions for it
@training-article-search-icon-size-small: 1.2rem;
@training-article-search-icon-size-medium: 2.6rem;
@training-article-search-icon-font-size-small: 1rem;
@training-article-search-icon-font-size-medium: 2rem;
@training-article-search-icon-border-small: 0.34rem;
@training-article-search-icon-border-medium: 0.7rem;
@training-article-search-icon-padding-small: 0.15rem;
@training-article-search-icon-padding-medium: 0.4rem;


// PUBLIC MIXIN DEFINITION(S)

// START - Specific configurations for the article type icons

// This is the default training icon block (return mobile/desktop sizes)
.article-type-icon-style-mixin(training) {
  #article-type-icon-styles > .training(@purple);
}

// This is a training icon block with a background color passed but no other arguments (return mobile/desktop sizes)
.article-type-icon-style-mixin(training, @bg-color) when (iscolor(@bg-color)) {
  #article-type-icon-styles > .training(@bg-color);
}

// This is a training icon block with non-standard color AND size arguments (return a single code block)
.article-type-icon-style-mixin(
  training,
  @size,
  @font-size: @size,
  @border-size: @training-article-search-icon-border-medium,
  @border-color: @white,
  @bg-color: @purple,
  @padding: @training-article-search-icon-padding-medium
) when (isnumber(@size)), (isem(@size)), (isunit(@size, rem)) {
  #article-type-icon-styles > .training(@bg-color, @size, @border-color, @border-size, @font-size, @padding);
}

// This is a regular article icon block with a non-standard size but default color (a single block due to fixed size)
.article-type-icon-style-mixin(@size) when (isnumber(@size)), (isem(@size)), (isunit(@size, rem)) {
  #article-type-icon-styles > .single(@white, @size);
}

// This is a single article icon block with all options
.article-type-icon-style-mixin(
  @bg-color,
  @size,
  @font-size: @size,
  @border-size: @article-search-icon-border-medium,
  @border-color: @white
) when (iscolor(@bg-color)) and (isnumber(@size)), (isem(@size)), (isunit(@size, rem)) {
  #article-type-icon-styles > .single(@bg-color, @size, @border-color, @border-size, @font-size);
}

// This is a regular article icon block with a non-standard background but otherwise default sizing (mobile/desktop)
.article-type-icon-style-mixin(@bg-color) when (iscolor(@bg-color)) {
  #article-type-icon-styles > .default(@bg-color);
}

// This is an article icon block with full control over all options (mobile and desktop)
// Since it has default values, it can also be called with no arguments to use default styling
.article-type-icon-style-mixin(
  @bg-color: @white,
  @icon-size-small: @article-search-icon-size-small,
  @border-size-small: @article-search-icon-border-small,
  @border-color: @white,
  @icon-font-size-small: @icon-size-small,
  @border-size-medium: @article-search-icon-border-medium,
  @icon-size-medium: @article-search-icon-size-medium,
  @icon-font-size-medium: @icon-size-medium
) when (default()) {
  #article-type-icon-styles > .default(
    @bg-color,
    @icon-size-small,
    @icon-size-medium,
    @border-color,
    @border-size-small,
    @border-size-medium,
    @icon-font-size-small,
    @icon-font-size-medium
  );
}

// End - Specific configurations




// NOTE: these styles are referenced by the 'public' mixin style (.article-type-icon-style-mixin) - don't reference directly
.common-article-icon-styles() {
  .border-radius(50%);
}

.training-icon-specific-styles() {
  background-clip: padding-box; // Remove the extra border on IE11 (only for Training type icons)
}

.article-type-icon-styles-factory(
    @bg-color,
    @size,
    @border-color,
    @border-size,
    @font-size: @size
  ) {

  background-color: @bg-color;
  .size(@size);
  font-size: @font-size;
  line-height: @size;
  border: @border-size solid @border-color;
}

#article-type-icon-styles {

  .training(@bg-color: @purple) {
    .common-article-icon-styles();
    .training-icon-specific-styles();

    .article-type-icon-styles-factory(
      @bg-color: @bg-color,
      @size: @training-article-search-icon-size-small,
      @border-color: @white,
      @border-size: @training-article-search-icon-border-small,
      @font-size: @training-article-search-icon-font-size-small
    );

    &:before {
      padding-left: @training-article-search-icon-padding-small;
    }

    .screen-md-landscape-min({
      .article-type-icon-styles-factory(
        @bg-color: @bg-color,
        @size: @training-article-search-icon-size-medium,
        @border-color: @white,
        @border-size: @training-article-search-icon-border-medium,
        @font-size: @training-article-search-icon-font-size-medium
      );

      &:before {
        padding-left: @training-article-search-icon-padding-medium;
      }
    });
  }

  .training(
    @bg-color: @purple,
    @icon-size: @training-article-search-icon-size-medium,
    @border-color: @white,
    @border-size: @training-article-search-icon-border-medium,
    @font-size: @training-article-search-icon-font-size-medium,
    @padding: @training-article-search-icon-padding-medium
  ) when (default()) {
    .common-article-icon-styles();
    .training-icon-specific-styles();

    .article-type-icon-styles-factory(
      @bg-color: @bg-color,
      @size: @icon-size,
      @border-color: @border-color,
      @border-size: @border-size,
      @font-size: @font-size
    );

    &:before {
      padding-left: @padding;
    }
  }

  .single(
    @bg-color: @white,
    @icon-size: @article-search-icon-size-medium,
    @border-color: @white,
    @border-size: @article-search-icon-border-medium,
    @font-size: @icon-size
  ) {
    .common-article-icon-styles();

    .article-type-icon-styles-factory(
      @bg-color: @bg-color,
      @size: @icon-size,
      @border-color: @border-color,
      @border-size: @border-size,
      @font-size: @font-size
    );
  }

  .default(
    @bg-color: @white,
    @icon-size-small: @article-search-icon-size-small,
    @icon-size-medium: @article-search-icon-size-medium,
    @border-color: @white,
    @border-size-small: @article-search-icon-border-small,
    @border-size-medium: @article-search-icon-border-medium,
    @font-size-small: @icon-size-small,
    @font-size-medium: @icon-size-medium
  ) {
    .common-article-icon-styles();

    .article-type-icon-styles-factory(
      @bg-color: @bg-color,
      @size: @icon-size-small,
      @border-color: @border-color,
      @border-size: @border-size-small,
      @font-size: @font-size-small
    );

    .screen-md-landscape-min({
      .article-type-icon-styles-factory(
        @bg-color: @bg-color,
        @size: @icon-size-medium,
        @border-color: @border-color,
        @border-size: @border-size-medium,
        @font-size: @font-size-medium
      );
    });
  }
}
