.dz-button-container {
  .display-flex();
  .justify-content(center);

  .discovery-zone-button {
    margin: 0;
    .flex-basis(400px);
    .flex-shrink(1);
    max-width: initial;
    min-width: initial;
  }
}
