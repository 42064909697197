.page-insights {
  .page-header {
    margin-bottom: @ax-spacing-m;

    h1 {
      background-color: inherit;
    }

    .breadcrumb-path {
      padding: 1.6rem 1.6rem 0 1.6rem;
      .justify-content(space-between);

      button {
        font-weight: @ax-font-weight-regular;
      }

      .main-crumbs .pull-left:nth-of-type(2) {
        .display-flex();

        .screen-md-landscape-min({
          display: inherit;
        });
      }

      .main-crumbs {
        font-size: @ax-font-size-xs;

        .screen-md-landscape-min({
          font-size: @ax-font-size-s;
        });
      }

      .all-crumb, .regular-crumb {
        &.focus-visible:focus {
          .ax-focus-keyboard();
        }
      }
    }

    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-l;
    });
  }

  .page-insights-overview {
    margin: 0 0 @ax-spacing-l;
    padding: @ax-spacing-m;
    background-color: @ax-color-grey-20;
    border-radius: @ax-border-radius-m;

    .ax-breakpoint-m({
      margin: 0 0 @ax-spacing-xl;
    });

    .page-insights-overview__header {
      font-size: @ax-font-size-m;
      font-weight: @ax-font-weight-bold;
      margin-bottom: @ax-spacing-m;

      &--hyphen,
      &--allTeams {
        color: @ax-color-grey-70;
      }
    }

    .page-insights-overview-tile-group {
      display: block;

      .ax-breakpoint-l({
        .display-flex();
      });

      .page-insights-overview-tile-wrapper {
        &:not(:last-child) {
          margin-bottom: @ax-spacing-xs;
        }

        .ax-breakpoint-l({
          .flex(1 1 0px);
          .box-sizing(border-box);
          .full-width();
          max-width: 100%;
          min-width: 0;
          margin: 0 1.2rem 0 1.2rem;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &:not(:last-child) {
            margin-bottom: 0;
          }
        });
      }
    }

    .page-insights-overview-tile {
      padding: @ax-spacing-s;
      background-color: @ax-color-white;
      border-radius: @ax-border-radius-s;
      border: .1rem solid transparent;

      &__title {
        margin-bottom: @ax-spacing-xl;

        &--label {
          font-size: @ax-font-size-xs;
          font-weight: @ax-font-weight-medium;
        }

        &--date {
          text-align: right;
          font-size: 1.2rem;

          .ax-breakpoint-m({
            font-size: @ax-font-size-xs;
          })
        }
      }

      &__stats {
        &--value {
          font-size: @ax-font-size-s;
          font-weight: @ax-font-weight-medium;
        }

        &--label {
          margin-top: @ax-spacing-xs;

          .label-icon {
            font-size: @ax-font-size-m;
            padding-top: @ax-spacing-xxs;

            .ax-breakpoint-m({
              font-size: @ax-font-size-l;
              padding-top: 0;
            });
          }

          .icon-frequenttraining {
            color: @ax-color-red-60;
          }

          .icon-content {
            color: @ax-color-grey-60;
          }

          .icon-reach:before {
            color: @ax-color-blue-60;
          }

          .icon-viewers:before {
            color: @ax-color-icon-green-a11y;
          }

          p {
            font-size: 1.2rem;
            margin-left: @ax-spacing-xs;

            .ax-breakpoint-m({
              font-size: @ax-font-size-xs;
            });
          }
        }
      }

      .ax-breakpoint-m({
        padding: @ax-spacing-m;
      });
    }
  }

  .page-insights-engagement {
    .page-insights-engagement__header {
      h2 {
        font-size: @ax-font-size-xs;
        color: @ax-color-grey-70;
        padding-bottom: @ax-spacing-s;
        margin: 0;
        line-height: normal;
      }

      p {
        font-size: @ax-font-size-l;
        font-weight: @ax-font-weight-bold;
        margin: 0;
      }

      .ax-checkbox {
        margin-top: @ax-spacing-l;
      }
    }
    .page-insights-engagement__wrapper, .page-insights-reactions__wrapper {
      background-color: @white;
      border: 0.1rem solid @ax-color-grey-20;
      .border-radius(0.3rem);
      margin-top: @ax-spacing-m;

      .page-insights-engagement__title {
        font-weight: @ax-font-weight-bold;
        margin-bottom: 0;
        font-size: @ax-font-size-s;
      }

      .highlights {
        margin-bottom: 0;
      }

      .highlight {
        margin-bottom: @ax-spacing-l;
        border-left: 0.3rem solid @ax-color-grey-40;
      }
    }

    .page-insights-engagement__wrapper {
      padding: @ax-spacing-m @ax-spacing-m 0;
    }

    .page-insights-reactions__wrapper {
      cursor: pointer;
      .box-shadow(none);

      &:hover {
        .box-shadow(@card-box-shadow-default);
      }

      .insights-reactions-tile {
        padding: @ax-spacing-m @ax-spacing-m 0;
        padding-bottom: @ax-spacing-m;
      }

      .highlight {
        margin-bottom: 6rem;
      }

      img {
        width: @ax-spacing-xl;
        height: @ax-spacing-xl;
      }

      .page-insights-reactions__details-button {
        display: flex;
        width: auto;
        &.focus-visible:focus {
          .ax-focus-keyboard();
        }

        //effect of off-screen class applied here, because inner offscreen text does not recieve focus
        &:focus {
          span:first-child {
            position: static;
            overflow: auto;
            height: auto;
            width: auto;
            clip: auto;
          }
        }
      }

      .reactions__categories--wrapper {
        &:nth-child(4), &:nth-child(5) {
          display: none;
          .ax-breakpoint-m({
            display: block;
          });
        }

        .reactions__categories {
          &--count {
            line-height: @ax-spacing-xl;
            margin: 0;
          }
        }
      }
    }
  }

  .subteams-region {
    margin: @ax-spacing-s;
    margin-bottom: @ax-spacing-m;

    thead {
      height: 3rem;
    }

    tr {
      cursor: pointer;

      &.focus-visible:focus {
        .transform(scale(1)); // this hack prevents other table rows from being rendered "in the way" of the box-shadow.
        .ax-focus-keyboard();
      }
    }

    td, th {
      text-align: right;
      &:first-child {
        text-align: left;
      }

      // hide the 2nd and 3rd columns in mobile view
      &:nth-child(2), &:nth-child(3) {
        display: none;
      }
    }

    th, td {
      font-weight: @ax-font-weight-regular;
    }

    td.subteam-table-name {
      font-weight: @ax-font-weight-bold;
      width: auto;
    }
    td.subteam-table-impressions, td.subteam-table-engagements, td.subteam-table-viewers {
      width: 15%;
    }
    td.subteam-table-navigation-arrow {
      width: 4rem;
      text-align: center;
      button:focus {
        .ax-focus-keyboard();
      }
    }

    .ax-breakpoint-m({
      // show the 2nd and 3rd columns in desktop view
      td, th {
        &:nth-child(2), &:nth-child(3) {
          display: table-cell;
        }
      }

      // desktop view doesn't need margin around the table
      margin: 0;
    });

  }
}

.page-insights-how-to {
  &#modalview {
    .icon-remove {
      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }
  }

  .page-insights-how-to-content {
    margin: @ax-spacing-l @ax-spacing-m;

    .ax-breakpoint-m({
      margin: 6.2rem 12rem @ax-spacing-xl;
    });

    &__item {
      .flex-basis(100%);
      margin-bottom: @ax-spacing-l;

      .ax-breakpoint-m({
        .flex-basis(50%);
        max-width: 50%;
        margin-bottom: @ax-spacing-xxl;

        &:nth-child(odd) {
          padding-right: @ax-spacing-xxl;
        }

        &:nth-child(even) {
          padding-left: @ax-spacing-xxl;
        }
      });

      &--description {
        color: @ax-color-grey-60;
        margin: 0;
      }
    }
  }
}
