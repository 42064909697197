.branded-banner,
.branded-banner div {
  .size(100%);
}

.branded-banner {
  position: relative;
}

.branded-banner div {
  position: absolute;
  top: 0;
  left: 0;
}

.branded-banner__low-res,
.branded-banner__high-res {
  background-position: center;
  background-size: cover;
}

.home-page-layout__banner {
  height: 50rem;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &--shorter {
    height: 42rem;
  }

  .screen-sm-max({
    height: 39rem;

    &--shorter {
      height: 37rem;
    }
  });
}
