.breadcrumb-path {
  .display-flex();
  .align-items(center);

  .header-breadcrumbs {
    font-weight: @ax-font-weight-medium;

    .main-crumbs {
      font-size: @h1-font-size;
      line-height: normal;

      button {
        border: none;
        background: none;
        padding: 0px;
        color: @primary-blue;
      }

      .all-crumb,
      .regular-crumb {
        text-decoration: underline;
      }

      [class^="icon-"],
      [class*=" icon-"],
      .icon {
        display: block;
        float: left;
        padding:0 5px 0 0;
        line-height: 2rem;
        color: @primary-grey;
      }

      .separator,
      .slash {
        padding: 0 5px;
        display: block;
        float: left;
        font-weight: @ax-font-weight-regular;
      }

      .regular-crumb {
        display: block;
        float:left;
        @media (max-width: @screen-lg-min) {
          max-width:165px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .no-link {
        display: block;
        float:left;
        .regular-crumb {
          color:@black;
          text-decoration: none;
        }

        @media (max-width: @screen-lg-min) {
          max-width:300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

