.attachment-card {
  .display-flex();
  .align-items(center);
  font-size: 1.1rem;
  .box-sizing();

  margin-bottom: 1rem;
  max-width: 23rem;
  width: 100%;

  .screen-md-portrait-min({
    max-width: 24%;
    margin-right: 1%;
  });

  &:nth-child(4n) {
    margin-right: 0;
  }
}

.attachment-card__filename {
  .text-ellipsis();

	margin-left: 1rem;
	max-width: 100px;
	overflow: hidden;
	vertical-align: middle;
	white-space: nowrap;
}

.attachment-card__image {
  font-size: 2rem;
	vertical-align: middle;
}

.attachment-card__download-link {
  .display-flex();
  .flex-grow(1);
  .justify-content(flex-end);

  margin-left: 1.8rem;
	text-decoration: none;
  vertical-align: middle;

  &.focus-visible:focus {
    .box-shadow(inset 0px 0px 0.2rem 0.2rem @primary-blue);
  }

  .icon-download {
    color: @primary-blue;
    opacity: 1;
    vertical-align: text-bottom;
  }
}


