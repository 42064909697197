
.path-overview {
  position: relative;
  padding: @ax-spacing-s;

  .ax-breakpoint-m({
    padding: 0;

    & > div {
      margin-bottom: 1rem;
    }
  });

  .read-more__content--container .read-more__preview-gradient {
    .gradient(rgba(@ax-color-app-background,0), @ax-color-app-background);
  }

  .titled-section__region h2 {
    font-weight: @ax-font-weight-medium;
    margin-bottom: @ax-spacing-s;
  }

  .read-more__overflow-content {
    & > div {
      white-space: pre-wrap;
    }
  }

  .program-content {
    margin-top: @ax-spacing-m;
  }
}

.path-overview__sidebar {
  margin-bottom: 1rem;

  .progress-card  {
    margin-bottom: 1rem;

    .progress-text {
      text-transform: uppercase;
      font-size: @ax-font-size-xs;
      font-weight: @ax-font-weight-regular;
      color: @ax-color-grey-80;
      margin-bottom: @ax-spacing-m;

      .ax-breakpoint-m({
        margin-bottom: 4rem;
      })
    }
  }

  .ax-breakpoint-m({
    position: absolute;
    right: 0;
  })
}

.path-overview__title {
  margin-top: 4rem;
  font-size: @ax-font-size-s;
}

.path-overview__intro {
  margin-bottom: 3.2rem;

  .media-preview {
    margin-bottom: @ax-spacing-s;
  }

  .image-wrapper {
    .border-radius(@ax-border-radius-m);
    position: relative;
    overflow: hidden;
    margin-bottom: 4rem;

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (9 / 16) * 100%; // The following enforces the image contained within to be at a 16:9 image ratio.
    }

    > img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      object-fit: cover;
    }
  }

  .path-overview__title {
    margin-bottom: @ax-spacing-xs;
  }
}

.enroll {
  margin-bottom: 1rem;
  position: relative;
}

.enroll__enroll-button {
  width: 100%;
}

