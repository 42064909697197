@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.external-link-wrapper {
  .url-row {
    margin: 0.1em;
  }
  .url-panel {
    background-color: @primary-bg;
    padding: 3em;
    margin: 0;
    word-wrap: break-word;
  }

  .panel-heading {
    h2, .desc {
      word-wrap: break-word;
    }
  }
}
