.game-info-video-wrapper {
  width: auto;

  .ax-breakpoint-m({
    border: 1rem solid @black;
    background: @black;
    text-align: center;
    margin-bottom: @ax-spacing-m;
  });

  .play-wrapper {
    width: 100%;
    margin: 0;
  }
}

.game-action-wrapper {
  margin: @ax-spacing-l 0 0;

  .ax-breakpoint-m({
    border-bottom: .1rem solid @accent-grey;
    margin-bottom: @ax-spacing-l;
    padding-bottom: @ax-spacing-l;
  });

  .game-info-text {
    border-top: .1rem solid @accent-grey;
    padding: @ax-spacing-l 0;

    .ax-breakpoint-m({
      border: none;
      padding: 0;

      .overview,
      .instructions {
        margin: 0;
      }
    });
  }

  .game-challenge-info {
    .border-radius(@ax-border-radius-m);
    background: @primary-bg;
    border: .2rem solid @games-color-main;
    color: @games-color-main;
    font-weight: @ax-font-weight-medium;
    margin-bottom: @ax-spacing-m;
    padding: @ax-spacing-m;
    text-align: left;

    .ax-breakpoint-m({
      background: none;
      border: none;
      font-weight: @ax-font-weight-regular;
      margin: 0;
      padding: 0;
      text-align: center;
    });

    .small-name-logo {
      float: left;
      height: 4rem;
      margin: 0 @ax-spacing-s @ax-spacing-m auto;
      width: 4rem;

      .ax-breakpoint-m({
        font-size: 2.1rem;
        float: none;
        height: 7rem;
        line-height: 7rem;
        margin: 0 auto;
        padding: 0;
        width: 7rem;
      });
    }
  }
}

.game-info-action-buttons {
  text-align: center;

  .ax-breakpoint-m({
    .gradient(@startColor: @primary-bg, @endColor: @accent-grey);
    border: .2rem solid @secondary-grey;
    padding: @ax-spacing-m;
    text-align: center;
    .border-radius(@ax-border-radius-m);
    .box-shadow-black(0, .1rem, .2rem, 0.1);

    .game-challenge-meta {
      text-align: center;
      padding: 0 1rem;
      margin: @ax-spacing-s 0 @ax-spacing-m 0;
      color: @games-color-main;
      font-weight: @ax-font-weight-medium;
      font-size: @topic-font-size;

      &__wager {
        display: block;
      }
    }
  });

  .game-challenge-meta__description {
    margin-bottom: @ax-spacing-s
  }

  .play-now-button {
    margin-bottom: @ax-spacing-l;
  }

  .play-now-button,
  .challenge-play-now-button {
    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-m;
    });
  }
}

.game-title {
  margin-bottom: @ax-spacing-m;

  .ax-breakpoint-m({
    font-size: 2.1rem;
    margin-left: -0.2em;

    .game-info-meta {
      float: left;
    }
  });

  .game-type-icon {
    background-color: @white!important;
  }
}

.icon.game-details,
.game-type-icon {
  background-image:url('@training/assets/images/new-game-select-icons@half.png?_v11');
  background-repeat: no-repeat;
  height: 40px; // px needed for image sprite background
  width: 40px; // px needed for image sprite background
  float:left;
  margin-left: -@ax-spacing-xs;
  margin-right: @ax-spacing-xs;
  .game-icon-position-by-name(50, -5);

  .ax-breakpoint-m({
    vertical-align: middle;
    margin-right: @ax-spacing-s;
    margin-left: 0;
  });
}
