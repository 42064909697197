/* ======== details-item.less START ======== */

.detail-item {
  display: block;
  margin: 0 -@ax-gutter-page;
  padding: 1em @ax-gutter-page;
  border-bottom:1px solid @accent-grey;
  word-break: break-word;

  .ax-breakpoint-m({
    margin: 0;
  });

  .label {
    margin-bottom: 0;
    line-height: 2.6rem;
    font-weight: @ax-font-weight-regular;
  }

  .value {
    display: block;
    color: @primary-grey;
  }

  &.last {
    border-bottom: 0px;
  }

  &__value {
    white-space: pre-line;
  }
}

/* ======== details-item.less END */
