
#disabled-cookies-wrapper {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 1em;
  text-align: center;

  .heading {
    font-size: 2em;
    text-align: center;
    padding-top: 1.5em;
    padding-bottom: 1em;
    font-weight: @ax-font-weight-medium;

    .screen-md-portrait-min({
      padding-top: 3em;
    });
  }

  .message {
    .screen-md-portrait-min({
      margin: 0 auto;
      padding: 1.5em 0;
      width: 500px;
    });

    .text {
      font-weight: @ax-font-weight-medium;
      text-align: center;
    }

    .buttonbar {
      margin-top: 3em;
      margin-bottom: 4em;
    }
  }

  .contact {
    margin-top: 3em;
  }

  .language-selector-wrapper {
    margin-top: 1em;
    width: 100%;
  }
}
