@import '@common/vendor/swiper/swiper-custom.less';

.swiper-container--overflow {
  overflow: initial;
}

.swiper-container {
  padding: @ax-gutter-page;
  margin: -@ax-gutter-page;
}

.carousel-gap-generator(@slideItem, @gap) {
  @unquoted-slideItem: ~'@{slideItem}';
  @unquoted-gap: ~'calc(@{gap} / 2)';

  @{unquoted-slideItem} {
    padding-right: @unquoted-gap;
    padding-left: @unquoted-gap;
  }

  @{unquoted-slideItem}:first-child {
    padding-left: inherit;
  }

  @{unquoted-slideItem}:last-child {
    padding-right: inherit;
  }
}

.swiper-container--gap-std {
  .swiper-slide {
    .box-sizing(border-box);
  }

  .carousel-gap-generator('.swiper-slide', 1.2rem);

  .screen-md-portrait-min({
    .carousel-gap-generator('.swiper-slide', 1.6rem);
  });
}

.button-prev,
.button-next {
  .transform(translate(0, -50%));
  z-index: 2;
  position: absolute;
  top: 50%;
  border: 0;
  background: transparent;
  padding: @ax-spacing-xxs;

  span {
    .box-sizing(border-box);
    .size(4rem);
    .box-shadow-black(0, 1px, 3px);
    .border-radius(50%);
    .opacity(0.95);
    .transition(opacity @transition-speed ease);
    .display-flex();
    .align-items(center);
    .justify-content(center);
    border-style: none;
    background: @white;

    &:before {
      .transition(opacity @transition-speed ease);
      font-size: 2.7rem;
      .opacity(0.7);
    }
  }

  &:hover,
  &:focus {
    span {
      .opacity(1);

      &:before {
        .opacity(1);
      }
    }
  }

  &.disabled span:before {
    .opacity(.2);
    cursor: auto;
    pointer-events: none;
  }
}

.button-prev {
  left: 16px;

  span {
    padding-right: .3rem;
  }
}

.button-next {
  right: 16px;

  span {
    padding-left: .2rem;
  }
}
