.checkboxlist {
  .full-width();
  .border-radius(@ax-border-radius-m);
  height: 30rem;
  border: 0.1rem solid @secondary-grey;
  overflow-y: scroll;
  overflow-x: hidden;

  li {
    border-bottom: 0.1rem solid @secondary-grey;
    padding: @ax-spacing-m;

    .checkbox {
      display: inline;
      width: 1.4rem;
      margin: 0;

      label {
        padding-left: 0;
      }

      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }

    &:nth-child(odd) {
      background-color: @secondary-bg;
    }
  }
}
