@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
.oauth-icon--facebook {
  background-image: url('@common/assets/images/facebook.svg');
}

.oauth-icon--google {
  background-image: url('@common/assets/images/google.svg');
}

.oauth-icon--linkedin {
  background-image: url('@common/assets/images/linkedin.svg');
}

.oauth-icon--salesforce {
  background-image: url('@common/assets/images/salesforce.svg');
}

.oauth-icon--zendesk {
  background-image: url('@common/assets/images/zendesk.svg');
}
