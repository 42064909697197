.game-scores {
  .flex-direction(column);

  .all-games .gameslist .game-wrapper {
    min-width: auto;
  }

  h2 {
    margin: @ax-spacing-m 0;
  }

  .ax-breakpoint-m({
    .flex-direction();

    .challenge-info {
      padding-left: @ax-spacing-l;
    }

    .gameslist{
      margin-bottom: 2em;
    }

    .challenge-stats-region,
    .recent-challenges-region {
      margin-bottom: 1.5em;
    }
  });

  .screen-md-portrait-min({
    // TODO: Remove this when everything uses proper mobile gutters
    padding: 0;
  })
}

.gameboard {

  .ax-breakpoint-m({
    margin-bottom: 4em;
  });

  table {
    width: 100%;
    table-layout: fixed;
  }

  tr {
    border-bottom: .1rem solid @accent-grey;

    &.you {
      font-weight: @ax-font-weight-medium;

      td {
        background-color: @secondary-bg;
      }

      .ax-breakpoint-m({
        font-weight: @ax-font-weight-bold;

        td {
          background-color: @element-hover-bright;
        }
      });
    }

    th.score {width: 20%;}

    .ax-breakpoint-m({
      &.separator td {
        border-top: @ax-spacing-s solid @accent-grey;
      }
    });
  }

  td {
    font-size: inherit!important;
   }
}

.gamescores {
  background-position: -18rem  -9rem;
  white-space: nowrap;
}

.rank {
  width: 4rem;
  padding-right: @ax-spacing-s;
  padding-left: 0;

  .ax-breakpoint-m({
    width: 6rem;
  });

  &.rank.cell-1,
  &.rank.cell-2,
  &.rank.cell-3 {
    text-indent: -999em;
    text-align: center;
    padding-left: .5em;

    span{
      .off-screen();
    }
  }
}

.position-1,
.position-2,
.position-3 {
  .border-radius(@ax-border-radius-m);
  background-image: url('@training/assets/images/game-select-trophies.png');
  background-repeat: no-repeat;
  display: block;
  float: left;
  height: 35px; // PX needed to accomodate background sprite image
  width: 35px;

  .ax-breakpoint-m({
    background-image: url('@training/assets/images/achievements_icons_sprite.png?_v6');
    float: none;
    height: 50px; // PX needed to accomodate background sprite image
    width: 50px;
  });
}

.position-1 {
  background-position: 0 0;

  .ax-breakpoint-m({
    background-position: -50px 0; // PX needed to accomodate background sprite image
  });
}

.position-2 {
  background-position: 0 -35px;

  .ax-breakpoint-m({
    background-position: -100px 0; // PX needed to accomodate background sprite image
  });
}

.position-3 {
  background-position: 0 -70px;

  .ax-breakpoint-m({
    background-position: -150px 0; // PX needed to accomodate background sprite image
  });
}

.game-scores-details {
  &.nooneplayed {
    position:relative;
    height: 15rem;
    line-height:15rem;
    text-align:center;
    font-size: @topic-font-size;
    color:@secondary-text-color;
  }

  &.gameboard .nooneplayed {
    position:relative;
    height:auto;
    line-height:normal;
    text-align:center;
    background:none;
  }

  .scoreinfocontainer {
    .name {
      margin-right: @ax-spacing-s;
    }
  }

  .highscores{
    display: block;
    margin: 0;

    .ax-breakpoint-m({
      position: relative;
      text-align: left;
      margin-bottom: @ax-spacing-l;
      vertical-align: middle;
    });
  }

  .yourhighscore {
    border-left: @ax-spacing-xs solid @training-color;
    text-align: left;
    padding: @ax-spacing-s @ax-spacing-m;
    margin-bottom: @ax-spacing-l;

    .ax-breakpoint-m({
      margin-bottom: 0;
      position: relative;
      width: 33%;
      float: left;
      height: 8rem;
      padding: 0 0 0 @ax-spacing-m;
    });
  }

  .alltimehighscore {
    border-left: @ax-spacing-xs solid @training-color;
    text-align: left;
    padding: @ax-spacing-s @ax-spacing-m;
    margin-bottom: @ax-spacing-l;

    .ax-breakpoint-m({
      position: relative;
      width: 33%;
      float: left;
      height: 8rem;
      margin-bottom: 0;
      padding: 0 0 0 @ax-spacing-m;
    });
  }

  tr {
    border-bottom: .1re, solid @accent-grey;
  }

  td {
    font-size: inherit!important;
  }
}
