// TODO - These will be replaced with new breakpoints defined in ax-breakpoints.less

/* =============================================================================
   Media queries for different screen sizes
   ========================================================================== */

// xs only -- no need for xs-and-larger (just use no size query) or xs-and-smaller (same as xs-only)
.screen-xs(@rules) {
  @media only screen and (max-width: @screen-xs-max) {
    @rules();
  }
}

// sm and larger
.screen-sm-min(@rules) {
  @media only screen and (min-width: @screen-sm-min) {
    @rules();
  }
}

// sm only
.screen-sm(@rules) {
  @media only screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    @rules();
  }
}

// sm and smaller (this used to be just .screen-sm before there was an xs size to account for)
.screen-sm-max(@rules) {
  @media only screen and (max-width: @screen-sm-max) {
    @rules();
  }
}

// md-portrait and larger
.screen-md-portrait-min(@rules) {
  @media only screen and (min-width: @screen-md-portrait-min) {
    @rules();
  }
}

// md-portrait only
.screen-md-portrait(@rules) {
  @media only screen and (min-width: @screen-md-portrait-min) and (max-width: @screen-md-portrait-max) {
    @rules();
  }
}

// md-portrait and smaller
.screen-md-portrait-max(@rules) {
  @media only screen and (max-width: @screen-md-portrait-max) {
    @rules();
  }
}

// md-landscape and larger
.screen-md-landscape-min(@rules) {
  @media only screen and (min-width: @screen-md-landscape-min) {
    @rules();
  }
}

// md-landscape only
.screen-md-landscape(@rules) {
  @media only screen and (min-width: @screen-md-landscape-min) and (max-width: @screen-md-landscape-max) {
    @rules();
  }
}

// md-landscape and smaller
.screen-md-landscape-max(@rules) {
  @media only screen and (max-width: @screen-md-landscape-max) {
    @rules();
  }
}

// lg and larger
.screen-lg-min(@rules) {
  @media only screen and (min-width: @screen-lg-min) {
    @rules();
  }
}

// lg only
.screen-lg(@rules) {
  @media only screen and (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
    @rules();
  }
}

// lg and smaller
.screen-lg-max(@rules) {
  @media only screen and (max-width: @screen-lg-max) {
    @rules();
  }
}

// xl and larger
.screen-xl(@rules) {
  @media only screen and (min-width: @screen-xl-min) {
    @rules();
  }
}

.screen-height-xs-max(@rules) {
  @media only screen and (max-height: @screen-height-xs-max) {
    @rules();
  }
}

.screen-height-xxs-max(@rules) {
  @media only screen and (max-height: @screen-height-xxs-max) {
    @rules();
  }
}

// 1: xs only, 2: sm and larger
.screen(@rules-xs, @rules-sm) {
  .screen-sm-max(@rules-xs);
  .screen-md-portrait-min(@rules-sm);
}

// 1: xs only, 2: sm only, 3: md and larger
.screen(@rules-xs, @rules-sm, @rules-md) {
  .screen-sm-max(@rules-xs);
  .screen-md-portrait(@rules-sm);
  .screen-md-landscape-min(@rules-md);
}

// 1: xs only, 2: sm only, 3: md only, 4: lg and larger
.screen(@rules-xs, @rules-sm, @rules-md, @rules-lg) {
  .screen-sm-max(@rules-xs);
  .screen-md-portrait(@rules-sm);
  .screen-md-landscape(@rules-md);
  .screen-lg-min(@rules-lg);
}

// 1: xs only, 2: sm only, 3: md only, 4: lg only, 5: xl and larger
.screen(@rules-xs, @rules-sm, @rules-md, @rules-lg, @rules-xl) {
  .screen-sm-max(@rules-xs);
  .screen-md-portrait(@rules-sm);
  .screen-md-landscape(@rules-md);
  .screen-lg(@rules-lg);
  .screen-xl(@rules-xl);
}

// Media query for retina styling rules
.retina(@rules) {
  @media (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 124.8dpi) {
    @rules();
  }
}
