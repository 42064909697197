.active-invites {
  margin-bottom: @ax-spacing-l;
}

.active-invite {
  margin: @ax-spacing-s 0;
  position: relative;

  .ax-breakpoint-m({
    margin: @ax-spacing-s 0;
  });

  .active-invite-wrapper{
    .display-flex();
    padding: @ax-spacing-s;

    .ax-breakpoint-m({
      padding: @ax-spacing-m;
    });
  }

  .department-meta {
    color: @ax-color-grey-60;
  }

  .name-logo {
    .flex-shrink(0);
    border: .1rem solid @white;
    margin-right: @ax-spacing-s;

    .ax-breakpoint-m({
      font-size: @topic-font-size;
    });
  }

  .name-details {
    .flex-grow(1);
    float: left;
    margin-top: @ax-spacing-xs;
    overflow: hidden;
  }
}
