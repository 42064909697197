
.ui-widget-overlay {
  background: black;
  z-index: @page-index + 1;
}


.ui-dialog {
  width: 90%!important;
  padding:0;
  font-size: @ax-font-size-body;
  z-index: @page-index + 2;

  .screen-md-portrait-min({
    width: 50%!important;
  });

  .ui-dialog-titlebar {
    background: @primary-bg;
    border:0;
    margin: 0;
    padding: 0.5em;
    .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
  }

  .ui-dialog-titlebar-close {
    display: none;
  }

  .ui-dialog-buttonpane {
    margin: 0;
    background: @primary-bg;
    padding: 1em;

    .ui-dialog-buttonset {
      float: none;
      text-align: center;
      display: flex;
      justify-content: center;

      .ui-button {
        &:nth-child(odd) {
          margin: 0 1em 0 0;
        }

        &:nth-child(even),
        &:last-child {
          margin: 0;
        }

        &.axon-button {
          .ax-button();

          &:focus, &:hover {
            // had to use !important to override the buttonsCommon.html
            color: @_btn-color !important;
          }
          
          .ui-button-text {
            padding: 0;
          }

          &.delete {
            ._destructive-button-variation();
            &:focus {
            // had to use !important to override the buttonsCommon.html 
              color: @_btn-destructive-color !important
            }
            &:hover {
              color: @_btn-destructive-color-hover !important
            }
          }
        }
      }
    }
  }
}
