// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Legacy spacing utils
//  - You should favor applying spacing through standard BEM usage
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Clearfix - slightly enhanced, new micro clearfix hack
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix { display: inline-block; }

// Backslash hack
* html .clearfix { height: 1%; }
.clearfix { display: block; }

// Margins/Padding
.nomargin {
  margin: 0!important;
  &.top {margin-top: 0;}
  &.bottom {margin-bottom: 0;}
  &.left {margin-left: 0;}
  &.right {margin-right: 0;}
}

.no-padding {
  padding: 0!important;
  &.top {padding-top: 0!important;}
  &.bottom {padding-bottom: 0!important;}
  &.left {padding-left: 0!important;}
  &.right {padding-right: 0!important;}
}

.no-spacing {
  .nomargin();
  .no-padding();
  .no-border();
  &.top {
    .nomargin.top();
    .no-padding.top();
    .no-border.top();
  }
  &.bottom {
    .nomargin.bottom();
    .no-padding.bottom();
    .no-border.bottom();
  }
  &.left {
    .nomargin.left();
    .no-padding.left();
    .no-border.left();
  }
  &.right {
    .nomargin.right();
    .no-padding.right();
    .no-border.right();
  }
}

.topmargin {
  margin-top: 1.5em;
}

.margin-center { margin: 0 auto; }

// Flex box
.flex-center {
  .display-flex();
  .align-items(center);
  .justify-content(center);
}

// Floats
.pull-right,
.floatright,
.right {
  float: right;
}

.pull-left,
.floatleft,
.left {
  float: left;
}

.floatcenter {
  float: left;
  display: inline;
  left: 50%;
  position: relative;
}

// Misc
.no-gutter {
  margin-right: 0;
  margin-left: 0;

  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
