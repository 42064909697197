.base-card {
  .border-radius(@ax-border-radius-m);
  .display-flex();
  .flex-direction(column);
  .align-items(stretch);
  .size(100%);

  &__title {
    .flex(1 1 auto);
    .align-self(center);
    // Fixes issue with IE11 where flex-basis: auto isn't calculated properly and the title pushes the view all button out of the card.
    -ms-overflow-x: hidden;
  }

  &__view-all {
    margin-left: @ax-spacing-m;

    button {
      .ax-button__label {
        display: none;
      }

      .view-all__icon {
        padding-left: 0;
      }

      .ax-breakpoint-m({
        .ax-button__label {
          display: inline-block;
        }

        .view-all__icon {
          display: none;
        }
      })
    }
  }

  &__wrapper {
    padding: @ax-spacing-m;
    .display-flex();
    .justify-content(space-between);
    .border-radius(@ax-border-radius-m);

    &--upper {
      .flex(0 0 auto);
      &.clickable.focus-visible:focus {
        .ax-focus-keyboard();
      }
      &.clickable:hover {
        background-color: @subtle-hover;
      }

      .basic-header-title,
      .subtext-header-title,
      .pretext-header-title {
        p {
          margin: 0;
        }
      }

      .icon--badge.icon-training::before {
        color: @ax-color-white;
      }
    }

    &--lower {
      padding-top: 0;
      .flex(1 0 auto);

      .base-card__wrapper--upper.clickable + &,
      .base-card.divided & {
        border-top: 1px solid @accent-grey;
        padding-top: 1.6rem;
      }

      .base-card.not-divided .base-card__wrapper--upper.clickable + & {
        border-top: none;
        padding-top: 0;
      }

      .title-header__badge-region .icon--badge {
        margin-top: @ax-spacing-xs;
        margin-right: @ax-spacing-m;
      }

      .basic-header-title .h1,
      .subtext-header-title .h1,
      .pretext-header-title .h1 {
        font-size: 1.3rem;
        line-height: 2.1rem;
      }

      .base-card--bottom-empty & {
        display: none;
      }
    }
  }

  // Progress bars within session cards
  &__progress {
    .full-width();
    .display-flex();
    margin-top: @ax-spacing-m;
    .justify-content(space-between);
    .flex-direction(column-reverse);
    .screen-md-portrait-min({
      .flex-direction(row);
      margin-top: 0;
    });

    .progress-bar-region {
      .full-width;
      .flex(1 1 auto);
      .flex-direction(column-reverse);
    }

    .progress-message {
      .flex(0 0 auto);

      .screen-md-portrait-min({
        margin: 0 0 0 @ax-spacing-m;
      });
    }

    .progress-bar .progress-bar-inset {
      background-color: @primary-blue;
    }
  }
}
