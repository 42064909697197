// Report card main list, drilldown page

@import "knowledge-item-list.less";
@import "knowledge-item-questions.less";

.knowledgeitem {
  .ax-breakpoint-m({
    padding: @ax-spacing-l @ax-spacing-s;
  });

  &:hover,
  &:focus {
    .transition(all @ax-transition-speed);
    cursor: pointer;
  }

  .droparrow {
    display: none;

    .ax-breakpoint-m({
      .button-reset-style();
      color: @ax-color-grey-60;
      display: block;
      float: left;
      height: 100%;
      line-height: inherit;
      margin: @ax-spacing-s 0 @ax-spacing-s @ax-spacing-xs;
      width: @ax-spacing-m;

      &.focus-visible:focus {
        outline: auto @ax-spacing-xs @primary-blue;
      }
    });

    &.selected {
      background: url("@training/assets/images/reportcard_arrow.gif?_v12") bottom left no-repeat;
      height: 16px;
      left: 0;
      position: absolute;
      top: 5.3rem;
      width: 16px;

      .ax-breakpoint-m({
        padding: 2.5em 0.5em;
      });
    }

    &.expanded {
      background-position: bottom left;

      .ax-breakpoint-m({
        margin: @ax-spacing-xs @ax-spacing-xs @ax-spacing-s 0;
      });
    }
  }

  .score  {
    .border-radius(50%);
    height: 1.6rem;
    line-height: 1.6rem;
    width: 1.6rem;
  }

  .totalanswered {
    font-size: 1.1rem;
    height: 7rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 7rem;

    .answercount {
      font-size: 2.3rem;
      line-height: 2rem;
      padding-top: 1.8rem;
    }
  }

  .trainingoptions {
    position: absolute;
    right: 4.5rem;
    text-align: right;
    top: @ax-spacing-l;

    .axon-button {
      padding: 0 0.5em;
      width: auto;
    }
  }

  .certification-status {
    position: absolute;
    right: 1.2rem;
    top: @ax-spacing-xl;
    .flex-center();

    .ax-breakpoint-m({
      top: 3rem;
    });

    .icon {
      background-color: transparent;
      height: 24px;
      margin-right: 0;
      margin-top: -0.5rem;
      width: 24px;
    }
  }

  .extratrainingbuttons, .preview-module {
    display: none;

    .ax-breakpoint-m({
      display: block;
    });
  }

  .status {
    .border-radius(3rem);
    background: url('@common/assets/images/graduated.gif') center center no-repeat @white;
    display: none;
    float:left;
    height: 30px;
    margin-left: 1.2rem;
    position: relative;
    text-align: center;
    width: 30px;

    .ax-media-query-retina({
      background-image: url('@common/assets/images/graduated@2x.gif');
      background-size: 35px;
    });

    img {
      width: 100%;
    }
  }

  // Answer history
  .questionswrap .answerblock {
    display: block;
    margin-left: 0.75em;
    padding: 1em 0.5em;
    word-wrap: break-word;

    .answers-wrapper {
      .questionblock {
        border: 0;
        margin: 0.5em 0.5em 0 0;
        padding: 0;

        &:active {
          border: .1rem solid @black;
          height: 1.2rem;
          width: 1.2rem;
        }
      }

      button {
        .border-radius(50%);
        .button-reset-style();
        float: left;
        height: 1.3rem;
        margin-bottom: 0.5em;
        margin-right: 0.5em;
        margin-top: 0.5em;
        width: 1.3rem;

        &.correctanswer {
          background-color: @green;
          padding-left: 0.3em;
        }

        &.incorrectanswer {
          background-color: @red;
          padding-left: 0.3em;
        }
      }
    }
  }
}

.topicholder {
  padding: @ax-spacing-m;

  .ax-breakpoint-m({
    margin-right: 20rem;
    padding: 0;
  });

  .percentage {
    display: block;
    float: left;
    min-height: 7.5rem;
    padding-right: @ax-spacing-xs;
    text-align: left;
    width: 4rem;
    word-wrap: break-word;

    .ax-breakpoint-m({
      font-size: @topic-font-size;
      margin: 0 0.5em 0 0.2em;
      min-height: 0;
      padding: 0.4em 0 0 0;
      text-align: center;
      vertical-align: middle;
      width: 4.5rem;
    });
  }

  .areas {
    display: block;
    margin-left: @ax-spacing-xl * 2.75;
    margin-right: @ax-spacing-xl;
    overflow: auto;

    .ax-breakpoint-m({
      margin-left: 4.7em;
    });

    &.subscontainer {
      font-size: @ax-font-size-s;
      font-weight: @ax-font-weight-regular;
      margin-bottom: 0;

      .subs {
        color: @secondary-text-color;
        display: inline;
      }

      .topicname {
        margin: 0;
        display: block;
      }
    }
  }

  .beginner,
  .expert,
  .graduate,
  .graduated,
  .intermediate,
  .needs-coaching,
  .not-started,
  .overconfident,
  .recommended,
  .star,
  .underconfident {
    display: block;
    float: left;
    font-size: @ax-font-size-m;
    line-height: @ax-font-size-m;
    min-height: @ax-spacing-l;
    text-align: center;
    width: @ax-spacing-l;
    padding-right: @ax-spacing-xs;

    .ax-breakpoint-m({
      height: @ax-spacing-xl;
      line-height: @ax-spacing-xl;
      margin: 0 @ax-spacing-s 0 0;
      width: @ax-spacing-xl;
    });
  }

  .graduated, .graduate {
    color: @primary-blue;
  }

  .expert {
    background-size: 20px;
  }

  .recommended {
    color: @yellow;
    font-size: @ax-font-size-xl;
    margin: 0.5em 0.8em;
  }

  .row-arrow {
    .row-chevron(.75em, .5em, @ax-spacing-m, @ax-spacing-s);
    // TODO: Remove extra positioning when row-chevron uses new breakpoints
    top: @ax-spacing-m;
    right: @ax-spacing-s;

    &.focus-visible:focus {
      outline: auto @ax-spacing-xs @primary-blue;
    }

    .ax-breakpoint-m({
      display: none;
    });
  }
}

.questionblock {
  cursor: pointer;
  display: block;
  line-height: 3.2em;
  margin-left: -@ax-spacing-s;
  padding-left: 2.3em;

  .ax-breakpoint-m({
    padding-left: 2.3em;
    margin-bottom: 0.7em;
  });

  &.correct {
    background: url('@training/assets/images/question_correct_check_mobile.gif') left center no-repeat @white;

    .ax-media-query-retina({
      background-image: url('@training/assets/images/question_correct_check_mobile@2x.gif');
      background-size: 24px;
    });
  }

  &.incorrect {
    background: url('@training/assets/images/question_wrong_x_mobile.gif') left center no-repeat @white;

    .ax-media-query-retina({
      background-image: url('@training/assets/images/question_wrong_x_mobile@2x.gif');
      background-size: 24px;
    });
  }
}
