@menu-item-padding: @ax-spacing-l;

.menu-item {
  .display-flex();
  .align-items(center);
  .box-sizing(border-box);
  color: @white;
  height: 100%;
  margin-right: @ax-spacing-m;
  padding: 0 @menu-item-padding;
  position: relative;

  &.focus-visible:focus,
  &.active,
  &.hover {
    background-color:rgba(255, 255, 255, 0.2);
  }

  &.focus-visible:focus {
    .ax-focus-keyboard(inset);
  }

  &:last-child {
    margin-right: 0;
  }

  .item-icon {
    font-size: 2.4rem;
    color: @white;
    margin-right: @ax-spacing-s;
    opacity: 1;
    min-width: 2.4rem; // helps ensure width of menu-item is calculated correctedly for overflow/more dropdown
  }

  .item-text {
    font-size: 1.5rem;
    white-space: nowrap;
    line-height: normal;
  }

  .notification {
    position: relative;
    margin-left: @ax-spacing-xs;
    margin-bottom: @ax-spacing-s
  }

  &.selected {
    border: solid 0.2rem transparent;

    .item-selected-bar {
      background-color: @white;
      bottom: 1rem;
      height: .2rem;
      left: @menu-item-padding;
      opacity: 0.9;
      position: absolute;
      width: calc(100% - 4.5rem);
    }

    .item-text {
      color: @white;
    }
  }
}
