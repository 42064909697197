.base-card--achievements-skeleton {
  .achievements-action {
    width: @ax-spacing-l;

    .ax-breakpoint-m ({
      width: 15rem;
    });
  }

  .achievements__row-region {
    .achievements__row {
      display: flex;

      &--title {
        margin-bottom: @ax-spacing-xs;
      }
    }

    &:not(:last-child) {
      margin-bottom: @ax-spacing-s;
    }
  }
}
