.ax-markdown {
  a {
    text-decoration: underline;
  }

  strong {
    font-weight: @ax-font-weight-bold;
  }

  ol li {
    list-style: decimal;
  }

  ul li {
    list-style-type: disc;
    margin: 0 0 0 @ax-spacing-s;
    padding: 0 @ax-spacing-s;
    list-style: initial;
  }

  ul, ol {
    padding: 0;
    margin: 0 0 0 @ax-spacing-m;

    li {
      padding-left: 0;
      padding-right: 0;
      margin-right: 0;
      margin-left: @ax-spacing-s;
    }
  }

  p, ul, ol {
    margin-bottom: @ax-spacing-m;
  }

  p {
    white-space: pre-wrap;
  }

  hr {
    margin: @ax-spacing-m 0;
  }

  blockquote, code {
    display: block;
    border-left: 2px solid @ax-color-blue-80;
    margin: 0;
    padding: 0 @ax-spacing-s;
    white-space: normal;
  }

  table {
    th, td {
      border: 1px solid @ax-color-grey-30;
      padding: 1rem 0.5rem;
    }
  }
}
