@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

@_padding-password-button: @ax-spacing-xs;
@_size-password-button: @button-icon-size + (@_padding-password-button * 2);

[data-editor='Password'] {
  padding: 0;
  position: relative;

  .eye-icon-button {
    .border-radius(0 @ax-border-radius-m @ax-border-radius-m 0);
    background-color: transparent;
    border: .1rem solid transparent;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: @_size-password-button;

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }
  }

  .ax-input .icon-eye_open,
  .ax-input .icon-eye_close {
    .box-shadow(none);
    background-color: transparent;
    color: @ax-color-grey-60;
    display: inline-block;
    font-size: @button-icon-size;
    line-height: inherit;
    text-align: center;

    &:focus,
    &:hover {
      .clickable();
      color: @ax-color-grey-80;
    }
  }
}
