.ax-form-input-base-styles() {
  // Also used for legacy input containers, styled to look like inputs
  .border-radius(@_input-radius);
  .box-sizing(border-box);
  font-size: @_input-font-size;
  background: @ax-color-white;
  border: @_input-width-border solid @_input-color-border;
  color: @_input-color-font;
  font-weight: @_input-font-weight;
  line-height: @_input-line-height;
  max-width: 100%;
  min-height: @_input-size-icon-button-m;
  padding: @_input-padding-y-m @_input-padding-y-l;

  &.focus-visible:focus {
    .ax-focus-input();
  }

  &:disabled,
  &:disabled:hover {
    background: @ax-color-grey-20;
    border-color: @ax-color-grey-30;
    cursor: not-allowed;
  }

  &:-moz-focusring {
    // Hide dotted outline from Firefox
    outline: 0;
  }
}

.ax-form-input-container-modifiers() {
  position: relative;

  input,
  textarea,
  select {
    min-width: @_input-width-min;
  }

  label {
    color: @ax-color-text-secondary;
    display: block;
    margin-bottom: @ax-spacing-xs;

    &.label--required:after {
      color: @ax-color-red-60;
      content: '*';
      display: inline-block;
    }
  }

  &&--error {
    input,
    textarea,
    select {
      box-shadow: 0 0 0 .1rem @ax-color-red-60;
      border-color: @ax-color-red-60;

      &.focus-visible:focus {
        .ax-focus-input();
      }
    }
  }

  &&--l {
    input,
    textarea,
    select {
      min-height: @_input-size-icon-button-l;
      padding-bottom: @_input-padding-y-l;
      padding-top: @_input-padding-y-l;
    }
  }

  &&--full-width {
    width: 100%;

    input,
    textarea,
    select,
    .ax-select__container {
      min-width: 0;
      width: 100%;
    }
  }
}
