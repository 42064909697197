.page-title {
  background: #f0f0f0;

  .page-title-inner-container {
    .display-flex();
    .justify-content(space-between);
  }

  h1 {
    padding-left: 0;
    padding-right: 0;
  }
}
