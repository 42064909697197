.progress-bar {
  height: @ax-progressBar-height;
  width: 100%;
  position: relative;
  background-color: @ax-progressBar-background-color;
  .border-radius(@ax-progressBar-border-radius);
  margin-top: @ax-spacing-s;
  border: @ax-progressBar-border;

  .progress-bar-inset {
    background-color: @ax-progressBar-inset-background-color;
    height: 100%;
    width: 0;
    .border-radius(@ax-border-radius-m);
  }
}
