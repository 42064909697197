@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
/* CSS for Training Desktop App */
@import "app-common.less";

// Components
@import "partials/achievements.less";
@import "partials/articles-topics-layout.less";
@import "partials/challenge-list.less";
@import "partials/common.less";
@import "partials/feed-icons.less";
@import "partials/footer.less";
@import "partials/form-inputs.less";
@import "partials/form-upload-avatar.less";
@import "partials/form.less";
@import "partials/highlights.less";
@import "partials/knowledge-item.less";
@import "partials/multi-column-list.less";
@import "partials/navigation-tabs.less";
@import "partials/page-header.less";
@import "partials/report-card.less";
@import "partials/selection-language.less";

// Pages
@import "dzview.less";
@import "matching-questions.less";
@import "pages/events/events.less";
@import "pages/games/games.less";
@import "pages/rewards/auction.less";
@import "pages/rewards/slot-machine.less";
@import "pages/search/search.less";
@import "pages/tell-a-friend/tell-a-friend.less";
@import "pages/top-performers.less";
@import "pages/user-login.less";
@import "pages/user-profile.less";
@import "pages/user-registration.less";
@import "tasks.less";

html[dir=rtl] {
  unicode-bidi: embed;
}
@import "@common/vendor/icomoon/icomoonCustom.less";

// Special Froala Classes
@import "froala-custom.less";
