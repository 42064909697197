.loading-row {
  border: 0;
  min-height: 1.6rem;
  padding: 0.8em 0.5em;
  .display-flex();
  .align-items(center);
  .justify-content(center);
}

.loading-row .loading-spinner {
  width: 16px;
  height: 16px;
  margin-right: auto;
  margin-left: auto;
  background-image: url('@common/assets/images/small-loader.gif');
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center center;

  &.spinner--loading-new-white {
    width: 32px;
    height: 32px;
    background-image: url('@common/assets/images/loading_new_white.gif');
  }
}
