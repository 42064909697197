@ax-avatar-size-xs: 2rem;
@ax-avatar-size-sm: 2.4rem;
@ax-avatar-size-md: 3.2rem;
@ax-avatar-size-lg: 4.8rem;
@ax-avatar-size-xl: 7.2rem;

:root {
  --ax-avatar-size-xs: @ax-avatar-size-xs;
  --ax-avatar-size-sm: @ax-avatar-size-sm;
  --ax-avatar-size-md: @ax-avatar-size-md;
  --ax-avatar-size-lg: @ax-avatar-size-lg;
  --ax-avatar-size-xl: @ax-avatar-size-xl;
}