@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.search-box {
  .search-box__wrap {
    margin: 0;
    position: relative;
    width: 100%;
  }

  .ax-input .search-box__close {
    display: none;
  }

  &.loading .search-box__wrap .search-icon {
    background: url('@common/assets/images/small-loader.gif') center center no-repeat transparent;
  }

  &.search-box--active .ax-input .search-box__close {
    display: block;
  }
}
