.events__row {
  &--wrapper {
    .display-flex();
    .align-items(center);
  }

  &:not(:last-child) {
    margin-bottom: @ax-spacing-m;
  }

  .event__column {
    .display-flex();
    .flex-direction(column);
  }

  .date {
    .flex-shrink(0);
    .flex-basis(@ax-spacing-xl + @ax-spacing-l); // Match the width of the leaderboard first row
    text-align: center;
    margin-right: @ax-spacing-m;
    margin-bottom: 0;
  }

  .info {
    .display-flex();
    .flex(1 1 0px);
    min-width: 0;

    .event-name {
      .text-ellipsis();
    }

    .event-status {

      &.color-green {
        color: @ax-color-green-60;
      }

      &.color-red {
        color: @ax-color-red-60;
      }
    }
  }

  .no-rows {
    color: @secondary-text-color;
  }
}
