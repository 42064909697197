// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//   FORM INPUT EXAMPLE
//   <div class="ax-input">
//     <label for="input-1">Input demo label</label>
//     <input type="text" id="input-1"/>
//   </div>

//   FORM INPUT WITH ICON BUTTON
//   <div class="ax-input ax-input--icon-right">
//     <label for="input-icon-1">Input with icon button demo label</label>
//     <input type="text" id="input-icon-1"/>
//     <button class="icon-remove_circle" type="button"/>
//   </div>

//   FORM INPUT WITH NON-FUNCTIONAL ICON
//   <div class="ax-input ax-input--icon-left">
//     <span class="icon-search" aria-hidden="true" role="presentation"></span>
//     <label for="input-icon-1">Input with non-functional icon demo label</label>
//     <input type="text" id="input-icon-1"/>
//   </div>

//   FORM TEXTAREA EXAMPLE
//   <div class="ax-input">
//     <label for="textarea-1">Textarea demo label</label>
//     <textarea id="textarea-1"/>
//   </div>

//  MODIFIERS
//    - .ax-input--[m/l] - Modify input height (medium is default)
//    - .ax-input--error - Add error styling to input
//    - .ax-input--icon-left - Add input styling to accomodate a non-functional icon on the left (ie. search)
//    - .ax-input--icon-right - Add input styling to accomodate icon button on the right (ie. clear input)
//    - .ax-input--required - Add required * after the label
//    - .ax-input--full-width - Make container 100% width

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@_input-color-background: @ax-color-white;
@_input-color-border: @ax-color-grey-30;
@_input-color-font: @ax-color-grey-80;
@_input-color-placeholder: @ax-color-grey-60;
@_input-line-height: @ax-line-height-s;
@_input-padding-y-l: @ax-spacing-s - @_input-width-border;
@_input-padding-y-m: @ax-spacing-xs - @_input-width-border;
@_input-radius: @ax-border-radius-m;
@_input-size-icon-button-l: @_input-line-height + ((@_input-width-border + @_input-padding-y-l) * 2);
@_input-size-icon-button-m: @_input-line-height + ((@_input-width-border + @_input-padding-y-m) * 2); //min-height
@_input-width-border: .1rem;
@_input-width-min: 26.4rem;
@_input-disabled-color-background: @ax-color-grey-20;
@_input-disabled-color-border: @ax-color-grey-30;
@_input-disabled-font-color: @ax-color-grey-50;
@_input-font-size: @ax-font-size-s;
@_input-font-weight: @ax-font-weight-regular;

:root {
  --axInput-color-background: @_input-color-background;
  --axInput-color-border: @_input-color-border;
  --axInput-color-font: @_input-color-font;
  --axInput-color-placeholder: @_input-color-placeholder;
  --axInput-line-height: @_input-line-height;
  --axInput-padding-y-l: @_input-padding-y-l;
  --axInput-padding-y-m: @_input-padding-y-m;
  --axInput-radius: @_input-radius;
  --axInput-width-border: @_input-width-border;
  --axInput-width-min: @_input-width-min;
  --axInput-disabled-color-background: @_input-disabled-color-background;
  --axInput-disabled-color-border: @_input-disabled-color-border;
  --axInput-disabled-font-color: @_input-disabled-font-color;
  --axInput-font-size: @_input-font-size;
  --axInput-font-weight: @_input-font-weight;
}

input,
textarea {
  .ax-form-input-base-styles();

  &::-ms-clear,
  &::-ms-reveal {
    // Hide clear 'x' from IE11
    display: none;
    height: 0;
    width : 0;
  }

  &[type="search"] {
    .text-ellipsis();
    // Prevent iOS rounded corners for search input
    -webkit-appearance: none;
  }
}

.ax-input {
  .ax-form-input-container-modifiers();

  &&--icon-right,
  &&--icon-left {
    button,
    span {
      .box-sizing(border-box);
      border: @_input-width-border solid transparent;
      color: @ax-color-grey-80;
      display: inline-block;
      font-size: @ax-font-size-xl;
      position: absolute;
      text-align: center;
      z-index: 1;
    }

    span {
      .border-radius(0 @_input-radius @_input-radius 0);
      .size(@_input-size-icon-button-m);
      bottom: 0;
      left: @_input-width-border;
      margin-left: @_input-padding-y-m;
      margin-right: @_input-padding-y-m;
      padding-bottom: @_input-padding-y-m;
      padding-top: @_input-padding-y-m;
      pointer-events: none;
    }

    button {
      .border-radius(@_input-radius);
      bottom: @_input-padding-y-m;
      cursor: pointer;
      right: @_input-padding-y-m;

      &.focus-visible:focus {
        .ax-focus-mouse();
      }

      &:hover {
        background: @ax-color-grey-20;
      }
    }

    input[style*='background-image: url("data:image/png'] {
      // If using lastpass helpers, reposition button to avoid overlap
      padding-right: @_input-size-icon-button-m * 2;

      + button {
        .border-radius(0);
        right: @_input-size-icon-button-m;
      }
    }

    &.ax-input--l {
      span {
        .size(@_input-size-icon-button-l);
        padding-bottom: @_input-padding-y-l;
        padding-top: @_input-padding-y-l;
      }

      button {
        padding: @_input-padding-y-m;
        bottom: @_input-padding-y-m + @_input-width-border;
      }

      input[style*='background-image: url("data:image/png'] {
        // If using lastpass helpers, reposition button to avoid overlap
        padding-right: @_input-size-icon-button-l * 2;

        + button {
          right: @_input-size-icon-button-l;
        }
      }
    }
  }

  &&--icon-right {
    input,
    textarea {
      padding-right: @_input-size-icon-button-m;
    }

    &.ax-input--l {
      input {
        padding-right: @_input-size-icon-button-l;
      }
    }
  }

  &&--icon-left {
    input,
    textarea {
      padding-left: @_input-size-icon-button-m + (@_input-padding-y-m * 2);
    }

    &.ax-input--l {
      input {
        padding-left: @_input-size-icon-button-l + (@_input-padding-y-m * 2);
      }
    }
  }
}
