/* rtl:begin:ignore */

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//      EXAMPLE - Interchangeable elements
//      <h1>Heading - Extra Large</h1> -OR- <div class="ax-font__xl">Heading - Extra Large</div>
//      <h2>Heading - Large</h2>       -OR- <div class="ax-font__l">Heading - Large</div>
//      <h3>Heading - Medium</h3>      -OR- <div class="ax-font__m">Heading - Medium</div>
//      <h4>Heading - Small</h4>       -OR- <div class="ax-font__s">Heading - Small</div>
//      N/A                                 <div class="ax-font__display">Display</div>
//      <p>Paragraph</p>               -OR- <div class="ax-font__p">Paragraph</div>
//      <label>Label</labe>            -OR- <div class="ax-font__label">Label</div>
//      N/A                                 <div class="ax-font__caption [optional: ax-font__caption--bold]">Caption</div>
//
//      FONT MODIFIERS
//      - .ax-font--no-margin - Remove any set margins
//      - .ax-font--secondary - Lighter grey font for supporting/secondary text
//      - .ax-font--inherit-color - Override applied color and revert to inheritting color from parent

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@_ax-font-size-h1:                     @ax-font-size-xl;
@_ax-font-size-h2:                     @ax-font-size-l;
@_ax-font-size-h3:                     @ax-font-size-m;
@_ax-font-size-h4:                     @ax-font-size-s;
@_ax-font-size-display:                @ax-font-size-m;
@_ax-font-size-p:                      @ax-font-size-s;
@_ax-font-size-label:                  @ax-font-size-xs;
@_ax-font-size-caption:                @ax-font-size-xs;

@_ax-line-height-h1:                   @ax-line-height-xl;
@_ax-line-height-h2:                   @ax-line-height-l;
@_ax-line-height-h3:                   @ax-line-height-m;
@_ax-line-height-h4:                   @ax-line-height-s;
@_ax-line-height-display:              2.6rem;
@_ax-line-height-p:                    @ax-line-height-s;
@_ax-line-height-label:                @ax-line-height-s;
@_ax-line-height-caption:              @ax-line-height-xs;


h1,
.ax-font__xl {
  font-size: @_ax-font-size-h1;
  font-weight: @ax-font-weight-regular;
  margin: 0 0 @ax-spacing-l;
}

h2,
.ax-font__l {
  font-size: @_ax-font-size-h2;
  font-weight: @ax-font-weight-medium;
  margin: 0 0 @ax-spacing-m;
}

h3,
.ax-font__m {
  font-size: @_ax-font-size-h3;
  font-weight: @ax-font-weight-bold;
  margin: 0 0 @ax-spacing-m;
}

h4,
.ax-font__s {
  font-size: @_ax-font-size-h4;
  font-weight: @ax-font-weight-bold;
  margin: 0 0 @ax-spacing-s;
}

p,
.ax-font__p {
  margin: 0 0 @ax-spacing-l;
}

label,
.ax-font__label {
  display: inline-block;
  font-size: @_ax-font-size-label;
  font-weight: @ax-font-weight-medium;
  margin-bottom: @ax-spacing-xs;
}
[class^='Mui'],
[class*=' Mui'] {
  label {
    // Unset above label styles for any Mui component (causes svg's to be incorrect size)
    font-size: inherit;
  }
}

.ax-font__display {
  font-size: @_ax-font-size-display;
  line-height: @_ax-line-height-display;
  margin-bottom: @ax-spacing-l;
}

.ax-font__caption {
  font-size: @_ax-font-size-caption;
  font-weight: @ax-font-weight-regular;
  line-height: @_ax-line-height-caption;

  &&--bold {
    font-weight: @ax-font-weight-medium;
  }
}

.ax-font {
  &--no-margin {
    margin: 0;
  }

  &--secondary {
    color: @ax-color-text-secondary;
  }

  &--inherit-color {
    color: inherit;
  }

  &--bold {
    font-weight: @ax-font-weight-bold;
  }
}

/* rtl:end:ignore */
