.edit-layout {
  .search-by-tag-title {
    line-height: 3.5rem;
    margin: .7rem @ax-spacing-m 0 0;
    float: left;
  }

  .ax-container {
    max-width: none;
  }
  .row-content {
    padding: 0;

    &.scroll {
      overflow: scroll;
    }
  }

  #title-container {
    label {
      display: none;
    }
  }

  .title-container__button-container {
    &--right {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  .title-header {
    padding: 1.5rem;
  }

  .more-container {
    display: block;

    .page-header__action-container {
      background-color: transparent;
      border: none;
      padding: 0 0 0 1rem;

      .icon-ellipsis_horizontal {
        background-color: @white;
        color: @secondary-text-color;
        padding: 0.3rem;
        margin: 0;
      }
    }

    .ax-breakpoint-m({
      display: none;
    })
  }

  .knowledge-item-metadata-view {
    border-left: 1px solid #ddd;
  }

  #content {
    background-color: @white;
    position: relative;
    float: left;

    .character-count .content {
      left: .5em;
    }

    #squire_editor.page-content-view #page-container .page-module {
      &.scorm-module,
      &.tincan-module {
        .flex-grow(1);
        height: 100%;
        .display-flex();
        .flex-direction(column);
      }
    }

    #squire_editor #page-container {
      overflow: scroll;
    }

    #squire_editor.page-content-view #page-container .page-module.scorm-module iframe,
    #squire_editor.page-content-view #page-container .page-module.tincan-module iframe {
      border: 0;
      height: 100%;
      width: 100%;
    }

    #squire_editor.page-content-view {
      .flex-grow(1);
      margin-bottom: 1rem;
      padding-top: 1.5em;
      word-wrap: break-word;
      .page-content {
        padding: 0;
      }
    }

    #squire_editor.page-content-view.training-module {
      padding-top: 0;
    }

    .squire-editor:not(.training-module) {
      padding: 1em @ax-gutter-page;
      font-family: inherit!important;
      font-size: inherit!important;
      color: #595959!important;
      ul {
        li {
          list-style-type: disc;
        }
      }
      ul, ol {
        padding: 0;
        margin: 0 0 0 .8em;
        li {
          padding-left: 0;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }

    .page-content-notifications {
      padding: @ax-spacing-m;
    }
  }

  #metadata {
    position: absolute;
    left: 100%;
    width: 300px;
    background-color: @ax-color-app-background;
    z-index: 10;
  }

  #meta-data-container {
    // There is some vendor property that overrides this unless !important is included
    // The IOS app breaks the duet-date-picker modal in Article Edit page.
    -webkit-overflow-scrolling: auto !important;

    float: right;
    width: 300px;
    height: 100%;
    padding: @ax-spacing-l 0;

    .box-sizing(border-box);

    .edit-notes, .expiry-notes {
      height: 10rem;
      .full-width();
      resize: none;
    }

    .comment-value {
      overflow-wrap: break-word;
      word-wrap: break-word;
      .full-width();
      color: @black;
    }

    #comments_region {
      textarea:focus,
      textarea:hover,
      textarea.pressed {
        .focus();
      }
    }

    #document-form {
      #attachment-header {
        padding-left: 0;
        padding-bottom: 0;
      }

      .documents-container {
        .media-button{
          a {
            float: left;
            width: auto;
            padding-left: 2em;
            padding-right: 2em;
          }
        }
      }
    }

    .language_region {
      .ax-select__container {
        display: block;
      }

      #language-selector {
        .full-width();
        min-width: 0;
      }
    }

    .reference-form input,
    .edit-notes,
    .expiry-notes,
    .tag-select-container .select2-selection,
    .publish-date-controls .date,
    .publish-date-controls .chosen-single {
      background-color: @ax-color-white;
    }

    .metadata-value {
      color: @secondary-text-color;
    }

    .metadata__input {
      position: relative;
      padding: 0 @ax-spacing-m @ax-spacing-l;

      h3, label {
        font-weight: @ax-font-weight-bold;
        margin-bottom: @ax-spacing-xs;
      }

      label {
        font-size: @ax-font-size-m;
      }

      .metadata__recommendations--text-subtitle {
        margin-top: @ax-spacing-l;
      }

      .metadata__recommendations--text-detail {
        margin-top: @ax-spacing-s;
      }

      .scheduled-date-select, .expiry-date-select {
        margin-bottom: @ax-spacing-m;

        .ax-checkbox {
          margin-top: @ax-spacing-s;
        }

        label {
          font-size: @ax-font-size-s;
        }
      }

      .scheduled-date-controls, .expiry-date-controls {

        .scheduled-date-controls__control, .expiry-date-controls__control {
          margin-bottom: @ax-spacing-m;

          label {
            font-size: @ax-font-size-s;
          }
        }

        .icon-calendar_empty {
          display: table-cell;
        }
        .date-input-container {
          display: table-cell;
          .full-width();
          input {
            .full-width();
          }
        }
        .datetime-popdown {
          right: 0 !important;
        }
      }

      .filterable-community {
        margin: 0;
      }
    }

    .metadata__input.hide {
      display: none;
    }

    .date-picker-with-time-zone-display {
      position: relative;
    }

    .date-pick-wrap {
      display: block;
      background-color: transparent;
      margin: 0;
      .border-radius(@ax-border-radius-m);
      position: relative;
      margin-right: auto;

      .display-flex();
      .flex-direction(row);

      .icon-calendar_empty {
        display: inline-block;
        background-color: @primary-bg;
        text-align: center;
        .border-radius(@ax-border-radius-m 0 0 @ax-border-radius-m);
        padding: 0 0.3em;
        border: 1px solid @secondary-grey;
        border-right: 0;
        vertical-align: top;
        height: 32px;
        line-height: 3.2rem;
      }

      input {
        margin: 0;
        width: 100%;
        line-height: 3.4rem;
        height: 34px;
        .border-radius(0 @ax-border-radius-m @ax-border-radius-m 0);
      }
      .duet-date__input,
      .time-picker {
        .border-radius(@ax-border-radius-m);
        margin-top: 0.2rem;
      }

      .popdown {
        .time-select {
          width: auto;
        }
        .ui-datepicker {
          width: 100%;
        }

        .ui-datepicker-title {
          .display-flex();

          select {
            .flex(1 1 auto);
            width: auto;
            margin: 0 @ax-spacing-xs 0 0;
          }
        }
      }
    }
  }

  .media-input-wrap {
    opacity: 0.01;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 1px;
    width: 0px;
    overflow: hidden;
  }

  .btn-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .screen-md-portrait({
    body {
      padding: 0;
    }

    .squire-ui {
      .menu {
        text-align: center;
        padding-top: 1.2rem;
        padding-bottom: 0.6rem;
        .group {
          .item {
            padding: 0 0.2rem;
          }
          margin: 0;
        }
      }

      #title-container {
        text-align: center;
        background: @primary-bg;

        input {
          display: inline-block;
          margin: 0.3em auto;
          font-size: 2rem;
          width: 95%;
          .box-sizing(border-box);
        }
      }

      .btn-group {
        position: absolute;
        display: block;
        width: 95%;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 4.1rem;
      }

      .menu .dropdown-menu {
        .full-width();
        left: 0;
        .pad-med {
          .axon-button {
            margin: .5em auto;
          }
          select {
            .full-width();
            background-image: none;
          }
          input {
            width: 96%;
          }
        }
      }
    }

    #meta-data-container {
      .full-width();
      float: left;
    }

    .squire-buttons .axon-button {
      width: 40%;
      float: left;
      padding: 0;
      height: 3em;
      line-height: 3em;
      &.first {
        float: left;
        margin-left: 2em;
        margin-right: 0.5em;
      }
      &.last {
        float: right;
        margin-left: 0.5em;
        margin-right: 2em;
      }
    }

    .button-bar {
      padding: .5em;
    }

    #content-section {
      .page-list-item {
        width: calc(100% / 2 - 1/2 * 1.6rem);
      }
    }
  });

  // Squire stuff
  @_menu-item-padding: @ax-spacing-xs;

  #menu-container {
    border-bottom: 0.1rem solid @secondary-grey;
  }

  .character-count {
    position: relative;
    .content {
      position: absolute;
      top: -@ax-spacing-xl;
    }
    .warning {
      color: @red;
    }
  }

  .normal-cursor {
    cursor: default !important;
  }

  .selected-list-item {
    background-color: @element-hover-bright;
    border-left: 0.4rem solid @app-zone-color;
  }

  #link-form {
    text-align: left;
  }

  .link-metadata__attachments {
    border: 0.1rem solid #ccc;
    .border-radius(@ax-border-radius-m);
    max-height: 16rem;
    overflow: scroll;

    .attachment__container {
      border: 0;
      border-bottom: 0.1rem solid #ccc;
      .border-radius(0);
      margin: 0;
      position: relative;
      &:hover {
        background-color: @element-hover-bright;
      }
      &.selected {
        background-color: @element-hover-bright;
        border-left: 0.4rem solid @app-zone-color;
      }
      &.empty {
        border: 0;
      }
    }
  }

  .row-body {
    position: relative;
  }

  .row-facets {
    background-color: @primary-bg;
    overflow-y: hidden;
  }

  .content-coverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: @deep-black;
    opacity: 0.8;
    display: none;
  }

  #content-section,
  #secondary-content-section {
    position: relative;
    .box-sizing(border-box);

    .gutter-sizer {
      width: 1.6rem;
    }

    .page-list-item {
      width: calc(100% / 3 - 2/3 * 1.6rem);
      margin-bottom: 1.6rem;

      .page-list-item__container {
        a {
          text-decoration: none;
          &:hover,
          &:focus {
            color: @primary-blue;
          }
        }

        .page-list-item__heading {
          white-space: nowrap;
          overflow: hidden;
        }

        img {
          .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
          .flex(1);
          .margin-center();
          height: auto;
          max-height: 100%;
          max-width: 100%;
          min-height: 4rem;
          width: 100%;
        }

        .page-list-item__content {
          padding: 0 0 .25em 0;
          margin: 0 1em 0em 1em;
          max-height: 15.8em;
          overflow: hidden;
          br {
            display: none;
          }
          word-wrap: break-word;
        }

        .page-list-item__footer {
          display: inline-block;
          .full-width();
          position: relative;
          padding: 1.2em 1em 1em;

          .page-list-item__footer-meta-wrapper {
            display: block;
            width: 75%;
            float: left;
            .page-preview__meta-content {
              min-height: auto;
              margin: auto;
            }
            .meta-content__last-edit-date {
              line-height: 2.5em;
              color: @meta-color;
            }
          }
          .page-list-item__footer-star-wrapper {
            display: block;
            width: 25%;
            float: right;
            .star-container {
              float: right;
              padding-left: 0;
            }
          }
        }

        h3 {
          a {
            color: @black;
          }

          padding: .75em .75em .75em 0;
          margin-left: .75em;
          line-height: 3.6rem;
          .full-width();

            .icon {
              font-size: @dz-create-icon-size;
              display: inline-block;
              width: 3.6rem;
              height: 3.6rem;
              vertical-align: middle;
            }

            .icon-training-article-nobg {
              background-color: #4e13e5;
              .border-radius(50%);
              font-size: 2.2rem;
              line-height: 3.6rem;
              text-align: center;
            }

            .page-item-title {
              margin-left: .25em;
              display: inline-block;
              vertical-align: middle;
              width: calc(100% - 6.6rem);
              height: auto;
              line-height: 1.8rem;
            }

        }
        h3.page-list-item__heading-reference {
          a {
            color: @primary-blue;
            text-decoration: underline;
          }
        }

        p {
          padding: .25em 1em;
        }

        button.axon-button,
        a.axon-button {
          margin: .5em 1em;
        }

        .icon--attachment {
          color: @meta-color;
          padding-left: 1.5em;
          display: block;
          height: 18px;
          width: auto;
          float: right;
          margin-right: 1em;
          line-height: 1.5em;
        }

        .notification-container {
          display: none;
          margin-top: .75em;
          &.has-first-image {
            position: absolute;
            .full-width();
            margin-top: .4em;
          }
        }
      }

      div.page-preview__meta-content {
        margin: 1em 0 2.5em 0;
      }
    }
  }

  .squire-ui {
    padding-left: @ax-gutter-page - @_menu-item-padding;

    .menu {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 0;
      height: 4.4rem;
      padding-top: 1.2rem;
      padding-bottom: 0.6rem;
      box-sizing: border-box;
    }

    .menu .item {
      color: @deep-black;
      float: left;
      background: @white;
      padding: 0 @_menu-item-padding;
      -webkit-font-smoothing: subpixel-antialiased;
      cursor: pointer;
      border: none;
    }

    .menu .group {
      display: inline-block;
      margin: 0;
    }

    .menu .group--image,
    .menu .group--video,
    .menu .group--link {
      overflow: hidden;
    }

    .menu .group--list {
      border-left: 0.1rem solid @secondary-grey;
      border-right: 0.1rem solid @secondary-grey;
      padding: 0 .2rem;
    }

    .btn-group > .btn:first-child {
      margin-left: 0;
    }

    .btn {
      display: inline-block;
      margin-bottom: 0;
      font-weight: @ax-font-weight-regular;
      text-align: center;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 0.6rem 1.2rem;
      font-size: 1.4rem;
      line-height: 1.42857143;
      .border-radius(@ax-border-radius-m);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .btn-group > .btn, .btn-group-vertical > .btn {
      position: relative;
      float: left;
    }

    .hide {
      display: none;
    }

    input {
      margin: 1em 0 1em 1em;
      font-size: 2.6rem;
      height: 1.5em;
      width: 20em;
      line-height: 1.5em;
    }

    #insert-video {
      position: relative;
      cursor: pointer;
    }

    #insert-image {
      cursor: pointer;
    }

    .dropdown-menu {
      .box-shadow-black(0px, 1px, 2px, 0.1);
      left: -25rem;
      border: 0.1rem solid @secondary-grey;
      width: 25rem;
      font-weight: @ax-font-weight-regular;
      font-size: inherit;
      color: @black;
      padding: 0;
      position: absolute;
      top: 100%;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 1.6rem;
      margin: 0.2rem 0 0;
      list-style: none;
      text-align: left;
      background-color: @white;
      .border-radius(@ax-border-radius-m);
      -webkit-box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.175);
      box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.175);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;

      .pad-med {
        padding: 1rem;

        form {
          margin: 0;
        }

        #link-form {
          text-align: left;
        }

        p {
          white-space: normal;
          margin-bottom: .25em;
        }

        .button-wrapper {
          .display-flex();
          .justify-content(space-between);

          .axon-button {
            margin: .25em 0;
            min-width: 0;

            &.gray {
              background-color: @secondary-grey;
            }
          }
        }

        select {
          margin: 1em auto;
        }

        .video-provider-select {
          .full-width();
          margin-top: 0;
        }

        input {
          clear: both;
          float: none;
          display: block;
          .full-width();
          margin: 0 auto 1em auto;
          line-height: 3.4rem;
          height: 34px;
          padding: 0;
        .box-shadow-black(inset 0px, 1px, 2px, 0.1);
        background: @secondary-bg;
        }

        #video-id-input {
          .full-width();
        }

        .link-metadata__attachments {
          border: 0.1rem solid #ccc;
          .border-radius(@ax-border-radius-m);
          max-height: 16rem;
          overflow: scroll;

          .attachment__container {
            border: 0;
            border-bottom: 1px solid #ccc;
            .border-radius(0);
            margin: 0;
            position: relative;
            &:hover {
              background-color: @element-hover-bright;
            }
            &.selected {
              background-color: @element-hover-bright;
              border-left: 0.4rem solid @app-zone-color;
            }
            &.empty {
              border: 0;
            }
          }
        }
      }
    }

    .video-tab-container {
      .display-flex();
      padding: 1rem 1rem 0 1rem;

      button {
        width: 50%;
        -webkit-appearance: none;
        background-color: @white;
        border: 0;
        border-bottom: 0.2rem solid @secondary-grey;

        &.selected {
          border-bottom: 0.2rem solid @primary-blue;
        }

        .icon-upload, .icon-embed {
          font-size: @button-icon-size;
          color: @ax-color-text;
        }
      }
    }
  }

  .squire-iframe {
    .full-width();
    height: 100%;
    color: #333;
    background-color: @white;
    background-repeat: no-repeat;
    background-position: right center;
    outline: none;
    border: 0px;
    .box-sizing(border-box);
  }

  #action-bar {
    text-align: center;

    .article-edit-button-bar {
      .display-flex();
      padding: @ax-spacing-s;

      button.ax-button--branded-secondary {
        margin-left: @ax-spacing-s;
      }

      .menu-dropdown__toggle-button {
        max-height: @ax-spacing-xxl;
      }
    }
  }

  .screen-md-landscape-min ({
    .squire-ui {
      .menu .group--image,
      .menu .group--video {
        width: 2.9rem;
        overflow: hidden;
      }
    }

    .edit-layout {
      #content {
        .squire-editor {
          padding: 1em 1em;
        }
      }
    }

    #action-bar {
      text-align: left;
    }
  });

  /* ==== Button group ===*/
  .button-bar {
    padding: 1em @ax-gutter-page;
    background-color: @primary-bg;
  }

  .axon-button.sidebyside {
    display: inline-block;
    min-width: auto;
    margin: 0 0 0 5px;
  }
}

.character-count-container {
  position: relative;
  .character-count {
    color: @secondary-grey;
    right: .5rem;
    position: absolute;
    top: -@ax-spacing-xl;
  }
  .warning {
    color: @red;
  }
}

@import 'TagSelect.less';
