.multi-filter-layout {
  &.opened, &.filter-opened {
    .filterable-selector {
      .screen-sm-max({
        .title-wrapper .css-close-filter {
          background-image: none;
          width: auto;
          height: auto;
          top: 1rem;
          right: 1.1rem;
          font-size: @icon-nav-size;
          line-height: 2rem;
        }
      });
    }
  }

  .edit-view-button-bar .axon-button {
    display: inline-block;
  }
}

.screen-sm-max({
  .task-multifilter .multi-filter-layout.filter-opened, .multi-filter-layout.filter-opened, .multi-filter-layout.advanced-opened {
    & .filterable-selector, & .advanced-dropdown-container {
      @mobileModalHeaderHeight: 5.2rem;
      @mobileModalFooterHeight: 5.7rem;

      top: calc(@header-height + var(--ax-app-header-border-width, 0rem) + @mobileModalHeaderHeight);
      bottom: calc(@bottom-menu-height-regular + @mobileModalFooterHeight);
    }

    & .advanced-dropdown-container .filterable-selector {
      top: 0;
    }

    & .advanced-filter-wrapper .advanced-dropdown-container .quick-filter-hierarchy-selector .dropdown-container {
      height: calc(100% - 300px);
    }

    .advanced-filter-wrapper .button-container {
      border-radius: 0;
    }
  }
});


.taskAssignmentMultiFilter {
  .filter-group-header {
    height: 4.6rem;
    border-top: 1px solid @ax-color-grey-20;
    border-bottom: 1px solid @ax-color-grey-20;
    .display-flex();
    .flex-direction(row);
    .align-items(center);

    .filter-hold-left {
      padding: @ax-spacing-s @ax-spacing-m;
      background: none;
      .flex(auto);

      [class^="icon-"] {
        margin-right: @ax-spacing-s;
      }
    }

    .create-filter-button {
      margin-right: @ax-spacing-xs;
      [class^="icon-"] {
        font-size: @ax-icon-fontSize-s;
      }
    }
  }

  .filterable-selector {
    .filterable-selector-item {
      a {
        padding: @ax-spacing-s @ax-spacing-m @ax-spacing-s 4.4rem;
        flex-wrap: nowrap;

        &.has-parents {
          flex-wrap: wrap;
        }

        .icon-eye_open {
          font-size: @ax-icon-fontSize-s;
          line-height: @ax-icon-fontSize-s;
          width: auto;
          height: auto;
          background: none;
          color: @ax-color-black;
        }
      }
    }
    .filterable-selector-more-item a {
      padding: @ax-spacing-s @ax-spacing-m @ax-spacing-s 4.4rem;
    }
  }
}
