.reactions-details-modal {
  .tabbed-layout {
    max-height: calc(100vh - 20rem);
  }

  .collection-footer {
    padding: @ax-spacing-m @ax-spacing-m @ax-spacing-xxl @ax-spacing-l;
    text-align: center;

    span.filled {
      width: 18px;
      height: 18px;
      vertical-align: text-top;
      display: inline-block;
    }
  }

  .tab-content {
    span.filled {
      float: left;
      margin-right: @ax-spacing-xs;
      width: 24px;
      height: 24px;
      display: block;
    }
  }

  .reaction-collection {
    .userview-reaction {
      margin: @ax-spacing-l;
    }

    .user-avatar {
      position: relative;

      .reaction-badge {
        position: absolute;
        top: -@ax-spacing-xs;
        right: -@ax-spacing-xs;
        .border-radius(50%);
        padding: @ax-spacing-xs @ax-spacing-xs 0 @ax-spacing-xs;
        width: @ax-font-size-m;
        height: @ax-font-size-m;

        &.reaction-celebrate {
          background: @ax-color-app-background url('@training/assets/images/svgicons/reaction-celebrate-filled.svg') no-repeat 50% 50%;
          background-size: @ax-font-size-m @ax-font-size-m;
        }

        &.reaction-like {
          background: @ax-color-app-background url('@training/assets/images/svgicons/reaction-like-filled.svg') no-repeat 50% 50%;
          background-size: @ax-font-size-m @ax-font-size-m;
        }

        &.reaction-love {
          background: @ax-color-app-background url('@training/assets/images/svgicons/reaction-love-filled.svg') no-repeat 50% 50%;
          background-size: @ax-font-size-m @ax-font-size-m;
        }

        &.reaction-thinking {
          background: @ax-color-app-background url('@training/assets/images/svgicons/reaction-thinking-filled.svg') no-repeat 50% 50%;
          background-size: @ax-font-size-m @ax-font-size-m;
        }

        &.reaction-upset {
          background: @ax-color-app-background url('@training/assets/images/svgicons/reaction-upset-filled.svg') no-repeat 50% 50%;
          background-size: @ax-font-size-m @ax-font-size-m;
        }
      }
    }

  }
}
