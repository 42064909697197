@import "auction-modal.less";

.auctionitem {
  margin-bottom: @ax-spacing-l;
  padding: @ax-spacing-m;
  position: relative;
  text-align: center;

  .ax-breakpoint-m({
    margin-bottom: 0;
    margin-top: @ax-spacing-l;
  });

  .auctionimage {
    .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
    display: block;
    margin: 0 auto @ax-spacing-s;
    padding-top: @ax-spacing-xl;
    width: 100%;

    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-m;
      width: auto;
    });

    .auctionitemimage {
      display: inline-block;

      &.focus-visible:focus img {
        .ax-focus-keyboard();
      }

      .ax-breakpoint-m({
        height: 13rem;
      });
    }
  }

  img {
    display: block;
    height: auto;
    max-height: 10rem;
    width: auto;

    .ax-breakpoint-m({
      max-height: 100%;
      max-width: 100%;
    });
  }

  .zoom-overlay .zoom-icon {
    right: @ax-spacing-xs;
    bottom: @ax-spacing-xs;
  }

  .auctiontitle {
    margin-bottom: @ax-spacing-xs;
    font-weight: @ax-font-weight-regular;

    .ax-breakpoint-m({
      .text-ellipsis();
      margin-bottom: @ax-spacing-s;
    });
  }

  .auctiontime {
    margin-bottom: @ax-spacing-s;

    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-m;
    });
  }

  .outbid {
    .border-radius(@ax-border-radius-m);
    background: @red;
    color: @white;
    left: 0;
    padding: @ax-spacing-s;
    position: absolute;
    right: 0;
    top: 0;

    .ax-breakpoint-m({
      .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
      left: -0.1rem;
      right: -0.1rem;
      top: -0.1rem;
    });
  }

  .bids {
    border-right: .1rem solid @ax-color-grey-30;
  }

  .wrapbuttons {
    margin-top: @ax-spacing-s;

    .ax-breakpoint-m({
      margin-top: @ax-spacing-m;
    });

    .auctionbuttons {
      display: block;
      margin: 0;
      position: relative;
      width: 100%;

      .minbid {
        padding-left: @ax-spacing-xs;
      }
    }
  }

  .winningbid,
  .boughtitem {
    .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
    background: @ax-color-green-60;
    color: @ax-color-white;
    left: -.1rem;
    padding: @ax-spacing-s;
    position: absolute;
    right: -.1rem;
    top: -.1rem;

    .ax-breakpoint-m({
      .border-radius(@ax-border-radius-s @ax-border-radius-s 0 0);
    });
  }
}

.no-rewards {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.auctionCost h3 {
  border: 0;
  margin-bottom: 0;
}

.winning-bids {
  float: none!important;
  width: 100%;
}

.winning-bids.floatleft {
  background: url('@training/assets/images/h1_dots.gif') bottom left repeat-x;
  padding-bottom: @ax-spacing-l;
}

.auctionarea .notice,
.notice {
  .border-radius(@ax-border-radius-m);
  background:rgb(237,28,36);
  background:rgba(237,28,36,0.9);
  border: .5rem solid rgba(237,28,36,1.0);
  color: @white;
  left: 10%;
  line-height: 1.3em;
  padding: @ax-spacing-s;
  position: absolute;
  right: 10%;
  top: 2.5rem;
}

// Locked Auction
.auctionitem.itemlocked {
  .auctionimage .locked {
    .border-radius(@ax-border-radius-m);
    background: url("@training/assets/images/lock-closed.png?_v12") center center no-repeat @white;
    border: .1rem solid @secondary-grey;
    height: 5rem;
    left: 45%;
    position: absolute;
    top: 20%;
    width: 5rem;

    .ax-breakpoint-m({
      .border-radius(50%);
      background: url("@training/assets/images/lock-closed.png?_v13") center center no-repeat @primary-bg;
      height: 10rem;
      left: 34%;
      top: 8%;
      width: 10rem;
    });
  }

  img {
    opacity: 0.5;
  }
}

.auctionitem.itemlocked .auctionbuttons,
.auctionitem.itemlocked .auctionCost,
.auctionitem.itemtimeout .auctionbuttons,
.auctionitem.itemtimeout .auctionCost {
  display: none;
}

// Pricing
.auctionitem .pricing {
  color: @black;
  display: block;
  margin: @ax-spacing-m auto;
  width: 90%;

  div {
    display: inline-block;
    margin: 0 auto;

    .ax-breakpoint-m({
      font-size: 1.2rem;
    });
  }
}
