/* ======== user-audit.less START ======== */

.detail-item.user-audit {
  .audit-name,
  .audit-date {
    .detail-item .value;
  }
}

/* ======== user-audit.less END ======== */
