@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.mejs-controls {
  .mejs-captions-button {
    > .mejs-captions-selector {
      max-height: 15rem;
      overflow-y: auto;
    }
  }
}

.mejs-overlay .mejs-overlay-loading {
  .border-radius(@ax-border-radius-l);
  background-color: rgba(50, 50, 50, 0.7);
  width: 90px;
  height: 90px;
  padding: 5px;
}

.mejs-video,
.mejs-time-slider {
  &.focus-visible:focus {
    box-shadow: 0px 0px 2px 2px @primary-blue;
  }
}

.mejs-overlay .mejs-overlay-button,
.mejs-captions-button > button,
.mejs-playpause-button > button,
.mejs-volume-button > button,
.mejs-volume-slider,
.mejs-fullscreen-button > button{
  &.focus-visible:focus {
    box-shadow: inset 0px 0px 2px 2px @primary-blue;
  }
}



