@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

//Chosen Multi-dropdown w/Search vendor override
//SINGLE DROPDOWN

.chosen-background {
  background: @secondary-bg;
}

.chosen-background-closed {
  .closed-background();
}

.chosen-background-open {
  .open-background();
}

.chosen-background-highlight {
  background: @ax-color-white;
  font-weight: @ax-font-weight-bold;
}

.chosen-border-color {
  border-color: @secondary-grey;
}

.chosen-text-color {
  color: @ax-color-text;
}

.chosen-focus {
  .focus;
  .box-shadow();
}

.chosen-container {
  .border-radius(@ax-border-radius-m);
  .no-outline;
  max-width: 100%;
  min-width: 100px;
  font-size: 1.3rem;

  .chosen-single {
    .select;
    .chosen-background-closed;
    .no-outline;
    font-size: inherit!important;
    font-family: inherit!important;
    // If you're adjusting these padding values, make sure to update AxonSelect editor as well =(
    padding-right: 26px;
    padding-left: 8px;

    & div {
      display: none;
    }

    & span {
      width: auto;
      margin-right: 0;
    }
  }

  .chosen-search {
    border-bottom: 1px solid;
    .chosen-border-color;
    padding: 0.5em;
    z-index: 999;

    input[type="text"] {
      .border-radius(@ax-border-radius-m);
      .chosen-border-color;
      height: 2.6rem;
      min-height: 0;
      width: 100%;
    }
  }

  .chosen-results {
    .highlighted {
      .chosen-background-highlight;
      .chosen-text-color;
    }

    .no-results span {
      display: inline;
    }

    li em {
      .partial-match;
      text-decoration: none;
    }
  }

  .chosen-drop {
    display: none;
    .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);
    .chosen-background;
    margin-top: 0;
    margin-bottom: 1em;
    z-index: 999;

    ul {
      .chosen-text-color;
      padding: 0;
      margin: 0;

      li {
        border-bottom: 1px solid;
        .chosen-border-color;
        .chosen-text-color;
        float: none;
        display: block;
        width: 100%;
        // If you're adjusting the left/right padding value, make sure to update AxonSelect editor as well =(
        padding: 1em 6px;

        &.highlighted {
          .chosen-background-highlight;
          .chosen-text-color;
        }

        &.result-selected {
          .chosen-background-highlight;
          // This border-left width comes from branding but it's left in as an FYI
          // border-left: 4px;
        }
      }
    }
  }

  &.chosen-with-drop .chosen-drop {
    display: block;
  }

  &:not(.chosen-disabled) {
    .chosen-single {
      &:hover,
      &:focus {
        .chosen-focus;
      }
    }

    .chosen-search input[type="text"] {
      &:hover,
      &:focus {
        .chosen-focus;
      }
    }
  }
}

//Closed State
.chosen-container-single.short .chosen-single {
  height: 34px;
  line-height: 3.4rem;
}

//MULTI DROPDOWN (AOI's)
.chosen-container-multi {
  width: 95%;

  .chosen-choices {
    .border-radius(@ax-border-radius-m);
    .box-shadow-black(inset 0px, 1px, 2px, 0.1);
    .chosen-border-color;
    .chosen-text-color;
    .chosen-background;
    padding: 0.5em;

    li {
      width: auto;
      margin: 0.5em 0;
      .rtl-only({
        float: right;
      });

      &.search-choice {
        .box-shadow-black(0px, 1px, 2px, 0.1);
        .chosen-border-color;
        .chosen-text-color;
        background: @white;
        border-left: 3px solid @primary-blue;
        margin-left: 3px;
        margin-right: 3px;

        span {
          background: @white;
          padding: 0.5em;
        }

        .search-choice-close {
          top: 11px;
        }
      }

      &.search-field input[type="text"] {
        height: 38px;
      }

      span {
        width: 100%;
      }
    }

    &:hover {
      .chosen-focus;
    }
  }

  .chosen-drop {
    ul {
      li {
        &.result-selected {
          .chosen-text-color;
        }
      }
    }
  }

  &.chosen-with-drop .chosen-choices {
    .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
  }
}

//Open State
.chosen-container-active {
  .chosen-single {
    .chosen-focus;
    .chosen-background;
    .chosen-background-closed;

    &:hover {
      .chosen-focus;
    }
  }

  .chosen-choices,
  .chosen-choices:hover {
    .chosen-focus;
  }

  // Drop down visible
  &.chosen-with-drop {
    .chosen-single {
      .chosen-background-open;
    }

    .chosen-drop {
      .chosen-background;
      border-top: 0px;
    }
  }
}
