// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Legacy color utils
//  - These do not follow our BEM standards and should be avoided
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.black {
  color: @black;
}

.blue {
  color: @primary-blue;
}

.deep-black {
  color: @deep-black;
}

.gray {
  color: @primary-grey;
}

.green {
  color: @green;
}

.orange {
  color: @orange;
}

.red {
  color: @red;
}

.white {
  color: @white;
}

.yellow {
  color: @yellow
}

.bronze {
  color: @ax-color-bronze;
}

.gold {
  color: @ax-color-gold;
}

.silver {
  color: @ax-color-silver;
}

.bg-bright {
  background-color: @element-hover-bright;
}
