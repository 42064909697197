.MuiCardHeader-avatar .MuiSvgIcon-root,
.icon-lock {
  font-size: @ax-font-size-xl;
}

.nudges {
  &__completed-card {
    .justify-content(center);

    &--icon {
      display: none;

      .ax-breakpoint-m({
        display: block;
      });
    }

    &--text {
      margin-left: 0;

      .ax-breakpoint-m({
        margin-left: @ax-spacing-s;
      });
    }

    .ax-breakpoint-m({
      .justify-content(flex-end);
    });
  }
}

.communication-view {
  .card.focus-visible {
    .ax-focus-keyboard();
    color: @ax-color-text-secondary;
  }
}
