.knowledgeitem {
  .questions {
    clear: both;
    margin: 0 @ax-spacing-s;
    padding: 0;
    z-index: 998;

    a {
      color: @black;
      text-decoration: none;
    }

    .title {
      font-size: 1.6rem;
      border-bottom: .1rem dotted @ax-color-grey-30;
      display: none;
      margin-bottom: @ax-spacing-xs;
      padding: @ax-spacing-xs 0;

      .ax-breakpoint-m({
        padding-top: 0;
      });
    }

    .graduation {
      display: none;
      margin-top: @ax-spacing-xs;
      padding-top: @ax-spacing-xs;

      .ax-breakpoint-m({
        font-size: 1.1rem;
      });
    }

    .questionswrap {
      p.noquestions {
        font-size: 1.4rem;
        display: none;

        .ax-breakpoint-m({
          font-size: @topic-font-size;
        });
      }

      .answerblock {
        border-top: .1rem solid @ax-color-grey-30;
        cursor: default;
        display: block;
        padding: @ax-spacing-m 0;

        &:nth-of-type(2) {
          border-top: none;
        }

        p {
          margin: 0;
        }

        .answers-wrapper .questionblock {
          .box-shadow-black(0, .1rem, .2rem, 0.1);
          margin: 0.5em 0.5em 0 0;
          padding: 0;

          //border must always be active so the button is visible in high contrast mode
          border: .2rem solid @transparent-white;

          &:hover,
          &:focus {
            border: .2rem solid @black;
          }
        }
      }
    }

    .footer {
      display: none;
      margin-top: @ax-spacing-xs;
      padding-top: @ax-spacing-xs;

      .ax-breakpoint-m({
        font-size: 1.1rem;
      });
    }
  }

  &.statusNotStarted .questions .questionswrap p.noquestions {
    display: none;

    .ax-breakpoint-m({
      display: block;
    });
  }

  &.statusGraduate .questions .questionswrap {
    p.noquestions {
      .ax-breakpoint-m({
        .questions .graduation {
          display: block;
        }
      });
    }

    .graduation,
    .status {
      display: block;
    }
  }
}


// Modal
.modal.questionanswer {
  width: 93%;

  .answerwrapper{
    border-color: @ax-color-grey-40;
    margin-top: 1em !important;
  }

  .questionarea {
    padding: 0 0.8em 0 0.8em!important;
  }

  .questioncontent .answer {
    padding: 0;
  }

  .close {
    background: url("@common/assets/images/modal-close.png") center center no-repeat;
    cursor: pointer;
    height: 32px;
    position: absolute;
    right:-2px;
    text-indent: -999em;
    top:-2px;
    width: 32px;
    z-index: 999;
  }
}
