@card-border-radius: @ax-border-radius-m;
@card-border: none;
@card-box-shadow-default: @ax-box-shadow-s;
@card-box-shadow-informational: 0 0 0 .1rem rgba(@ax-color-grey-80, @ax-opacity-s);
@card-box-shadow-interactive-hover: 0 0 0 0.1rem rgba(@ax-color-grey-80, @ax-opacity-s), 0 0.2rem 0.4rem 0 rgba(@ax-color-grey-80, @ax-opacity-l), 0 0.4rem 0.8rem 0 rgba(@ax-color-grey-80, @ax-opacity-l);
@card-interactive-top-hover: -@ax-spacing-xxs;
@card-interactive-transition: all @ax-transition-speed ease-in-out;
@card-padding-l: @ax-spacing-l @ax-spacing-m;
@card-padding-m: @ax-spacing-m;
@card-padding-s: @ax-spacing-s;

:root {
  --axCard-border-radius: @card-border-radius;
  --axCard-border: @card-border;
  --axCard-box-shadow-informational: @card-box-shadow-informational;
  --axCard-box-shadow-default-hover: @card-box-shadow-interactive-hover;
  --axCard-box-shadow-default: @card-box-shadow-default;
  --axCard-interactive-top-hover:@card-interactive-top-hover;
  --axCard-interactive-transition: @card-interactive-transition;
  --axCard-spacing-l: @card-padding-l;
  --axCard-spacing-m: @card-padding-m;
  --axCard-spacing-s: @card-padding-s;
}
