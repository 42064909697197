
.broadcast-article__header {
  .display-flex();
  .justify-content(space-between);

  overflow: auto;
	padding: @ax-spacing-m 0 4rem;
	position: relative;
	word-break: break-all;

  .screen-md-portrait-min({
    padding: @ax-spacing-m 9rem @ax-spacing-m 0;
  });
}

.broadcast-article__read-button {
  @broadcast-article-grey: @ax-color-grey-20;

  .transition(all @ax-transition-speed);

	background: transparent;
	border: none;
  bottom: 0;
  left: 0;
	height: 4rem;
	padding: 0 3.5rem;
	position: absolute;
  text-align: center;
	width: 100%;

  .screen-md-portrait-min({
    border-left: 1px solid @broadcast-article-grey;
    height: 100%;
    left: auto;
    padding: 0 3.5rem;
    right: 0;
    text-align: left;
    top: 0;
    width: auto;
  });

  &:focus {
    .screen-md-portrait-min({
      background-color: @broadcast-article-grey;
      border-color: @broadcast-article-grey;
    });
  }

  &:hover,
  &.focus-visible:focus {
    .screen-md-portrait-min({
      background-color: darken(@broadcast-article-grey, 15%);
      border-color: darken(@broadcast-article-grey, 15%);
    });
  }
}

.broadcast-article__header-content {
  .display-flex();
}

.broadcast-article__type-icon {
  @broadcast-article__icon--small: 2.4rem;
  @broadcast-article__icon--large: 4rem;

  .size(@broadcast-article__icon--small);
  .box-sizing();
  .border-radius(50%);

  background-color: @white;
  font-size: @broadcast-article__icon--small;
  line-height: @broadcast-article__icon--small;
  margin: auto @ax-spacing-m;


  .screen-md-portrait-min({
    .size(@broadcast-article__icon--large);
    font-size: @broadcast-article__icon--large;
    line-height: @broadcast-article__icon--large;
  });

  &&--training {
    font-size: 1.2rem;
    text-align: center;
    background-color: #4F13E5;
    border: 0.2rem solid @white;
    box-sizing: content-box;

    .screen-md-portrait-min({
      font-size: 1.6rem;
    });
  }
}



.broadcast-article__image {
  .border-radius(@ax-border-radius-m);

  background: @primary-bg url('@training/assets/images/discoveryzone-light.svg') no-repeat center center / 30%;
	height: 4rem;
	position: relative;
	width: 4rem;
  margin-right: @ax-spacing-m;

  .screen-md-portrait-min({
    height: 6.5rem;
    width: 6.5rem;
  });
}

.broadcast-article__headlines {
  .display-flex();
  .flex-direction(column);
  .justify-content(center);
  word-break: break-all;
}

.broadcast-article__kicker {
  font-size: 1.2rem;

  font-weight: @ax-font-weight-regular;
	line-height: 1.4rem;
	margin: 0;
	margin-top: 0.2rem;
	text-transform: capitalize;
  color: @ax-color-grey-60;

  .screen-md-portrait-min({
    font-size: 1.6rem;

    line-height: 1.9rem;
    margin-top: 0.7rem;
  });
}

.broadcast-article__title {
  font-size: 1.4rem;
  font-weight: @ax-font-weight-bold;
	line-height: @ax-spacing-m;
	margin: 0;
	margin-right: @ax-spacing-m;
	word-break: break-word;

  .screen-md-portrait-min({
    font-size: 2rem;
    line-height: 2.3rem;
    font-weight: @ax-font-weight-regular;
  });
}

.broadcast-article__content-wrapper {
  border-top: 0.1rem solid @accent-grey;
	color: @black;
	display: none;
	padding: 1.5em @ax-spacing-m;
	word-wrap: break-word;

  .screen-md-portrait-min({
    padding: 1.5em @ax-spacing-l;
  });

  a.broadcast-article-link.focus-visible:focus {
    .box-shadow(inset 0 0 0.2rem 0.2rem @primary-blue);
  }

  iframe {
    width: 100%;
  }
}

.broadcast-article__attachment-wrapper {
  .attachment-list {
    .display-flex();
    .flex-wrap();

    border-top: 0.1rem solid @accent-grey;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

.broadcast-article .external-link-wrapper .url-panel.full-width {
  box-sizing: border-box;
}
