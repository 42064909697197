.display-flex (@display: flex) when (@display = inline-flex){
  ._display-flex-properties(@display, ~"inline-");
}
.display-flex (@display: flex) when (default()){
  ._display-flex-properties(@display);
}
._display-flex-properties(@display, @display-box: ~"") {
  display: ~"-webkit-@{display-box}box";
  display: ~"-moz-@{display-box}box";
  display: ~"-webkit-@{display}";
  display: ~"-ms-@{display}box";
  display: @display;
}

.flex-wrap (@wrap: wrap) {
  -webkit-flex-wrap: @wrap;
  -ms-flex-wrap: @wrap;
  flex-wrap: @wrap;
}

.flex-direction(@direction: row){
  -webkit-flex-direction: @direction;
  -ms-flex-direction: @direction;
  -moz-flex-direction: @direction;
  flex-direction: @direction;
}

.flex(@flex: 0 1 auto) {
  -webkit-flex: @flex;
  -moz-flex: @flex;
  -ms-flex: @flex;
  flex: @flex;
}

.flex-basis (@width: auto) {
  -webkit-flex-basis: @width;
  -moz-flex-basis: @width;
  -ms-flex-basis: @width;
  flex-basis: @width;
}

.align-items(@align: stretch) {
  .ms-flex-align(@value) {
      ._(@value);
      ._(baseline)    { -ms-flex-align: baseline; }
      ._(stretch)     { -ms-flex-align: stretch; }
      ._(flex-end)    { -ms-flex-align: end; }
      ._(flex-start)  { -ms-flex-align: start; }
      ._(center)      { -ms-flex-align: center; }
  }
  -webkit-align-items: @align;
  -moz-align-items: @align;
  -ms-align-items: @align;
  align-items: @align;
  .ms-flex-align(@align);
}

.align-self(@align: auto) {
  .ms-flex-align-self(@value) {
    ._(@value);
    ._(baseline)    { -ms-flex-item-align: baseline; }
    ._(stretch)     { -ms-flex-item-align: stretch; }
    ._(flex-end)    { -ms-flex-item-align: end; }
    ._(flex-start)  { -ms-flex-item-align: start; }
    ._(center)      { -ms-flex-item-align: center; }
  }
  -webkit-align-self: @align;
  -moz-align-self: @align;
  -ms-align-self: @align;
  align-self: @align;
  .ms-flex-align-self(@align);
}

.justify-content(@justify: flex-start) {
  .ms-flex-justify(@value) {
      ._(@value);
      ._(normal)        { -ms-flex-pack: start; }
      ._(space-between) { -ms-flex-pack: justify; }
      ._(space-around)  { -ms-flex-pack: distribute; }
      ._(flex-end)      { -ms-flex-pack: end; }
      ._(flex-start)    { -ms-flex-pack: start; }
      ._(center)        { -ms-flex-pack: center; }
  }
  -webkit-justify-content: @justify;
  -moz-justify-content: @justify;
  -ms-justify-content: @justify;
  justify-content: @justify;
  .ms-flex-justify(@justify);
}

.flex-grow(@grow: 1) {
  -webkit-flex-grow: @grow;
  -moz-flex-grow: @grow;
  -ms-flex-grow: @grow;
  flex-grow: @grow;
}

.flex-shrink(@shrink: 1) {
  -webkit-flex-shrink: @shrink;
  -moz-flex-shrink: @shrink;
  -ms-flex-shrink: @shrink;
  flex-shrink: @shrink;
}

.flex-flow(@flow: row nowrap) {
  -webkit-flex-flow: @flow;
  -moz-flex-flow: @flow;
  -ms-flex-flow: @flow;
  flex-flow: @flow;
}

.flex-order(@order: 0) {
  -webkit-box-ordinal-group: @order + 1;
  -moz-box-ordinal-group: @order + 1;
  -webkit-order: @order;
  -ms-flex-order: @order;
  order: @order;
}

.flex-fill-height-or-more {
  .display-flex();
  .flex-direction(column);

  > * {
    .display-flex();
    .flex-direction(column);
    .justify-content(center);
    .flex(1 1 auto);
  }
}

.flex-column-center-horizontally-mixin {
  .display-flex();
  .flex-direction(column);
  .align-items(center);
}
