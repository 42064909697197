// User Profile - Pages Layout
.settingswrapper {

  .expired {
    display: none;
  }

  .left {
    margin-bottom: @ax-spacing-l;
  }

  // Content Column
  .right {
    h1 {
      display: none;
    }
  }
}

.settingswrapper .settingslist {
  .setting {
    border-bottom: .1rem solid @secondary-grey;

    &:first-child {
      border-top: .1rem solid @secondary-grey;
    }

    .ax-breakpoint-m({
      margin-right: @ax-spacing-xs;
    });

    button {
      background: @white;
    }
  }

  .ax-breakpoint-m({
    .selected {
      border-left: .3rem solid @training-color;
    }
  });
}

// Side Navigation
.settingswrapper .settingslist .setting.touchable.menu-thumb {
  button {
    .button-reset-style();
    padding: @ax-spacing-m;
    width: 100%;
    border: 0;
    text-align: left;
    color: @ax-color-text;

    &:hover {
      background: darken(@element-hover-bright, 10%);
    }

    &.focus-visible:focus {
      background: darken(@element-hover-bright, 10%);
    }
  }

  .ax-breakpoint-m({
    &.selected button {
      background: @element-hover-bright;
    }
  });
}
