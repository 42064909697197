.event-checkin__card {
  .base-card__wrapper-title {

    .screen-md-portrait-min({
      .align-items(center);
    });
  }

  .base-card__wrapper--lower {
    .display-flex();

    .form-wrapper {
      .display-flex();
      .flex(1 0 auto);
      .justify-content(flex-end);
      max-width: 100%;

      input {
        .flex-grow(2);

        &.rederror {
          border-color: @error-state;
        }

        .screen-md-portrait-min({
          .flex-grow(0);
          min-width: 20rem;
          height: 4.4rem;
        });
      }

      button {
        line-height: normal;
        margin-left: 1rem;
        min-width: 0;
        padding-bottom: @ax-spacing-xs;
        padding-top: @ax-spacing-xs;

        .screen-md-portrait-min({
          min-width: 20rem;
          height: 4.4rem;
        });
      }
    }

    .screen-md-portrait-min({
      .justify-content(flex-end);
    });
  }
}
