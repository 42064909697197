&.menu-app-item {
  &.admin.selectable {
    .item-icon, .item-text {
      color: @admin-color;
    }
  }

  &.training.selectable {
    .item-icon, .item-text {
      color: @training-color;
    }
  }

  &.manager.selectable {
    .item-icon, .item-text {
      color: @manager-color;
    }
  }
}
