// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
//  UI 3.0 COMMON FEATURE VARIABLES ONLY
//
//  Check out the Axonify UI Kit Feature styles artboard for visual examples and usage details:
//  https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7391-5593&mode=design&t=Rszjf8HezSfHbyOE-0
// - - - - - - -

/**
* @tokens UIKit:Programs
* @presenter Color
*/
// Programs
@ax-color-assessments:        #5018A8;
@ax-color-certification:      #F96639;
@ax-color-certification-40:   #FBE3D9;
@ax-color-certification-80:   #ED5500;
@ax-color-programs:           #035E42;
@ax-color-training:           #029B55;

/**
* @tokens UIKit:Assessments
* @presenter Color
*/
// Assessments
@ax-color-assessments-100:    #41095C;
@ax-color-assessments-20:     #E6D5EB;
@ax-color-assessments-60:     #812F99;
@ax-color-assessments-task:   #880E0E;
@ax-color-evaluation-40:      #E5DEF9;
@ax-color-evaluation-80:      #5018A8;

/**
* @tokens UIKit:Content
* @presenter Color
*/
// Content
@ax-color-article-40:         #D1E1FF;
@ax-color-article-80:         #2231AC;
@ax-color-article:            @ax-color-blue-80;
@ax-color-event-40:           #F9E1F7;
@ax-color-event-80:           #731D6E;
@ax-color-event:              #AA44A5;
@ax-color-external-link-40:   #E5EFD6;
@ax-color-external-link-80:   #365D13;
@ax-color-external-link:      #8DBA54;
@ax-color-post:               @ax-color-white;
@ax-color-question-40:        #D4F7EE;
@ax-color-question-80:        #0C5D41;
@ax-color-question:           #10A889;
@ax-color-task-40:            #FBE1E1;
@ax-color-task-80:            #880E0E;
@ax-color-task:               #C14242;
@ax-color-topic-40:           #D6F7EF;
@ax-color-topic-80:           #035E42;

/**
* @tokens UIKit:Achievements
* @presenter Color
*/
// Achievements - Gamification
@ax-color-answer-streak:      #83C143;
@ax-color-graduation:         @ax-color-blue-60;
@ax-color-lost-game:          @ax-color-task;
@ax-color-training-frequent:  #46BAA1;
@ax-color-training-lifetime:  @ax-color-task;
@ax-color-training-frequency: #2FA58C;
/**
* @tokens-end
*/

/**
* @tokens UIKit:Trophies
* @presenter Color
*/
// Trophies
@ax-color-gold:                       #A88A3E;
@ax-color-silver:                     #919195;
@ax-color-bronze:                     #AA6F17;
/**
* @tokens-end
*/

:root {
  --ax-color-answer-streak: @ax-color-answer-streak;
  --ax-color-training-frequency: @ax-color-training-frequency;
  --ax-color-assessments-self-directed-60: @ax-color-assessments-60;

  // Trophies
  --ax-color-gold: @ax-color-gold;
  --ax-color-silver: @ax-color-silver;
  --ax-color-bronze: @ax-color-bronze;
}