.MuiButtonBase-root {
  &.ax-button {
    color: @ax-color-grey-80;
  }

  &.ax-button--destructive {
    color: @ax-color-red-60;

    &:hover {
      color: @ax-color-grey-10;
    }
  }
}

.create-task-form {
  .day-picker {
    &_secondary {
      margin-top: @ax-spacing-s;
      color: @ax-color-grey-60;
    }
  }

  .date-picker-control, .assign-to {
    .full-width();

    .ax-breakpoint-m({
      width: 50%;
    });
  }

  .assign-to {
    .MuiButton-root {
      font-weight: @ax-font-weight-regular;
      .justify-content(flex-start);
      padding-left: @ax-spacing-s;
    }

    .MuiButton-startIcon {
      margin-right: @ax-spacing-s;
      margin-left: 0;
    }
  }

  .completion, .day-picker, .assign-to, .verification-type  {
    .MuiFormLabel-root, .MuiFormControlLabel-label {
      font-size: @ax-font-size-xs;
    }

    .MuiFormLabel-root {
      font-weight: @ax-font-weight-medium;
    }
  }

  .verification-type .MuiFormControlLabel-root {
    margin-left: 0;
  }
}

.user-list {
  background-color: @ax-color-white;
  padding-top: 0;
  padding-bottom: 0;
}

.MuiSvgIcon-root.circle-outline {
  color: transparent;
  cursor: pointer;
  visibility: hidden;

  &--visible {
    color: @ax-color-blue-60;
    margin-right: @ax-spacing-m;
    font-size: 2.5rem;
    visibility: visible;
  }
}

.icon--badge.icon-groups {
  background-color: @ax-color-grey-30;
  border-radius: 0.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
  color: @ax-color-black;
  width: 4rem;
  height: 4rem;
  .align-items(center);
}

.view-more-card {
  .view-card__load-more,
  .view-card__text {
    font-weight: @ax-font-weight-bold;
  }
}

.MuiCard-root {
  .MuiBadge-badge {
    max-width: 2rem;
    right: 0.5rem;
    top: 0.5rem;
  }

  .icon-ok.material-icons {
    font-size: 1.5rem;
  }
}

.MuiDivider-root.divider {
  &--fullwidth {
    margin: 0 -@ax-spacing-m;
  }
}

.task-list-buttons {
  .display-flex();
  .flex-direction(column-reverse);

  label {
    padding-right: 0;
    margin-top: @ax-spacing-m;
    .align-items(flex-start);
  }

  .ax-breakpoint-m({
    .flex-direction(row);
    .justify-content(flex-end);
    .align-items(center);

    label {
      .align-items(center);
      padding-right: @ax-spacing-m;
      margin-top: 0;
    }
  });
}

.task-list {
  padding: @ax-spacing-s 0 0;

  .MuiFormControlLabel-root{
    margin: 0;
  }

  .MuiFormControlLabel-label {
    .off-screen();
  }
}

.subtitle-box {
  .ax-breakpoint-m({
    .display-flex(inline-flex);
    .flex-wrap(no-wrap);
  });
}

.margin-top--l {
  margin-top: @ax-spacing-l
}


.margin {
  &-bottom {
    &--s {
      margin-bottom: @ax-spacing-s;
    }
  }

  &-right {
    &--s {
      margin-right: @ax-spacing-s;
    }
  }
}

.MuiTypography-root {
  &.add-photo-title {
    font-weight: @ax-font-weight-bold;
    margin-bottom: @ax-spacing-s;
    font-size: @ax-font-size-m;
    color: @ax-color-grey-80;
    line-height: @ax-line-height-m;
    text-align: center;
  }

  &.add-photo-file-size {
    font-size: @ax-font-size-s;
    color: @ax-color-grey-60;
    line-height: @ax-line-height-s;
    &--bottom {
      margin-bottom: @ax-spacing-s;
    }
  }
}

.MuiTimelineConnector-root {
  background-color: @ax-color-grey-30;
}

.mark-complete-user {
  input {
    height: 100%;
  }
}

.image-preview-box {
  .size(80%);

  .ax-breakpoint-m({
    .size(auto);
  })
}

.image-preview-box .image-preview,
.timeline-image .image-preview {
  max-height: 80%;
  max-width: 80%;
  .border-radius(@ax-border-radius-m);

  .ax-breakpoint-m({
    max-height: 20rem;
    max-width: 30rem;
  })
}
