/**
 * Modal Overrides
 */
 #modalview.create-post-modal {
   overflow: visible;

   > .scrollable {
     overflow: visible;
   }
 }
/**
 * END Modal Overrides
 */

.modal.create-post-modal {
  max-width: 74.8rem;

  .adjustable-container {
    .ax-breakpoint-m({
      //force a max height for the modal to allow content scrolling
      max-height: calc(100vh - 100px);
    });
  }

  /* needed in Android to ensure the layout is consistent */
  .adjustable-content-wrapper, .adjustable-content, .create-post-modal-content {
    height: 100%;

    .ax-breakpoint-m({
      height: none;
    });
  }

  .modalHeader {
    .display-flex();
    .justify-content(space-between);
    box-shadow: none;
    background-color: transparent;
    border-bottom: 0.1rem solid @ax-color-grey-20;
    margin-bottom: 0;
    padding: @ax-spacing-s @ax-spacing-m;

    h1 {
      padding: inherit;
    }

    .icon-remove {
      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }

    .post-modal-mobile-close {
      .ax-breakpoint-m({
        .hidden;
      });
    }

    .post-button-mobile {
      .ax-breakpoint-m({
        .hidden;
      });
    }

    .post-modal-desktop-close {
      display: none;

      .ax-breakpoint-m({
        display: block;
      });
    }
  }

  .picker-region {
    .profile-icon-region {
      .size(6rem);

      .user-profile-icon {
        .round-avatar();

        .size(6rem);
        line-height: 6rem;
      }
    }

    h2 {
      font-size: @ax-font-size-xs;
      margin: 0 0 1rem 1rem;
      line-height: normal;
    }

    .filterable-community {
      max-width: 37rem;
      margin: 0;
    }
  }

  .picker-region,
  .inappropriate-content-message {
    padding: @ax-spacing-m @ax-spacing-l;
  }

  .inappropriate-content-message {
    padding-bottom: @ax-spacing-s;

    .icon-exclamation_sign {
      margin-right: @ax-spacing-s;
    }

    p {
      margin-bottom: 0;
    }
  }


  .modal-body {
    height: 100%;
    max-height: calc(100vh - 282px);

    .ax-breakpoint-m({
      height: none;
      max-height: none;
    });
  }

  .post-body {
    padding: 0 @ax-spacing-l @ax-spacing-s @ax-spacing-l;

    .fr-second-toolbar {
      display: none;
    }
    
    .editor-container.froala-editor.fr-box.fr-basic.fr-top { //froala has some very specific selectors we need to override
      .fr-wrapper {
        .ax-breakpoint-m({
          min-height: 25rem;
        });

        border: 0.1rem solid @ax-color-grey-40;
        .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);
        background-color: @_input-color-background;
      }

      .fr-placeholder {
        color: @_input-color-placeholder;
      }

      .fr-toolbar {
        .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
      }

      .fr-second-toolbar {
        border: none;
      }
    }

    .ax-breakpoint-m({
      overflow: auto;
      // force a max height for the modal to allow content scrolling
      // this calculation works for both desktop and tablet sizes.
      max-height: calc(100vh - 394px);
    });

    .media-container {
      display: none;
      border: 0.1rem solid @ax-color-grey-40;
      .border-radius(@ax-border-radius-m);
      background-color: @ax-color-grey-10;
      .full-width();
      margin: @ax-spacing-s 0;
      position: relative;

      .icon.icon-remove {
        position: absolute;
        top: @ax-spacing-s;
        right: @ax-spacing-s;
        .border-radius(50%);
        box-shadow: @ax-box-shadow-m;
        background-color: @ax-color-white;
        color: @ax-color-grey-80;
        margin: 0;

        &:hover {
          background-color: @ax-color-grey-20;
        }

        &.focus-visible:focus {
          .ax-focus-keyboard();
        }
      }

      figure.media--image {
        height: 25rem;

        .ax-breakpoint-m({
          height: 40rem;
        });
      }

      figure.media--video {
        height: 20rem;
      }

      img {
        width: auto;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        min-height: auto;
        display: block;
        margin: 0 auto;
        object-fit: contain;
        .border-radius(@ax-border-radius-m);

        &.video-placeholder {
          .full-width();
          height: 25rem;
          display: block;
          text-align: center;
          background-repeat: no-repeat;
          background-color: @accent-grey;
          background-position: center center;
          background-image: url('@training/assets/images/icn_type_video@2x.png');
        }
      }
    }

    .media-input-wrap {
      width: 0px;
      height: 1px;
      opacity: 0.01;
      overflow: hidden;
    }
  }

  .button-container {
    .display-flex();
    padding: @ax-spacing-s 0;

    .ax-breakpoint-m({
      border-top: 0.1rem solid @ax-color-grey-20;
    });

    #menu-container {
      .ax-breakpoint-m({
        margin-left: 1.2rem;
      });

      .full-width();

      .squire-ui {
        .group {
          .display-flex();
          .justify-content(center);

          .ax-breakpoint-m({
            .justify-content(flex-start);
          });

          .media-form {
            .full-width();

            .ax-breakpoint-m({
              width: auto;
            });
          }

          #insert-media {
            cursor: pointer;
            padding: @ax-spacing-s;
            .border-radius(@ax-border-radius-m);
            .box-shadow-black(0px, 1px, 2px, 0.1);
            border: 0.1rem solid @ax-color-grey-30;
            font-size: @ax-font-size-s;

            .ax-breakpoint-m({
              border: none;
              box-shadow: none;
            });

            &:hover {
              .border-radius(@ax-border-radius-m);
              background-color: @ax-color-grey-20;
            }

            &.focus-ring {
              .ax-focus-keyboard();
            }
          }
        }
      }
    }
  }

  .post-button-container {
    display: none;

    .ax-breakpoint-m({
      margin-right: @ax-spacing-l;
      display: block;
    });

    .post-button-desktop {
      min-width: auto;
      max-height: 4rem;

      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }
  }
}

.post-create-modal__picker-region__community-picker-region {
  // using padding so it doesn't clash with .ax-grid--no-gutter
  padding: @ax-filterable-community-margin;
}