.ie11-stacking-context-fix {
  position: absolute;
}

.reaction-tray {
  .border-radius(4rem);
  .box-shadow(@ax-box-shadow-m);
  .display-flex();
  .transition(opacity @ax-transition-speed @ax-transition-ease);
  background-color: @white;
  top: 0;
  left: 0;
  width: 23.8rem; // this is unfortunately needed by IE11 $#@!
  opacity: 0;
  visibility: hidden;

  &.popover-visible {
    .transition(visibility 0s, opacity 0.25s ease);
    visibility: visible;
    opacity: 100;
  }

  .reaction-button-with-tooltip {
    .display-flex();
    .flex-direction(column);
    margin: 0;
    padding: @ax-spacing-xxs;

    &:first-child {
      padding-left: @ax-spacing-m;
    }

    &:last-child {
      padding-right: @ax-spacing-m;
    }
  }

  .reaction-button {
    margin: 0;

    .reaction-icon span {
      .size(3rem);
    }

    &:hover, &.show-label, &:focus {
      color: @primary-hover-blue;

      + .tooltip {
        visibility: visible;
      }
    }

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }
  }

  .tooltip {
    .align-self(center);
    .border-radius(2rem);
    background-color: @black;
    border: .1rem solid @black;
    color: @white;
    padding: @ax-spacing-xs @ax-spacing-m;
    position: absolute;
    top: -@ax-spacing-l;
    visibility: hidden;
    white-space: nowrap;
  }
}

.reactions-region {
  .full-width();
  .display-flex();
  .align-self(flex-start);
  .flex(0 1 auto);
  position: relative;

  .reaction-icon {
    &.filled {
      span.filled {
        display: inline-block;
      }

      span.outline {
        display: none;
      }
    }

    span {
      .size(2rem);
      .prevent-ios-tap-context();

      &.filled {
        display: none;
      }

      &.outline {
        display: inline-block;
      }
    }
  }

  .reaction-view-container {
    .full-width();

    .reaction-buttons {
      padding: @ax-spacing-xs 0 0;
      margin-top: @ax-spacing-s;
      border-top: .1rem solid @accent-grey;

      .reaction-info-group {
        .flex(1 1 auto);

        .reaction-info-group-left {
          .most-liked {
            .box-sizing(content-box);
            display: inline-block;
            .border-radius(50%);
            border: .1rem solid @subtle-border-grey;
            background: @white;
            color: @black;
            padding: @ax-spacing-xs;
            margin-right: @ax-spacing-s;
            overflow: hidden;
            height: 2rem;
            width: 2rem;
          }

          .reaction-count, .middot {
            display: inline-block;
          }

          .middot {
            &.for-page-views {
              display: none;

              .ax-breakpoint-m({
                display: inline-block;
              });
            }

            margin-right: @ax-spacing-xs;
          }
        }
        .reaction-info-group-right {
          height: 3rem;
          line-height: 3rem;
          .comment-count {
            white-space: nowrap;
            a {
              color: @secondary-text-color /* deliberately overriding the hyperlink style */
            }
          }
        }

        .view-metrics {
          text-align: right;

          .ax-breakpoint-m({
            text-align: left;
          });
        }
      }

      .reaction-buttons-group {
        margin: @ax-spacing-s 0 0;
        text-align: center;

        .reaction-anchor,
        .favorite-region {
          color: @secondary-text-color;
        }

        button.focus-visible:focus {
          .ax-focus-keyboard();
        }

        button {
          vertical-align: middle;
          padding: @ax-spacing-xs;

          .reaction-bar-button-icon {
            height: 2rem;
            display: inline-block;
            vertical-align: middle;
          }

          .reaction-bar-button-text {
            vertical-align: middle;
          }
        }

        > div {
          margin: 0;

          &:hover,
          &:focus {
            background-color: @ax-color-grey-20;
          }
        }

        button:not(.reaction-button){
          &:hover, &:focus {
            color: @primary-hover-blue;

            .path2:before {
              color: @primary-hover-blue;
            }
          }
        }
      }
    }
  }
}

.comms-enabled {
  .reaction-buttons-group.show-top-border {
    border-top: .1rem solid @accent-grey;
    padding-top: @ax-spacing-s;
  }
}

.comments-region {
  .comments-container {
    padding-top: @ax-spacing-s;
    padding-bottom: @ax-spacing-s;
    border-top: .1rem solid @accent-grey;

    .comments-nope {
      margin-bottom: @ax-spacing-s;
    }

    .page-comment-count {
      font-weight: @ax-font-weight-bold;
    }
  }

  h2 {
    margin-bottom: @ax-spacing-s;
  }

  .comment-view-container > .comment-template:not(:last-child) {
    border-bottom: .1rem solid @accent-grey;
    padding-bottom: @ax-spacing-s;
    margin-bottom: @ax-spacing-s;
  }

  .comment {
    .display-flex();
    .flex-direction(row);

    .comment-left {
      min-width: 5rem;

      .article-comment__avatar {
        .round-avatar();
      }

    }
    .comment-right {
      .flex-direction(column);
      width: calc(100% - 5rem);

      .comment-header {
        .display-flex();
        .flex-direction(row);
        .justify-content(space-between);

        .comment-header-left {
          .flex-direction(column);
          .align-self(flex-start);

          .comment-name {
            font-weight: @ax-font-weight-bold;
            margin: 0;
            font-size: @ax-font-size-s;
          }

          .comment-when {
            color: @secondary-text-color;
            margin-bottom: @ax-spacing-s;
          }
        }

        .comment-header-right {
          align-self: flex-end;

          .ellipsis-menu-region {
            position: relative;

            // overriding some of the default styling of this component
            .menu-dropdown__toggle-button {
              border: none;
              background: none;
            }

          }
        }
      }

      .comment-body p {
        overflow-wrap: break-word;
        word-wrap: break-word;

        &.ltr-content {
          .ltr-content();
        }

        &.rtl-content {
          .rtl-content();
        }
      }
    }
  }

  .comment-form {
    .comment-form-group {
      .flex-direction(column);
      overflow: hidden;
      margin-top: @ax-spacing-l;
      margin-left: @ax-spacing-xxl;

      .comment-textarea {
        .full-width();
        padding: @ax-spacing-s;
        margin-bottom: @ax-spacing-s;
        resize: none;
        overflow: hidden;
        box-sizing: border-box;
        min-height: 4rem;
      }

      button {
        float: right;
      }
    }
    &.add-mobile-whitespace {
      margin-bottom: 25vh;
    }
  }
}

.inappropriate-content-message .icon-exclamation_sign {
  margin-right: @ax-spacing-s;
}

// This defines the article and list views for the Reactions UI.
.search__results:not(.ax-grid),
.article-search__results-region:not(.grid-view),
.search__article-results-region,
.post-container,
.article-footer {
  .reactions-region {
    z-index: 3; // For non-grid view: Must be >= to that of the video+controls components, otherwise reactions pop under on multiple views (incl. timeline for posts with videos)
  }

  .reaction-info-group-left {
    cursor: pointer; // needed to indicate top reactions can open a reactions modal

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }
  }

  .ax-breakpoint-m({
    .reactions-region .reaction-view-container .reaction-buttons {
      .flex-direction(row);

      .reaction-info-group {
        .reaction-info-group-left .middot {
          margin: 0 @ax-spacing-xs;
        }
      }

      .reaction-buttons-group {
        margin-top: 0;

        button:not(.reaction-button){
          padding: @ax-spacing-xs @ax-spacing-s;
        }
      }
    }

    .comms-enabled .reaction-buttons-group.show-top-border {
      border-top: none;
      padding-top: 0;
    }

  });
}

// in the article footer, we don't want top-margin above the hr and on large screens want the buttons to be pushed to one side
.article-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: -@ax-spacing-s; // Adjust for padding causing text to bleed under reactions bar.
  z-index: 3; // Must be >= to that of the video+controls components, otherwise reactions pop under on the article view

  .reaction-view-container {
    margin-top: 0;

    .reaction-buttons {
      background-color: @white;
    }
  }
}

.article-search__results-region,
.search__article-results-region,
.article-details__content {
  &.grid-view {
    // Modify the discover grid view

    .reactions-region .reaction-info-group,
    .reactions-region .reaction-buttons-group {
      .full-width();
    }

    .reactions-region .reaction-view-container .reaction-buttons {
      padding-bottom: @ax-spacing-xs;
    }

    // in grid view, let's make those buttons always wrap to two lines. It solves a
    // lot of positioning headaches on narrow screens
    .reaction-bar-button {
      .full-width();

      .reaction-bar-button-text {
        .text-ellipsis();
        display: block;
      }

      .reaction-bar-button-icon {
        display: block !important;
      }
    }

    .reaction-bar-button-container,
    .favorite-region {
      .flex(~"1 1 0px"); // needs to be passed in as a string, to ensure CSS generator doesn't strip out `px` (needed for browser support)
      .full-width();
    }
  }
}

.article-search__results-region,
.search__article-results-region {
  .reactions-region .reaction-view-container .reaction-buttons {
    padding-top: @ax-spacing-s;
  }
}

.article-details__content .reactions-region .reaction-view-container .reaction-buttons {
  padding-bottom: @ax-spacing-xs;
}

span {
  &.reaction-icon__filled--celebrate {
    background:url('@training/assets/images/svgicons/reaction-celebrate-filled.svg') center/contain no-repeat;
  }
  &.reaction-icon__outline--celebrate {
    background:url('@training/assets/images/svgicons/reaction-celebrate-outline.svg') center/contain no-repeat;
  }

  &.reaction-icon__filled--like {
    background:url('@training/assets/images/svgicons/reaction-like-filled.svg') center/contain no-repeat;
  }
  &.reaction-icon__outline--like {
    background:url('@training/assets/images/svgicons/reaction-like-outline.svg') center/contain no-repeat;
  }

  &.reaction-icon__filled--love {
    background:url('@training/assets/images/svgicons/reaction-love-filled.svg') center/contain no-repeat;
  }
  &.reaction-icon__outline--love {
    background:url('@training/assets/images/svgicons/reaction-love-outline.svg') center/contain no-repeat;
  }

  &.reaction-icon__filled--thinking {
    background:url('@training/assets/images/svgicons/reaction-thinking-filled.svg') center/contain no-repeat;
  }
  &.reaction-icon__outline--thinking {
    background:url('@training/assets/images/svgicons/reaction-thinking-outline.svg') center/contain no-repeat;
  }

  &.reaction-icon__filled--upset {
    background:url('@training/assets/images/svgicons/reaction-upset-filled.svg') center/contain no-repeat;
  }
  &.reaction-icon__outline--upset {
    background:url('@training/assets/images/svgicons/reaction-upset-outline.svg') center/contain no-repeat;
  }
}
