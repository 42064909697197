@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.content-scroll-down-container {
    position: relative;
    height: 0;
    width: 100%;
    z-index: 2;

    .content-scroll-down {
        .transform(translateX(-50%));
        position: absolute;
        bottom: @ax-spacing-s;
        left: 50%;

        button {
            .size(auto);
            border-radius: @ax-line-height-m / 2;
            padding: 0 (@ax-line-height-m / 2);
            white-space: nowrap;

            span {
                line-height: @ax-line-height-m;
                vertical-align: top;
            }

            .content-scroll-down-arrow {
                color: @primary-grey;

                &:first-child {
                    margin-right: .5rem;
                }

                &:last-child {
                    margin-left: .5rem;
                }
            }
        }
    }
}

html[dir='rtl'] .content-scroll-down-container .content-scroll-down {
    .transform(translateX(50%));
}
