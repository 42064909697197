@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.attachment__container {
  margin: 10px 10px 0 0;
  position: relative;
  line-height: 3.6rem;
  overflow: visible;

  .attachment-icon {
    font-size: @dz-attachment-icon-size;
    line-height: 4rem;
    text-align: center;
    vertical-align: top;
    display: inline-block;
    width: 3.2rem;
  }

  .attachment-name {
    height: auto;
    max-height: 4rem;
    width: calc(100% - 5rem);
    cursor: default;
    font-size: 1.3rem;
    .text-ellipsis;
    display: inline-block;
    vertical-align: middle;
    line-height: @ax-line-height-xs;
    margin: 0;
  }

  .attachment--viewable {
    cursor: pointer;
    color: @ax-color-blue-60;
    border: none;
    background: none;
    text-align: left;
    padding: 0;

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }
  }

  .action-button {
    .border-radius(2.5rem);
    margin: 0;
    background-color: @ax-color-grey-60;
    position:absolute;
    right: -10px;
    top: -10px;
    width: 2.6rem;
    height: 2.6rem;
    padding: 0;
    cursor: pointer;
    color: @white;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.6rem;

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }
  }

  .action-button--download {
    &:focus,
    &:hover {
      background-color: @ax-color-grey-70;
    }
  }
  .action-button--delete {
    &:hover {
      background-color: @ax-color-grey-70;
    }
  }
}
