@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.media-zoom.media-zoom--image * {
  pointer-events: none;
}

.media-zoom {
  .opacity(0);
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 0;
  overflow: hidden;

  &.media-zoom--video {
    display: flex;
    align-items: center;
    justify-content: center;

    .media-zoom-wrapper {
      width: 100%;
    }
  }

  .media-zoom-wrapper {
    max-width: 100%;
    max-height: 100%;

    .media-zoom-region {
      background: black;
      .box-shadow(0px 0px 90px 30px rgba(0, 0, 0, 0.8));
    }

    .loading-wrapper-container .spinner-wrapper .spinner-blanket {
      background: black;
    }
  }

  .media-close-button {
    .button-reset-style();
    pointer-events: auto;
    .absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 4rem;
    height: 4rem;

    span {
      color: darken(@white, 20%);
      font-size: 4rem;
      .text-stroke();

      &:hover {
        color: @white;
      }
    }

    &.focus-visible:focus {
      outline: solid 3px @primary-blue; // IE fallback
      outline: auto 5px @primary-blue;

      span {
        color: @white;
      }
    }
  }
}
