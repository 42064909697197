.header-slideout {
  .display-flex();
  .align-items(center);
  color: @white;
  height: 100%;
}

.header-slideout__button {
  .display-flex();
  .align-items(center);
  .justify-content(center);
  color: @white;
  height: @ms-teams-header-height;
  width: @ms-teams-header-height;

  &:hover {
    cursor: pointer;
    background: rgba(@white, @ax-opacity-l);
  }
}


.header-slideout__menu {
  position: fixed;
  top: @ms-teams-header-height;
  width: 236px;
  height: calc(100% - @ms-teams-header-height);
  left: 0;
  z-index: 100;
  background-color: @ax-color-grey-20;
  overflow-x: hidden;
  overflow-y: auto;
}

.header-slideout__item {
  .display-flex();
  .align-items(center);
  .box-sizing(border-box);
  min-width: 100%;
  background-color: @ax-color-grey-20;
  border: 0;
  border-left: .3rem solid transparent;
  border-right: .3rem solid transparent;
  color: @ax-color-text;
  padding: @ax-spacing-s;
  white-space: nowrap;

  &.selected {
    border-left: .3rem solid @black;
  }

  &:hover {
    background: rgba(@white, @ax-opacity-l);
  }

  &.hover,
  &.active,
  &.focus-visible:focus {
    background-color: rgba(@white, @ax-opacity-l);
  }

  &.focus-visible:focus {
    .ax-focus-keyboard(inset);
  }

  .header-slideout__item-wrapper {
    .display-flex();
    .align-items(center);
    .box-sizing(border-box);
    //min-width: 100%; // width: 100% in a button sets a min-width: 0 on Firefox 38
    background-color: @ax-color-grey-20;
    border: 0;
    border-left: .3rem solid transparent;
    border-right: .3rem solid transparent;
    color: @ax-color-text;
    padding-left: @ax-spacing-s;
    white-space: nowrap;

    &.selected {
      border-left: .3rem solid @black;
    }

    &.hover,
    &.active,
    &.focus-visible:focus {
      background-color: rgba(@white, @ax-opacity-l);
    }

    ;
  }

  &.focus-visible:focus {
    .ax-focus-keyboard(inset);
  }

  .header-slideout__item-wrapper {
    .display-flex();
    .align-items(center);
    font-size: inherit;
  }

  .header-slideout__item-icon {
    font-size: 2rem;
    color: @ax-color-text;
    margin-right: @ax-spacing-s;
    opacity: 1;
  }

  .header-slideout__item-text {
    font-size: @ax-font-size-xs;
  }
}

.header-slideout__item-icon {
  font-size: 2rem;
  color: @ax-color-text;
  margin-right: @ax-spacing-s;
  opacity: 1;
}

.header-slideout__item-text {
  font-size: @ax-font-size-xs;
}
