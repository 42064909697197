@_top-performers-border-highlight: .3rem;
@_top-performers-size-icon: 6.7rem;

h1.page-header__content {
  margin-bottom: 0;
}

.how-to-link {
  margin-right: @ax-gutter-page;
}

.leaderboard-view .datepicker-wrapper {
  margin-top: @ax-spacing-m;
}

.top-performers-list-left {
  margin-bottom: @ax-spacing-l;
}

.top-performers-list-left,
.top-performers-list-right {
  margin-top: @ax-spacing-l;

  .ax-breakpoint-m({
    margin-bottom: @ax-spacing-m;
  });

  table {
    table-layout: fixed;
    width: 100%;

    th.cell-rank {
      width: 6rem;

      .ax-breakpoint-m({
        width: 10rem;
      });
    }

    th.cell-photo {
      width: 4rem;

      .ax-breakpoint-m({
        width: 10rem;
      });
    }

    th.cell-points {
      width: 10rem;

      .ax-breakpoint-m({
        width: 15rem;
      });
    }

    &.usertable {
      tr {
        &:nth-child(2) {
          border-top: 0;
        }
      }
    }
  }
}

tr.ranked-row {
  border-bottom: .1rem solid @accent-grey;
  border-top: .1rem solid @accent-grey;
  height: 7.5rem;
  padding: 0;

  &:first-child {
    border-top: 0;
  }

  .rank-wrapper {
    height: 100%;
    line-height: 100%;
    min-width: 5rem;

    .rank {
      height: @_top-performers-size-icon;
      line-height: @_top-performers-size-icon;

      .trophy {
        border: 0;
        display: block;
        float: left;
        font-size: 4.5rem;
        height: @_top-performers-size-icon;
        line-height: @_top-performers-size-icon;
        margin: 0;
        padding: 0;
        width:  100%;
      }
    }
  }

  td {
    border: 0;
    padding: 0;

    .ax-breakpoint-m({
      padding: 0 @ax-spacing-s;
    });

    .name-logo {
      background-color: @accent-grey;
      height: 3.5rem;
      line-height: 2.7em;
      margin: @ax-spacing-s 0;
      width:  3.5rem;

      .ax-breakpoint-m({
        height: 4.5rem;
        line-height: 4.5rem;
        width:  4.5rem;
      });
    }

    .name {
      position: relative;
    }

    &.avatar-wrapper {
      max-width: 5rem;

      .name-logo {
        font-size: 1.3rem;
        margin-left: auto;
        margin-right: @ax-spacing-m;
      }
    }
  }

  &.you {
    font-weight: @ax-font-weight-medium;

    td {
      background-color: @secondary-bg;

      .ax-breakpoint-m({
        background-color: @element-hover-bright;
      });
    }

    .rank-wrapper {
      padding-left: 0;

      .rank {
        border-left: @_top-performers-border-highlight solid @training-color;
        text-indent: -@_top-performers-border-highlight;

        .ax-breakpoint-m({
          padding-left: @ax-spacing-xs + @_top-performers-border-highlight;
        });

        &.bronze-border {
          border-color: @ax-color-bronze;
        }

        &.gold-border   {
          border-color: @ax-color-gold;
        }

        &.silver-border {
          border-color: @ax-color-silver;
        }
      }
    }
  }
}
