// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//  COLUMN MODIFIERS
//  See documentation in ax-grid.less

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// - - - - - - - - - - - - - - - - -
//   AUTO SIZE
// - - - - - - - - - - - - - - - - -
// Scale column size to fit content size
._AutoSizeRule(@size-prefix: ~'') {
  @_auto-size-class: ~'.ax-grid__col--@{size-prefix}auto-size';

  // Set width for rows and height for columns
  .ax-grid,
  .ax-grid.ax-grid--no-gutter {
    > @{_auto-size-class} {
      .flex(0 0 auto);
    }

    &:not(ax-grid--@{size-prefix}direction-column) > @{_auto-size-class} {
      width: auto;
    }

    &.ax-grid--@{size-prefix}direction-column > @{_auto-size-class} {
      height: auto;
    }
  }
}

// Create rules
._AutoSizeRule();

.ax-breakpoint-m({
  ._AutoSizeRule(@ax-breakpoint-prefix-optional-m);
});

.ax-breakpoint-l({
  ._AutoSizeRule(@ax-breakpoint-prefix-optional-l);
});

// - - - - - - - - - - - - - - - - -
//   OFFSET
// - - - - - - - - - - - - - - - - -
// Setting an offset is closely connected to the base size of columns
// The actual usage of the offset mixin can be found within the initial column loop
._create-column-offset(@column, @gutter, @size-prefix) {
  @_size: percentage(@column / @_grid-column-count);
  @_size-plus-half-gutter: calc(@_size + (@gutter / 2));
  @_offset-class: ~'ax-grid__col--@{size-prefix}offset-@{column}';

  > .@{_offset-class} {
    margin-left: @_size-plus-half-gutter;
  }

  &.ax-grid--no-gutter > .@{_offset-class} {
    margin-left: @_size;
  }
}

// - - - - - - - - - - - - - - - - -
//   ALIGN SELF
// - - - - - - - - - - - - - - - - -
// Align specified column vertically/horizontally
._AlignSelfRules(@size-prefix: ~'') {
  .ax-grid .ax-grid__col--@{size-prefix}align-self-start {
    .align-self(flex-start);
  }
  .ax-grid .ax-grid__col--@{size-prefix}align-self-center {
    .align-self(center);
  }
  .ax-grid .ax-grid__col--@{size-prefix}align-self-end {
    .align-self(flex-end);
  }
  .ax-grid .ax-grid__col--@{size-prefix}align-self-stretch {
    .align-self(stretch);
  }
}

// Create rules
._AlignSelfRules();

.ax-breakpoint-m({
  ._AlignSelfRules(@ax-breakpoint-prefix-optional-m);
});

.ax-breakpoint-l({
  ._AlignSelfRules(@ax-breakpoint-prefix-optional-l);
});

// - - - - - - - - - - - - - - - - -
//   GROW
// - - - - - - - - - - - - - - - - -
//
// The actual usage of the grow mixin can be found within the initial column loop
._create-column-grow(@column, @size-prefix) {
  > .ax-grid__col--@{size-prefix}grow-@{column} {
    .flex-grow(@column);
  }

}

// - - - - - - - - - - - - - - - - -
//   ORDER
// - - - - - - - - - - - - - - - - -
// Setting an order utilizes the column loop to set each order value
// The actual usage of the order mixin can be found within the initial column loop
._create-column-order(@column, @size-prefix) {
  > .ax-grid__col--@{size-prefix}order-@{column} {
    .flex-order(@column);
  }
}
