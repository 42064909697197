@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.translatable-name {
  .border-radius(@ax-border-radius-m);
  border: 0.1rem solid @ax-color-grey-30;
  padding: @ax-spacing-m;
  margin-bottom: @ax-spacing-m;

  label {
    display: block;
    font-weight: @ax-font-weight-medium;
  }

  label[for="name"],
  label[for="description"] {
    margin-top: @ax-spacing-l;
  }

  &__limit {
    margin-bottom: @ax-spacing-l;
    color: @ax-color-grey-60;
  }

  .icon-exclamation {
    margin-right: @ax-spacing-s;
    color: @ax-color-blue-60;
  }
}
