@mobile-dimensions: @ax-avatar-size-sm;
@desktop-dimensions: @ax-avatar-size-md;

.user-profile-icon {
  width: @mobile-dimensions;
  height: @mobile-dimensions;
  margin: @ax-spacing-xs;
  text-align: center;
  color: @white;
  line-height: @mobile-dimensions;
  font-size: 1.1rem;
  background-color: @secondary-grey;
  .border-radius(50%);
}

.header-dropdown-button .user-profile-icon {
  margin: 0 @ax-spacing-xs 0 0;
}

&.header-top .user-profile-icon {
  width:  @desktop-dimensions;
  height: @desktop-dimensions;
  font-size: 1.6rem;
  line-height: @desktop-dimensions;
}
