@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.externalSourceIFrame {
  background-color: @white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;

  .header {
    background-color: @accent-grey;
    text-align: center;

    .page-title {
      font-size: @topic-font-size;
      font-weight: @ax-font-weight-medium;
      .text-ellipsis;
      background: @accent-grey;
      display: inline-block;
      padding: 0.5em 0.4em;
      width: 80%;
    }
  }

  .close {
    position: absolute;
    cursor: pointer;
    z-index: 810;
    right: 0;
    background-color: transparent;
    border: none;
    font-size: 2.2rem;
    margin: 7px;
    opacity: 1;
  }

  .contentwrapper {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    overflow: auto;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
}
