@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
#pdf-container {
  .open-pdf-button-container {
    text-align: center;

    .open-pdf {
      margin: 3rem 1rem;
    }
  }
}
