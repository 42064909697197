.post-container {
  max-width: 72rem;
  margin: @ax-spacing-s auto;
  background: @white;
  margin-bottom: 6rem; // required so it clears the footer

  .border-radius(@ax-border-radius-m);
  .box-shadow(@ax-box-shadow-s);
  .box-sizing(border-box);

  .reactions-region {
    background: @white; // because it needs to obscure things that scroll beneath it
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .comments-region {
    .comments-container {
      padding: @ax-spacing-m;
    }
  }

  .content.ltr-content {
    .ltr-content();
  }

  .content.rtl-content {
    .rtl-content();
  }
}
