.learning-path-explorer__header {
  font-weight: @ax-font-weight-bold;
  margin-top: 0;
}

.learning-path-explorer__stacked-content {
  padding-bottom: @ax-spacing-m;

  .search__results > li {
    margin-bottom: @ax-spacing-m;
  }
}

.learning-path-explorer__search-box {
  padding-top: @ax-spacing-m;
  padding-bottom: @ax-spacing-xl;
  width: 100%;
}

.learning-path-explorer__empty-results {
  font-weight: @ax-font-weight-bold;
}
