@import (reference) "@common/prerequisites/prerequisites.less";

// Vendor file, avoid changes. Edit the DuetDatePicker file instead.

// ---------------------------------------------
// DUET DATE PICKER
// ---------------------------------------------

.duet-date *,
.duet-date *::before,
.duet-date *::after {
  box-sizing: border-box;
  margin: 0;
  width: auto;
}

.duet-date {
  box-sizing: border-box;
  color: var(--duet-color-text);
  display: block;
  font-family: var(--duet-font);
  margin: 0;
  position: relative;
  text-align: left;
  width: 100%;
}

// ---------------------------------------------
// DUET DATE PICKER __ INPUT
// ---------------------------------------------

.duet-date__input {
  -webkit-appearance: none;
  appearance: none;
  background: var(--duet-color-surface);
  border-radius: var(--duet-radius);
  color: var(--duet-color-text);
  float: none;
  font-family: var(--duet-font);
  font-size: 100%;
  line-height: normal;
  padding-left: 0.7em; // instead of padding: 14px 60px 14px 14px;
  width: 100%;

  &:focus {
    border-color: var(--duet-color-primary);
    box-shadow: 0 0 0 1px var(--duet-color-primary);
    outline: 0;
  }

  &::-webkit-input-placeholder {
    color: var(--duet-color-placeholder);
    opacity: 1;
  }

  &:-moz-placeholder {
    color: var(--duet-color-placeholder);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: var(--duet-color-placeholder);
  }
}

.duet-date__input-wrapper {
  position: relative;
  width: 100%;
}

// ---------------------------------------------
// DUET DATE PICKER __ TOGGLE
// ---------------------------------------------

.duet-date__toggle {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  align-items: center;
  appearance: none;
  background: var(--duet-color-button);
  border: 0;
  border-radius: 0;
  border-bottom-right-radius: var(--duet-radius);
  border-top-right-radius: var(--duet-radius);
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1);
  color: var(--duet-color-text);
  cursor: pointer;
  display: flex;
  height: calc(100% - 2px);
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 1px;
  user-select: none;
  width: 48px;
  z-index: 2;

  &:focus {
    box-shadow: 0 0 0 2px var(--duet-color-primary);
    outline: 0;
  }
}

.duet-date__toggle-icon {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
}

// ---------------------------------------------
// DUET DATE PICKER __ DIALOG
// ---------------------------------------------

.duet-date__dialog {
  display: flex;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 100%;
  transform: scale(0.96) translateZ(0) translateY(-20px);
  transform-origin: top left;
  transition: transform 300ms ease, opacity 300ms ease, visibility 300ms ease;
  visibility: hidden;
  width: 100%;
  will-change: transform, opacity, visibility;
  z-index: var(--duet-z-index);

  @media (max-width: 35.9375em) {
    background: var(--duet-color-overlay);
    bottom: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateZ(0);
    transform-origin: bottom center;
  }

  &.is-left {
    left: auto;
    right: 0;
    width: auto;
  }

  &.is-active {
    opacity: 1;
    // The value of 1.0001 fixes a Chrome glitch with scaling
    transform: scale(1.0001) translateZ(0) translateY(0);
    visibility: visible;
  }
}

.duet-date__dialog-content {
  background: var(--duet-color-surface);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--duet-radius);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  margin-left: 0;
  margin-top: 0;
  min-width: 10rem;
  padding: 1rem 1rem 1.25rem;
  position: relative;
  transform: none;
  width: 80%;
  z-index: var(--duet-z-index);

  @media (max-width: 35.9375em) {
    border: 0;
    border-radius: 0;
    border-top-left-radius: var(--duet-radius);
    border-top-right-radius: var(--duet-radius);
    bottom: 0;
    left: 0;
    margin: 0;
    max-width: none;
    min-height: 26em;
    opacity: 0;
    padding: 0 8% 20px;
    position: absolute;
    transform: translateZ(0) translateY(100%);
    transition: transform 400ms ease, opacity 400ms ease, visibility 400ms ease;
    visibility: hidden;
    will-change: transform, opacity, visibility;

    .is-active & {
      opacity: 1;
      transform: translateZ(0) translateY(0);
      visibility: visible;
    }
  }
}

// ---------------------------------------------
// DUET DATE PICKER __ TABLE
// ---------------------------------------------

.duet-date__table {
  border-collapse: collapse;
  border-spacing: 0;
  color: var(--duet-color-text);
  font-size: @ax-font-size-s;
  font-weight: var(--duet-font-normal);
  line-height: 1.25;
  text-align: center;
  width: 100%;
}

// reset values
.duet-date__table > thead {
  background: none;
  border-bottom: none;
}

.duet-date__table-header > thead,
.duet-date__table-header > th {
  padding: 0;
}

.duet-date__table-header {
  font-size: @ax-font-size-s;
  font-weight: var(--duet-font-bold);
  letter-spacing: 1px;
  line-height: 1.25;
  padding-bottom: 0.5rem;
  text-decoration: none;
  text-transform: uppercase;
  background: none;
}

.duet-date__cell {
  text-align: center;
  padding: 0.5em 0 0.5em 0.5em;
}

.duet-date__day {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 50%;
  color: var(--duet-color-text);
  cursor: pointer;
  display: inline-block;
  font-family: var(--duet-font);
  font-size: @ax-font-size-s;
  font-variant-numeric: tabular-nums;
  font-weight: var(--duet-font-normal);
  height: 2.25rem;
  line-height: 1.25;
  padding: 0.2rem;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 2.25rem;
  z-index: 1;

  &.is-today {
    box-shadow: 0 0 0 1px var(--duet-color-primary);
    position: relative;
    z-index: 200;
  }

  &:hover::before,
  &.is-today::before {
    background: var(--duet-color-primary);
    border-radius: 50%;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.06;
    position: absolute;
    right: 0;
    top: 0;
  }

  &[aria-pressed="true"],
  &:focus {
    background: var(--duet-color-primary);
    box-shadow: none;
    color: var(--duet-color-text-active);
    outline: 0;
  }

  &:active {
    background: var(--duet-color-primary);
    box-shadow: 0 0 5px var(--duet-color-primary);
    color: var(--duet-color-text-active);
    z-index: 200;
  }

  &:focus {
    box-shadow: 0 0 5px var(--duet-color-primary);
    z-index: 200;
  }

  &:not(.is-month) {
    box-shadow: none;
  }

  &:not(.is-month),
  &[aria-disabled="true"] {
    background: transparent;
    color: var(--duet-color-text);
    cursor: default;
    opacity: 0.5;
  }

  &[aria-disabled="true"] {
    &.is-today {
      box-shadow: 0 0 0 1px var(--duet-color-primary);

      &:focus {
        box-shadow: 0 0 5px var(--duet-color-primary);
        background: var(--duet-color-primary);
        color: var(--duet-color-text-active);
      }
    }

    &:not(.is-today)::before {
      display: none;
    }
  }

  &.is-outside {
    background: var(--duet-color-button);
    box-shadow: none;
    color: var(--duet-color-text);
    cursor: default;
    opacity: 0.6;
    pointer-events: none;

    &::before {
      display: none;
    }
  }
}

// ---------------------------------------------
// DUET DATE PICKER __ HEADER
// ---------------------------------------------

.duet-date__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

// ---------------------------------------------
// DUET DATE PICKER __ NAVIGATION
// ---------------------------------------------

.duet-date__nav {
  white-space: nowrap;
  flex-direction: row-reverse;
}

.duet-date__prev,
.duet-date__next {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  background: var(--duet-color-button);
  border: 0;
  border-radius: 50%;
  color: var(--duet-color-text);
  cursor: pointer;
  display: inline-flex;

  justify-content: center;
  margin-left: 0.5rem;
  padding: 0;
  transition: background-color 300ms ease;
  height: 2.5rem;
  width: 2.5rem;

  @media (max-width: 35.9375em) {
    height: 2.5rem;
    width: 2.5rem;
  }

  &:focus {
    .ax-focus-keyboard();
    outline: 0;
  }

  &:active:focus {
    box-shadow: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  svg {
    margin: 0 auto;
  }
}

// ---------------------------------------------
// DUET DATE PICKER __ SELECT
// ---------------------------------------------

.duet-date__select {
  display: inline-flex;
  margin-top: 0.25rem;
  position: relative;

  span {
    margin-right: 0.25rem;
  }

  select {
    cursor: pointer;
    font-size: @ax-font-size-m;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    &:focus + .duet-date__select-label {
      .ax-focus-keyboard();
    }
  }
}

.duet-date__select-label {
  align-items: center;
  border-radius: var(--duet-radius);
  color: var(--duet-color-text);
  display: flex;
  font-size: @ax-font-size-m;
  font-weight: var(--duet-font-bold);
  line-height: 1.25;
  padding: 0 0.25rem 0 0.5rem;
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: 1;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

// ---------------------------------------------
// DUET DATE PICKER __ MOBILE
// ---------------------------------------------

.duet-date__mobile {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  margin-left: -10%;
  overflow: hidden;
  padding: 0.75rem 1.25rem;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120%;

  @media (min-width: 36em) {
    border: 0;
    margin: 0;
    overflow: visible;
    padding: 0;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    width: auto;
  }
}

.duet-date__mobile-heading {
  display: inline-block;
  font-weight: var(--duet-font-bold);
  max-width: 84%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 36em) {
    display: none;
  }
}

// ---------------------------------------------
// DUET DATE PICKER __ CLOSE
// ---------------------------------------------

.duet-date__close {
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  background: var(--duet-color-button);
  border: 0;
  border-radius: 50%;
  color: var(--duet-color-text);
  cursor: pointer;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  padding: 0;
  width: 1.5rem;

  @media (min-width: 36em) {
    opacity: 0;
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--duet-color-primary);
    outline: none;

    @media (min-width: 36em) {
      opacity: 1;
    }
  }

  svg {
    margin: 0 auto;
  }
}

// ---------------------------------------------
// DUET DATE PICKER __ VISUALLY HIDDEN
// ---------------------------------------------

.duet-date__vhidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}
