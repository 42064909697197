.learningevent-detail,
.learningevent-attendees {
  padding: @ax-spacing-m 0 0;
}

.learningevent-detail h3 {
  font-size: @ax-font-size-l;
  font-weight: @ax-font-weight-medium;
}

.learning-events-details-page {
  .learningevent-full-notice {
    background-color: @element-hover-bright;
    font-weight: @ax-font-weight-medium;
    margin-top: @ax-spacing-m;
    padding: @ax-spacing-s;

    .ax-breakpoint-m({
      padding: @ax-spacing-m;
    });
  }

  .learningevent-location,
  .instructor-container,
  .learningevent-description,
  .learningevent-link {
    padding-bottom: 0;

    .learningevent-details-link.focus-visible:focus {
      box-shadow: inset 0px 0px 2px 2px @primary-blue;
    }
  }

  .learningevent-title {
    font-size: @ax-font-size-xl;
    font-weight: @ax-font-weight-regular;
    margin: 0 0 @ax-spacing-l;
  }

  .learningevent-description {
    max-width: 38em;
  }

  .learningevent-eventcode {
    color: @primary-grey;
  }

  .learningevent-sessions {
    border-bottom: .1rem solid @accent-grey;
    margin-bottom: @ax-spacing-l;
    padding-bottom: @ax-spacing-l;
  }

  .userview-user {
    margin-bottom: @ax-spacing-l;
  }
}

.sessioninfo-date,
.sessioninfo-room {
  color: @primary-grey;
}

.learningevent-list {
  padding: @ax-spacing-m 0;
}

.event-item.focus-visible:focus .learning-event-item {
  .ax-focus-keyboard();
}

.learning-events-details-session {
  .sessioninfo {
    display: block;

    .sessioninfo-cell:first-of-type {
      font-weight: @ax-font-weight-medium;
    }
  }
}

.learningevent-attendees {
  .hidden-users-snapshot-view,
  .userview-user {
    border-bottom: .1rem solid @accent-grey;
    padding: @ax-spacing-s 0;

    .ax-breakpoint-m({
      border-bottom: none;
      margin-bottom: @ax-spacing-m;
      padding: 0;
    });
  }

  .hidden-users-snapshot-view {
    margin-top: 0;

    .user-info {
      .display-flex();
      .flex-direction(column);
      .justify-content(center);
      line-height: @ax-line-height-xs;
      padding-top: 0;

      .hidden-attendees,
      .user-name {
        margin-left: 0;
        color: @ax-color-text;
      }
    }
  }
}

.event-details.page-content .tab-container {
  // Prevent tab content width change when changing tabs
  // But not if child is ax-grid, because that width is already correct
  position: relative;

  > div:not(.ax-grid) {
    width: 100%;
  }
}
