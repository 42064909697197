@import 'dzview/attachment.less';
@import 'dzview/dz-article.less';
@import 'dzview/broadcast-article.less';

.broadcast-message__link-content {
  margin-top: 5rem;
}

.axon-button.open-dz-button {
  min-width: 16.4rem;
  margin-top: 1.5em;
  text-decoration: none;
  font-size: 1.2rem;

  .icon-external_link {
    font-size: 1.4rem;
    margin-right: 0.7rem;
  }
}
