.inline-datepicker-wrapper {
  margin-top: 1.5em;
}

.month-year-date-select {
  display: inline-block;
  min-width: 1.3rem;
}

.direction-button {
  .button-reset-style();
  background: @secondary-bg;
  border: 1px solid @secondary-grey;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  text-align: center;

  &.prev-button {
    margin-right: -5px;
    margin-left: 5px;
    .border-radius(@ax-border-radius-m 0 0 @ax-border-radius-m);
  }

  &.next-button {
    .border-radius(0 @ax-border-radius-m @ax-border-radius-m 0);
  }

  &.button-disabled {
    cursor: default !important;
    opacity: 0.35;
    pointer-events: none;
  }

  &.focus-visible:focus {
    outline: solid 3px @primary-blue;
    outline: auto 5px @primary-blue;
  }

  span {
    font-size: 1.6rem;
  }
}

.direction-button:hover {
  background: @white;
}
