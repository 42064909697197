.progress-card {
  text-align: center;
  padding: 1rem 8rem;

  .ax-breakpoint-m({
    padding: 2.4rem 4rem;
  });

  h2,
  h3 {
    margin: 0;
  }

  h3 {
    color: @ax-color-grey-70;
    font-size: @ax-font-size-xs;
    margin-bottom: @ax-spacing-m;
    text-transform: uppercase;
    font-weight: @ax-font-weight-regular;

    .ax-breakpoint-m({
      margin-bottom: 4rem;
    })
  }

  h2 {
    font-size: 3.6rem;
    margin-bottom: 2rem;
    color: @ax-color-grey-60;
  }

  .progress-value {
    font-weight: @ax-font-weight-medium;
    color: @ax-color-grey-60;
    font-size: 3.6rem;
    margin-bottom: 2rem;
  }

  .progress-bar-inset {
    background-color: @primary-blue;
  }
}
