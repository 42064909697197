@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "app-common.less";

@import "@common/prerequisites/assets/stylesheets/variables.less";
@import (reference) "@common/prerequisites/assets/stylesheets/mixins/mixins.less";

/*-- SPINNER --*/
#spinner,
.spinner {
  .border-radius(@ax-border-radius-l);
  position: fixed;
  left: 50%;
  top: 40%;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  background-color: @black;
  z-index: 9999;
}

#spinner .img,
.spinner .img {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 32px;
  height: 32px;
  background-image: url('@common/assets/images/loading_new_black.gif');
  .backface-cull-fix();
  background-position: center center;
  background-repeat: no-repeat;
  background-color: @black;
}

/*========================== Retina image overrides ==========================*/
/*
  See these articles about proper use of screen resolution media queries:
    http://www.w3.org/blog/CSS/2012/06/14/unprefix-webkit-device-pixel-ratio/
    http://css-tricks.com/snippets/css/retina-display-media-query/
*/
@media
only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (                min-resolution: 124.8dpi),
only screen and (                min-resolution: 1.3dppx) {

  #spinner .img,
  .spinner .img {
    background-image: url('@common/assets/images/loading_new_black@2x.gif');
    background-size: 32px;
  }

}
