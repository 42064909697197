@tag-group-view-height: 16rem;
@tag-group-filter-padding: @ax-spacing-s + @ax-spacing-xs;

.tag-group {
  text-align: left;
  border: 0;
  margin-bottom: 0;
  padding: 0;
  word-wrap: break-word;

  .tag-group__header{
    padding: @tag-group-filter-padding;
    .display-flex();

    .tag-group__title{
      font-size: @ax-font-size-m;
      font-weight: @ax-font-weight-medium;
      text-align: left;
      line-height: 2rem;
      margin-bottom: 0;
    }
  }

  .tag-group__buttons{
    padding: @ax-spacing-s;
    border-top: 0.1rem solid @secondary-grey;
    font-weight: @ax-font-weight-regular;
    button{
      margin: 0 @ax-spacing-xs 0 @ax-spacing-xs;
    }
  }

  .tag-group__checkboxes{
    padding: 0 @tag-group-filter-padding @ax-spacing-s @tag-group-filter-padding;
    font-size: @ax-font-size-s;
    font-weight: @ax-font-weight-regular;
    line-height: @ax-line-height-m;

    input[type=checkbox].focus-visible:focus {
      .ax-focus-keyboard();
    }

    li:not(:last-child) label {
      margin-bottom: @ax-spacing-m;
    }
  }

  .ax-breakpoint-m({
    &.tag-group__selected{
      border: 0.1rem solid @primary-blue;
    }
    margin-bottom: @ax-spacing-m;
    border: 0.1rem solid @secondary-grey;
    .box-shadow-black(0px, 1px, 2px, 0.1);
    .border-radius(@ax-border-radius-m);
  });

  .tag-group__modal-launch{
    .ax-breakpoint-m({
      display: none;
    })
  }

  .tag-group__clear-all-button{
    border: none;
    color: @black;
    background-color: @transparent-white;
    &.disabled {
      color: @primary-grey;
    }

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }
  }

  .scrollable-tag-group{
    max-height: @tag-group-view-height;
  }

  .tag-group__desktop-view{
    display: none;

    .ax-breakpoint-m({
      display: block;
    });

    &.tag-group__desktop-view--open {
      .js-tag-group-min-title {
        display: none;
      }
    }

    &:not(.tag-group__desktop-view--open) {
      .js-tag-group-open-title,
      .js-tag-group__contents {
        .off-screen();
      }
    }
  }

  .tag-group__apply-button.focus-visible:focus {
    .ax-focus-keyboard();
  }
}

.grouped-tags-region {
  .ax-breakpoint-m({
    margin-top: @ax-spacing-s;
  });

  .tag-group__modal-launch-icon{
    font-size: 1.5rem;
  }

  li {
    display: inline-block;
    .ax-breakpoint-m({
      display: block;
    })
  }
}

.tag-group-modal {
  word-wrap: break-word;
  .adjustable-content {
    padding: @ax-spacing-m;
  }

  h3 {
    font-size: @ax-font-size-m;
    margin-bottom: @ax-spacing-xl;
  }

  li {
    font-size: @ax-font-size-s;
    margin-bottom: @ax-spacing-l;
  }
}
