@import (reference) "@common/prerequisites/prerequisites.less";

.table-layout {
  .full-width();

  tbody tr {
    height: 7rem;

    &:nth-child(odd) {
      background-color: @ax-color-white;
    }

    td.empty-view:hover {
      background-color: @white;
      cursor: default;
    }
  }
}
