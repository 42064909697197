#tag-select-wrapper {
  ul {
    margin-left: 0;
  }

  ul li {
    border: none;
    padding: @ax-spacing-xs @ax-spacing-s;
  }

  ul li.tag-create__container {
    padding-bottom: @ax-spacing-m;
  }

  ul li input {
    min-height: 0;
  }

  ul li.select2-selection__choice {
    .text-ellipsis();

    .select2-selection__choice__remove {
      margin-right: @ax-spacing-s;
    }

    @media screen and (max-height: 600px) {
      padding-top: @ax-spacing-xs;
      padding-bottom: @ax-spacing-xs;
      margin-top: @ax-spacing-s;
    }
  }

  .select2-container--default .select2-selection--multiple {
    overflow-y: auto;
    max-height: calc(~"100vh - 380px"); // based roughly on current header and foot values, to leave enough room for select 2 dropdown
    border: .1rem solid @ax-color-grey-30;
    .border-radius(@ax-border-radius-m);
    font-weight: @ax-font-weight-regular;
    outline: 0;
    background-color: @ax-color-grey-20;
    .box-shadow-black(inset 0px, 1px, 2px, 0.1);
  }
}
