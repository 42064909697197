@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.js-language-chooser-dropdown {
  .menu-dropdown__button-container {
    button {
      width: 100%;
      font-size: @ax-font-size-s;
      padding: @ax-spacing-s;
      .display-flex();

      .dropdown-button-text {
        margin-right: @ax-spacing-xs;
        line-height: @ax-line-height-m;
      }

    }
  }
  .menu-dropdown__popover {
    width: 100%;
    max-height: 30rem;
    overflow-y: auto;

    button {
      padding: @ax-spacing-s;
      .display-flex();

      .dropdown-button-text {
        margin-right: @ax-spacing-xs;
      }
    }
  }

  .language-name {
    margin-right: @ax-spacing-xs;
    line-height: @ax-line-height-m;
  }

  .menu-dropdown__caret-down {
    position: absolute;
    right: @ax-spacing-s;
    margin-top: @ax-spacing-xxs;
  }

}

.menu-dropdown-button-inner-wrapper {
  .display-flex();
  padding-right: @ax-spacing-l;
}

.pill {
  font-size: 1.2rem;
  padding: @ax-spacing-xs @ax-spacing-m;
  border-radius: 1.5rem;
  background: @ax-color-grey-40;
  text-transform: uppercase;
  font-weight: normal;
  height: 1.8rem;
  line-height: 2.1rem;
  word-break: normal;
  white-space: nowrap;

  &--scheduled {
    background: @ax-color-blue-40;
  }

  &--published {
    background: @ax-color-green-40;
  }

  &--in-review {
    background: @ax-color-blue-40;
  }

  &--expired {
    background: @ax-color-orange-40;
  }

  &--reported {
    background: @ax-color-red-40;
  }
}

.expiry-helper-text {
  font-size: @ax-font-size-xs;
  font-family: @ax-font-family;
  color: @ax-color-grey-60;
  margin-top: @ax-spacing-xxs;
  margin-bottom: @ax-spacing-xxs;
}

.numeric-indicator {
  display: inline-block;
  background-color: @ax-color-grey-30;
  border-radius: 3rem;
  margin: 0 @ax-spacing-s 0 @ax-spacing-s;
  font-size: @ax-font-size-xs;
  padding: 0 @ax-spacing-xs;
}
