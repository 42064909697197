@_color_fill_reference: #E7F4FD;
@_color_text_reference: #0274B0;
@_color_fill_question: #D4F7EE;
@_color_text_question: #18775E;
@_color_fill_module: #E0DCF9;
@_color_text_module: #1206BE;

.timeline-card-content {
  &:not(.post-card-content) {
    border: 0.1rem solid @ax-color-grey-20;
    .border-radius(@ax-border-radius-m);
  }

  .content-container {
    padding: @ax-spacing-m @ax-spacing-s;

    .ax-breakpoint-l({
      padding: @ax-spacing-m;
    });

    .title-container {
      margin: 0;
    }

    .title {
      font-size: @ax-font-size-s;
      font-weight: @ax-font-weight-medium;
      margin: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      &.reference {
        color: @ax-color-blue-60;
        margin-top: @ax-spacing-s;

        p {
          display: inline-block;
          line-height: 2.1rem;
        }

        .icon-external_link {
          color: @ax-color-blue-60;
          opacity: 1;
        }

        &.focus-visible:focus {
          .ax-focus-keyboard();
        }
      }

      .screen-md-portrait-min({
        font-size: @ax-font-size-l;
        font-weight: @ax-font-weight-regular;
      });
    }

    .page-type-label {
      font-size: 1.2rem;
      border-radius: 1.5rem;
      display: inline-block;
      padding: @ax-spacing-xs @ax-spacing-s;
      text-align: center;
      margin-right: 0;

      &.reference {
        background: @_color_fill_reference;
        color: @_color_text_reference;
      }
      &.question {
        background: @_color_fill_question;
        color: @_color_text_question;
      }
      &.training {
        background: @_color_fill_module;
        color: @_color_text_module;
      }
    }

    .content {
      display: none;


      &.article-content {
        height: 2.4rem;
        margin-bottom: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .ax-breakpoint-m({
        font-size: @ax-font-size-s;
        color: @ax-color-grey-60;
        margin-top: @ax-spacing-s;
      });
    }

    .content,
    .content div {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }

  .image-container {
    width: 12.9rem;
    margin-left: @ax-spacing-m;

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }

    img {
      .flex(0 0 auto);
      .size(auto, auto);
      max-height: 15.5rem;
      .border-radius(@ax-border-radius-m);
      display: block;
      margin: 0 auto;
      object-fit: contain;
    }

    .zoom-overlay {
      position: relative;
      bottom: 0;
      right: 0.2rem;

      .zoom-icon {
        .border-radius(@ax-border-radius-m);
      }
    }
  }
}

.post-card-content {
  min-height: 5rem;

  .content-container {
    .display-flex();
    .flex-direction(column);
    padding: 0;

    .content {
      display: -webkit-box;
      margin: 0;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &.ltr-content {
        .ltr-content();
      }

      &.rtl-content {
        .rtl-content();
      }
    }

    .continue-link {
      .align-self(flex-end);
      padding: 0 1rem;
      background-color: @white;

      &:hover {
        text-decoration: underline;
      }

      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }
  }

  .image-container,
  .video-container {
    .full-width();
    height: 20rem;
    margin-top: @ax-spacing-m;
    margin-left: 0;
    border: 0.1rem solid @ax-color-grey-40;
    .border-radius(@ax-border-radius-m);
    background-color: @ax-color-grey-10;

    .empty-transcodings {
      text-align: center;
      border: none;
      background: @ax-color-grey-10;
    }

    .ax-breakpoint-l({
      height: 40rem;
    });
  }

  .image-container img {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    min-height: auto;
    height: 100%;
    display: block;
    margin: 0 auto;
  }
}
