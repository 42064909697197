[class*="icon-"] {
  font-size: @icon-button-size;
}

[class^="icon-"].icon--reset-styles {
  padding: 0;
  margin: 0;
  color: inherit;
  background-color: transparent;
}

.icon-question_sign {
  color: @primary-blue;
  margin-left: @ax-spacing-xs;
}

//Add number below an icon
.add-number-below-icon(
  @iconNumber,
  @iconFontSize,
  @iconFontSizeMobile,
  @containerSize,
  @containerSizeMobile,
  @fontSize: 1.2rem
) {
  .box-sizing();
  display: block;
  font-size: @iconFontSizeMobile;
  line-height: @iconFontSizeMobile;
  padding-top: .2rem;
  position: relative;

  .ax-breakpoint-m({
    font-size: @iconFontSize;
    line-height: @iconFontSize;
  });

  &:after {
    .align-items(flex-end);
    .box-sizing();
    .display-flex();
    .justify-content(center);
    bottom: 0;
    color: #fff;
    content: @iconNumber;
    font-family: @ax-font-family;
    font-size: @fontSize;
    font-weight: @ax-font-weight-bold;
    height: @containerSizeMobile;
    left: 0;
    line-height: normal;
    padding-bottom: .2rem;
    position: absolute;
    width: @containerSizeMobile;

    .ax-breakpoint-m({
      height: @containerSize;
      width: @containerSize;
    });
  }
}

//Alias file for icons that use the same glyph.
//Some icons are pre-styled to keep consistency throughout all apps.

.icon-snowflake {
  .icon-uniF2DC;
}
.icon-TopicLevelGraduation2,
.icon-TopicLevelGraduation3,
.icon-TopicLevelGraduation4,
.icon-TopicLevelGraduation5 {
  .icon-TopicLevelGraduation1;
}
.icon-AuctionWinner, .icon-BuyNowWinner, .icon-RaffleWinner {
  .icon-rewards;
}

.icon-SlotsWinner {
  .icon-slots;
}

.icon-expired {
    .icon-time;
}

.icon-certification {
    .icon-certifications;
}

.icon-text {
    .icon-file_text_alt;
}

.icon-formalexam {
    .icon-ol;
}

.icon-exam {
    .icon-ol;
}

.icon-intro {
    .icon-light_bulb;
}

.icon-assignedpath {
  .icon-guided_learning;
}

.icon-selfdirected {
  .icon-compass;
}

.icon-evaluation {
  .icon-eye_open;
}

.icon-team {
    .icon-sitemap;
}

.icon-group {
    .icon-groups;
}

.icon-users {
    .icon-profile;
}

.icon-exports {
    .icon-download_alt;
}

.icon-auction {
    .icon-rewards;
}

.icon-coin_leaderboard {
    .icon-topperformers;
}

.icon-extra_training {
    .icon-extratraining;
}

.icon-always_on_daily_training {
  .icon-reinforcement;
}

.icon-training_paywall {
  &:before {
    content: @icon-training;
  }
}

.icon-PageView_ConversationalSearch,
.icon-conversational_search {
  .icon-max;

  &:before {
    color: @ax-color-white;
  }
}

.icon-game_scores {
    .icon-games;
}

.icon-knowledge_map {
    .icon-reportcard;
}

.icon-learning_events {
    .icon-events;
}

.icon-referral {
    .icon-tellafriend;
}

.icon-knowledge {
  color: @primary-blue;
}

.icon-expert {
    background-image: url('@common/assets/images/expert.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.slick-cell .icon-expert {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
}

.icon-graduate {
    .icon-graduated;
    color:@primary-blue!important;
}

.icon-notstarted {
  .icon-not-started;
}

.icon-not-started,
.icon-beginner,
.icon-intermediate {
  color:@primary-blue;
}

.icon-certified {
    .icon-certifications;
    color: @ax-color-green-60 !important;
}

.icon-topperformers {
  color:@ax-color-gold;
}

.icon-low {
    .icon-low;
    color:@red!important;
}

.icon-medium {
    .icon-medium;
    color:@primary-blue;
}

.icon-high {
    .icon-high;
    color:@ax-color-icon-green-a11y!important;
}

.icon-overdue {
    .icon-overdue_certs;
    color:@red!important;
}

.icon-overconfident {
  .icon-overconfident;
  color:@red!important;
}

.icon-needs-coaching {
  .icon-needs_coaching;
  color:@red!important;
}

.icon-non_participants {
  .icon-non_participants;
  color:@red;
}

.icon-due {
    .icon-due_certs;
    color:@yellow!important;
}

.icon-underconfident {
  .icon-underconfident;
  color:@yellow!important;
}

.icon-non_participants,
.icon-needs_coaching,
.icon-overdue_certs {
  color:@red;
}

.icon-due_certs {
  color:@yellow;
}

.icon-behaviors_forms {
  .icon-behaviors;
  color:@primary-blue;
}

.icon-behaviors_improvements {
  .icon-behaviors;
  color:@yellow;
}

.icon-intro_due {
  .icon-intro;
  color: @yellow;
}

.icon-prizes {
    .icon-rewards;
}

//User Activity Icons - LEARNERZONE
.icon-LeaderZone_PageView_Underconfident {
    .icon-underconfident;
}
.icon-LeaderZone_PageView_MilestonesUserProgress {
  .icon-milestone;
}

.icon-PageView_AutomaticRefresherTraining,
.icon-PageView_HubRefresherTraining {
    .icon-refresher;
}

.icon-LeaderZone_PageView_MilestonesTeamStats {
  .icon-milestone;
}

.icon-Session_StartTime,
.icon-PageView_PreTrainingHub,
.icon-PageView_PostTrainingHub,
.icon-LeaderZone_Session_StartTime {
    .icon-home;
}

.icon-Assessment_StartTime
.icon-LeaderZone_Assessment_StartTime {
    .icon-flag;
}

.icon-Assessment_EndTime
.icon-LeaderZone_Assessment_EndTime,
.icon-Assessment_EndTime {
    .icon-flag_checkered;
}

.icon-PageView_TaskCompletion,
.icon-PageView_TaskCreation {
    .icon-check;
}

.icon-Question {
    .icon-content;
}

.icon-PageView_AutomaticIntroductoryTraining,
.icon-PageView_HubIntroductoryTraining {
    .icon-light_bulb;
}

.icon-TrainingModule {
    .icon-video;
}

.icon-Session_EndTime {
    .icon-logout;
}

.icon-timeline,
.icon-PageView_News,
.icon-PageView_Messages,
.icon-PageView_Timelines_Page,
.icon-PageView_Timelines_BroadcastMessage,
.icon-PageView_Timeline_AllMessages_Page {
  .icon-news;
}

.icon-PageView_Timeline_PriorityMessages_Page {
  .icon-priority-messages;
}

.icon-PageView_Profile,
.icon-PageView_CoachSelection,
.icon-PageView_LanguageSelection {
  .icon-profile;
}

.icon-PageView_LearningEvents {
  .icon-events;
}

.icon-PageView_GuidedLearning_ProgramsPage,
.icon-PageView_GuidedLearning_ProgramDetailsPage {
  .icon-guided_learning;
}

.icon-PageView_Tasks_ListingViewPage,
.icon-PageView_Tasks_TaskDetailView,
.icon-PageView_TaskListView,
.icon-PageView_GroupSelectionView,
.icon-PageView_CompletedNudges  {
  .icon-todo;
}

.icon-recognition_pins,
.icon-PageView_RecognitionPinNotifications,
.icon-PageView_RecognitionPinSummaryList,
.icon-PageView_RecognitionPinPinHistoryDialog,
.icon-PageView_RecognitionPinSendDialog {
  .icon-clap;
}

.icon-consolidated_comms {
  .icon-news;
}

.icon-correctanswer {
    .icon-circle;
    color:@ax-color-icon-green-a11y!important;
    background-color: transparent!important;
}

.icon-incorrectanswer {
    .icon-circle;
    color:@red!important;
    background-color: transparent!important;
}

.icon-PageView_BroadcastMessage,
.icon-PageView_UserMessage {
    .icon-bullhorn;
}

.icon-PageView_TopPerformers {
    .icon-topperformers;
}

.icon-PageView_AutomaticCertificationTraining,
.icon-PageView_HubCertificationTraining {
    .icon-certifications;
}

.icon-PageView_ExtraTraining,
.icon-PageView_LearnerTraining {
    .icon-extratraining;
}

.icon-SurveyQuestion {
    .icon-survey;
}

.icon-PageView_TellAFriend,
.icon-PageView_AutomaticTellAFriend {
    .icon-tellafriend;
}

.icon-PageView_Rewards {
    .icon-rewards;
}

.icon-PageView_Achievements {
    .icon-achievements;
}

.icon-PageView_GameChallengeDetails,
.icon-PageView_GameChallengeSelection,
.icon-PageView_GameDetails,
.icon-PageView_GameScores,
.icon-PageView_GameSelection {
    .icon-games;
}

.icon-PageView_ReportCard {
    .icon-reportcard;
}

.icon-PageView_Discovery_AllTab,
.icon-PageView_Discovery_ArticlesTab,
.icon-PageView_Discovery_Community,
.icon-PageView_Discovery_TopicsTab,
.icon-PageView_Discovery_PathsTab {
  .icon-search;
}

.icon-PageView_Article_Details,
.icon-PageView_TaskDetails,
.icon-PageView_NudgeDetails {
  .icon-file;
}

.icon-training:before {
  color: inherit;
}

.icon-PageView_Topic_Details,
.icon-PageView_Training,
.icon-manage_training {
  .icon-training;

  &:before {
    color: @ax-color-white;
  }
}

.icon-ClickCommunicationsNudgehyperlink {
  .icon-complete-communications;
}

//User Activity Icons - LEADERZONE
.icon-LeaderZone_Module_Insights_StartTime,
.icon-LeaderZone_Module_Insights_EndTime,
.icon-LeaderZone_PageView_InsightsIndex {
  .icon-insights
}

.icon-LeaderZone_PageView_ExportIndex {
    .icon-download_alt;
}

.icon-LeaderZone_Module_Exports_StartTime,
.icon-LeaderZone_Module_Exports_EndTime {
    .icon-time;
}

.icon-LeaderZone_PageView_TopPerformers {
    .icon-topperformers;
}

.icon-LeaderZone_PageView_BehaviorImprovements,
.icon-LeaderZone_PageView_BehaviorDetail {
    .icon-behaviors;
}

.icon-LeaderZone_PageView_OverdueCertifications,
.icon-LeaderZone_PageView_OverdueCertificationTopicUsers {
    .icon-overdue_certs;
}

.icon-LeaderZone_PageView_NeedsCoaching {
    .icon-needs_coaching;
}

.icon-LeaderZone_PageView_DueCertifications,
.icon-LeaderZone_PageView_DueCertificationTopicUsers {
    .icon-due_certs;
}

.icon-LeaderZone_PageView_CertificationTeamStats {
    .icon-certifications;
}

.icon-LeaderZone_PageView_Graduates {
    .icon-graduated;
}

.icon-LeaderZone_PageView_PrizeWinners {
    .icon-rewards;
}

.icon-LeaderZone_PageView_UserMessage {
    .icon-teammessage;
}

.icon-hub_stats,
.icon-LeaderZone_Module_Metrics_StartTime {
    .icon-metrics;
}

.icon-LeaderZone_PageView_MetricsIndex,
.icon-LeaderZone_Module_Metrics_EndTime,
.icon-user_metrics {
    .icon-usermetrics;
}

.icon-LeaderZone_Module_Behaviors_StartTime,
.icon-LeaderZone_PageView_RecordBehaviorObservations,
.icon-LeaderZone_PageView_BehaviorsIndex,
.icon-LeaderZone_PageView_RecordBehaviorObservations,
.icon-LeaderZone_Module_Behaviors_EndTime,
.icon-LeaderZone_PageView_UserBehaviorForm,
.icon-LeaderZone_PageView_UserBehaviorDetails,
.icon-LeaderZone_PageView_CreateBehaviorForm,
.icon-LeaderZone_PageView_BehaviorSummary,
.icon-LeaderZone_PageView_BehaviorSummaryUsers {
    .icon-behaviors;
}

.icon-LeaderZone_PageView_DueIntroductoryTraining,
.icon-LeaderZone_PageView_DueIntroTrainingSubjectUsers {
  .icon-intro;
}

.icon-LeaderZone_Session_EndTime {
    .icon-logout;
}

.icon-LeaderZone_PageView_TopicPerformance,
.icon-LeaderZone_PageView_TopicPerformanceTopicsUsers {
  .icon-metrics;
}

.icon-LeaderZone_PageView_Experts {
  .icon-star-solid;
}

.icon-LeaderZone_Module_Messages_StartTime,
.icon-LeaderZone_PageView_TeamMessage,
.icon-LeaderZone_Module_Messages_EndTime,
.icon-team_message {
  .icon-teammessage;
}

.icon-LeaderZone_Module_Inspections_StartTime,
.icon-LeaderZone_PageView_RecordInspectionObservations,
.icon-LeaderZone_Module_Inspections_EndTime,
.icon-LeaderZone_PageView_InspectionFormTopicSummary,
.icon-LeaderZone_PageView_InspectionsIndex,
.icon-LeaderZone_PageView_CreateInspectionForm {
    .icon-inspections;
}

.icon-LeaderZone_Module_People_StartTime,
.icon-LeaderZone_Module_People_EndTime,
.icon-LeaderZone_PageView_PeopleIndex {
    .icon-groups;
}

.icon-LeaderZone_Module_TeamMetrics_StartTime,
.icon-LeaderZone_PageView_TeamMetricsIndex,
.icon-LeaderZone_Module_TeamMetrics_EndTime,
.icon-team_metrics {
    .icon-teammetrics;
}

.icon-LeaderZone_PageView_UserProfile,
.icon-LeaderZone_PageView_UserBehaviorProfile {
    .icon-profile;
}

.icon-LeaderZone_PageView_NonParticipants {
    .icon-non_participants;
}

.icon-LeaderZone_PageView_ParticipationTeamStats {
    .icon-non_participants;
}

.icon-LeaderZone_PageView_LearningEventsIndex,
.icon-LeaderZone_Module_LearningEvents_StartTime,
.icon-LeaderZone_Module_LearningEvents_EndTime,
.icon-LeaderZone_PageView_LearningEventsEnrollUsers {
    .icon-events;
}

.icon-LeaderZone_PageView_Overconfident {
    .icon-overconfident;
}

.icon-LeaderZone_PageView_KnowledgeProfile,
.icon-LeaderZone_PageView_KnowledgeTopicStats {
    .icon-knowledge;
}

.icon-LeaderZone_PageView_AnswerHistory,
.icon-Assessment_StartTime {
    .icon-content;
}

.icon-LeaderZone_PageView_FrequencyTeamStats,
.icon-LeaderZone_PageView_KnowledgeSubjectStats,
.icon-LeaderZone_PageView_FrequencyTeamStats {
    .icon-teammetrics;
}

.icon-LeaderZone_Module_Compare_StartTime,
.icon-LeaderZone_Module_Compare_StartTime,
.icon-LeaderZone_PageView_CompareIndex,
.icon-LeaderZone_Module_Compare_EndTime {
    .icon-compare;
}

.icon-LeaderZone_Module_Impacts_StartTime,
.icon-LeaderZone_Module_Impacts_EndTime,
.icon-LeaderZone_PageView_ImpactsMetrics,
.icon-LeaderZone_PageView_ImpactsIndex,
.icon-impact {
  .icon-impacts;
}

.icon-DeepLinkTraining {
  .icon-link;
}

.icon-Game_StartTime,
.icon-Game_EndTime {
    .icon-games;
}

.icon-execution_tasks {
  .icon-checklist;
}

.icon-LeaderZone_PageView_ManageTrainingIndex,
.icon-LeaderZone_Module_ManageTraining_StartTime,
.icon-LeaderZone_Module_ManageTraining_EndTime {
  .icon-training;

  &:before {
    color: @ax-color-white;
  }
}

//ADMIN REPORTS TILE ICONS
#gamesSummary  {
  .icon-type {
    .icon-games;
  }
}

#deviceTypeSummary  {
  .icon-type {
    .icon-game_popularity;
  }
}

#questionSummary  {
  .icon-type {
    .icon-question_summary;
  }
}

#scormSummary  {
  .icon-type {
    .icon-question_summary;
  }
}

#winners  {
  .icon-type {
    .icon-rewards;
  }
}

#surveySummary  {
  .icon-type {
    .icon-survey;
  }
}

#exams  {
  .icon-type {
    .icon-ol;
  }
}

#hubActivities  {
  .icon-type {
    .icon-hub_activity;
  }
}

#trainingActivities  {
  .icon-type {
    .icon-training_activity;
  }
}

#behaviour  {
  .icon-type {
    .icon-behaviors;
  }
}

#certification {
  .icon-type {
    .icon-certifications;
  }
}

#reviews {
  .icon-type {
    .icon-inspections;
  }
}

#broadcastSummary {
  .icon-type {
    .icon-broadcast;
  }
}

#milestones {
  .icon-type {
    .icon-milestone;
  }
}

#taskSummary {
  .icon-type {
    .icon-checklist;
  }
}

#campaignSummary {
  .icon-type {
    .icon-complete-communications;
  }
}

.icon-MilestoneAchievement {
  .icon-milestone;
}

//ADMIN REWARDS ICONS
.type-icon {
  .icon-legal;
}

//LEADERZONE
.icon-compare {
  .icon-random;
}

.icon-people {
  .icon-groups;
}

.icon-behaviors {
  .icon-eye_open;
}

.icon-exclamation_sign {
    color: @red !important;
}

.icon-empty_circle {
  &:before {
    content: "\ea15";
  }
}

//DISCOVER
.icon-article,
.icon-document {
  .icon-create-article;
  color:@primary-blue!important;
}

.icon-reference {
  .icon-create-link;
  color:@ax-color-icon-green-a11y!important;
}

.icon-question {
  .icon-create-question;
  color:@light-blue!important;
}

//No Bg icons for transparent create use
.icon-article-nobg,
.icon-document-nobg {
  .icon-create-article-nobg;
  color:@white!important;
}

.icon-reference-nobg {
  .icon-create-link-nobg;
  color:@white!important;
}

.icon-question-nobg {
  .icon-create-question-nobg;
  color:@white!important;
}

.icon-training-article-nobg {
  .icon-extratraining;
  color:@white!important;
}

.icon-subject {
  .icon-folder_close_alt;
}

.icon-topic {
  .icon-file_text_alt;
}

.icon-skip {
  .icon-double_angle_right;
}

.icon-bolt {
  .icon-bolt1;
}

.icon-task {
  .icon-checklist;
}

.icon-campaigns-tasks,
.icon-PageView_NewTask_MyTasks,
.icon-PageView_NewTask_MyTeams,
.icon-PageView_NewTaskDetails_MyTasks,
.icon-PageView_NewTaskDetails_MyTeams {
  .icon-tasks;
}
