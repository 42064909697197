/* Multi-column Lists */
ul.multi-column-list li {
  float:none;
  clear: both;
  display: block;
  .box-sizing(border-box);

  .screen-md-portrait-min({
    clear: left;
    float: left;
    padding: 0 0 1.5em 0;
  });
}

/* column sizes */
ul.multi-column-list li.column-1,
ul.multi-column-list li.column-2 {

  // Mobile - Force single list over Js formatting
  .screen-sm-max({
    width: 100% !important; // Override inline style
    float: none;
    margin: 0 !important; // Override inline style
  });

  .screen-md-portrait-min({
    width: 48% !important; // Override inline style
    margin: 0;
  });
}

ul.multi-column-list li.column-2 {
  float: right;
}

// List Stories
ul.multi-column-list li {
  .story {
    margin-left: 5.7em;

    .screen-md-landscape-max({
      width: auto;
    });
  }

  .story .content {
    margin-bottom: 0;

    .screen-md-landscape-max({
      margin: 0;
    });
  }

  .story .content .name {
    display: none;
  }

  .story .timesince {
    position: static;
    display: block;
    margin-bottom: 1em;
  }

  div.news-icon {
    margin: 2px 5px 5px 5px;
  }
}

// Empty fallback
ul.multi-column-list .nonews {
  width: 100%;
  color: @secondary-text-color;
  border:0;
  font-size: 1.7rem;
}

// Hub Activity
.news-container {

  .screen-md-portrait-min({
    margin: 0 1.5em;
  });

  h3 {
    margin-bottom: 0.5em;
  }

  ul.multi-column-list {
    li {
      padding-top: 1.5em;
    }
  }
}
