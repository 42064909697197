.search {
  .browse-all-communities,
  .browse-recent-communities {
    .ax-breakpoint-m({
      margin-top: inherit;  // this should be removed when tabs count is fixed on mobile in staging
    });

    .community-type-header h3 {
      font-weight: @ax-font-weight-regular;
    }

    .articles-header,
    .communities-list-header {
      margin-bottom: @ax-spacing-m;

      .ax-breakpoint-m({
        margin: 0;
      });
      
      h2 {
        font-size: 1.8rem;
        font-weight: @ax-font-weight-regular;
        margin: 0;

        .ax-breakpoint-m({
          font-size: 2rem;
        });
      }
    }

    .articles-header {
      .ax-breakpoint-m({
        margin: 0 0 2.2rem;
      });

      [class^="icon-"],
      [class*=" icon-"] {
        background-image: none;
        height: auto;
        margin-top: 0;
        width: auto;
      }

      &__title {
        word-break: break-word;
      }
    }

    .community-type-header {
      margin-top: @ax-spacing-m;
    }
  }

  .browse-all-communities {
    .ax-breakpoint-m({
      margin-left: @ax-spacing-m;
      margin-right: @ax-spacing-m;
    });
  }

  .view-all-communities-wrapper,
  .results-button-item-view {
    margin-left: @ax-spacing-s;
    .axon-button {
      background-color: @ax-color-grey-20;
      border: .1rem solid @ax-color-grey-30;
      color: @ax-color-grey-80;
      height: 4.4rem;
      min-width: 16rem;

      .ax-breakpoint-m({
        height: 4.8rem;
        min-width: 18.5rem;

        &:hover, &:focus {
          background-color: @white;
        }
      });

      .icon-chevron_right {
        color: @black;
        margin-left: @ax-spacing-s;
      }
    }

    .more-results-button {
      margin-top: @ax-spacing-l;

      .ax-breakpoint-m({
        margin: @ax-spacing-l 0;
      });
    }
  }
}
