/* rtl:begin:ignore */

// Globals
html {
  height:100%;
  width: 100%;
  overflow: hidden;
}

strong,
b {
  font-weight: @ax-font-weight-medium;
}

a {
  color: @primary-blue;
  text-decoration: none;
}

ul,
li {
  list-style: none;
  list-style-position: inside;
  padding: 0;
  margin:0;
}

// Form elements
select {
  .border-radius(@ax-border-radius-m);

  .screen-sm-max({
    background-image: none;
  });
}

// Common
.col-pad {
  padding: 1em 0 0;

  .screen-md-portrait-min({
    padding: 1.5em 0 0;
  });
}

.contentpad {
  margin: 0 0.8rem;

  .screen-md-portrait-min({
    margin: 0 1.6rem;
  });
}

.title-pad {
  margin-top: 1.5em;
}

/* rtl:end:ignore */
