.dz-article {
  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h1,
  h2,
  h3,
  p {
    margin: 1em 0;
  }

  h4,
  h5,
  h6 {
    margin: 0;
  }

  ol,
  ul {
    padding: 0;
    margin: 0 0 0 1.8em;
    list-style: decimal;

    li {
      padding-left: 0;
      padding-right: 0;
      margin-right: 0;
    }
  }

  ul li{
    list-style: disc;
  }

  ol li {
    list-style: decimal;
  }

  blockquote {
    border-left: 2px solid @primary-blue;
    margin: 0;
    padding: 0 1rem;
  }

  pre {
    padding: 0;
    margin: 0;
    border: 0;
    display: block;
    font-size: 1.3rem;
    line-height: 2rem;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
    background-color: @secondary-bg;
    .border-radius(@ax-border-radius-m);
  }

  .video-placeholder {
    width: 100%;
    max-width: 60rem;
    height: 25rem;
    background-color: @accent-grey;
    background-image: url('@training/assets/images/icn_type_video@2x.png');
    background-position: center center;
    background-repeat: no-repeat;
    line-height: 25rem;
    text-align: center;
    display: block;
  }

  .header {
    font-size: 2.3rem;
  }
}
