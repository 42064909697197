.avail-invites-small {
  margin-bottom: @ax-spacing-m;

  .ax-breakpoint-m({
    margin-bottom: @ax-spacing-l;
  });

  .avail-small-wrapper {
    padding: @ax-spacing-m;

    .small-claim-left {
      float: left;
      width: @ax-spacing-l * 2;
    }

    .small-claim-right {
      overflow: hidden;
    }

    .small-invites-details {
      margin-bottom: @ax-spacing-s;

      .name {
        font-weight: @ax-font-weight-regular;
      }

      .ax-breakpoint-m({
        margin-left: @ax-spacing-m;
      });
    }
  }

  .small-invite-buttonbar {
    padding: 0 @ax-spacing-m @ax-spacing-m;
  }
}

.small-name-logo {
  .border-radius(50%);
  font-size: @ax-font-size-xs;
  color: @white;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  width: 4rem;

  .ax-breakpoint-m({
    font-size: @ax-font-size-s;
    height: 5rem;
    line-height: 5rem;
    width: 5rem;
  });
}

.no-invites {
  font-size: @ax-font-size-s;
  background-size: 30%;
  background: url('@training/assets/images/no-taf.gif?_v2') center 10% no-repeat;
  color: @ax-color-grey-40;
  line-height: @ax-line-height-s;
  margin: @ax-spacing-m 0 @ax-spacing-l;
  padding: 20rem 3.6rem 0;
  text-align: center;

  .ax-breakpoint-m({
    font-size: @ax-font-size-l;
    line-height: @ax-line-height-l;
    margin: @ax-spacing-l 0 (@ax-spacing-l *2);
    padding-bottom: 9.6rem;
    padding-top: 22rem;
  });
}
