.recovery-wrapper {
  margin: 0 auto;
  text-align: left;

  .screen-md-portrait-min({
    width: 500px;
  });

  .screen-md-portrait-max({
    width: 92%;
  });

  .recovery-question {
    font-weight: @ax-font-weight-bold;
  }

  .recovery-icon {
    width: 4.5rem;
    height: 4.5rem;
    line-height: 4.5rem;
    margin: 0 1rem 0 0;
    font-size: 2.4rem;
    .border-radius(@ax-border-radius-m);
    outline: none;
    background-color: @ax-color-icon-green-a11y;
    color: #ffffff !important;
    text-align: center;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }

  .recovery-input {
    input {
      margin-bottom: @ax-spacing-l;
      border-color: @secondary-grey;
    }
    ul.flow-options {
      padding-top: @ax-spacing-m;

      input {
        box-shadow: none;
        width: auto;
        margin-bottom: 0;
      }
    }
  }

  .axon-button.submit {
    padding-left: 3em;
    padding-right: 3em;
    margin-left: 0;
  }

  .title-wrapper {
    margin: 2em auto;
  }

  .title-block {
    .display-flex();
    .justify-content(center);
  }

  .recovery-submit {
    text-align: center;
  }

  .recovery-title {
    font-size: 1.2em;
    font-weight: @ax-font-weight-bold;
    display: inline-block;
    margin: 0;
  }

  .recovery-success-message {
    margin-top: 1em;
  }

  .recovery-option-container {
    margin: 0 0 @ax-spacing-l;
  }

  #user-recovery-username,
  #password-recovery-username,
  #recovery-answer,
  .recovery-input label {
    width: 100%;
  }

  .contact {
    padding: 0;
    text-align: center;

    &__email {
      white-space: nowrap;
    }
  }
}

.login-footer {
  text-align: center;
  padding: 1.5em 0 0 0;
  margin: 1.5em 0 0 0;
  border-top: 1px solid @accent-grey;
  div {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
