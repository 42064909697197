// Form - highlighted
form.highlighted-form input {
  display: block;
  margin-bottom: @ax-spacing-l;
  width: 100%;
}

form.highlighted-form select {
  margin-bottom: @ax-spacing-l;
}

form.highlighted-form {
  input#username,
  [data-editor='Password'] {
    width: 100%;
    margin-bottom: @ax-spacing-l;
  }

  [data-editor='Password'] input {
    margin-bottom: 0;
  }
}

// Highlighted Form Error
form.highlighted-form {
  .rederror[data-editor='Password'] input,
  .rederror[data-editor='Location'] #location-input,
  input.rederror,
  textarea.rederror,
  select.rederror {
    border: .1rem solid @red;
  }
}
