@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

@datepicker-main-color: @primary-bg;

/* === DATE PICKER ===*/
#ui-datepicker-div {
  z-index: 999!important;
}


.popdown-header-footer {
  height: 30px;
  line-height: 3rem;
  cursor: pointer;
  border-bottom: 1px solid @secondary-grey;
  background-color: @datepicker-main-color;
  background-position: 35% center;
  background-repeat: no-repeat;
  .box-shadow-black(inset -1px, 1px, 2px, 0.10);
}

.popdown-header-footer-span {
  width: auto;
  float: none!important;
}

.popdown {
  .border-radius(@ax-border-radius-s);
  border: 1px solid @secondary-grey!important;
  display: none;
  background: @white;
  margin: 0;
  position: absolute;
  right:auto!important;
  z-index: 999;
  text-align: center;
  .box-shadow-black(0px 2px 5px rgba(115,115,115,0.3));

  div.time {
    padding: 0 1em;
  }

  .back-to-calendar {
    .popdown-header-footer;

    span {
      .popdown-header-footer-span;
      display: inline!important;
      border:0;
      padding: 0;
      .border-radius(0);
      vertical-align: middle;
      text-decoration: none;
    }
  }

  .go-to-timepicker {
    .popdown-header-footer;
    border-bottom: 0;
    border-top: 1px solid @secondary-grey;
    display: none;
    text-decoration: none;

    span {
      .popdown-header-footer-span;
      display: inline!important;
    }
  }

  .now-container {
    .popdown-header-footer;

    #now {
      color: @primary-blue;
      font-weight: @ax-font-weight-medium;
    }
  }

  .time-picker-wrap {
   margin-top: 43%;
  }

  .time-picker {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 35px;
    overflow: hidden;
    background-color: #fff;
    display: none;
  }
}

/*General Datepicker Styling*/

.ui-datepicker-arrows {
  top: .6rem;
  cursor: pointer;
}

.image-background-settings {
  opacity: 1;
  background-position: center center;
  background-repeat: no-repeat;
}

.ui-datepicker-td-padding-border {
  padding: .5em;
  border: 0 !important;
  background: transparent !important;
  .border-radius(0 0 0 0)!important;
}

.ui-state-default {
  font-size:inherit;
  font-family: inherit;
  font-weight: @ax-font-weight-regular;
}

.ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  .ui-state-default;
}

/*General Datepicker Styling*/
#ui-datepicker-div {
  z-index: 999!important;
}

.ui-datepicker {
  background: transparent;
  padding: 0;
  border: 0;
  width: 20em;
  .box-shadow-black(0px, 1px, 0px, 0.10);

  .ui-widget-content {
    .ui-state-default {
      .ui-state-default;
      background-color: transparent !important;
    }
  }

  select {
    line-height: 1.5;
    font-size: 1.2rem;
  }

  td {
    padding: 0!important;
    line-height: normal;

    span,
    a {
      .ui-datepicker-td-padding-border;

      &:hover,
      &:focus {
        background-color: @accent-grey!important;
      }
    }

    a.ui-state-active {
      background: @element-hover-bright;
    }
  }

  th {
    font-weight: @ax-font-weight-medium;
    padding: 0;
  }

  .ui-datepicker-calendar {
    table-layout: fixed;

    thead {
      border-bottom: 0;
      background: transparent;
    }

    span {
      width: auto;
      float: none!important;
    }

    .ui-datepicker-week-end {
      color: black;
    }
  }

  select.ui-datepicker-year,
  select.ui-datepicker-month {
    -moz-appearance: button; // Fix vertical alignment in FF
    width: 47%;
    margin: 0 5px 0 0;
  }

  select.ui-datepicker-year {
    margin: 0;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    .ui-datepicker-arrows();

    span {
      .image-background-settings();
    }
  }

  .ui-datepicker-prev span {
    background-image: url('@common/assets/images/prev-arrow.png')/*rtl:url('@common/assets/images/next-arrow.png')*/;
  }

  .ui-datepicker-next span {
    background-image: url('@common/assets/images/next-arrow.png')/*rtl:url('@common/assets/images/prev-arrow.png')*/;
  }

  .ui-datepicker-header {
    padding: .5em;
    background-color: transparent;
    .border-radius(0);

    .ui-state-default {
      .ui-state-default;
      background-color: transparent!important;
    }
  }
}

.ui-datepicker-title span {
  width: auto !important;
  display: inline !important;
  color:@black;

  select.ui-datepicker-year,
  select.ui-datepicker-month {
    width: 47%;
    margin: 0 5px 0 0;
    font-size: 1.2rem;
  }

  span {
    width: auto !important;
    display: inline !important;
  }
}

.ui-datepicker-year-month {
  margin-bottom: 0.75em;
}

.inline-datepicker-wrapper {
  float: none;

  .ui-datepicker-calendar,
  .ui-datepicker-buttonpane.ui-widget-content {
    .hidden;
  }

  .ui-datepicker {
    border: none;
    margin: 0;
    width: 100%;
    padding: 0 0 0.5em;
    float: left;
    box-shadow: none;
    .box-sizing(border-box);

    .ui-datepicker-header {
      .box-sizing(border-box);
      width: 100%;
      padding: 0em 5.7em 0.1em 0.1em;
      margin-right: 10em;
      margin-left: auto;
      text-align: left;
      background: @white;

      .ui-datepicker-title {
        text-align: left;
        margin: 0 0.5em 0 0;
        line-height: 3.5rem;
      }

      select.ui-datepicker-year {
        .ui-datepicker-year-month;
        margin: 0;
        width: 36%;
        float:right;
      }

      select.ui-datepicker-month {
        .ui-datepicker-year-month;
        margin: 0 0.5em 0 0;
        width: 60%;
      }
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
      .transition-property(~"background-color, border-color");
      .transition(all @ax-transition-speed @ax-transition-ease);
      background-color: @_btn-default-background;
      border: @_btn-default-border;
      position: absolute;
      right:0;
      top:0;
      left:auto;
      width: @_btn-size-m;
      height: @_btn-size-m;
      line-height: @_btn-size-m;
      .box-sizing(border-box);

      &:hover,
      &.ui-datepicker-prev-hover,
      &.ui-datepicker-next-hover {
        background: @_btn-default-background-hover !important; // override jquery-ui.css use of important
        border: @_btn-default-border-hover;
      }

      &:focus {
        .ax-focus-keyboard();
      }
    }

    .ui-datepicker-prev {
      right: 3.2rem;
      .border-radius(@ax-border-radius-m 0 0 @ax-border-radius-m);
    }

    .ui-datepicker-next {
      .border-radius(0 @ax-border-radius-m @ax-border-radius-m 0);
    }
  }

  .to-text {
    line-height: 3.5rem;
    margin:0 1em;
  }

  #startDate {
    margin: 0 auto;
  }

  .go-to-timepicker,
  .now-container,
  .time-picker {
    .hidden;
  }
}

/* Ipad,Desktop */
.screen-md-portrait-min({

  .ui-datepicker-month-year-default {
    margin: 0;
    width: 49%;
    display: block;
  }

  .inline-datepicker-wrapper {
    float: left;

    .ui-datepicker {
      border: none;
      margin: 0 auto;
      padding: 0;
      float: left;
      width: 100%;
      .box-shadow();

      .ui-datepicker-header {
        background: transparent!important;

        select.ui-datepicker-month {
          .ui-datepicker-month-year-default;
          float:left;
        }

        select.ui-datepicker-year {
          .ui-datepicker-month-year-default;
          float:right;
        }
      }
    }
  }
});

/* Mobile */
.screen-sm-max({
  .mobile-filter-bg {
    .ui-datepicker {
      background: transparent;
    }

    .inline-datepicker-wrapper {
      .ui-datepicker {
        .ui-datepicker-header {
          background-color: transparent;
        }
      }
    }
  }
});

/* ================================== RETINA ===*/
.retina({

  .arrows-background-size {
    background-size: 32px;
  }

  .ui-datepicker {
    .ui-datepicker-next span {
      .arrows-background-size;
      background-image: url('@common/assets/images/next-arrow@2x.png')/*rtl:url('@common/assets/images/prev-arrow@2x.png')*/;
    }

    .ui-datepicker-prev span {
      .arrows-background-size;
      background-image: url('@common/assets/images/prev-arrow@2x.png')/*rtl:url('@common/assets/images/next-arrow@2x.png')*/;
    }
  }
});

/* RTL */
.rtl-only({
  .ui-datepicker-rtl { direction: rtl; }
});
