@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.page-control-top-region {
  margin-bottom: @ax-spacing-l;
  .screen-md-portrait-min({
    margin-bottom: @ax-spacing-xl;
  });
}

.page-control-bottom-region {
  margin-top: @ax-spacing-l;
  .screen-md-portrait-min({
    margin: 4.8rem 0;
  });
}

.page-control-bottom-region, .page-control-top-region {
  .pagination-controls {
    .display-flex();
    .align-items(center);
    .justify-content(center);
    color: @ax-pagination-pageItem-color;
    font-weight: @ax-pagination-pageItem-fontWeight;

    [class^="icon"] {
      font-size: @ax-pagination-pageItem-icon-fontSize;
    }

    .left-controls {
      .display-flex();

      .first-page-button {
        .display-flex();
        .align-items(center);

        .first-page-text {
          display: none;

          .screen-md-portrait-min({
            margin-left: 8px;
            display: inline;
          });
        }
      }
    }

    .right-controls {
      .display-flex();

      .last-page-button {
        .display-flex();
        .align-items(center);
        
        .last-page-text {
          display: none;

          .screen-md-portrait-min({
            margin-right: 8px;
            display: inline;
          });
        }
      }
    }

    .pagination-button {
      border: none;
      .border-radius(@ax-border-radius-m);
      background: none;
      margin: 0 3px;
      padding: @ax-pagination-pageItem-padding;
      height: @ax-pagination-pageItem-height-sm;

      .ax-breakpoint-m({
        height: @ax-pagination-pageItem-height-md;
      });

      &.focus-visible:focus {
        .ax-focus-keyboard();
      }

      &:hover {
        background: @pagination-grey;
      }

      &__first, &__last {
        padding: 0 8px;

        .icon-double_angle_left,
        .icon-double_angle_right {
          vertical-align: middle;
        }
      }

      &__next,
      &__prev {
        .display-flex();
        .align-items(center);
        padding: @ax-pagination-pageItem-icon-padding;
      }
    }

    .page-number-list {
      .display-flex();
      .align-items(center);

      .current-page-number {
        margin: 0;
        padding: @ax-pagination-pageItem-padding;
        .border-radius(@ax-border-radius-m);
        background: @ax-pagination-pageItem-selected-background;
        border: @ax-pagination-pageItem-selected-border;
        height: @ax-pagination-pageItem-height-sm;

        .ax-breakpoint-m({
          height: @ax-pagination-pageItem-height-md;
        })
      }
    }
  }
}