@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.chosen-container .chosen-drop li.has-value-dropdown-item {
  .display-flex();
  .justify-content(space-between);

  &.has-value-dropdown-item--has-value {
    span {
      color: @green;
    }
  }
}
