.agreement-page {
  @header-height: 5rem;
  height: 100%;

  .section-title {
    .display-flex();
    padding: @ax-spacing-m;
    margin: 0;
  }

  .block-header {
    background-color: @primary-bg;
  }

  .agreement-language-selector {
    padding: 13px;
    label {
      display: inline-block;
    }
  }

  .agreement-content-container {
    padding: 1em;
    overflow-wrap: break-word;

    .screen-md-portrait-max({
      padding-bottom: 13rem;
    });

    .screen-md-portrait-min({
      padding-bottom: 70px;
    });

    a.focus-visible:focus {
      box-shadow: inset 0px 0px 2px 2px @primary-blue;
    }
  }

  .agreement-footer-container {
    height: auto;
    box-sizing: border-box;
    padding: 0.5em;
    margin: 0;
    line-height: 4rem;
    background-color: @primary-bg;
    position: fixed;
    bottom: 0;
    width: 100%;

    .button-container {
      .display-flex();
    }

    .checkbox-container {
      padding-bottom: @ax-spacing-s;
    }

    .screen-md-portrait-min({
      .checkbox-container {
        float: left;
      }

      .button-container {
        float: right;
      }

      .checkbox-container {
        padding-bottom: 0;
      }
    });

    .screen-md-portrait-max({
        .button-container {
          .axon-button {
            width: 100%;
          }
        }
    });

    .axon-button {
      // This is done to keep a similar look and feel with training
      // Once we have unified auth app, we should remove this line
      min-width: 150px;
      margin: 0;
    }

    #agree-box {
      margin: 0em 0.7em;

      &.focus-visible:focus {
        box-shadow: inset 0px 0px 2px 2px @primary-blue;
      }

      .screen-md-portrait-max({
        vertical-align: middle;
      });
    }
  }
}
