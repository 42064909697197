.video-page {
  .fit-parent();

  .ax-container {
    .fit-parent();
    .display-flex();
    .align-items(center);
    .justify-content(center);
  }

  .page-content {
    .flex-basis(100%);
    max-width: 100%;
  }
}
