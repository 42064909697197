.menu-item {
  .display-flex();
  .flex-direction(column);
  .justify-content(center);
  .align-items(center);
  height: 100%;
  background-color: @white;
  color: @ax-color-text;
  padding: 0 @ax-spacing-xs;

  &.focus-visible:focus,
  &.active,
  &.hover {
    background-color: rgba(0,0,0,0.1);
  }

  .item-icon {
    margin-top: @ax-spacing-xxs;
    font-size:  @ax-bottom-navigation-icon-size;
    color:      @ax-bottom-navigation-icon-color;
    opacity:    1;
  }

  .item-text {
    text-align: center;
    .text-ellipsis();
    .full-width();
    color:       @ax-bottom-navigation-label-color;
    font-size:   @ax-bottom-navigation-label-font-size;
    font-weight: @ax-bottom-navigation-label-font-weight;
    line-height: @ax-bottom-navigation-label-line-height;
    padding:     @ax-bottom-navigation-label-padding;
    .screen-md-landscape-min({
      font-size: @ax-font-size-body;
    });
  }

  .notification {
    position: absolute;
    margin-left: @ax-spacing-l;
    margin-bottom: @ax-spacing-xl;

    .ax-breakpoint-m({
      margin-left: @ax-spacing-xxl;
    });
  }
}
