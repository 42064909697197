@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.loading-wrapper-container {
  background: transparent;
  height: 100%;

  &.stack-layers {
    > .loading-content-region {
      position: relative;
      z-index: 1;
    }

    > .wrapper-positioner {
      z-index: 1;
      display: block;
    }
  }

  > .loading-content-region {
    height: 100%;
  }

  > .wrapper-positioner {
    height: 0;
    position: relative;
    display: none;

    .spinner-wrapper {
      position: absolute;
    }

    .spinner-blanket {
      width: 100%;
      height: 100%;
      background: @ax-color-app-background;
      opacity: 0;
    }

    .loading-spinner {
      position: absolute;
      .border-radius(@ax-border-radius-m);
      display: none;// hide the spinner by default

      .img {
        position: absolute;
      }

      &.small {
        background-color: transparent;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        margin-left: -8px;
        top: 50%;
        left: 50%;

        .img {
          width: 16px;
          height: 16px;
          background: url('@common/assets/images/small-loader.gif') center center no-repeat transparent;
          background-size: 16px;
        }
      }

      &.large {
        width: 80px;
        height: 80px;
        left: 50%;
        top: 40%;
        margin-top: -40px;
        margin-left: -40px;
        background-color: @black;

        .img {
          top: 50%;
          left: 50%;
          margin-top: -16px;
          margin-left: -16px;
          width: 32px;
          height: 32px;
          background: url('@common/assets/images/loading_new_black.gif') center center no-repeat;
          background-size:32px;
          .backface-cull-fix();

          .retina({
            background-image: url('@common/assets/images/loading_new_black@2x.gif');
          });
        }
      }
    }
  }
}
