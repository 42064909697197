@login-screen-xm-spacing: 1.6rem;
@login-screen-sm-min-spacing: 2.4rem;
@login-logo-height: 50px;
@go-to-login-button-height: 35px;

.login-layout {
  .display-flex();
  height: 100%;
  overflow: hidden;
}

.login-background-container {
  width: 0;
  overflow: hidden; // IE11 issue

  .screen-md-landscape-min({
    width: 60%;
    position: relative;
    .display-flex();
    .align-items(flex-end);
  });
}

.login-background-color,
.login-background-low-res,
.login-background-full-res,
.login-background-default {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.login-background-default {
  background-color: #dbe1e5;
  background-image: url('@common/assets/images/axon_background.gif');
  display: none;

  .retina({
    background-image: url('@common/assets/images/axon_background@2x.gif');
    background-size: 200px;
  });
}

.login-background-full-res,
.login-background-low-res {
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-scroll-container {
  .box-shadow(0 2px 12px 0 rgba(0,0,0,0.32));
  .fit-parent();
  position: relative;
  background: @white;
  top: calc(100% - 3 * @login-screen-xm-spacing - @login-logo-height - @go-to-login-button-height);

  .screen-sm-min({
    top: calc(100% - 3 * @login-screen-sm-min-spacing - @login-logo-height - @go-to-login-button-height);
  });

  .screen-md-landscape-min({
    top: 0;
    padding: 0;
    width: 40%;
    //we don't want this going smaller than the max logo width
    min-width: 440px;
    height: 100%;
  });

  // Return from password reset
  &.login-no-animation {
    top: 0;

    #go-to-login {
      display: none;
    }

    .login-form-region {
      display: block;
    }
  }
}

.login-form-container {
  .flex-column-center-horizontally-mixin();
  .box-sizing(border-box);
  .fit-parent();
  padding: @login-screen-xm-spacing;

  .screen-sm-min({
    padding: @login-screen-sm-min-spacing;
  });

  .screen-md-portrait-min({
    padding: @login-screen-sm-min-spacing 3.2rem;
  });

  .screen-md-landscape-min({
    padding: 0;
  });

  #go-to-login {
    font-size: 1.8rem;
    font-weight: @ax-font-weight-medium;
    .flex(0 0 auto);
    margin: @login-screen-xm-spacing 0;
    padding: 0;
    height: @go-to-login-button-height;

    width: 100%;

    .screen-sm-min({
      margin: @login-screen-sm-min-spacing 0;
    });

    .screen-md-landscape-min({
      display: none;
    });
  }


}

.login-form-region {
  padding: 1.6rem;
  width: 100%;
  display: none;
  box-sizing: border-box;

  .screen-sm-min({
    padding: 4.8rem 1.6rem 1.6rem 1.6rem;
  });

  .screen-md-portrait-min({
    padding: 9.6rem 3.2rem 3.2rem 3.2rem;
  });

  .screen-md-landscape-min({
    max-width: 440px;
    padding: 3.2rem;
    display: block;
  });
}

.login-terms-of-service-region-mobile,
.login-terms-of-service-region-desktop {
  background-color: @white;
  background-color: rgba(255,255,255,0.8);

  .screen-md-landscape-min({
    background-color: rgba(255,255,255,0.65);
  });
}

.login-terms-of-service-region-mobile {
  .border-radius(@ax-border-radius-m);
  margin-top: @ax-spacing-l;
  text-align: center;
  max-width: 100%;
  box-sizing: border-box;
  font-size: @ax-font-size-xs;
  line-height: @ax-line-height-xs;
  padding-bottom: @ax-spacing-l;

  .screen-md-landscape-min({
    display: none;
  });

  .screen-xs({
    padding: @ax-spacing-m;
  });
}

.login-terms-of-service-region-desktop {
  display: none;
  font-size: @ax-font-size-xs;
  line-height: @ax-line-height-xs;

  .screen-md-landscape-min({
    display: block;
    padding: @ax-spacing-m @ax-spacing-l;
    width: 100%;
    position: relative;
  });
}

.login-logo {
  .flex-shrink(0);
  display: block;
  width: 200px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  min-width: 400px;
  margin: 0;

  .screen-md-landscape-min({
    margin: 64px 0;
  });
}

#header.desktop-hideable {
  display: none;
}
