
.scrollable {
  /* Prevent background color leak outs */
  -webkit-background-clip: padding-box;
  -moz-background-clip:    padding;
  background-clip:         padding-box;

  /* Good scrolling on windows phone*/
  -ms-touch-action: pan-y;
  -ms-scroll-chaining: none;
  -ms-overflow-style: auto;

  /* Good scrolling on apple*/
  &.overflow-touch {
    -webkit-overflow-scrolling: touch;
  }

  &.auto {
    overflow: auto;
  }

  &.vertical {
    overflow-x: hidden;
  }

  &.horizontal {
    overflow-y: hidden;
  }

  .scrollable-content-wrapper {
    .no-margin-collapse();
  }
}
