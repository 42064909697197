@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.media-gallery {
  white-space: nowrap;
  padding: 0.5em 0;

  .media-thumbnail-container {
    display: inline-block;
  }

  .media-thumbnail-container {
    display: inline-block;
    line-height: 0;
    vertical-align: top;
    margin: 0 0.5em;
  }
}

.media-thumbnail-container {
  display: inline-block;
  vertical-align: top;
  max-height: 100%;
  position: relative;
  .box-shadow-black(0px, 0px, 10px);

  .input-container {
    opacity: 0;
    width: 0;
    height: 0px;
    overflow: hidden;
  }

  .remove {
    position: absolute;
    display: block;
    top: 0px;
    right: 0px;
    height: 2.5rem;
    width: 2.5rem;
    z-index: 900;
    cursor: pointer;
    background: url('@common/assets/images/modal-close.png') center center no-repeat;
    background-color: @accent-grey;

    .retina({
      background-image:url('@common/assets/images/modal-close@2x.png');
      background-size: 25px;
    });
  }
}
