@ax-tabs-lineHeight:           @ax-line-height-s;
@ax-tabs-color:                @ax-color-grey-70;
@ax-tabs-color-selected:       @ax-color-grey-80;
@ax-tabs-fontSize:             @ax-font-size-s;
@ax-tabs-divider-height:       0.2rem;
@ax-tabs-divider-color:        @ax-color-grey-20;
@ax-tabs-divider-borderBottom: @ax-tabs-divider-height solid @ax-tabs-divider-color;
@ax-tabs-height:               4rem;
@ax-tabs-padding:              @ax-spacing-s @ax-spacing-m;
@ax-tabs-background-hover:     rgba(0, 0, 0, @ax-opacity-s);
@ax-tabs-fontWeight:           @ax-font-weight-regular;

:root {
  --ax-tabs-lineHeight:           @ax-tabs-lineHeight;
  --ax-tabs-color:                @ax-tabs-color;
  --ax-tabs-color-selected:       @ax-tabs-color-selected;
  --ax-tabs-divider-height:       @ax-tabs-divider-height;
  --ax-tabs-divider-color:        @ax-tabs-divider-color;
  --ax-tabs-fontSize:             @ax-tabs-fontSize;
  --ax-tabs-divider-borderBottom: @ax-tabs-divider-borderBottom;
  --ax-tabs-height:               @ax-tabs-height;
  --ax-tabs-padding:              @ax-tabs-padding;
  --ax-tabs-background-hover:     @ax-tabs-background-hover;
  --ax-tabs-fontWeight:           @ax-tabs-fontWeight;
}

.ax-tabs {
  .display-flex();
  .flex-wrap(nowrap);
  padding:       0;
  list-style:    none;
  border-left:   0;
  border-right:  0;
  font-weight:   @ax-tabs-fontWeight;
  overflow-x:    auto;
  overflow-y:    hidden;
  border-bottom: @ax-tabs-divider-borderBottom;

  &.insidepad {
    margin-left:  -1.5em;
    margin-right: -1.5em;
  }

  .tab {
    font-size:     @ax-tabs-fontSize;
    line-height:   @ax-tabs-lineHeight;
    height:        @ax-tabs-height;
    flex:          0 0 auto;
    border-bottom: @ax-tabs-divider-height solid transparent;

    &.selected {
      border-bottom-color: var(--ax-tabs-borderColor-selected);
    }

    // prevents mobile browsers rendering a tap as a hover
    @media (hover: hover) {
      &:hover {
        background: @ax-tabs-background-hover;
      }
    }
  }

  .focus-visible,
  .focus-visible:focus {
    .ax-focus-keyboard(inset);  
    outline: none;
  }

  .tab a {
    display: inline-block;
    border:  0;
    padding: @ax-tabs-padding;
    margin:  0;
    color:   @ax-tabs-color;
    .transition(ease-in-out 0.2s);
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;
    text-align: center;

    &.selected,
    &:hover,
    &:focus-visible {
      color: @ax-tabs-color-selected;
    }
  }

  // TO-DO: evaluate if we still need this set of styles
  li {
    a {
      &:hover,
      &:focus {
        color: @ax-tabs-color-selected;
      }
    }

    &.selected {
      a {
        color: @ax-tabs-color-selected;

        &:hover,
        &:focus {
          text-decoration: none;
          color:           @ax-tabs-color-selected;
        }
      }
    }
  }

  /*Firefox Fix for subtab height*/
  @-moz-document url-prefix() {
    .subtabs .tab {
      line-height: 5.3rem;
    }
  }
}