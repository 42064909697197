// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//   FORM ELEMENTS USAGE
//  - States - Supports checked, unchecked, disabled, and checked/disabled states
//  - Branding - Uses 'Link Color' settings by default

//   CHECKBOX EXAMPLE
//   <div class="ax-checkbox">
//     <input type="checkbox" id="checkbox-1">
//     <label for="checkbox-1">Checkbox demo 1</label>
//   </div>

//   RADIO EXAMPLE
//   <div class="ax-radio">
//     <input type="radio" id="radio-1" name="radio-demo">
//     <label for="radio-1">Radio demo 1</label>
//   </div>

//   TOGGLE EXAMPLE
//   <div class="ax-toggle">
//     <input type="checkbox" id="toggle-1">
//     <label for="toggle-1">Toggle demo 1</label>
//   </div>

//  MODIFIERS
//    - .ax-[checkbox/radio/toggle]--inline - List elements horizontally instead of vertically (default)
//    - .ax-[checkbox/radio/toggle]--no-label - Remove spacing between element and associated label
//    - .ax-checkbox--partial - Replace checkbox with a horizontal line (as per UI Kit)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// TODO: migrate elements using @_color-default to use branding --ax-link-color
@_color-default         : @ax-color-blue-60; // Branded styles in axFormCommon.html
@_color-disabled-border : @ax-color-grey-30;
@_color-disabled        : @ax-color-grey-10;
@_color-disabled-checked: @ax-color-blue-40;
@_color-unchecked       : @ax-color-grey-50;
@_color-thumb           : @ax-color-white;
@_opacity-disabled      : 0.6;
@_opacity-enabled       : 1;
@_size-radio-inner      : 1rem;
@_size-switch-inner     : @_size - (@_spacing-switch-inner * 2);
@_size                  : 2rem;
@_spacing-switch-inner  : 0.2rem;
@_transition-speed      : 0.3s;
@_width-border          : 0.1rem;
@_width-switch          : 3.8rem;
@_switch-base-padding   : @ax-spacing-xs + @ax-border-radius-l;

:root {
  --ax-form-element-border-color-disabled    : var(--ax-color-grey-30);
  --ax-form-element-background-color-disabled: var(--ax-color-grey-20);
  --axToggle-color-default                   : @_color-default;
  --axToggle-color-disabled-border           : @_color-disabled-border;
  --axToggle-color-disabled                  : @_color-disabled;
  --axToggle-color-disabled-checked          : @_color-disabled-checked;
  --axToggle-color-unchecked                 : @_color-unchecked;
  --axToggle-color-thumb                     : @_color-thumb;
  --axToggle-opacity-disabled                : @_opacity-disabled;
  --axToggle-opacity-enabled                 : @_opacity-enabled;
  --axToggle-size-switch-inner               : @_size-switch-inner;
  --axElement-size                           : @_size;
  --axToggle-spacing-switch-inner            : @_spacing-switch-inner;
  --axToggle-transition-speed                : @_transition-speed;
  --axToggle-width-border                    : @_width-border;
  --axToggle-width-switch                    : @_width-switch;
  --axToggle-switch-base-padding             : @_switch-base-padding;
  --axToggle-switch-radio-inner              : @_size-radio-inner;
  --ax-radio-icon--branded                   : var(--ax-link-color);
  --ax-radio-icon-fill--checked              : var(--ax-color-blue-60);
  --ax-radio-icon-fill--checked-branded      : var(--ax-radio-icon--branded);
  --ax-radio-icon-fill--checked-disabled     : var(--ax-color-grey-50);
  --ax-radio-icon-fill--unchecked            : var(--ax-color-white);
  --ax-radio-icon-fill--unchecked-disabled   : var(--ax-form-element-background-color-disabled);
  --ax-radio-icon-stroke--checked            : var(--ax-color-blue-60);
  --ax-radio-icon-stroke--checked-branded    : var(--ax-radio-icon--branded);
  --ax-radio-icon-stroke--checked-disabled   : var(--ax-color-grey-30);
  --ax-radio-icon-stroke--unchecked          : var(--ax-color-blue-60);
  --ax-radio-icon-stroke--unchecked-branded  : var(--ax-radio-icon--branded);
  --ax-radio-icon-stroke--unchecked-disabled : var(--ax-form-element-border-color-disabled );
}

.ax-radio,
.ax-checkbox,
.ax-toggle {
  position: relative;

  input[type="checkbox"],
  input[type="radio"] {
    .sr-only();
    margin: 0;

    &:disabled {
      + label {
        color       : var(--ax-color-grey-50);
        border-color: var(--ax-form-element-border-color-disabled);
        cursor      : not-allowed;

        &:before {
          border-color    : var(--ax-form-element-border-color-disabled);
          background-color: var(--ax-form-element-background-color-disabled);
        }
      }
    }

    &.focus-visible:focus + label:before {
      .ax-focus-mouse();
    }
  }

  &:not(:last-child) label {
    margin-bottom: @ax-spacing-m;
  }

  &&--no-label label:before {
    margin-right: 0;
  }

  &&--inline {
    display: inline-block;

    &:not(:last-child) {
      margin-right: @ax-spacing-m;
    }

    label {
      margin-bottom: 0;
    }
  }

  label {
    .align-items(flex-start);
    .display-flex();
    .justify-content(flex-start);
    cursor: pointer;
    font-size: var(--ax-font-size-s);
    font-weight: @ax-font-weight-regular;
    margin: 0;
    line-height: @_size;
    position: relative;

    &:before,
    &:after {
      .flex-shrink(0);
      pointer-events: none;
    }

    &:before {
      .box-sizing(border-box);
      .display-flex();
      .transition(all @ax-transition-speed ease);
      .transition-property(~"background-color, opacity");
      background-color: var(--ax-color-white);
      border: solid @_width-border var(--ax-radio-icon-stroke--unchecked-branded);
      content: ' ';
      margin-right: @ax-spacing-s;
      // Exclude border from final size
      .size(@_size);
    }

    &:after {
      .box-sizing(content-box);
      .display-flex();
      .transform(scale(0));
      .transition(transform @_transition-speed ease);
      border-bottom-color: @white;
      border-left-color: @white;
      content: ' ';
      position: absolute;
    }
  }
}

// - - - - - - - - - - - - - - - - -
//   RADIO
// - - - - - - - - - - - - - - - - -
.ax-radio {
  label {
    &:before,
    &:after {
      border-radius: 50%;
    }

    &:after {
      .size(@_size-radio-inner);
      .transition(transform @_transition-speed ease);
      background-color: @_color-unchecked;
      // Position in center of circle
      left: @_size-radio-inner / 2;
      top: @_size-radio-inner / 2;
    }
  }

  input[type="radio"]:checked {
    + label {
      &:before {
        border-color: var(--ax-radio-icon-stroke--checked-branded);
      }

      &:after {
        .transform(scale(1));
        background-color: var(--ax-radio-icon-fill--checked-branded);
      }
    }

    &:disabled + label {
      // Checked and disabled
      &:before {
        border-color    : var(--ax-form-element-border-color-disabled);
      }

      &:after {
        .transform(scale(1));
        background-color: var(--ax-radio-icon-fill--checked-disabled);
      }
    }
  }
}

// - - - - - - - - - - - - - - - - -
//   RADIO - REACT (it uses SVG icons)
// - - - - - - - - - - - - - - - - -
.ax-radio_icon--unchecked {
  fill  : var(--ax-radio-icon-fill--unchecked);
  stroke: var(--ax-radio-icon-stroke--unchecked);
}

.ax-radio_icon--unchecked-disabled {
  fill  : var(--ax-radio-icon-fill--unchecked-disabled);
  stroke: var(--ax-radio-icon-stroke--unchecked-disabled);
}

.ax-radio_icon--unchecked-branded {
  fill  : var(--ax-radio-icon-fill--unchecked);
  stroke: var(--ax-radio-icon-stroke--unchecked-branded);
}

.ax-radio_icon--checked {
  .outer-ring {
    stroke: var(--ax-radio-icon-stroke--checked);
  }

  .fill {
    fill: var(--ax-radio-icon-fill--checked);
  }
}

.ax-radio_icon--checked-branded {
  .outer-ring {
    stroke: var(--ax-radio-icon-stroke--checked-branded);
  }

  .fill {
    fill: var(--ax-radio-icon-fill--checked-branded);
  }
}

.ax-radio_icon--checked-disabled {
  .outer-ring {
    stroke: var(--ax-radio-icon-stroke--checked-disabled);
  }

  .fill {
    fill: var(--ax-radio-icon-fill--checked-disabled);
  }
}

// - - - - - - - - - - - - - - - - -
//   CHECKBOX
// - - - - - - - - - - - - - - - - -
.ax-checkbox {
  label {
    &:before {
      .border-radius(@ax-border-radius-m);
    }

    &:after {
      .transform(rotate(-45deg) scale(0));
      .transition(transform @_transition-speed ease);
      background: none;
      height: .5rem;
      left: .2rem;
      top: .4rem;
      transform-origin: center center;
      width: 1.2rem;
    }
  }

  input[type="checkbox"]:checked {
    + label {
      &:before {
        background-color: @_color-default;
        border-color: transparent;
      }

      &:after {
        .all-dir(.transform(rotate(-45deg) scale(.7)));
        border-bottom: solid .4rem @white;
        border-left: solid .4rem @white/*rtl:ignore*/;
        // Pulls the rule from label &:after here since elements inside a MUI box compute box-sizing: border-box
        .box-sizing(content-box);
      }
    }

    &:disabled + label:before {
      // Checked and disabled
      opacity: @_opacity-disabled;
    }
  }

  &&--partial input[type="checkbox"] + label {
    &:before {
      border-color: transparent;
    }

    &:after {
      .transform(rotate(0) scale(.7));
      border-left-width: 0;
      border-bottom: solid .4rem @white;
      width: 1.6rem;
    }
  }
}

// - - - - - - - - - - - - - - - - -
//   TOGGLE
// - - - - - - - - - - - - - - - - -
.ax-toggle {
  width: fit-content;
  label {
    &:before {
      .border-radius(10rem);
      background-color: @_color-unchecked;
      border: none;
      width: @_width-switch;
    }

    &:after {
      .border-radius(50%);
      .size(@_size-switch-inner);
      .transform(scale(1));
      .transition(left @_transition-speed ease);
      background-color: @white;
      left: @_spacing-switch-inner;
      top: @_spacing-switch-inner;
    }
  }

  input[type=checkbox] {
    &:checked {
      + label {
        &:before {
          background-color: @_color-default;
        }

        &:after {
          left: calc(@_size - (@_spacing-switch-inner / 2));
        }
      }

      &:disabled + label:before {
        // Checked and disabled
        background-color: @_color-default;
        opacity: @_opacity-disabled;
      }
    }

    &:disabled + label:before {
      // Toggles use different disabled color than checkbox/radio
      background-color: @_color-disabled-border;
    }
  }
}
