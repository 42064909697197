@spacing-regular: 1.2rem;
@spacing-smaller: 1rem;

@height-regular: 4rem;
@width-regular: 4rem;

@border-color-containers: rgba(0,0,0,.16);

.h1title { // used in some modals
  font-size: @ax-font-size-s;
  font-weight: @ax-font-weight-medium;
  line-height: 3.5rem;
  .text-ellipsis();

  .screen-md-portrait-min({
    line-height: 4rem;
  });
}

.page-header {
  .align-items(center);
  background-color: @primary-bg;
  border: 0;
  .clearfix();
  .display-flex();
  margin: 0;
  padding: 0;

  &--fixed {
    border-bottom: 1px solid @border-color-containers;
  }

  .page-header { // If for whatever reason we have nested headers remove padding
    .page-header__content{
      padding: 0;
    }
  }
}

.page-header__icon,
.page-header__icon.game-type-icon { // workaround to overcome specificity issue for now
  .align-items(center);
  .border-radius(@ax-border-radius-m);
  .display-flex();
  .flex-shrink(0);
  .justify-content(center);
  margin-right: @spacing-regular;
  min-height: @height-regular;
  min-width: @width-regular;
  height: 0!important; // IE fix
  width: 0!important;
}

.page-header__icon {
  background-color: @training-color;
  color: @white;
  font-size: 2.5rem;
  margin-right: @spacing-smaller;
  min-height: @height-regular;
  min-width: @width-regular;
  overflow: hidden;

  &--searching {
    margin-right: 0.5rem;
  }

  &--custom-icon {
    background-color: @white;

    img {
      .border-radius(@ax-border-radius-m);
      height: 4rem;
      margin: .4rem;
      width: 4rem;
    }
  }

  &.icon-non_participants,
  &.icon-overconfident,
  &.icon-underconfident {
    color: @white !important; // unfortunately we use important for these icon colors
  }
}

.page-header .page-header__icon.game-type-icon {
  background-color: @white;
}

.page-header__title,
.page-header__kicker {
  color: @ax-color-text;
  font-weight: @ax-font-weight-regular;
}

.page-header__title,
h1.page-header__title {
  .align-items(center);
  .display-flex();
  .flex-grow(1);
  font-size: 1.8rem;
  line-height: normal;
  margin: 0 @ax-spacing-l 0 0;
  overflow: hidden;
  padding: 0;
  font-weight: @ax-font-weight-regular;

  .screen-md-portrait-min({
    font-size: 2.0rem;
  });

  .inner-heading {
    font-size: 1.8rem;
    margin: 0;

    .screen-md-portrait-min({
       font-size: 2.0rem;
    });
  }
  
  &--inline-block {
    display: inline-block;
  }
}

a.page-header__title {
  .text-ellipsis;
  color: @primary-blue;
  text-decoration: underline;
  display: block;
}

.page-header__kicker {
  font-size: 1.4rem;
  &&--uppercase {
    text-transform: uppercase;
  }
}

.page-header__how-to {
  .button-reset-style();
  font-size: 1.4rem;
  margin-left: @spacing-regular;

  .screen-md-portrait-max({
    margin-left: @spacing-smaller;
  });

  > div {
    .align-items(center);
    .display-flex();
  }

  .icon {
    background: none;
    color: @primary-blue;
    font-size: 3rem;
    margin: 0;

    .screen-md-portrait-max({
      font-size: 2.8rem;
    });
  }

  &.focus-visible:focus {
    box-shadow: inset 0 0 .2rem .2rem @primary-blue;
  }
}

.page-header__how-to-tip-label {
  margin-left: 0.2rem;
}

.page-header__action-container {
  .align-items(center);
  background-color: @white;
  border: 1px solid @border-color-containers;
  .border-radius(@ax-border-radius-m);
  color: @ax-color-text;
  .display-flex();
  height: 0!important; // IE fix
  .justify-content(center);
  min-width: @width-regular;
  min-height: @height-regular;
  padding: 0 1rem;

  &:hover,
  &:focus {
    background-color: @secondary-bg;
  }
}

.page-header__input {
  .box-sizing(border-box);
  background: @white;
  border: 1px solid @border-color-containers;
  .border-radius(@ax-border-radius-m);
  box-shadow: none !important;
  color: @ax-color-text;
  font-size: 2rem !important; // base app css uses !important
  font-weight: @ax-font-weight-regular;
  min-height: @height-regular;
  padding: 0 1.4rem;
  text-indent: 0;
  -webkit-appearance: none;
  width: 100%;

  &:hover, &:focus {
    border-color: @border-color-containers; // to overwrite default input styling
  }

  &--searching {
    font-size: 1.5rem !important;
    text-indent: 2.7rem;
  }
}

.page-header__content {
  .display-flex();
  .align-items(center);
  .justify-content(space-between);
  padding: @ax-gutter-page;

  & + .page-header__content {
    padding-top: 0;
  }

  &--justify-start {
    .justify-content(flex-start);
  }

  &--relative-md {
    .screen-md-landscape-min({
      position: relative;
    });
  }
}

.how-to-link {
  .ax-button__label,
  .text {
    display: none;

    .ax-breakpoint-m({
    display: inline-block;
    })
  }

  [class^="icon-"] {
    padding-right: 0;

    .ax-breakpoint-m({
      padding-right: @ax-spacing-s;
    })
  }
}