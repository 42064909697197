.gameslist .game.featured {
  .button-reset-style();
  margin: @ax-spacing-m 0;
  position: relative;
  float: none;
  height: 20rem;
  width: 100%;

  .ax-breakpoint-m({
    height: 34.4rem;
    margin-top: 0;
    padding: 0;
  });
}

.gameslist .game.featured .featured-text {
  .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  color: @white;
  left: 0;
  position: absolute;
  right: 0;
  display: block;
  padding: 0.5em;

  .ax-breakpoint-m({
    padding: 1em;
    text-align: left;
  });
}

.gameslist .game.featured .featured-name {
  color: inherit;
  font-size: @ax-font-size-l;
  font-weight: @ax-font-weight-medium;
}

.gameslist .game.featured .featured-desc {
  display: block;

  .ax-breakpoint-m({
    font-size: @topic-font-size;
  });
}

.gameslist .game.featured .featured-type {
  .border-radius(@ax-border-radius-m);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;

  &.BallBob {
    background-image: url("@training/assets/images/banners/ball-bob.png");
  }

  &.BattleFish {
    background-image: url("@training/assets/images/banners/battle-fish.png");
    background-position: top;
  }

  &.BlockArt {
    background-image: url("@training/assets/images/banners/block-art.png");
  }

  &.Block {
    background-image: url("@training/assets/images/banners/block-rush.png");
  }

  &.Breaker {
    background-image: url("@training/assets/images/banners/breaker.png");
  }

  &.Bubble {
    background-image: url("@training/assets/images/banners/bubble-shooter.png");
  }

  &.Curling {
    background-image: url("@training/assets/images/banners/curling.png?v_2");
  }

  &.CurlingUpdate {
    background-image: url("@training/assets/images/banners/curlingUpdate.png?v_2");
  }

  &.Catcher {
    background-image: url("@training/assets/images/banners/candy-catcher.png");
  }

  &.Cheese {
    background-image: url("@training/assets/images/banners/cheese.png");
    background-position: bottom;
  }

  &.Collisions {
    background-image: url("@training/assets/images/banners/collisions.png?v_2");
  }

  &.AtomicAtoms {
    background-image: url("@training/assets/images/banners/extreme-atom.png");
  }

  &.FieldGoal {
    background-image: url("@training/assets/images/banners/field-goal.png");
  }

  &.Fishy {
    background-image: url("@training/assets/images/banners/fishy.png");
  }

  &.FoodSearch {
    background-image: url("@training/assets/images/banners/food-search.png");
  }

  &.GemJam {
    background-image: url("@training/assets/images/banners/gem-jam.png");
  }

  &.Golf {
    background-image: url("@training/assets/images/banners/hole-in-one.png?v_2");
  }

  &.Head2Head {
    background-image: url("@training/assets/images/banners/head-to-head.png");
  }

  &.HoleInOne {
    background-image: url("@training/assets/images/banners/hole-in-one.png?v_2");
  }

  &.Invasion {
    background-image: url("@training/assets/images/banners/invasion.png");
    background-position: bottom;
  }

  &.Jigsaw {
    background-image: url("@training/assets/images/banners/jigsaw.png");
  }

  &.Curves {
    background-image: url("@training/assets/images/banners/learning-curves.png?v_3");
  }

  &.LightPuzzle {
    background-image: url("@training/assets/images/banners/light-puzzle.png");
  }

  &.LightsOut {
    background-image: url("@training/assets/images/banners/lights-out.png");
  }

  &.Memory {
    background-image: url("@training/assets/images/banners/memory.png?v_3");
  }

  &.Monkey {
    background-image: url("@training/assets/images/banners/monkey.png");
  }

  &.BumpOut {
    background-image: url("@training/assets/images/banners/bump-out.png");
  }

  &.Monster {
    background-image: url("@training/assets/images/banners/monster-kick-out.png");
  }

  &.Pinball {
    background-image: url("@training/assets/images/banners/pinball.png?v_2");
  }

  &.Pixel {
    background-image: url("@training/assets/images/banners/pixel.png");
  }

  &.Quiz {
    background-image: url("@training/assets/images/banners/quiz-show.png");
  }

  &.Shapes {
    background-image: url("@training/assets/images/banners/shuffle.png");
  }

  &.Shark {
    background-image: url("@training/assets/images/banners/shark-swim.png");
  }

  &.Simon {
    background-image: url("@training/assets/images/banners/simon.png");
  }

  &.Simon2 {
    background-image: url("@training/assets/images/banners/Simon2.png");
  }

  &.Snake {
    background-image: url("@training/assets/images/banners/snake.png");
  }

  &.SpaceRocks {
    background-image: url("@training/assets/images/banners/space-rocks.png");
  }

  &.Space {
    background-image: url("@training/assets/images/banners/space-attack.png");
  }

  &.BlackAway {
    background-image: url("@training/assets/images/banners/sponge-toss.png");
  }

  &.Sum {
    background-image: url("@training/assets/images/banners/sum-up.png");
  }

  &.Sushi {
    background-image: url("@training/assets/images/banners/sushi.png");
  }

  &.TeamRunner {
    background-image: url("@training/assets/images/banners/skyrun.png?v_2");
  }

  &.Convergence {
    background-image: url("@training/assets/images/banners/Convergence.png?_v1");
  }

  &.Summer {
    background-image: url("@training/assets/images/banners/Summer.png?_v1");
  }

  &.Elements {
    background-image: url("@training/assets/images/banners/elements.png?_v1");
  }

  &.TurtleHurtle {
    background-image: url("@training/assets/images/banners/TurtleHurtle.png?_v1");
  }

  &.Unicycle {
    background-image: url("@training/assets/images/banners/unicycle.png?_v1");
  }

  &.Soccer {
    background-image: url("@training/assets/images/banners/Kick-Off.png?_v1");
  }

  &.Uni {
    background-image: url("@training/assets/images/banners/unicycle.png");
  }

  &.Zombie {
    background-image: url("@training/assets/images/banners/zombie-taps.png");
    background-position: bottom;
  }
}