@padding-footer: @ax-spacing-s + @ax-spacing-xs;

footer {
  .box-shadow-black(0, 0, 2px, 0.2);
  .box-sizing(border-box);
  background: @primary-bg;
  bottom: 0;
  color: @ax-color-grey-70;
  font-size: 1.2rem;
  left: 0;
  margin:0 auto 0 0;
  position: absolute;
  text-align: left;
  width: 100%;
  line-height: @ax-line-height-xs;
  text-align: center;

  .footer__poweredby {
    padding-top: @padding-footer;
  }

  .poweredby__logo {
    background-image: url('@common/assets/images/axonify-logo.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 1.6rem;
    margin: 0 0 0 @ax-spacing-xs;
    width: 5rem !important; // Override column sizing
  }

  .footer__disclaimer {
    padding: @padding-footer;
    margin: 0 auto;
    max-width: @ax-page-width-l;
    text-align: center;
  }

  &.footer--no-disclaimer {
    .footer__poweredby {
      padding-bottom: @padding-footer;
    }

    .footer__disclaimer {
      display:none;
    }
  }

  .ax-breakpoint-m-max({
    .footer__poweredby,
    .footer__disclaimer {
      // Force hidden to ensure JS doesn't enable visibility for small devices
      display: none !important;
    }
  });
}
