@ax-icon-fontSize-s:  1.6rem;
@ax-icon-fontSize-m:  2.4rem;
@ax-icon-fontSize-l:  3.2rem;
@ax-icon-fontSize-xl: 4.0rem;

:root {
  --ax-icon-fontSize-s: @ax-icon-fontSize-s;
  --ax-icon-fontSize-m: @ax-icon-fontSize-m;
  --ax-icon-fontSize-l: @ax-icon-fontSize-l;
  --ax-icon-fontSize-xl: @ax-icon-fontSize-xl;
}

