@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.question-preview-modal {
  .modal-header.modal-header-details,
  .modal-footer.modal-footer-details {
    display: none;
  }
  &.initial-min-height {
    min-height: 250px;
  }

  .question-preview-container {
    .relative;
    height: 100%;
    z-index: 1;

    .close {
      border: none;
      right: @ax-spacing-xs;
      top: @ax-spacing-xs;
      margin: .6rem;
      z-index: 999;

      .ax-breakpoint-m({
        right: 0;
        top: 0;
      });
    }
  }

  .actionbar {
    z-index: 3;
    .relative;
    background: @white;
  }
}

/* This is needed so the close button is visible, we can't make the .questionmodal overflow: visible
cause it'll mess with the scrolling stuff*/
.questionanswermodal .close {
  background: url('@common/assets/images/modal-close.png') center center no-repeat;
  background-size: 25px;
  width: 25px;
  height: 25px;
  position: absolute;
  text-indent: -999em;
  cursor: pointer;
  z-index: 810;
  right: 5px;
  opacity: 1;

  &:hover,
  &:focus {
    opacity: 0.75;
  }

  &.focus-visible:focus {
    box-shadow: inset 0px 0px 2px 2px @primary-blue;
  }

  .retina({
    background-image: url('@common/assets/images/modal-close@2x.png');
    background-size: 25px;
  })
}


/*- modalview -*/
#modalview {
  .question-preview-content {
    padding-bottom: 0px;
  }
}

#modalview .question-preview-content-wrapper {
  width: 100%;
}

.questionanswermodal .contentwrapper .hinttext {
  min-height:33px;
}

.screen-sm-max({
  .question-preview-modal {
    &.initial-min-height {
      min-height: 100%;
    }
  }
});
