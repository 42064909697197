@ax-pagination-pageItem-color: @ax-color-grey-80;
@ax-pagination-pageItem-fontWeight: @ax-font-weight-bold;
@ax-pagination-pageItem-padding: @ax-spacing-xs @ax-spacing-m;

@ax-pagination-pageItem-selected-background: none;
@ax-pagination-pageItem-selected-border: 1px solid @ax-color-grey-40;

@ax-pagination-pageItem-icon-fontSize: @ax-icon-fontSize-m;
// mimicking the icon padding from MUI pagination button
@ax-pagination-pageItem-icon-padding: 0 6px;

// mimicking the height of MUI pagination button
@ax-pagination-pageItem-height-md: 32px;
@ax-pagination-pageItem-height-sm: 26px;

:root {
  --ax-pagination-pageItem-color: @ax-pagination-pageItem-color;
  --ax-pagination-pageItem-fontWeight: @ax-pagination-pageItem-fontWeight;
  --ax-pagination-pageItem-padding: @ax-pagination-pageItem-padding;
  
  --ax-pagination-pageItem-selected-background: @ax-pagination-pageItem-selected-background;
  --ax-pagination-pageItem-selected-border: @ax-pagination-pageItem-selected-border;

  --ax-pagination-pageItem-icon-fontSize: @ax-pagination-pageItem-icon-fontSize;
  --ax-pagination-pageItem-icon-padding: @ax-pagination-pageItem-icon-padding;

  --ax-pagination-pageItem-height-md: @ax-pagination-pageItem-height-md;
  --ax-pagination-pageItem-height-sm: @ax-pagination-pageItem-height-sm;
}