@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.completeScormMessage{
  height:0;
  overflow:hidden;
  text-align:center;
}

.scorm-list {
  padding: 0 @ax-spacing-xs;
  max-width: @ax-page-width-l;
  margin: 0 auto;

  .card {
    margin-bottom: @ax-spacing-m;

    .ax-grid {
      .align-items(center);

      p {
        margin: 0;
      }

      .scorm-status {
        color: @ax-color-grey-60;
      }
    }
  }
}
